import styled from '@emotion/styled'

import { IcCalendar } from '@/components/Icons'
import { convertISOToKoreanDate } from '@/utils/text'

import { classNameFromCategoryCode } from '../TeaTogetherCreatePage'

interface DateInfoType {
  startAt: string;
  endAt: string;
  categroy?: string;
}

export const EnrollmentDateInfo = ({ startAt, endAt }: DateInfoType) => {
  return (
    <DateInfoWrapper>
      <IcCalendar
        width={24}
        height={24} />
      <ContentWrapper>
        <Title>신청 기간</Title>
        <Time>{`${convertISOToKoreanDate(startAt)} ~ ${convertISOToKoreanDate(endAt)}`}</Time>
      </ContentWrapper>
    </DateInfoWrapper>
  )
}

export const TeaTogetherDateInfo = ({ startAt, endAt, categroy }: DateInfoType) => {
  return (
    <DateInfoWrapper>
      <IcCalendar
        width={24}
        height={24} />
      <ContentWrapper>
        { !categroy && <Title>모임 진행 시간</Title>}
        { categroy && <Title>{`${classNameFromCategoryCode(categroy)} 진행 시간`}</Title>}
        <Time>{`${convertISOToKoreanDate(startAt)} ~ ${convertISOToKoreanDate(endAt)}`}</Time>
      </ContentWrapper>
    </DateInfoWrapper>
  )
}

const DateInfoWrapper = styled.div`
width: 100%;
max-width: 800px; 
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
`
const Time = styled.div`
font-size: 16px;
color: ${({ theme }) => theme.black};
font-weight: 600;
`
const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`
