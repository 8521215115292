import { useMutation } from 'react-query'

import { postLike, PostLikeRequestType } from '@/apis/likes/postLike'

export default function usePostLike() {
  return useMutation<void, unknown, PostLikeRequestType>(
    async(postLikeRequestType: PostLikeRequestType) => {
      await postLike(postLikeRequestType)
    }
  )
}
