import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { TeaTogetherItemModel } from '@/apis/tea-together/getTeaTogethersForCity'
import { numberWithCommas } from '@/utils/numbers'

type TeaTogetherItemType = {
  teaTogether: TeaTogetherItemModel;
};

const TeaTogetherItem = ({ teaTogether }: TeaTogetherItemType) => {
  const navigate = useNavigate()

  return (
    <ItemWrapper
      backgroundImage={teaTogether.profileImage}
      onClick={() => navigate(`/tea-together/${teaTogether.id}`)}
    >
      <Overlay>
        <Title>{teaTogether.title}</Title>
        <UsernameText>{teaTogether.makerName}</UsernameText>
        <DescriptionText>{teaTogether.summary}</DescriptionText>
        <PriceText>{numberWithCommas(teaTogether.participationFee)}</PriceText>
      </Overlay>
    </ItemWrapper>
  )
}

export default TeaTogetherItem

const HoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.03); /* 호버 시 확대 효과 */
  }
`

const ItemWrapper = styled.div<{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 400px;
  height: 500px;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden; /* 배경 이미지가 border-radius 내에 있도록 함 */
  transition: box-shadow 0.3s ease; /* 효과를 부드럽게 하기 위한 트랜지션 */
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* 호버 시 그림자 효과 */
  }
  ${HoverEffect}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)); /* 그라데이션 추가 */
    z-index: 1; /* 이미지 위에 오버레이가 있도록 설정 */
  }
`

const Overlay = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%; /* Ensures the overlay covers the entire item */
`

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.white};
  font-size: 18px; /* 제목 글꼴 크기 조정 */
`

const DescriptionText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px; /* 설명 글꼴 크기 조정 */
`

export const UsernameText = styled.div`
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
`

const PriceText = styled.div`
  display: flex;
  font-weight: 700;
  justify-content: flex-end;
  color: ${({ theme }) => theme.white};
  font-size: 16px; /* 가격 글꼴 크기 조정 */
`
