import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Image from '../Common/Image'

type LogoProps = {
  width: string;
  height: string;
}

function LogoWhite({ width, height }: LogoProps) {
  return (
    <>
      <Link to="/">
        <LogoImage
          alt="daru logo"
          src='/images/logo-white.png'
          width={width}
          height={height}
        />
      </Link>
    </>
  )
}

export default LogoWhite

const LogoImage = styled(Image)`
`
