import styled from '@emotion/styled'
import { ChangeEvent } from 'react'

type TextAreaType = {
  value: string;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  cols?: number;
  onChange?: (value: string) => void;
}

function TextArea({
  value,
  placeholder,
  maxLength,
  rows = 5,
  cols,
  onChange
}: TextAreaType) {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange){
      return
    }
    onChange(value)
  }
  return (
    <TextAreaBlock
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      onChange={handleChange}
    />
  )
}

export default TextArea

const TextAreaBlock = styled.textarea`
  border: 1px solid ${({ theme }) => theme.gray4};
  background: ${({ theme }) => theme.gray5};
  color: ${({ theme }) => theme.black};
  font-size: 12px;
  border-radius: 14px;

  padding: 18px 16px;
  width: 100%;
`