import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { H1, H2, PageBlock, PageBlockCenter, PageImage } from '@/components/Common/Styled'
import { notify } from '@/components/Common/Toast'
import CheckBox from '@/components/Input/CheckBox'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import Splash from '@/components/Layout/Splash'
import useCancelReservation from '@/hooks/useCancelReservation'
import useReservationInfo from '@/hooks/useReservationInfo'
import useValidateCancelReservation from '@/hooks/useValidateCancelReservation'
import { isError } from '@/libs/request'
import { 클래스예약_위약금_정책 } from '@/meta'
import { Error } from '@/pages/Error'
import { ROUTER } from '@/router'
import { numberWithCommas } from '@/utils/numbers'
import { dateTextForPeriod } from '@/utils/text'

const { CLASS } = ROUTER

/*
취소 CASE
1. 잘못된 접근
2. 주최자 또는 참여자에 의해 취소된 경우
3. 취소조건(기간)에 해당되는 않는 경우
(5. 정상 취소 폼)
*/

enum ErrorCode {
  잘못된_요청 = 'BAD_REQUEST',
  이미_취소된_예약 = 'ALREADY_CANCELED',
  취소_기간_아님 = 'INVALID_PERIOD'
}

function CancelPage() {
  const { classId, phone } = useParams()
  if (!classId || !phone){
    return <Error />
  }

  return (
    <CancelContent
      classId={classId}
      applicantPhoneNumber={phone} />
  )
}

function CancelContent({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  /* TODO: API 요청이 2번 발생함. 확인필요 */
  const { data: validated, error: validatedError, isLoading } = useValidateCancelReservation({ classId, applicantPhoneNumber })

  /* TODO: 별도컴포넌트 분리 */
  const errorElement = useMemo(() => {
    if (isLoading) {
      return (
        <Splash />
      )
    }

    /* TODO: 조건 확인 */
    if ((validated && validated.validated && !!validated.applicantPhoneNumber) || !validatedError) {
      return (
        null
      )
    }

    if (!isError(validatedError)){
      return <Error />
    }

    if (validatedError.response?.status !== 400){
      return <Error />
    }

    if (validatedError.response.data.code === ErrorCode.잘못된_요청){
      return (
        <>
          <PageImage
            alt=""
            src='/images/alert.png'
            width={70}
            height={70}
          />
          <H2>잘못된 접근입니다.</H2>
        </>
      )

    }
    if (validatedError.response.data.code === ErrorCode.이미_취소된_예약){
      return (
        <>
          <PageImage
            alt=""
            src='/images/calendar.png'
            width={70}
            height={70}
          />
          <H2>{'주최자의 요청 혹은 참여자의 \n요청에 따라 예약이 취소되었습니다.'}</H2>
        </>
      )
    }
    if (validatedError.response.data.code === ErrorCode.취소_기간_아님){
      return (
        <>
          <PageImage
            alt=""
            src='/images/alert.png'
            width={70}
            height={70}
          />
          <H2>예약을 취소할 수 없습니다.</H2>
          <Title>취소시 위약금 정책</Title>
          <InfoList>
            <InfoListItem>시작 48시간전에 취소하면  100%</InfoListItem>
            <InfoListItem>시작 48시간전 ~ 24시간전 취소시 80% 환급</InfoListItem>
            <InfoListItem>시작 24시간전 ~ 12시간 전 취소시 50% 환급</InfoListItem>
            <InfoListItem>12시간전 부터는 취소 불가</InfoListItem>
          </InfoList>
        </>
      )
    }
    return (
      <Error />
    )
  }, [validated, isLoading, validatedError])

  if (errorElement){
    return (
      <Layout>
        <Header />
        <Content>
          <PageBlockCenter>
            {errorElement}
          </PageBlockCenter>
        </Content>
        <Link
          to='/'
          replace>
          <CTAButton
            disabled={false}
            label="메인으로 가기"
          />
        </Link>
      </Layout>
    )
  }

  if (!validated) {
    return (
      null
    )
  }

  return (
    <CancelOverview
      classId={classId}
      applicantPhoneNumber={validated.applicantPhoneNumber}
    />
  )
}

const Title = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.gray4};
  margin-top: 48px;
`

const InfoList = styled.ul`
  margin-top: 48px;
  padding: 60px 0;
  border-radius: 14px;
  background: ${({ theme }) => theme.white};  
  color: ${({ theme }) => theme.gray3};
  text-align: center;

  font-size: 12px;
  font-weight: 500;
`
const InfoListItem = styled.li`
  line-height: 18px;
  /* &:last-of-type{
    margin-bottom: 0;
  } */
`

function CancelOverview({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  const navigate = useNavigate()

  const { data: reservationInfo, isError: isReservationInfo } = useReservationInfo({ classId, applicantPhoneNumber })
  const { mutate: cancelReservation, isLoading } = useCancelReservation()

  const [check, setCheck] = useState<boolean>(false)

  const buttonDisabled = useMemo(() => {
    return !check
  }, [check])

  const handleCancelReservationClick = () => {
    if (!check){
      return notify('위약금 정책을 확인 해 주세요.')
    }

    cancelReservation({
      classId,
      applicantName,
      applicantPhoneNumber
    }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notify(message)
        }
      },
      onSuccess: () => {
        navigate(`${CLASS}/${classId}/reservation/cancel/complete`, { replace: true })
      }
    })
  }

  if (isReservationInfo){
    return <Error />
  }
  if (!reservationInfo){
    return null
  }

  const { cancelFee, classMainName, applicantName, classProgressDate, requiredClassFee, requestPersonnel } = reservationInfo

  return (
    <>
      {isLoading && <FullLoading />}

      <Layout>
        <Content>
          <PageBlock>
            <H1>예약취소</H1>

            <H2>클래스명</H2>
            <p>{classMainName}</p>

            <H2>일시</H2>
            <Penalty>
              {classProgressDate.map(({ classRoundStartDate, classRoundEndDate }, index) => {
                return (
                  <li key={index}>
                    <p key={index}>{index + 1}회차: {dateTextForPeriod(classRoundStartDate, classRoundEndDate)}</p>
                  </li>
                )
              })}
            </Penalty>

            <H2>신청자 이름(대표자)</H2>
            <p>{applicantName}</p>

            <H2>예약인원</H2>
            <p>{requestPersonnel}</p>

            <H2>취소시 위약금이 발생할 수 있어요.</H2>
            <Penalty>
              {클래스예약_위약금_정책.map((text, index) => {
                return (
                  <li key={index}>
                    <p>{text}</p>
                  </li>
                )
              })}
            </Penalty>
            <H2>예약금액</H2>
            <p>{numberWithCommas(requiredClassFee * requestPersonnel)}</p>

            <H2>취소수수료</H2>
            <p>{numberWithCommas(cancelFee)}</p>

            <Price>
              <span>예상 환급 금액</span>
              <strong>{numberWithCommas((requiredClassFee * requestPersonnel) - cancelFee)}</strong>
            </Price>

            <CheckBox
              name="check"
              label="위약금 정책을 확인하였습니다."
              checked={check}
              onChange={({ checked }) => {
                setCheck(checked)
              }}
            />
          </PageBlock>
        </Content>

        <CTAButton
          label='예약 취소 하기'
          disabled={buttonDisabled}
          onClick={handleCancelReservationClick}
        />
      </Layout>
    </>
  )
}

export default CancelPage

const Price = styled.div`
  border-radius: 14px;
  background: ${({ theme }) => theme.information};

  font-size: 15px;
  font-weight: 500;

  height: 71px;
  padding: 18px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  > strong{
    font-weight: bold;
  }
  margin-top: 46px;
  margin-bottom: 26px;
`

const Penalty = styled.ul`
  color: ${({ theme }) => theme.gray3};
  font-size: 12px;
  > li{
    line-height: 16px;
  }
`
