import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'

import { IcSearch, IcShoppingCart } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Logo from '@/components/Layout/LogoCustom'

import MainTabSlider from '../tea/component/MainSlider'
import ShoppingItem from './component/ShoppingItem'

const ShoppingMainPage = () => {
  //Slider
  const tabs = ['차 도구', 'Tab 2', 'Tab 3']
  const content = [
    <Page1 key={1} />,
    <Page2 key={2} />,
    <Page3 key={3} />
  ]
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <PageWrapperCentered>
      {/* Header */}
      <HeaderWrapper>
        <Logo
          width='58px'
          height='35px' />
        <NavWrapper>
          <IcSearch />
          <IcShoppingCart />
        </NavWrapper>
      </HeaderWrapper>
      <SliderButtonWrapper>
        {tabs.map((tab, index) => {
          return (
            <SliderButton
              key={index}
              onClick={() => setCurrentIndex(index)}
              currentIndex={index == currentIndex}> {tab} </SliderButton>
          )
        })}
      </SliderButtonWrapper>
      <MainTabSlider
        tabs={tabs}
        content={content}
        currentIndex={currentIndex}
        onSlideChange={(index: number) => setCurrentIndex(index)} />
      { /* 페스티벌 안내(CMS API 필요) */}
    </PageWrapperCentered>
  )
}
export default ShoppingMainPage

const Page1 = () => {
  const images = ['https://img.freepik.com/free-photo/tea-with-delights-colorful-surface_114579-61553.jpg?w=360', 'https://cdn.pixabay.com/photo/2016/11/29/13/05/breakfast-1869717_640.jpg']
  const images1 = ['https://m.theneighbor.co.kr/upload/Editor/Editor_202010158584_268722.jpg', 'https://mono.aks.ac.kr/s/media/8c/8c85c620-84b3-42ce-840d-710b73598e08.jpg?preset=page']
  const images2 = ['https://modo-phinf.pstatic.net/20220227_248/1645943957652QyzRx_JPEG/mosaMkaDhP.jpeg?type=w1100', 'https://contents.sixshop.com/thumbnails/uploadedFiles/122849/product/image_1678506167071_1500.jpg', 'https://i.pinimg.com/736x/03/9e/d4/039ed4b3533f14fbbe189a2fcc3ad214.jpg']
  return (
    <PageWrapperCentered>
      <Title>🔥 요즘 핫한</Title>
      <ItemListWrapper>
        <ShoppingItem
          title='[2022] 우롱차'
          shopName='티하우스'
          description='무이산에서 가져온 차에요'
          originalPrice={30000}
          discountedPrice={24000}
          discountRate={20}
          images={images} />
        <ShoppingItem
          title='하동 우전녹차'
          shopName='너의티타임'
          description='하동의 자연을 느껴봐요.'
          originalPrice={20000}
          discountedPrice={14000}
          discountRate={30}
          images={images1} />
        <ShoppingItem
          title='흰 백자/검은 다관'
          shopName='티까르도'
          description='무이산에서 가져온 차에요'
          originalPrice={15000}
          discountedPrice={13500}
          discountRate={10}
          images={images2} />
        <ShoppingItem
          title='[2022] 우롱차'
          shopName='티하우스'
          description='무이산에서 가져온 차에요'
          originalPrice={30000}
          discountedPrice={24000}
          discountRate={20}
          images={images} />
        <ShoppingItem
          title='하동 우전녹차'
          shopName='너의티타임'
          description='하동의 자연을 느껴봐요.'
          originalPrice={20000}
          discountedPrice={14000}
          discountRate={30}
          images={images1} />
        <ShoppingItem
          title='흰 백자/검은 다관'
          shopName='티까르도'
          description='무이산에서 가져온 차에요'
          originalPrice={15000}
          discountedPrice={13500}
          discountRate={10}
          images={images2} />
      </ItemListWrapper>
    </PageWrapperCentered>
  )
}
const Page2 = () => { return <>Page 2</> }
const Page3 = () => { return <>Page 3</> }

/* Page */
const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px; 
  align-items: center;
  gap: 10px;
`
/* Item Wrapper */
const ItemListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: auto;
  justify-content: space-around;
`
/* Title */
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.mainGreen)};
`
/* Slick Button Nav */
const SliderButtonWrapper = styled.div`
  width : 100%;
  padding : 10px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
`
const SliderButton = styled.div<{ currentIndex: boolean }>`
  color: ${ ({ currentIndex, theme }) => currentIndex ? theme.mainGreen : theme.black};
  font-weight: ${ ({ currentIndex }) => currentIndex ? '800' : '500'};
  font-size: 24px;
`

/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding : 5px 10px;
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  z-index: 10;
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 20px;
  height: 22px; 
`
