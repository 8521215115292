import styled from '@emotion/styled'
import { useState } from 'react'

import { PageWrapper } from '@/components/Layout/frame/Frame'
import Title from '@/components/Layout/Title'
import { numberWithCommas } from '@/utils/numbers'
import { convertISOToKoreanDate } from '@/utils/text'

import HeaderSubPage from '../tea-together/component/HeaderSubPage'
import SettlementModal from './component/SettlementModal'
type SettlementType = 'NOTSETTLED' | 'SETTLED'

const MySettlementPage = () => {
  const [status, setStatus] = useState<SettlementType>('NOTSETTLED')
  const [modal, setModal] = useState(false)
  const [selectedTeaTogether, setSelectedTeaTogether] = useState<number>(-1)
  const settlements: IncomeDetailCardType[] = [
    { settlement: 'NOTSETTLED', title: '하노으이 아름다운 티클래스', teaTogetherId: 1, teaTogetherEndAt: '', participationFee: 30000, rooms: [{ roomId: 1, reservedSeats: 5, times: [{ startAt: '2016-10-27T17:13:40Z', endAt: '2016-10-27T17:13:40Z' }] }] },
    { settlement: 'NOTSETTLED', title: '하노으이 아름다운 티클래스', teaTogetherId: 1, teaTogetherEndAt: '', participationFee: 30000, rooms: [{ roomId: 1, reservedSeats: 5, times: [{ startAt: '2016-10-27T17:13:40Z', endAt: '2016-10-27T17:13:40Z' }] }] },
    { settlement: 'SETTLED', title: '하노으이 아름다운 티클래스', teaTogetherId: 1, teaTogetherEndAt: '', participationFee: 30000, rooms: [{ roomId: 1, reservedSeats: 5, times: [{ startAt: '2016-10-27T17:13:40Z', endAt: '2016-10-27T17:13:40Z' }] }] },
    { settlement: 'SETTLED', title: '하노으이 아름다운 티클래스', teaTogetherId: 1, teaTogetherEndAt: '', participationFee: 30000, rooms: [{ roomId: 1, reservedSeats: 5, times: [{ startAt: '2016-10-27T17:13:40Z', endAt: '2016-10-27T17:13:40Z' }] }] }
  ]
  return (
    <PageWrapperCentered>
      <SettlementModal
        visible={modal}
        teaTogetherId={selectedTeaTogether}
        onClose={() => setModal(false)} />
      <HeaderSubPage title='정산 및 결제' />
      <Title
        title='정산하기'
        subtitle='수익금 확인하고 출금을 요청할 수 있어요' />
      <SettlementInfoWrapper>
        <SettlementCard
          totalRevenueInMonth={300000}
          availableAmount={20000} />
      </SettlementInfoWrapper>
      <TitleText>정산내역</TitleText>
      <NavWrapper>
        <Nav
          clicked={status == 'NOTSETTLED'}
          onClick={() => setStatus('NOTSETTLED')}>정산 대기</Nav>
        <Nav
          clicked={status == 'SETTLED'}
          onClick={() => setStatus('SETTLED')}>정산 완료</Nav>
      </NavWrapper>
      <IncomeDetailCardListWrapper>
        {settlements.filter((settlement) => settlement.settlement == status).map((data, index) => {
          return (
            <IncomeDetailCard
              key={index}
              settlement={data.settlement}
              teaTogetherId={data.teaTogetherId}
              title={data.title}
              teaTogetherEndAt={data.teaTogetherEndAt}
              participationFee={data.participationFee}
              rooms={data.rooms}
              onClick={() => {
                setSelectedTeaTogether(data.teaTogetherId)
                setModal(true)
              }} />
          )
        })}
      </IncomeDetailCardListWrapper>
      <ButtonWrapper>
        <CTAButton>출금하기</CTAButton>
      </ButtonWrapper>
    </PageWrapperCentered>
  )
}
export default MySettlementPage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 20px 0px 20px; 
  gap: 10px;
`

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 20px;
  height: 22px; 
`
const Nav = styled.span<{ clicked: boolean }>`
  color: ${(props) => (props.clicked ? props.theme.information : props.theme.black)};
  font-weight: ${(props) => (props.clicked ? '700' : '300')};
  cursor: pointer;
`

/* 정산내역 한 눈에 */
const SettlementInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  align-items: center;
`
const IncomeDetailCardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`
type SettlementCardType = {
  totalRevenueInMonth: number;
  availableAmount: number;
}
const SettlementCard = ({ totalRevenueInMonth, availableAmount }: SettlementCardType) => {
  return (
    <SettlementCardWrapper>
      <SettlementCardTitle>총 수익금</SettlementCardTitle>
      <SettlementTotalMoney>{numberWithCommas(totalRevenueInMonth)}</SettlementTotalMoney>
      <RowWrapper>
        <SettlementCardSubTitle>정산 가능 금액</SettlementCardSubTitle>
        <SettlementAvailableMoney>{numberWithCommas(availableAmount)}</SettlementAvailableMoney>
      </RowWrapper>
    </SettlementCardWrapper>
  )
}
const SettlementCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 20px 30px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
`
/* Settlement */
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const SettlementCardTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.mainGreen};
`
const SettlementCardSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.gray4};
`
const SettlementTotalMoney = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.black};
`
const SettlementAvailableMoney = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.black};
`
/* Basic */
const TitleText = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.black)};
`
/* Bottom Button */
const ButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 5vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CTAButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 10px 20px;
  width: 90%;
  min-width: 120px;
  max-width: 600px;
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.information};
`

type IncomeDetailCardType = {
  settlement: SettlementType;
  teaTogetherId: number;
  title: string;
  participationFee: number;
  teaTogetherEndAt: string;
  rooms: { roomId: number; times: { startAt: string; endAt: string }[]; reservedSeats: number }[];
  onClick?: (teaTogetherId: number) => void;
}
const IncomeDetailCard = ({ teaTogetherId, title, participationFee, rooms, settlement, onClick }: IncomeDetailCardType) => {
  const isSettled = settlement == 'SETTLED'
  const SettleHandler = () => {
    if (!isSettled && onClick){
      onClick(teaTogetherId)
    } else {
      alert('이미 정산 완료가 되었어요')
    }
  }
  return (
    <IncomeDetailCardWrapper>
      <RowWrapper>
        <CardTitle>{title}</CardTitle>
        <SettlementButton
          onClick={SettleHandler}
          settlement={isSettled}>{isSettled ? '정산완료' : '정산받기' }</SettlementButton>
      </RowWrapper>
      <RowWrapper>
        <Money>참여비 : {numberWithCommas(participationFee)}</Money>
      </RowWrapper>
      {rooms.map((room, index) => {
        return (
          <ColumnWrapper key={index}>
            <ReservedDate>{convertISOToKoreanDate(room.times[0].startAt)}</ReservedDate>
            <ReservedPeople>{room.reservedSeats}명</ReservedPeople>
          </ColumnWrapper>
        )
      })}
    </IncomeDetailCardWrapper>
  )
}
const IncomeDetailCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.black}
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px;
`
const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.black};
`
const SettlementButton = styled.div<{ settlement: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, settlement }) => settlement ? theme.gray3 : theme.information};
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
`
const Money = styled(CardTitle)`
  color: ${({ theme }) => theme.gray4};
  font-size: 14px;
`
const ReservedPeople = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  color: ${({ theme }) => theme.information};
`
const ReservedDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  color: ${({ theme }) => theme.black};
`
