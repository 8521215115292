import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { IcClose } from '@/components/Icons'

import { TeaTogetherType } from '../TeaTogetherCreatePage'

interface CalendarProps {
  month: number; // 0-based month (0 for January, 11 for December)
  year: number;
  updateTeaTogetherData: (newData: Partial<TeaTogetherType>) => void;
}
interface DateItem {
  day: number;
  month: number;
  year: number;
  startAtHour: number;
  startAtMinute: number;
  endAtHour: number;
  endAtMinute: number;
}

const Calendar: React.FC<CalendarProps> = ({ month: initialMonth, year: initialYear, updateTeaTogetherData }) => {
  const [month, setMonth] = useState(initialMonth)
  const [year, setYear] = useState(initialYear)
  const [selectedDates, setSelectedDates] = useState<DateItem[]>([])

  const weekDays = ['일', '월', '화', '수', '목', '금', '토']
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const firstDayOfWeek = new Date(year, month, 1).getDay()
  const isToday = (day: number): boolean => {
    const today = new Date()
    return day === today.getDate() && month === today.getMonth() && year === today.getFullYear()
  }
  const isPast = (day: number): boolean => {
    const dateToCheck = new Date(year, month, day)
    const today = new Date()
    return dateToCheck < today
  }

  const isSelected = (day: number): boolean => {
    return selectedDates.some((date) => date.day === day && date.month === month && date.year === year)
  }
  const days = []

  const goToPrevMonth = () => {
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }

  const goToNextMonth = () => {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  // Calendar
  for (let i = 0; i < firstDayOfWeek; i++) {
    days.push(
      <DayPadWrapper
        key={`pad-${i}`}
        className="day pad"></DayPadWrapper>
    )
  }

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(
      <DayWrapper
        onClick={() => handleDayClick(i)}
        key={i}
        className={`${isToday(i) ? 'today' : isPast(i) ? 'past' : ''} ${isSelected(i) ? 'selected' : ''}`}
      >
        {isToday(i) ? `${i}(오늘)` : i}
        {isSelected(i) ? `(${selectedDates.filter((date) => date.day === i && date.month === month && date.year === year)?.length || 0})` : ''}
      </DayWrapper>
    )
  }
  // Selected List
  const isFutureDate = (day: number): boolean => {
    const dateToCheck = new Date(year, month, day)
    const today = new Date()
    return dateToCheck > today
  }

  const isWithinTwoMonths = (day: number): boolean => {
    const dateToCheck = new Date(year, month, day)
    const today = new Date()
    const twoMonthsLater = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate())
    return dateToCheck <= twoMonthsLater
  }
  const handleDayClick = (day: number) => {
    if (!isFutureDate(day) || !isWithinTwoMonths(day)) return

    setSelectedDates((prevDates) => {
      // const existingDateIndexs = prevDates.filter(
      //   (d) => d.day === day && d.month === month && d.year === year
      // )
      const newDateItem = {
        day,
        month,
        year,
        startAtHour: 12,
        startAtMinute: 0,
        endAtHour: 13,
        endAtMinute: 0,
        clickCount: 1
      }

      const updatedDates = [...prevDates, newDateItem].sort((a, b) => {
        const dateA = new Date(a.year, a.month, a.day)
        const dateB = new Date(b.year, b.month, b.day)
        return dateA.getTime() - dateB.getTime()
      })

      return updatedDates
    }
    )
  }

  const handleDelete = (originalIndex: number) => {
    setSelectedDates((prevDates) => {
      // 복사본을 만들어서 해당 인덱스의 날짜를 제거합니다.
      const updatedDates = [...prevDates]
      updatedDates.splice(originalIndex, 1)

      return updatedDates
    })
  }

  // 시작시간 종료시간 안내
  const handleStartAtChange = (originalIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDates((prevDates) => {
      const updatedDate = { ...prevDates[originalIndex], startAtHour: parseInt(event.target.value.split(':')[0]), startAtMinute: parseInt(event.target.value.split(':')[1]) }
      const updatedDates = prevDates.map((dateItem, idx) => (idx === originalIndex ? updatedDate : dateItem))

      return updatedDates
    })
  }

  const handleEndAtChange = (originalIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDates((prevDates) => {
      const updatedDate = { ...prevDates[originalIndex], endAtHour: parseInt(event.target.value.split(':')[0]), endAtMinute: parseInt(event.target.value.split(':')[1]) }
      const updatedDates = prevDates.map((dateItem, idx) => (idx === originalIndex ? updatedDate : dateItem))

      return updatedDates
    })
  }

  //Infinite Depth 주의, 렌더링 될 떄 상태업데이트 되는 것 주의
  useEffect(() => {
    // selectedDates가 변경될 때마다 실행되며 times 배열 업데이트
    const updatedTimes = selectedDates.map((dateItem) => ({
      startAt: new Date(
        dateItem.year,
        dateItem.month,
        dateItem.day,
        dateItem.startAtHour,
        dateItem.startAtMinute
      ).toISOString(),
      endAt: new Date(
        dateItem.year,
        dateItem.month,
        dateItem.day,
        dateItem.endAtHour,
        dateItem.endAtMinute
      ).toISOString()
    }))
    console.log(updatedTimes)
    // setTeaTogetherData를 통해 times 업데이트
    updateTeaTogetherData({ times: updatedTimes })
  }, [selectedDates, updateTeaTogetherData])

  return (
    <>
      <Navigation>
        <button onClick={goToPrevMonth}>이전 달</button>
        <span>{year}년 {month + 1}월</span>
        <button onClick={goToNextMonth}>다음 달</button>
      </Navigation>
      <CalendarWrapper className="calendar">
        {weekDays.map((day) => (
          <DayHeader key={day}>{day}</DayHeader>
        ))}
        {days}
      </CalendarWrapper>
      <SelectedDatesList>
        <SelectedDatesListTitle>일정</SelectedDatesListTitle>
        <SelectedDatesListDescription>캘린더에서 원하는 날짜를 클릭하고 시간을 수정하세요</SelectedDatesListDescription>
        {selectedDates.map((dateItem, index) => (
          <SelectedDateItem key={`${dateItem.day}-${dateItem.month}-${dateItem.year}-${index}`}>
            <RowDiv>
              <DateTitle>
                {dateItem.year}년 {dateItem.month + 1}월 {dateItem.day}일
              </DateTitle>
              <IcClose
                width='16px'
                onClick={() => handleDelete(index)} />
            </RowDiv>
            <RowDiv>
              <TimeColumn>
        시작시간
                <DateTimeInput
                  type="time"
                  value={`${dateItem.startAtHour.toString().padStart(2, '0')}:${dateItem.startAtMinute.toString().padStart(2, '0')}`}
                  onChange={(e) => handleStartAtChange(index, e)}
                />
              </TimeColumn>
      ~
              <TimeColumn>
        종료시간
                <DateTimeInput
                  type="time"
                  value={`${dateItem.endAtHour.toString().padStart(2, '0')}:${dateItem.endAtMinute.toString().padStart(2, '0')}`}
                  onChange={(e) => handleEndAtChange(index, e)}
                />
              </TimeColumn>
            </RowDiv>
          </SelectedDateItem>
        ))}

      </SelectedDatesList>
    </>
  )
}

export default Calendar

const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`
const DayWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.white};
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.black};
  &.today {
    color: #01c897;
  }
  &.past {
    color: #aaa;  // 지난 날짜의 글자색 (예: 회색)
  }
  &.selected {
    background-color: #01c897; // 클릭된 배경색으로 원하는 색을 설정하실 수 있습니다.
    color: white;
    font-weight: 800;
    border-radius: 10px;
  }
`
const DayPadWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.white};
  padding: 10px;
  text-align: center;
  background-color: ${({ theme }) => theme.white};
`
const DayHeader = styled.div`
  font-weight: bold;
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.white};
`
const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  button {
    background-color: #f5f5f5;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
  }
`

//Selected List
const SelectedDatesList = styled.div`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`

const SelectedDatesListTitle = styled.div`
  font-weight: 700;
  color: #01c897;
  font-size: 24px;
`

const SelectedDatesListDescription = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.gray4};
  font-size: 16px;
`
const SelectedDateItem = styled.div`
  border: 1px solid ${({ theme }) => theme.gray3};
  border-radius: 10px;
  padding: 10px 20px;
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  gap: 2px;
  font-size: 16px;
  font-weight: 700;
`
const DateTitle = styled.div`
  font-weight: 700;
  color: #01c897;
  font-size: 20px;
`
const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`
const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
const DateTimeInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 2px;
  &::-webkit-calendar-picker-indicator, -webkit-inner-spin-button, -webkit-clear-button {
    display: none;
  }
  &::focus {
    border: 1px solid #01c897;
  }
`
