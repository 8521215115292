import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { Body1, H2, H4, Sub1, Sub2, Sub3 } from '@/components/Base/Text'
import { IcArrowLeft, IcEditPencil } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import useParticipatorInfo from '@/hooks/useParticipatorInfo'
import useTastingNoteOfParticipator from '@/hooks/useTastingNoteOfParticipator'
import timeSince from '@/utils/timeSince'

// import RadarChartTastingNoteItem from '../main-tea/RadarChartTastingNoteItem'
import ParticipatorReviewItem from './ParticipatorReviewItem'

const ParticipatorPage = () => {
  /* TODO: 나중에 festivalId로 조회하도록 동적으로 구성하기 */

  //PathVariable 가져오기
  const { participatorId } = useParams<{ participatorId: string }>()
  // 참여 단체 정보 조회
  const { data: participator } = useParticipatorInfo(1, Number(participatorId))

  // 참여단체 방명록 조회
  const { data: tastingNotesUnsorted } = useTastingNoteOfParticipator(1, Number(participatorId))

  const tastingNotes = tastingNotesUnsorted?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

  return (
    <PageWrapperCentered>
      <Header />
      <SubTitle>제 6회 경주 세계 차문화 축제</SubTitle>
      <BoothNumber>#{participator?.boothNumber}</BoothNumber>
      <BoothName>{participator?.name}</BoothName>
      <Buttons />
      <ContentWrapper>
        <ContentTitle>🏡 저희를 소개합니다</ContentTitle>
        <ContentDescription>{participator?.description}</ContentDescription>
        <ContentTitle>🍵 오늘 내어드릴 차에요</ContentTitle>
        <ContentDescription>{participator?.handleTea}</ContentDescription>
      </ContentWrapper>
      <ReviewTitle>다녀간 분들의 소감</ReviewTitle>
      <ReviewCardWrapper>
        <>
          {
            (!tastingNotes || tastingNotes.length === 0)
              ? <ContentDescription2>🧐아직 아무도 등록하지 않았군요! 최초로 등록해주세요😀</ContentDescription2>
              : tastingNotes.map((tastingNote, index) => {
                const icon = tastingNote.content.slice(0, 2)
                const description = tastingNote.content.slice(2)
                const tasteData = [
                  { subject: '단 맛', value: tastingNote.tastes.sweetness },
                  { subject: '쓴 맛', value: tastingNote.tastes.bitterness },
                  { subject: '신 맛', value: tastingNote.tastes.sourness },
                  { subject: '고소함', value: tastingNote.tastes.nuttiness },
                  { subject: '알싸함', value: tastingNote.tastes.spicy },
                  { subject: '떫음', value: tastingNote.tastes.astringency },
                  { subject: '상큼함', value: tastingNote.tastes.freshness }
                ]

                const user = (tastingNote.tag === 'GYEONGJU_2023') ? '[Festival]경주' : '다루디'
                return (
                  <ParticipatorReviewItem
                    key={index}
                    data={tasteData}
                    title={tastingNote.title}
                    description={description}
                    icon={icon}
                    createdAt={timeSince(tastingNote.createdAt)}
                    user={user}
                  />
                )
              })
          }
        </>
      </ReviewCardWrapper>
    </PageWrapperCentered>
  )
}

export default ParticipatorPage

const Header = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>부스 소개 페이지</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}
const PageWrapperCentered = styled(PageWrapper)`
  padding: 50px 0px 0px 0px; 
  align-items: center;
`
/* 헤더 */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`
/* 경주 국체 차문화 title */
const SubTitle = styled(Body1)`
  color: #01C897;
`
const BoothNumber = styled(H2)`
  color: ${((props) => props.theme.black)};
`
const BoothName = styled(H4)`
  color: ${((props) => props.theme.mainGreen)};
`
/* 버튼 */
const Buttons = () => {
  const navigate = useNavigate()
  const clickHandler1 = () => {
    navigate('create')
  }
  const clickHandler2 = () => {
    navigate('/festival/gyeongju/2023/')
  }
  // const clickHandler3 = () => {
  //   navigate('revise')
  // }
  return (
    <ButtonWrapper>
      { false && <BigButtonWrapper onClick = {() => clickHandler1()}>
        <IcEditPencil
          width='24px'
          height='24px' />
        <BigButtonText> 소감 작성하기 😀 🥰 🧐 👍🏻  </BigButtonText>
      </BigButtonWrapper>}
      { true && <BigButtonCloseWrapper onClick = {() => alert('행사가 종료되었어요. 다음 행사를 기대해주세요!')}>
        <BigButtonText> 행사가 종료되었어요. </BigButtonText>
      </BigButtonCloseWrapper>}
      <SmallButtonWrapper>
        <SmallButton1 onClick = {() => clickHandler2()}>메인으로 가기(지도)</SmallButton1>
        {/* <SmallButton2 onClick = {() => clickHandler3()}>수정하기</SmallButton2> */}
      </SmallButtonWrapper>
    </ButtonWrapper>
  )
}
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 10px; 
`
const SmallButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`
const BigButtonWrapper = styled.div`
  width: 100%;
  min-width: 150px;
  max-width: 400px;
  height: 60px;
  gap: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${((props) => props.theme.mainGreen)};
  cursor: pointer;
`
const BigButtonCloseWrapper = styled(BigButtonWrapper)`
  background: ${((props) => props.theme.gray2)};
  cursor: none;
`
const BigButtonText = styled(Sub3)`
  color: #fff;
`
const SmallButton1 = styled.div`
  background: ${((props) => props.theme.white)};
  color: ${((props) => props.theme.mainGreen)};
  cursor: pointer;
  height: 30px;
  display: flex;
  justify-content: center;
  max-width: 400px;
  min-width: 150px;
  width:100%;
  align-items: center;
  border-radius: 10px;
  padding: 20px 20px;
  border: 2px solid ${((props) => props.theme.mainGreen)};
`
// const SmallButton2 = styled.div`
//   background: ${((props) => props.theme.information)};
//   color: #fff;
//   cursor: pointer;
//   display: flex;
//   border-radius: 10px;
//   width:100%;
//   max-width: 400px;
//   min-width: 150px;
//   justify-content: center;
//   align-items: center;
//   padding: 20px 20px;
//   height: 30px;
// `
/* 소개글, 텍스트 */
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  width: 100%;
  max-width: 400px;
  gap: 20px;
`
const ContentTitle = styled(Sub1)`
  color: ${((props) => props.theme.mainGreen)};
`
const ContentDescription = styled.div`
  color: ${((props) => props.theme.black)};
  font-weight: 400;
  width: 100%;
  white-space: pre-line;
`
const ContentDescription2 = styled(Sub2)`
  color: ${((props) => props.theme.gray4)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin-bottom: 200px;
  white-space: pre-line;
`

/* 후기 및 사진 */
const ReviewTitle = styled(Sub1)`
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${((props) => props.theme.information)};
`
const ReviewCardWrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  max-width: 600px;
  gap : 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
