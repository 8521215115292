export function masking(text: string){
  return text.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1****$3')
}

export function dateText(dateISOString: string){
  const date = new Date(dateISOString)

  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}

export function dateTextForPeriod(startDateISOString: string, endDateISOString: string){
  const start = new Date(startDateISOString)
  const end = new Date(endDateISOString)

  return `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')} ${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')} ~ ${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}`
}

export function dateTextOnlyDate(dateISOString: string, delimiter = '-'){
  const date = new Date(dateISOString)

  return `${date.getFullYear()}${delimiter}${(date.getMonth() + 1).toString().padStart(2, '0')}${delimiter}${date.getDate().toString().padStart(2, '0')}`
}

export const nextDay = 1000 * 60 * 60 * 24

export function dateTextNextDayOnlyDate(dateString: string){
  const date = new Date()
  const [y, m, d] = dateString.split('-')
  const time = Number(`${date.setFullYear(Number(y), Number(m) - 1, Number(d))}`)
  const resetTime = new Date(time).setHours(0, 0, 0, 0)
  return dateTextOnlyDate(new Date(resetTime + nextDay).toISOString())
}

export function dateTime(dateString: string){
  const date = new Date()
  const [y, m, d] = dateString.split('-')
  const time = Number(`${date.setFullYear(Number(y), Number(m) - 1, Number(d))}`)
  return new Date(time).setHours(0, 0, 0, 0)
}

export function convertISOToKoreanDate(isoString: string): string {
  // ISO 8601 형식의 문자열을 Date 객체로 변환
  const date = new Date(isoString)
  // 날짜와 시간 정보 추출
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // 월은 0부터 시작하므로 1을 더해줌
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // 오후 또는 오전 표시 설정
  const ampm = hours >= 12 ? '오후' : '오전'

  // 12시간제 시간 계산
  const displayHours = hours > 12 ? hours - 12 : hours

  // 한국어로 날짜 및 시간 문자열 생성
  const koreanDate = `${year}년 ${month}월 ${day}일 ${ampm} ${displayHours}시 ${minutes}분`

  return koreanDate
}
