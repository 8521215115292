import styled from '@emotion/styled'
import { useRef, useState } from 'react'

import useMount from '@/hooks/useMount'
import useUnmount from '@/hooks/useUnmount'

const END_INDEX = process.env.REACT_APP_ENV === 'local' ? 1 : 4
// function Splash() {
//   const timerRef = useRef<ReturnType<typeof setInterval> | null>(null)
//   const [show, setShow] = useState(true)
//   const [text, setText] = useState('')

//   useMount(() => {
//     for (let i = 1; i <= END_INDEX;i++){
//       (function(i){
//         timerRef.current = setTimeout(() => {
//           if (i === END_INDEX){
//             setShow(false)
//           }

//           setText((state) => {
//             return `${state}.`
//           })

//         }, 1000 * i)
//       }(i))
//     }
//   })

//   useUnmount(() => {
//     if (timerRef.current) {
//       clearTimeout(timerRef.current as NodeJS.Timer)
//     }
//   })
//   return (
//     show ? <SplashBlock>
//       <Image
//         alt="daru logo"
//         src='/logo.png'
//         width={109}
//         height={66}
//       />{text}
//     </SplashBlock> : null
//   )
// }
// const SplashBlock = styled.div`
// position: fixed;
// left: 0;
// right: 0;
// top: 0;
// bottom: 0;

// text-align: center;
// color: ${({ theme }) => theme.mainGreen};
// background: ${({ theme }) => theme.white};
// z-index: 20;

// font-weight: bold;
// font-size: 6rem;
// display: flex;
// justify-content: center;
// align-items: center;
// `
const Splash = () => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const [show, setShow] = useState(true)

  useMount(() => {
    for (let i = 1; i <= END_INDEX; i++) {
      (function (i) {
        timerRef.current = setTimeout(() => {
          if (i === END_INDEX) {
            setShow(false)
          }
        }, 1000 * i)
      })(i)
    }
  })

  useUnmount(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  })

  return (
    show ? <SplashBlock>
      <LoadingVideo
        src='/mp4/pour_logo.mp4'
        autoPlay
        loop
        muted
      />
    </SplashBlock> : null
  )
}

export default Splash

const SplashBlock = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  text-align: center;
  color: ${({ theme }) => theme.mainGreen};
  background: ${({ theme }) => theme.white};
  z-index: 20;

  font-weight: bold;
  font-size: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingVideo = styled.video`
  width: 100%;  // Adjust the width and height as needed
  height: 100vh;
`
