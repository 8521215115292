import { Methods, request } from '@/libs/request'

export type GetVisitorLogsResponseType = {
  content: string;
  createdAt: string;
}[]

export const getVisitorLogs = ({ festivalId }: { festivalId: number }) => {
  return request<GetVisitorLogsResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}/visitor`,
    method: Methods.GET
  })
}
