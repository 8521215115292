import styled from '@emotion/styled'

import Checked from '@/assets/svg/icons/checkbox-circle-checked.svg'
import UnChecked from '@/assets/svg/icons/checkbox-circle-unchecked.svg'

import { Body2 } from '../Base/Text'
import Image from '../Common/Image'

const CheckBoxLong = ({ checked, setChecked, content }: { checked: boolean; setChecked: React.Dispatch<React.SetStateAction<boolean>>; content: string }) => {
  return (
    <Wrapper
      checked={checked}
      onClick={() => setChecked(!checked)}>
      {(checked) && <Image
        alt='checked'
        src={ Checked }
        width='23px'
        height='23px' />}
      {(!checked) && <Image
        alt='unchecked'
        src={ UnChecked }
        width='23px'
        height='23px' />}
      <Body2Converted checked={checked}>
        {content}
      </Body2Converted>

    </Wrapper>
  )
}
export default CheckBoxLong

const Wrapper = styled.div<{ checked: boolean }>`
/* CheckBox L=yes */

box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
padding: 14px 20px;
gap: 8px;

/* Green/Main Green */

border: 1px solid ${({ theme, checked }) => checked ? theme.mainGreen : theme.gray1 };
border-radius: 10px;
`

const Body2Converted = styled(Body2)<{ checked: boolean }>`
  color: ${({ theme, checked }) => checked ? theme.mainGreen : theme.gray2 }
`
