import styled from '@emotion/styled'
import { PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import ToastType from '@/enums/toast'
import { usePaymentWidget } from '@/hooks/usePaymentWidget'
import { numberWithCommas } from '@/utils/numbers'
import { PaymentStorageName } from '@/utils/payment'

// // const classId = '123'

// // const orderId = 'alksjflkj9'
// // const orderName = '다루티클래스 예약인원2명'
// // const customerName = '홍길동'
// const selector = '#payment-widget'
// const clientKey = process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY || ''
// const customerKey = nanoid()
const selector = '#payment-widget'

// TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
// TODO: customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.
// @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
//const clientKey = process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY || ''
const clientKey = 'test_gck_Ba5PzR0ArnX57YmqR45o3vmYnNeD'
const customerKey = nanoid()

export function PaymentPage() {
  const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey)
  // const paymentWidget = usePaymentWidget(clientKey, ANONYMOUS); // 비회원 결제
  const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance['renderPaymentMethods']> | null>(null)
  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false)
  const [email, setEmail] = useState<string>()
  const price = parseInt(localStorage.getItem(PaymentStorageName.amount) || '0')

  useEffect(() => {
    if (paymentWidget == null) {
      return
    }
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(selector, { value: price }, { variantKey: 'DEFAULT' })
    paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' })
    paymentMethodsWidget.on('ready', () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget
      isPaymentMethodsWidgetReady(true)
    })
  }, [paymentWidget, price])

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current
    if (paymentMethodsWidget == null) {
      return
    }
    paymentMethodsWidget.updateAmount(price)
  }, [price])

  return (
    <PaymentPageWrapper>
      <PaymentTitle>결제하여 예약을 진행하시겠습니까?</PaymentTitle>
      <span>총 주문금액: {numberWithCommas(price)}</span>
      <EmailInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='결제 내역 받을 이메일 입력' />
      <PaymentWrapper className="wrapper">
        <div className="box_section">
          <div id="payment-widget" />
          <div id="agreement" />
        </div>
      </PaymentWrapper>
      <PaymentButton
        disabled={!paymentMethodsWidgetReady}
        onClick={async () => {
          // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
          // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
          const successUrl = `${window.location.origin}/payment/loading`
          try {
            await paymentWidget?.requestPayment({
              orderId: localStorage.getItem(PaymentStorageName.orderId) || '',
              orderName: localStorage.getItem(PaymentStorageName.orderName) || '',
              customerName: localStorage.getItem(PaymentStorageName.customerName) || '',
              customerEmail: email,
              customerMobilePhone: '01094246901',
              successUrl: successUrl,
              failUrl: `${window.location.origin}/error`
            })
          } catch (error) {
            console.log(error)
            notifyRenewal({ message: '결제에 실패하였습니다.', type: ToastType.ERROR })
          }
        }}
      >
          결제하기
      </PaymentButton>
    </PaymentPageWrapper>
  )
}

export default PaymentPage

// interface handlePaymentType {
//   method: '카드' | '가상계좌' | '휴대폰' | '계좌이체' | '문화상품권' | '게임문화상품권' | '도서문화상품권' | '토스페이' | '토스결제' | '해외간편결제' | '미선택' ;
//   amount: number;
//   orderId: string;
//   orderName: string;
//   customerName: string;
//   id: number;
// }

// export const handlePayment = async ({ method, amount, orderId, orderName, customerName, id }: handlePaymentType) => {
//   const tossPayments = await loadTossPayments(clientKey)
//   const successUrl = `${window.location.origin}${TEATOGETHER}/${id}/reservation/complete`
//   tossPayments.requestPayment(method, {
//     // 결제 수단 파라미터
//     // 결제 정보 파라미터
//     amount,
//     orderId,
//     orderName,
//     customerName,
//     successUrl: successUrl,
//     failUrl: `${window.location.origin}/error`
//   })
//     .catch((err: Error) => {
//       notify(err.message)
//     })
// }

// function PaymentPage() {
//   const [clicked, setClicked] = useState(false)
//   const location = useLocation()
//   const queryParams = new URLSearchParams(location.search)
//   const price = queryParams.get('amount') || '0'
//   return (
//     <PaymentPageWrapper>
//       <PaymentTitle>결제하여 예약을 진행하시겠습니까?</PaymentTitle>
//       <span>총 주문금액: {numberWithCommas(price)}</span>
//       <div>
//         <Button
//           label="결제하기"
//           // onClick={() => setClicked(true)}
//           onClick={() => handlePayment({ method: '카드', amount: 1000, orderId: 'daru123112312321', orderName: '다루티클래스 예약인원2명', customerName: '홍길동', id: 1 })}
//           inline
//         ></Button>
//       </div>
//       {clicked && PaymentWidget({ amount: parseInt(price) })}
//     </PaymentPageWrapper>
//   )
// }

// export default PaymentPage

const PaymentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 10px;
`
const PaymentWrapper = styled.div`
  width: 90%;
`
const PaymentTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: black;
`
const EmailInput = styled.input`
  border: 1px solid ${({ theme }) => theme.mainGreen};
  border-radius: 5px;
  height: 20px;
  width: 200px;
  padding: 20px 10px;
`
const PaymentButton = styled.button`
  background-color: ${({ theme }) => theme.mainGreen};
  max-width: 400px;
  width: 90%;
  height: 40px;
  font-size: 20px;
  color: white;
  font-weight: 800;
  border-radius: 10px;
`

// interface PaymentWidgetProps {
//   amount: number;
// }
// const PaymentWidget = ({ amount }: PaymentWidgetProps) => {
//   const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey)
//   // const paymentWidget = usePaymentWidget(clientKey, ANONYMOUS); // 비회원 결제
//   const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance['renderPaymentMethods']> | null>(null)
//   const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false)
//   useEffect(() => {
//     if (paymentWidget == null) {
//       return
//     }
//     const paymentMethodsWidget = paymentWidget.renderPaymentMethods(selector, { value: amount }, { variantKey: 'DEFAULT' })
//     paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' })
//     paymentMethodsWidget.on('ready', () => {
//       paymentMethodsWidgetRef.current = paymentMethodsWidget
//       isPaymentMethodsWidgetReady(true)
//     })
//   }, [paymentWidget])
//   return (
//     <>
//       <div id="payment-widget" />
//       <div id="agreement" />
//       <button onClick={async () => {
//         try {
//           await paymentWidget?.requestPayment({
//             orderId: nanoid(),
//             orderName: '토스 티셔츠 외 2건',
//             customerName: '김토스',
//             customerEmail: 'customer123@gmail.com',
//             customerMobilePhone: '01012341234',
//             successUrl: `${window.location.origin}/success`,
//             failUrl: `${window.location.origin}/fail`
//           })
//         } catch (error) {
//           // 에러 처리하기
//           console.error(error)
//         }
//       }}></button>
//     </>
//   )
// }
