import styled from '@emotion/styled'
import { useState } from 'react'

const BusinessFooter = () => {
  const [open, setOpen] = useState(false)
  return (
    <FooterWrapper>
      {open ? <><SectionColumnWrapper>
        <SectionColumnWrapper onClick={() => setOpen(!open)}>닫기</SectionColumnWrapper>
        <TextBig>Copyright© DARU, All Rights Reserved</TextBig>
        <Text>사업자등록번호 407-06-77207(대표자 이한호)</Text>
        <Text>통신 판매업 신고번호 2022-서울관악-1945</Text>
        <Text>서울특별시 관악구 호암로 26길 72</Text>
        <Text>문의번호 : 010-9424-6901</Text>
      </SectionColumnWrapper>
      <SectionRowWrapper>
        <Text onClick={() => window.open('https://terms.daruteaworld.com/termsofservice.html')}>다루 이용 약관</Text>
        <Text onClick={() => window.open('https://terms.daruteaworld.com/privacypolicy.html')}>다루 개인 정보 처리방침</Text>
      </SectionRowWrapper></> : <SectionColumnWrapper onClick={() => setOpen(!open)}>사업자 정보 자세히보기</SectionColumnWrapper>}
    </FooterWrapper>
  )
}

export default BusinessFooter

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.gray1}
`

const SectionColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  color: white;
`
const SectionRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`

const Text = styled.div`

`
const TextBig = styled.div`
  font-size: 18px;
`
