import { ClassStatusType } from './apis/class/getClassDetail'

export const ACCOUNT = '하나은행 109-910779-50007'
export const EMAIL = 'contact@daruteaworld.com'
export const BETA_SERVICE_INFO = `해당 기능은 현재 준비 중에 있습니다. ${EMAIL}로 문의해주시면 답변드리겠습니다.`
export const alertBetaService = () => {
  alert(BETA_SERVICE_INFO)
}
export const BRAND_NAME = '다루, 차문화의 공간'

// 사용자에게 취소 상태는 노출하지 않음.
// 상태: BEFORE_RECRUITING, RECRUITING, FINISHED_RECRUITING, IN_PROGRESS, FINISH_CLASS, CANCELED_CLASS
type ClassStatusDisplayType = Exclude<ClassStatusType, 'CANCELD_CLASS'>
export const CLASS_STATUS: Record<ClassStatusDisplayType, { color: `#${string}`; text: string }> = {
  'BEFORE_RECRUITING': {
    color: '#c9c9c9',
    text: '모집전'
  },
  'RECRUITING': {
    color: '#ffd052',
    text: '모집중'
  },
  'FINISHED_RECRUITING': {
    color: '#00b588',
    text: '모집완료'
  },
  'IN_PROGRESS': {
    color: '#f00',
    text: '진행중'
  },
  'FINISH_CLASS': {
    color: '#000',
    text: '진행완료'
  }
}

export function getClassStatus(status: ClassStatusType): {
  color: `#${string}`;
  text: string;
}{
  if (status === 'CANCELD_CLASS'){
    return {
      color: '#2d2928',
      text: '-'
    }
  }
  return CLASS_STATUS[status] || {
    color: '#00b588',
    text: '-'
  }

}

export const BANK_CODE: Record<string, {
  imageFileName: string;
  textKey: string;
  name: string;
}> = {
  '39': { imageFileName: '경남', textKey:	'KYONGNAMBANK', name:	'경남은행' },
  '34': { imageFileName: '광주', textKey:	'GWANGJUBANK', name:	'광주은행' },
  '06': { imageFileName: '국민', textKey:	'KOOKMIN', name:	'KB국민은행' },
  '03': { imageFileName: '기업', textKey:	'IBK', name:	'IBK기업은행' },
  '11': { imageFileName: '농협', textKey:	'NONGHYEOP', name:	'NH농협은행' },
  '12': { imageFileName: '단위농협', textKey:	'LOCALNONGHYEOP', name:	'단위농협' },
  '31': { imageFileName: '대구', textKey:	'DAEGUBANK', name:	'DGB대구은행' },
  '32': { imageFileName: '부산', textKey:	'BUSANBANK', name:	'부산은행' },
  '02': { imageFileName: '산업', textKey:	'KDBBANK', name:	'KDB산업은행' },
  '45': { imageFileName: '새마을', textKey:	'SAEMAUL', name:	'새마을금고' },
  '64': { imageFileName: '산림', textKey:	'SANLIM', name:	'산림조합' },
  '07': { imageFileName: '수협', textKey:	'SUHYEOP', name:	'Sh수협은행' },
  '88': { imageFileName: '신한', textKey:	'SHINHAN', name:	'신한은행' },
  '48': { imageFileName: '신협', textKey:	'SHINHYEOP', name:	'신협' },
  '27': { imageFileName: '씨티', textKey:	'CITI', name:	'씨티은행' },
  '20': { imageFileName: '우리', textKey:	'WOORI', name:	'우리은행' },
  '71': { imageFileName: '우체국', textKey:	'POST', name:	'우체국예금보험' },
  '50': { imageFileName: '저축', textKey:	'SAVINGBANK', name:	'저축은행중앙회' },
  '37': { imageFileName: '전북', textKey:	'JEONBUKBANK', name:	'전북은행' },
  '35': { imageFileName: '제주', textKey:	'JEJUBANK', name:	'제주은행' },
  '90': { imageFileName: '카카오', textKey:	'KAKAOBANK', name:	'카카오뱅크' },
  '89': { imageFileName: '케이', textKey:	'KBANK', name:	'케이뱅크' },
  '92': { imageFileName: '토스', textKey:	'TOSSBANK', name:	'토스뱅크' },
  '81': { imageFileName: '하나', textKey:	'HANA', name:	'하나은행' },
  '23': { imageFileName: 'SC제일', textKey:	'SC', name:	'SC제일은행' }
  // '54': { imageFileName: '-', textKey:	'HSBC', name:	'홍콩상하이은행' }
}

export const 클래스예약_위약금_정책 = [
  '시작 48시간 전에 취소 시 100% 환급',
  '시작 48시간 전 ~ 24시간 전 취소 시 80% 환급',
  '시작 24시간 전 ~ 12시간 전 취소 시 50% 환급',
  '12시간 전 부터는 취소 불가'
]
export const 클래스예약_위약금_정책_참고사항 = [
  '본 예약은 개설자의 사정에 의해 취소될 수 있음을 고지드립니다.',
  '개설자의 사정에 의해 취소된 경우 100% 환불이 이루어짐을 고지드립니다.'
]

export const 메이커_신청_안내문 = [
  '모임을 통해 얻게된 고객의 정보를 함부로 사용하지 않아요.',
  '오로지 차에 관련한 모임만 열 수 있도록 해요. 예를 들어 독서모임(X), 보이차를 마시는 독서모임(O)',
  '소비자를 속이고 기만하는 행위(가격이 터무니 없거나 공지된 내용과 모임의 내용이 상이한 경우)는 서비스 이용 제재의 대상이 돼요.',
  '모임을 만든 후, 참여자로부터 예약을 받았지만 모임을 취소해야하는 경우 반드시 참여자에게 적절한 취소 사유를 전달 해야해요.',
  '법적 문제가 있거나 불건전한 소재의 모임은 운영될 수 없어요.',
  '비정상적인 활동을 보인 유저는 제재의 대상이 될 수 있어요.',
  '다루 서비스를 경유하지 않고 직접 거래할 목적으로 회원 간에 개인정보와 연락처를 교환하지 않을 것을 약속해요.'
]