import { useMutation } from 'react-query'

import { patchUser, PatchUserRequestType } from '@/apis/user/patchUser'

export default function usePatchUser() {
  return useMutation<void, unknown, PatchUserRequestType>(
    async (user) => {
      await patchUser(user)
    }
  )
}
