import { useQuery } from 'react-query'

import { validateCancelReservation } from '@/apis/reservation/validateCancelReservation'

const getQueryKey = ({ classId, applicantPhoneNumber }: {
  classId: string;
  applicantPhoneNumber: string; }) => [`validateCancelReservation/${classId}/${applicantPhoneNumber}`]

export default function useValidateCancelReservation({ classId, applicantPhoneNumber }: { classId: string;
  applicantPhoneNumber: string; }) {
  return useQuery(getQueryKey({ classId, applicantPhoneNumber }), async () => {
    const isValidated = await (await validateCancelReservation({ classId, applicantPhoneNumber }))
    return isValidated
  })
}
