
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Error, H2, PageBlock } from '@/components/Common/Styled'
import { TAB_INDEX } from '@/components/Common/TabSlider'
import Button from '@/components/Input/Button'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useMakerInfo from '@/hooks/useMakerInfo'
import { ROUTER } from '@/router'

const { MAKER } = ROUTER

function NicknameEditPage() {
  const { data: makerInfo } = useMakerInfo()
  const [duplicatedNickname, setDuplicateNickname] = useState(true)
  const [nickname, setNickname] = useState('')
  const buttonDisabled = useMemo(() => {
    return duplicatedNickname
  }, [duplicatedNickname])

  if (!makerInfo){
    return <>!makerInfo</>
  }

  const handleButtonClick = () => {
    setDuplicateNickname(false)
  }
  return (
    <Layout>
      <Header
        back
      />
      <Content>
        <PageBlock>
          <H2>별명입력</H2>
          <TextBlock>별명은 사람들에게 노출되는 자신의 이름과 같은 역할이에요.</TextBlock>
          <ListBlock>
            <ListItem>
              <h4>별명(최대 10글자) <Error>중복된 별명입니다.</Error>
              </h4>
              <Input
                type='text'
                placeholder="별명 입력"
                value={nickname}
                onChange={(nickname) => {
                  setNickname(nickname)
                }}
              />
              <Button
                disabled={!nickname}
                label='중복확인'
                onClick={handleButtonClick}
                inline />
            </ListItem>
          </ListBlock>
        </PageBlock>
      </Content>
      <Link
        to={`${MAKER}/${makerInfo.id}/my?${TAB_INDEX}=1`}
      >
        <CTAButton
          disabled={buttonDisabled}
          label="별명 수정"
        />
      </Link>
    </Layout>
  )
}

export default NicknameEditPage

const TextBlock = styled.div`
  padding: 10px 0;
`

const ListBlock = styled.ul`
  margin-top: 30px;
`

const ListItem = styled.li`
  margin-bottom: 28px;
  line-height: 28px;
`