import { useQuery } from 'react-query'

import { getReservationInfo } from '@/apis/reservation/getReservationInfo'

const getQueryKey = ({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) => [`classReservationConfirm/${classId}/${applicantPhoneNumber}`]

export default function useReservationInfo({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  return useQuery(getQueryKey({ classId, applicantPhoneNumber }), async () => {
    const classDetail = await getReservationInfo({ classId, applicantPhoneNumber })
    return classDetail
  })
}
