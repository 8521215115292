
import styled from '@emotion/styled'

import { H2, PageBlock, ScrollBlockX } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useSettlementList from '@/hooks/useSettlementList'
import useWaitingForWithdrawalList from '@/hooks/useWaitingForWithdrawalList'
import { numberWithCommas } from '@/utils/numbers'

function SettlementPage() {
  const { data: settlementList } = useSettlementList()
  const { data: waitingForWithdrawalList } = useWaitingForWithdrawalList()

  if (!settlementList || !waitingForWithdrawalList){
    return <>!settlementList || !waitingForWithdrawalList</>
  }
  settlementList
  return (
    <Layout>
      <Header />
      <Content>
        <PageBlock>
          <H2>정산내역</H2>
          <p>나의 정산 내역을 확인할 수 있어요. </p>
          {waitingForWithdrawalList &&
            <>
              <H4>출금 대기 내역</H4>

              <ScrollBlockX>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>날짜</th>
                      <th>항목</th>
                      <th>세부항목</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {waitingForWithdrawalList.map(({ id, date, description, price, title }) => {
                      return (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>{date}</td>
                          <td>{title}</td>
                          <td>{description}</td>
                          <td>{numberWithCommas(price)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </ScrollBlockX>
              <H4>정산 내역</H4>
            </>}
          <ScrollBlockX>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>날짜</th>
                  <th>항목</th>
                  <th>세부항목</th>
                  <th>금액</th>
                </tr>
              </thead>
              <tbody>
                {settlementList.map(({ id, date, description, price, title }, index) => {
                  return (
                    <tr key={index}>
                      <td>{id}</td>
                      <td>{date}</td>
                      <td>{title}</td>
                      <td>{description}</td>
                      <td>{numberWithCommas(price)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </ScrollBlockX>
        </PageBlock>
      </Content>
    </Layout>
  )
}

export default SettlementPage

const H4 = styled.h4`
  margin-top: 10px;
`

const Table = styled.table`
  td {
    padding: 10px;
    white-space: pre;
  }
`
