import { useEffect } from 'react'

declare global {
  interface Window {
    VConsole: any;
    daum: any;
  }
}

const SRC = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
export default function KakaoPostCode() {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = SRC
    window.document.body.appendChild(script)
  }, [])

  return null
}
