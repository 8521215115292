import styled from '@emotion/styled'
import { useState } from 'react'
//import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { IcCheveronDown, IcCheveronRight, IcHeart, IcShareBall, IcShoppingCart, IcStarFull } from '@/components/Icons'
import { numberWithCommas } from '@/utils/numbers'

import DetailPageHeader from './component/DetailPageHeader'
import Tag from './component/Tag'

const ShoppingItemDetailPage = () => {
  //const navigate = useNavigate()
  const HeaderRightAppend = () => {
    return (
      <>
        <IcShoppingCart
          width="24px"
          height="24px" />
      </>
    )
  }
  const [imageIndex, setImageIndex] = useState(1)
  const shoppingItem = {
    shop: '너의 티타임',
    likes: 45,
    reviews: 20,
    name: '[2022] 흑유 시보리다시 검은색 다관 다기 도자기 차 주전자 _SPMSDFSDF01 차 마실때',
    originalPrice: 49000,
    discountRate: 20,
    discountedPrice: 39200
  }
  const coupon = { maximumDiscount: 20000 }
  const sliderImages = ['https://modo-phinf.pstatic.net/20220227_248/1645943957652QyzRx_JPEG/mosaMkaDhP.jpeg?type=w1100', 'https://contents.sixshop.com/thumbnails/uploadedFiles/122849/product/image_1678506167071_1500.jpg', 'https://i.pinimg.com/736x/03/9e/d4/039ed4b3533f14fbbe189a2fcc3ad214.jpg']
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setImageIndex(index)
    }
  }
  return (
    <PageWrapper>
      <DetailPageHeader
        title={shoppingItem.shop}
        rightAppend={<HeaderRightAppend />} />
      <ImageWrapper>
        <ImageIndexWrapper>
          {imageIndex + 1} / {sliderImages.length}
        </ImageIndexWrapper>
        <Slider {...settings}>
          {sliderImages.map((image, index) => {
            return (
              <SliderImage
                src={image}
                key={index} />
            )
          })}
        </Slider>
      </ImageWrapper>
      <ShareWrapper>
        <ShopNav>
          {shoppingItem.shop}
          <IcCheveronRight
            width='20px'
            height='20px' />
        </ShopNav>
        <ShopNav>
          <IcShareBall
            width='24px'
            height='24px'
          />
        </ShopNav>
      </ShareWrapper>
      <Divider />
      <LikeBox>
        <IcHeart
          width='16px'
          height='16px'
          fill='#dd1100' />
        <LikeText><b>{shoppingItem.likes}</b>명이 좋아하는 상품이에요.</LikeText>
      </LikeBox>
      <InfoWrapper>
        <TitleWrapper>
          <TagsWrapper>
            <Tag>인기</Tag>
          </TagsWrapper>
          <Title>{shoppingItem.name}</Title>
          <ReviewWrapper>
            <ReviewStars reviewPoint={3.5} />
            <ReviewText> 리뷰 {shoppingItem.reviews}개</ReviewText>
          </ReviewWrapper>
          <Price
            originalPrice={shoppingItem.originalPrice}
            discountRate={shoppingItem.discountRate}
            discountedPrice={shoppingItem.discountedPrice} />
        </TitleWrapper>
        <Coupon maxDiscountPrice={coupon.maximumDiscount} />
      </InfoWrapper>
      <Divider />
    </PageWrapper>
  )
}
export default ShoppingItemDetailPage
const PageWrapper = styled.div`
  padding : 50px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
`
const SliderImage = styled.img`
  width: 100%;
  height: 60vh;
  object-fit: cover;
`
const ImageWrapper = styled.div`
  position: relative;
`
const ImageIndexWrapper = styled.div`
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.mainGreen};
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
`
const LikeBox = styled.div`
  padding: 5px 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.white};
  gap: 10px;
`
const LikeText = styled.div`
display: flex;
align-items: center;
font-weight: 200;
color: ${({ theme }) => theme.black};
`
const ShareWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between
`
const ShopNav = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 800;
  font-size: 16px;
  color: ${({ theme }) => theme.black};
`
const Divider = styled.div`
  height: 0px;
  border: 1px solid ${({ theme }) => theme.gray1};
`
//Title
const InfoWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${({ theme }) => theme.black};
`
//Review
interface ReviewStarsType {
  reviewPoint: number;
}
const ReviewStars = ({ reviewPoint }: ReviewStarsType) => {
  const fullStars = Math.floor(reviewPoint)
  const halfStar = reviewPoint - fullStars >= 0.5

  const renderStars = () => {
    const stars = []
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<IcStarFull
          key={i}
          width='12px'
          height='12px'
          fill='#eecc00' />)
      } else if (i === fullStars + 1 && halfStar) {
        stars.push(<IcStarFull
          key={i}
          width='12px'
          height='12px'
          fill='#ccc' />)
      } else {
        stars.push(<IcStarFull
          key={i}
          width='12px'
          height='12px'
          fill='#ccc' />)
      }
    }
    return stars
  }

  return <ReviewStarWrapper>{renderStars()}</ReviewStarWrapper>
}

const ReviewStarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`
const ReviewText = styled.div`
  display: flex;
`
const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  text-decoration: underline;
`

//Price
interface PriceType {
  originalPrice: number;
  discountRate: number;
  discountedPrice: number;
}
const Price = ({ originalPrice, discountRate, discountedPrice }: PriceType) => {
  return (
    <PriceRowBox>
      <PriceColumnBox>
        <DiscountRateText>D 할인</DiscountRateText>
        <DiscountedPriceText>{discountRate}%</DiscountedPriceText>
      </PriceColumnBox>
      <PriceColumnBox>
        <OriginalPrice>{numberWithCommas(originalPrice)}</OriginalPrice>
        <DiscountedPriceText>{numberWithCommas(discountedPrice)}</DiscountedPriceText>
      </PriceColumnBox>
    </PriceRowBox>
  )
}
const PriceRowBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const PriceColumnBox = styled.div`
  display: flex;
  flex-direction: column;  
`
const DiscountRateText = styled.div`
  color: ${({ theme }) => theme.mainGreen };
  font-weight: 700;
`
const DiscountedPriceText = styled.div`
  color: ${({ theme }) => theme.mainGreen };
  font-weight: 900;
`
const OriginalPrice = styled.div`
  text-decoration: line-through;
`

interface CouponType {
  maxDiscountPrice: number;
}
const Coupon = ({ maxDiscountPrice }: CouponType) => {
  return (
    <CouponWrapper>
      최대 {maxDiscountPrice}원 할인 쿠폰받기
      <IcCheveronDown
        width='24px'
        height='24px'
        fill='#01c897'
      />
    </CouponWrapper>
  )
}
const CouponWrapper = styled.div`
  padding: 10px 20px;
  font-weight: 800;
  display:flex;
  align-items: center;
  border: 1px solid #01c897;
  color: #01c897;
  border-radius: 4px;
  justify-content: space-between;
  max-width: 400px;
`
