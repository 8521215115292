import { Methods, request } from '@/libs/request'

export type GetImagesOfTeahouseType = string[];

export const getImagesOfTeahouse = (id: number) => {
  return request<GetImagesOfTeahouseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/teahouse/${id}/images`,
    method: Methods.GET
  })
}
