import { useState } from 'react'

import { postEntitleMakerBusiness } from '@/apis/maker/postEntitleMakerBusiness'
import { postEntitleMakerNormal, PostEntitleMakerRequestType, PostEntitleMakerResponseType } from '@/apis/maker/postEntitleMakerNormal'

export type UseEntitleMakerReturnType = {
  isLoading: boolean;
  isError: boolean;
  success: boolean;
  message: string;
  entitle: ({ data, isBusiness }: { data: PostEntitleMakerRequestType; isBusiness: boolean }) => Promise<void>;
}

export const useEntitleMaker = (): UseEntitleMakerReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  const entitle = async({ data, isBusiness }: { data: PostEntitleMakerRequestType; isBusiness: boolean }): Promise<void> => {
    try {
      setIsLoading(true)
      setMessage('잠시만 기다려주세요.')
      const response = isBusiness ? await postEntitleMakerBusiness(data) : await postEntitleMakerNormal(data)
      const { success, message }: PostEntitleMakerResponseType = response
      setSuccess(success)
      setMessage(message)
    } catch (error) {
      setIsError(true)
      setMessage('메이커 신청에 실패하였습니다.')
    } finally {
      setIsLoading(false)
    }
  }
  return { isLoading, isError, success, message, entitle }
}