
import styled from '@emotion/styled'

export const PageStyleBlock = styled.div`
  /* overflow-y: scroll;
  overscroll-behavior: contain;
  height: 100vh;
  height: calc(100vh + env(safe-area-inset-bottom)); */
  /* min-height: 100%;
  height: auto; */
  background: ${({ theme }) => theme.white};
  
  color: ${({ theme }) => theme.gray4};

  a{
    opacity: 1;
    color: ${({ theme }) => theme.gray4};
    /* &:active{
      opacity: 0.2;
    } */

    > span{
      transition: opacity 0.3s;
      &:active{
        opacity: 0.2;
      }
    }
  }
  
  /* Icon */
  // svg{
  //   width: 16px;
  //   height: 16px;
  //   vertical-align: middle;
  //   /* margin-bottom: 4px; */
  //   fill: ${({ theme }) => theme.black};
  // }

  // input, textarea {
  //   &:focus{
  //     outline: ${({ theme }) => theme.mainGreen} solid 2px;
  //   }
  //}
  // textarea {
  //     &:focus{
  //       outline: ${({ theme }) => theme.mainGreen} solid 2px;
  //     }
  //   }
textarea, pre {
    
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.black};
    ::placeholder {
      color: ${({ theme }) => theme.gray3};
  }
  input {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    &[type="number"] {
      -moz-appearance: textfield;
    }
  } 
  }

  button{
    cursor: pointer;
  
    transition: opacity 0s; 
    &:active{
      opacity: 0.9;
      &:disabled{
        opacity: 0.8;
      }
    }
  }

  strong{
    font-weight: bold;
  }
`
