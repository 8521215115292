import { Methods, request } from '@/libs/request'

export type GetParticipatorInfoResponseType = {
  id: number;
  boothNumber: number;
  name: string;
  description: string;
  handleTea: string;
}
export const getParticipatorInfo = ({ festivalId, participatorId }: { festivalId: number; participatorId: number }) => {
  return request<GetParticipatorInfoResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}/participator/${participatorId}`,
    method: Methods.GET
  })
}
