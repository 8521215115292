import { Methods, request } from '@/libs/request'

export type PostVisitorLogRequestType = {
  festivalId: number;
  content: string;
}

export const postVisitorLog = async({ festivalId, content }: PostVisitorLogRequestType) => request<void>({
  url: `/festival/${festivalId}/visitor`,
  method: Methods.POST,
  data: { content }
})

