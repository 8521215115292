import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type GetMyHandleResponseType = {
  handle: string;
}

export const getMyHandle = () => {
  return request<GetMyHandleResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/user/handle',
    method: Methods.GET,
    headers: { 'Authorization': `Bearer ${getAccessToken()}` }
  })
}
