import { Methods, request } from '@/libs/request'
import { TeahouseType } from '@/pages/teahouse/TeahouseCreatePage'
import { getAccessToken } from '@/utils/jwt'

export type postTeahouseType = TeahouseType

export const postTeahouse = async({ name, location, locationLocal, specificLocation, nation, city, cityLocal, subCity, subCityLocal }: postTeahouseType) => {
  return request<{ id: number }>({
    url: 'teahouse',
    method: Methods.POST,
    data: { name, location, locationLocal, specificLocation, nation, city, cityLocal, subCity, subCityLocal },
    headers: { Authorization: `Bearer ${getAccessToken()}` }
  })
}
