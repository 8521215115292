import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PatchTeahouseMenusRequestType = {
  id: number;
  name: string;
  description: string;
  image: string;
  category: string;
  price: number;
}[]

export const patchTeahouseMenus = async(teahouseId: number, menus: PatchTeahouseMenusRequestType) => request<void>({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  url: `teahouse/${teahouseId}/menu`,
  method: Methods.PATCH,
  data: menus,
  headers: { 'Authorization': `Bearer ${getAccessToken()}` }
})
