import { Methods, request } from '@/libs/request'
import { PostDetailInfoModel } from '@/models/post/postDetailInfoModel'

export type GetPostResponseType = PostDetailInfoModel;

export const getPost = ({ postId }: { postId: number }) => {
  return request<GetPostResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/post/${postId}`,
    method: Methods.GET
  })
}
