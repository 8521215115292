import styled from '@emotion/styled'
import { useState } from 'react'

import ToastType from '@/enums/toast'
import usePostConfirmSMS from '@/hooks/usePostConfirmSMS'
import usePostQuickStart from '@/hooks/usePostQuickStart'
import usePostSendSMS from '@/hooks/usePostSendSMS'
import { isError } from '@/libs/request'
import { storeAccessToken, storeRefreshToken } from '@/utils/jwt'

import { notifyRenewal } from '../Common/ToastRenewal'
import InputDefault from '../Input/InputDefault'
import InputPhoneNumber from '../Input/InputPhoneNumber'

const OVERLAY_SELECTOR_ID = 'auth_modal_overlay'
const MODAL_SELECTOR_ID = 'auth_modal'
type AuthRequiredType = 'ABSOLUTE' | 'FLEXIBLE';

const AuthModal = () => {
  //인증번호 SEND 훅
  const { mutate: postSendSMS } = usePostSendSMS()
  //QuickStart 훅
  const { mutate: postQuickStart } = usePostQuickStart()
  //인증번호 Confirm 훅
  const { mutate: postConfirmSMS } = usePostConfirmSMS()
  const [code, setCode] = useState(82)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [confirmData, setConfirmData] = useState('')

  //if (!isModalVisible) return null
  //[Handler]인증번호 보내기
  const clickSendHandler = () => {
    postSendSMS({ phoneNumber, code }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '인증번호가 발송되었습니다', type: ToastType.INFORMATION })
        setIsClicked(true)
      }
    })
  }
  //[Handler]인증번호 확인
  const clickConfirmHandler = () => {
    postConfirmSMS({ phoneNumber, code, validationNumber: Number(confirmData) }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message: '인증번호오류: ' + message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        postQuickStart({ phoneNumber, code }, {
          onError: (error) => {
            if (isError(error)){
              const message = error?.response?.data?.message || 'Error'
              notifyRenewal({ message: '회원등록오류: ' + message, type: ToastType.ERROR })
            }
          },
          onSuccess: (data) => {
            storeAccessToken(data.accessToken)
            storeRefreshToken(data.refreshToken)
            closeModal()
            notifyRenewal({ message: 'Success', type: ToastType.INFORMATION })
          }
        })
      }
    })
  }
  return (
    <Overlay
      id={OVERLAY_SELECTOR_ID}
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <ModalWrapper id={MODAL_SELECTOR_ID}>
        <div onClick={() => closeModal()}>끄기</div>
        <SubTitle>로그인이 필요해요</SubTitle>
        <Content>간편 로그인(회원가입)을 통해서 바로 즐길 수 있어요</Content>
        <Title>딱! 이것만 입력해주세요</Title>
        <InputWrapper>
          <InputPhoneNumber
            title='휴대폰 번호(Phone Number)'
            placeholder='01000000000'
            hasButton={true}
            onClick={() => clickSendHandler()}
            setCode={setCode}
            setPhonenumber={setPhoneNumber} />
          {isClicked && <InputDefault
            hasButton={true}
            title='인증번호 확인(Confirm)'
            placeholder='인증번호 6자리'
            buttonName='인증'
            inputType='number'
            onClick={clickConfirmHandler}
            setInputValue={setConfirmData} />}
        </InputWrapper>
      </ModalWrapper>
    </Overlay>
  )
}

export default AuthModal

export const toggleAuthModal = (required: AuthRequiredType) => {
  if (typeof window !== 'undefined' && document) {
    console.log('toggle modal', required)
    const modalOverlay = document.getElementById(OVERLAY_SELECTOR_ID) as HTMLElement
    modalOverlay.style.display = 'flex'
  }
}

const closeModal = () => {
  const modalOverlay = document.getElementById(OVERLAY_SELECTOR_ID) as HTMLElement
  modalOverlay.style.display = 'none'
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
`

const ModalWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 400px;
  max-width: 90%;
`
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.mainGreen)};
`
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${((props) => props.theme.mainGreen)};
`
const Content = styled.div`
  font-size: 18px;
  font-weight: 500;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

`
