
import { Link } from 'react-router-dom'

import { H2, PageBlockCenter } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import { ROUTER } from '@/router'

const { MAKER } = ROUTER

function JoinCompletePage() {
  /* TODO: makerId */
  const MAKER_ID = 11
  return (
    <Layout>
      <Header />
      <Content>
        <PageBlockCenter>
          <H2>회원가입이 완료되었습니다.</H2>
        </PageBlockCenter>
      </Content>
      <Link
        to={`${MAKER}/${MAKER_ID}/my`}
        replace>
        <CTAButton
          disabled={false}
          label="마이페이지로 이동하기"
        />
      </Link>
    </Layout>
  )
}

export default JoinCompletePage
