import { Methods, request } from '@/libs/request'

export type PostCheckMakerNickNameRequestType = {
  nickName: string;
}
export type PostCheckMakerNickNameResponseType = {
  duplicated: boolean;
}

////api/v1/daru-maker/validation/nickname/{nickname}
export const postCheckMakerNickName = async({ nickName }: PostCheckMakerNickNameRequestType) => request<PostCheckMakerNickNameResponseType>({
  url: `daru-maker/nickname/validation/${ nickName }`,
  method: Methods.GET
})
