export type PostSettleIncomeRequestType = {
  requestPrice: number;
}
export type PostSettleIncomeResponseType = {
  requestPrice: number;
  date: string;
}
export const postSettleIncome = async ({ requestPrice }: PostSettleIncomeRequestType) => {
  console.log(requestPrice)
  return new Promise<PostSettleIncomeResponseType>((resolve) => {
    setTimeout(() => {
      resolve({
        requestPrice,
        date: '2022.09.15. 18:00:33'
      })
    }, 3000)
  })
}