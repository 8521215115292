import { useQuery } from 'react-query'

import { kakaoSearch } from '@/apis/teahouse/kakaoSearch'

const getQueryKey = (keyword: string) => [keyword]

export default function useKakaoSearch(keyword: string){
  return useQuery(
    getQueryKey(keyword),
    async () => {
      const results = await kakaoSearch(keyword)
      return results
    }
  )
}
