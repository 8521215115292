import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

type ButtonType = {
  color?: `#${string}`;
  link?: string;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  inline?: boolean;
  ghost?: boolean;
  isRadius?: boolean;
}
function Button({
  color,
  link,
  label,
  onClick,
  disabled = false,
  inline = false,
  ghost = false,
  isRadius = false
}: ButtonType) {
  return (
    !disabled && link ? (
      <Link to={link}>
        <ButtonBlock
          onClick={onClick && onClick}
          disabled={disabled}
          inline={inline}
          ghost={ghost}
          isRadius={isRadius}
          color={color}
        >
          {label}
        </ButtonBlock>
      </Link>
    ) : (<ButtonBlock
      onClick={onClick && onClick}
      disabled={disabled}
      inline={inline}
      ghost={ghost}
      isRadius={isRadius}
      color={color}
    >
      {label}
    </ButtonBlock>)
  )
}

export default Button

const ButtonBlock = styled.button<{ disabled: boolean; inline: boolean; ghost: boolean; isRadius: boolean; color?: string }>`
  border: 1px solid ${({ theme, disabled }) => disabled ? theme.gray1 : theme.mainGreen};
  background: ${({ theme, ghost }) => ghost ? theme.gray1 : theme.mainGreen};
  color: ${({ theme, ghost }) => ghost ? theme.mainGreen : theme.white};
  font-size: 16px;
  opacity: 1;

  ${({ ghost, color }) => color &&
  !ghost ?
    css`
      color: #fff;
      background: ${color};
      border: 1px solid ${color};
` : css`
      color: ${color};
      /* border: 1px solid ${color}; */
  `};


  ${({ disabled, theme }) => disabled && css`
    &:disabled{
      background : ${theme.gray3};
      color : ${theme.gray4};
    }
  `};

  border-radius: ${({ inline, isRadius }) => isRadius ?
    inline ? '5px' : '24px'
    : 0};

  ${({ inline }) => inline ? css`
    height: 100%;
    display: inline-block;
    padding: 4px 8px;
  ` : css`
    display: block;
    height: 50px;
    width: 100%;
  `};
`