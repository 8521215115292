import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PatchUserRequestType = {
  nickname?: string;
  handle?: string;
  profileImage?: string;
  description?: string;
}

export const patchUser = async(user: PatchUserRequestType) => request<void>({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  url: 'user',
  method: Methods.PATCH,
  data: user,
  headers: { 'Authorization': `Bearer ${getAccessToken()}` }
})
