import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import ToastType from '@/enums/toast'
import usePostApprovePayment from '@/hooks/usePostApprovePayment'
import { isError } from '@/libs/request'
import { deletePaymentInfoOfLocalStorage, PaymentStorageName, PaymentType } from '@/utils/payment'

const PaymentLoadingPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutate: postApprovePayment } = usePostApprovePayment()
  useEffect(() => {
    const type = localStorage.getItem(PaymentStorageName.type)
    console.log('💎', type)
    if (type == PaymentType.teaTogether){
      console.log('💎💎💎')
      const paymentApproveRequestData = {
        orderId: searchParams.get('orderId'),
        paymentKey: searchParams.get('paymentKey'),
        amount: Number(searchParams.get('amount')),
        roomId: Number(localStorage.getItem(PaymentStorageName.roomId)),
        count: Number(localStorage.getItem(PaymentStorageName.count)),
        customerName: localStorage.getItem(PaymentStorageName.customerName),
        orderName: localStorage.getItem(PaymentStorageName.orderName)
      }
      console.log(paymentApproveRequestData)
      const { orderId, paymentKey, amount, roomId, count, orderName, customerName } = paymentApproveRequestData
      const teaTogetherId = localStorage.getItem(PaymentStorageName.teaTogetherId)
      if (
        amount &&
      orderId &&
      paymentKey &&
      teaTogetherId &&
      roomId &&
      count &&
      orderName &&
      customerName
      ) {
        console.log('💎💎💎Completed💎💎💎')
        //complete API 소환 -> 1. Payment Success -> 2. Reservation Success
        postApprovePayment(
          {
            orderId,
            paymentKey,
            amount,
            order: { roomId, count },
            teaTogetherId: parseInt(teaTogetherId),
            orderName,
            customerName
          },
          {
            onError: (error) => {
              if (isError(error)) {
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message, type: ToastType.ERROR })
              }
              navigate('/error', { replace: true })
            },
            onSuccess: () => {
            // 성공 위치로 이동
              notifyRenewal({ message: '예약에 성공하였습니다.', type: ToastType.INFORMATION })
              deletePaymentInfoOfLocalStorage()
              navigate('/payment/complete', { replace: true })
            }
          }
        )
      } else {
        alert('오류')
      }
    }
  }, [navigate, postApprovePayment, searchParams])
  return (
    <PaymentPageWrapper>
      <PaymentTitle>결제가 진행 중입니다.</PaymentTitle>
      <CircularProgress />
    </PaymentPageWrapper>
  )
}
export default PaymentLoadingPage

const PaymentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px;
`
const PaymentTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: black;
`
