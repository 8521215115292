import { useQuery } from 'react-query'

import { getProfile } from '@/apis/user/getProfile'

const getQueryKey = () => ['user/profile']

export default function useProfile(handle: string) {
  return useQuery(
    getQueryKey(),
    async () => {
      const profile = await getProfile(handle)
      return profile
    }
  )
}
