import { ReactNode, useState } from 'react'

import { PostResetPasswordRequestType as DataType } from '@/apis/auth/postResetPassword'
import { ResetPasswordContext } from '@/context/LostPasswordContext'

const ResetPasswordProvider = ({ children }: { children?: ReactNode }) => {
  const [data, setData] = useState<DataType>({
    password: '',
    passwordConfirm: '',
    phoneNumber: '',
    code: 82
  })
  const [step, setStep] = useState(0)
  return (
    <ResetPasswordContext.Provider value={{ data, setData, step, setStep }}>
      {children}
    </ResetPasswordContext.Provider>
  )
}
export default ResetPasswordProvider
