import { Methods, request } from '@/libs/request'

export type GetTeaTogehtersOfTeahouseType = {
  id: number;
  title: string;
  category: string;
}[];

export const getTeaTogehtersOfTeahouse = (id: number) => {
  return request<GetTeaTogehtersOfTeahouseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/teahouse/${id}/tea-together`,
    method: Methods.GET
  })
}
