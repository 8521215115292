import { Methods, request } from '@/libs/request'

export type SearchTeahousesResponseType = {
  id: number;
  name: string;
  city: string;
  subCity: string;
  location: string;
  cityLocal: string;
  subCityLocal: string;
  locationLocal: string;
  latitude: number;
  longtitude: number;
}[]

export const searchTeahouse = (keyword: string) => {
  return request<SearchTeahousesResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/teahouse/search?keyword=${keyword}`,
    method: Methods.GET
  })
}
