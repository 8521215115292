import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import ToastType from '@/enums/toast'
import usePostApprovePayment from '@/hooks/usePostApprovePayment'
import { isError } from '@/libs/request'

const TeaTogetherReservationLoadingPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutate: postApprovePayment } = usePostApprovePayment()
  const { teaTogetherId } = useParams<{ teaTogetherId: string }>()
  useEffect(() => {
    const paymentApproveRequestData = {
      orderId: searchParams.get('orderId'),
      paymentKey: searchParams.get('paymentKey'),
      amount: Number(searchParams.get('amount')),
      roomId: Number(searchParams.get('ri')),
      count: Number(searchParams.get('ct')),
      customerName: searchParams.get('cn'),
      orderName: searchParams.get('on')
    }
    //QueryParameter 가져오기
    console.log(paymentApproveRequestData)
    const { orderId, paymentKey, amount, roomId, count, orderName, customerName } = paymentApproveRequestData
    if (
      amount &&
      orderId &&
      paymentKey &&
      teaTogetherId &&
      roomId &&
      count &&
      orderName &&
      customerName
    ) {
      //complete API 소환 -> 1. Payment Success -> 2. Reservation Success
      postApprovePayment(
        {
          orderId,
          paymentKey,
          amount,
          order: { roomId, count },
          teaTogetherId: Number(teaTogetherId),
          orderName,
          customerName
        },
        {
          onError: (error) => {
            if (isError(error)) {
              const message = error?.response?.data?.message || 'Error'
              notifyRenewal({ message, type: ToastType.ERROR })
            }
            navigate('/error', { replace: true })
          },
          onSuccess: () => {
            // 성공 위치로 이동
            notifyRenewal({ message: '예약에 성공하였습니다.', type: ToastType.INFORMATION })
            navigate('complete', { replace: true })
          }
        }
      )
    }
    //성공시 성공 URL, 실패시 실패 URL ㄱㄱ
  }, [navigate, postApprovePayment, searchParams, teaTogetherId])
  return (
    <>
      결제 완료가 되었습니다.
      예약 진행 중입니다.
      잠시만 기다려주세요.
    </>
  )
}
export default TeaTogetherReservationLoadingPage
