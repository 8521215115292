import { atom } from 'recoil'

const authState = atom({
  key: 'authState',
  default: {
    user: false,
    maker: false,
    redirect: '/'
  }
})
export default authState
