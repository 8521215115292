import { Methods, request } from '@/libs/request'

export type PostSendSMSRequestType = {
  code: number;
  phoneNumber: string;
}
export type PostSendSMSResponseType = {
  success: boolean;
  expireIn: number;
}
///api/v1/ncp/send-sms/validation/phone-number/sign-up
export const postSendSMS = async ({
  code,
  phoneNumber
}: PostSendSMSRequestType) => {
  try {
    const response = await request<PostSendSMSResponseType>({
      url: 'ncp/send-sms/validation/phone-number/sign-up',
      method: Methods.POST,
      data: {
        code,
        phoneNumber
      }
    })
    return response
  } catch (error) {
    console.error('휴대폰 인증 번호 발송 에러 발생', error)
    // 예상치 못한 에러 발생 시 적절한 예외 처리를 수행합니다.
    throw new Error('Failed to Request Validation SMS')
  }
}
