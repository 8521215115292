import { useQuery } from 'react-query'

import { getLikes } from '@/apis/likes/getLikes'

const getQueryKey = (id: number, type: 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER') => [`like/${type.toLowerCase}`, id]

export default function useLikes(id: number, type: 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER'){
  return useQuery(
    getQueryKey(id, type),
    async () => {
      const data = await getLikes(id, type)
      return data
    }
  )
}
