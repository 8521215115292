import styled from '@emotion/styled'
import { useState } from 'react'

import { convertNumberToTimeFormat, isTodayFromWeekday, Weekday } from '@/utils/timeSince'

export type TeahouseTimetableType = {
  times: TimetableType;
}
export type TimetableType = {
  day: Weekday;
  openedAt: number;
  closedAt: number;
  breakStartAt: number;
  breakEndAt: number;
}[];
const TeahouseTimetable = ({ times }: TeahouseTimetableType) => {
  const [showMore, setShowMore] = useState(false)
  const now = new Date()
  const currentHour = now.getHours() + now.getMinutes() / 60
  const isOperatingNow = times.some((time) => isTodayFromWeekday(time.day) &&
    currentHour >= time.openedAt &&
    currentHour < time.closedAt
  )
  return (
    <TimeTableWrapper>
      <CurrentNotificationText isCurrent={isOperatingNow}>{isOperatingNow ? '지금 운영 중이에요😀' : '지금은 운영하지 않아요 🥲'}</CurrentNotificationText>
      {showMore && <TimetableListWrapper>
        {
          times.map((teahouseTime, index) => {
            return (
              <TeahouseTimeItem
                time={teahouseTime}
                key={index} />
            )
          })
        }
      </TimetableListWrapper>}
      <ShowMoreButton onClick={() => setShowMore(!showMore)}>
        {showMore ? '감추기' : '더보기'}
      </ShowMoreButton>
    </TimeTableWrapper>
  )
}
export default TeahouseTimetable

const TimeTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`
const CurrentNotificationText = styled.div<{ isCurrent: boolean }>`
  width: 100%;
  color: ${({ isCurrent }) => isCurrent ? '#01c897' : '#aaa' };
  font-weight: ${({ isCurrent }) => isCurrent ? '800' : '400' };
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px 0;
`
const TimetableListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 768px) {
    gap: 5px;
    flex-direction: column;
  }
`
const ShowMoreButton = styled.button`
  background-color: #01c897;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
`
type TimetableItemType = {
  time: {
    day: Weekday;
    openedAt: number;
    closedAt: number;
    breakStartAt: number;
    breakEndAt: number;
  };
}
const TeahouseTimeItem = ({ time }: TimetableItemType) => {

  return (
    <TimetableItemWrapper isToday={isTodayFromWeekday(time.day)}>
      <DayText>{time.day}</DayText>
      <TimeText>{convertNumberToTimeFormat(time.openedAt)}</TimeText>
      <TimeText>~</TimeText>
      <TimeText>{convertNumberToTimeFormat(time.closedAt)}</TimeText>
    </TimetableItemWrapper>
  )
}
const TimetableItemWrapper = styled.div<{ isToday: boolean }>`
  border: ${({ isToday }) => isToday ? '2px solid #01c897' : '1px solid #ccc'};
  background-color: ${({ isToday }) => isToday ? 'rgba(1, 200, 151, 0.3)' : 'none'};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 300px;
    gap: 5px;
    flex-direction: row;
    align-items: center;
  }
`
const DayText = styled.div`
  font-weight: 800;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0px;
  }
`
const TimeText = styled.div`
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
