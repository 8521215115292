import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode, useRef } from 'react'

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  height: string;
  withinWidth: string;
  children: ReactNode;
}

const BottomSheet = ({ isOpen, onClose, height, withinWidth, children }: BottomSheetProps) => {
  const sheetRef = useRef<HTMLDivElement>(null)

  return (
    <Container
      onClick={onClose}
      style={{ display: isOpen ? 'flex' : 'none' }}>
      <Backdrop />
      <Sheet
        ref={sheetRef}
        isOpen={isOpen}
        height={height}
        onClick={(e) => e.stopPropagation()}>
        <ContentWrapper withinWidth={withinWidth}>
          {children}
        </ContentWrapper>
      </Sheet>
    </Container>
  )
}
export default BottomSheet

//aninamation in appearing, disappearing
const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const Sheet = styled.div<{ isOpen: boolean; height: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => props.height};
  background-color: #fff;
  padding: 16px;
  border-top-left-radius: 16px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 16px;
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s forwards;
`
const ContentWrapper = styled.div<{ withinWidth: string }>`
  width: ${(props) => props.withinWidth}
`