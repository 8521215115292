import { useMutation } from 'react-query'

import { postSendSMS, PostSendSMSRequestType } from '@/apis/auth/postSendSMS'

export default function usePostSendSMS() {
  return useMutation<void, unknown, PostSendSMSRequestType>(
    async({ phoneNumber, code }) => {
      await postSendSMS({ phoneNumber, code })
    }
  )
}
