import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ReactNode } from 'react'

import NotFound from './pages/NotFound'

const SentryContainer = ({ children }: { children: ReactNode }) => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.APP_ENV,
      integrations: [new BrowserTracing()]
    })
    return <Sentry.ErrorBoundary fallback={<NotFound />}>{children}</Sentry.ErrorBoundary>
  } else {
    return <>{children}</>
  }
}

export default SentryContainer
