import { Maker } from '@/context/EntitleMakerContext'
import { Methods, request } from '@/libs/request'

export type PostEntitleMakerRequestType = Maker

export type PostEntitleMakerResponseType = {
  success: boolean;
  message: string;
}

export const postEntitleMakerNormal = async({ makerName, bankName, bankAccountNumber, normalUserName, residentRegistrationNumber }: PostEntitleMakerRequestType) => request<PostEntitleMakerResponseType>({
  url: 'daru-maker/entitle/normal',
  method: Methods.POST,
  data: {
    makerName,
    bankName,
    bankAccountNumber,
    normalUserName,
    residentRegistrationNumber
  }
})

