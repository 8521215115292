import * as React from "react";
import { SVGProps } from "react";

const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="m0 11 2-2 5 5L18 3l2 2L7 18z" />
  </svg>
);

export default SvgCheckmark;
