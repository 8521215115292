import { Methods, request } from '@/libs/request'

export type PostResetPasswordRequestType = {
  phoneNumber: string;
  code: number;
  password: string;
  passwordConfirm: string;
}
export type PostResetPasswordResponseType = {
  success: boolean;
}
export const postResetPassword = async({
  phoneNumber,
  code,
  password,
  passwordConfirm
}: PostResetPasswordRequestType) => {
  try {
    const response = await request<PostResetPasswordResponseType>({
      url: '/auth/reset-password',
      method: Methods.POST,
      data: {
        phoneNumber,
        code,
        password,
        passwordConfirm
      }
    })
    return response
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}
