import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type GetMyInfoResponseType = {
  id: number;
  handle: string;
  nickname: string;
  description: string;
  profileImage: string;
  isMaker: boolean;
  isTeahouseOwner: boolean;
  reservations: UserReservationType[];
}

export type UserReservationType = {
  id: number;
  type: string;
  reservationTitle: string;
  targetId: number;
  note: string;
  status: string;
  count: number;
  times: ReservationTimeType[];
}

type ReservationTimeType = {
  id?: number;
  startAt: string;
  endAt: string;
}

export const getMyInfo = () => {
  return request<GetMyInfoResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/user/my',
    method: Methods.GET,
    headers: { 'Authorization': `Bearer ${getAccessToken()}` }
  })
}
