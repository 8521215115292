import styled from '@emotion/styled'
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'

import icon from '@/assets/svg/icons/ellipse.svg'

import StyledHr from '../Layout/StyledHr'
//타이틀, 버튼, 텍스트 | 넘버 | 이메일 , 설명 문구 넣어주기
type InputBirthType = {
  title?: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const InputBirth = forwardRef<HTMLInputElement, InputBirthType>(({
  title,
  setInputValue
}, ref) => {
  const [isInput1Focused, setIsInput1Focused] = useState(false)
  const [isInput2Focused, setIsInput2Focused] = useState(false)
  const [input1, setInput1] = useState('')
  const [input2, setInput2] = useState('')
  useEffect(() => {
    const join = `${input1}-${input2}`
    setInputValue(join)
  }, [input1, input2, setInputValue])
  //First Input Handler
  const handleInput1Focus = () => {
    setIsInput1Focused(true)
  }
  const handleInput1Blur = () => {
    setIsInput1Focused(false)
  }
  const handleInput1 = async (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    let newValue = inputValue.replace(/[^0-9]/g, '')
    if (newValue.length > 6) {
      // 입력값이 6자리를 초과하는 경우 잘라냄
      newValue = newValue.slice(0, 6)
    }
    setInput1(newValue)
  }
  //Second Input Handler
  const handleInput2Focus = () => {
    setIsInput2Focused(true)
  }
  const handleInput2Blur = () => {
    setIsInput2Focused(false)
  }
  const handleInput2 = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    let newValue = inputValue.replace(/[^1-4]/g, '')
    if (newValue.length > 1) {
      // 입력값이 1자리를 초과하는 경우 잘라냄
      newValue = inputValue.slice(0, 1)
    }
    setInput2(newValue)
  }

  return (
    <ContentBlock>
      <TitleSection>
        {title}
      </TitleSection>
      <InputSection>
        <InputWrapper>
          <Input
            type='text'
            placeholder='YYMMDD'
            value={input1}
            onInput={handleInput1}
            onFocus={handleInput1Focus}
            onBlur={handleInput1Blur}
            width='130px'
            textAlign='left'
            ref={ref} />
          <StyledHr
            isFocused={isInput1Focused} />
        </InputWrapper>
        <GapText>-</GapText>
        <InputWrapper>
          <Input
            type='text'
            placeholder='0'
            value={input2}
            onInput={handleInput2}
            onFocus={handleInput2Focus}
            onBlur={handleInput2Blur}
            width='31px'
            textAlign='center'
            ref={ref} />
          <StyledHr
            isFocused={isInput2Focused} />
        </InputWrapper>
        <Spans />
      </InputSection>
    </ContentBlock>
  )
})
InputBirth.displayName = 'InputBirth'
export default InputBirth

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 8px;
  height: auto;
  overflow: visible;
  max-width: 500px;
`
const TitleSection = styled.div`
/* 휴대폰 번호 */
left: 0%;
right: 77.56%;
top: 0%;
bottom: 69.23%;

/* Content1_medium */

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;

/* Basic/Black */

color: #191919;
`
const InputSection = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;
gap: 10px;
`

const InputWrapper = styled.div`
display: flex;
flex-direction: column;
`
const Input = styled.input<{ width: string; textAlign: string }>`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
width: ${(props) => (props.width)};
text-align: ${(props) => (props.textAlign)};
`
const GapText = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;

/* Basic/Gray2 */

color: #B4B4B4;
`
const SpanWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
height: 24px;
`

const Span = styled.img`
`
const Spans = () => {
  return (
    <SpanWrapper>
      <Span src={icon} />
      <Span src={icon} />
      <Span src={icon} />
      <Span src={icon} />
      <Span src={icon} />
      <Span src={icon} />
    </SpanWrapper>
  )
}
