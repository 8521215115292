
import styled from '@emotion/styled'
import { Link, useParams } from 'react-router-dom'

import { H2, PageBlock } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useClassDetail from '@/hooks/useClassDetail'
import useReviewListByMaker from '@/hooks/useReviewListByMaker'
import { dateTextOnlyDate } from '@/utils/text'

function ListPage() {
  const { classId } = useParams()

  if (!classId){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <ReviewList classId={classId} />
}

function ReviewList({ classId }: { classId: string }) {
  const { data: classDetail } = useClassDetail({ classId })

  if (!classDetail){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <ReviewContent makerId={classDetail.maker.id} />
}

function ReviewContent({ makerId }: { makerId: number }) {
  const { data, isLoading } = useReviewListByMaker({ makerId })

  if (isLoading){
    return <FullLoading />
  }

  if (!data){
    return null
  }

  const { maker, reviews } = data

  return (
    <Layout>
      <Header
        title="리뷰하기"
        back />
      <Content>
        <PageBlock>
          <H2>{maker.nickname}</H2>
          <p>나의 클래스/모임에 참여한 손님들의 생생한 후기를 볼 수 있어요.</p>
          <ScoreBlock>
            <TotalScore>{maker.rate} / 10</TotalScore>
            <div>{maker.totalLength}명의 평가</div>
          </ScoreBlock>

          <ClassList>
            {reviews.map(({ classMainName, startDate, reviews }, index) => {
              return (
                <ClassItem key={index}>
                  <ClassTitle>{dateTextOnlyDate(startDate, '.')} {classMainName}</ClassTitle>
                  <ReviewListBlock>
                    {reviews.length > 0 ? (reviews.map(({ reviewContent, reviewRate }, index) => {
                      return (
                        <ReviewItem key={index}>
                          <Score>{reviewRate}</Score>
                          <div>{reviewContent}</div>
                        </ReviewItem>
                      )
                    })) : (<>등록된 리뷰가 없습니다.</>)}
                  </ReviewListBlock>
                </ClassItem>
              )
            })}
          </ClassList>
        </PageBlock>
      </Content>
      <Link
        to='/'
        replace>
        <CTAButton
          disabled={false}
          label="메인으로 가기"
        />
      </Link>
    </Layout>
  )
}

export default ListPage

const ScoreBlock = styled.div`
  background-color: ${({ theme }) => theme.white };
  color: ${({ theme }) => theme.gray5 };
  padding: 30px 0;
  text-align: center;

  margin: 45px -15px;
`

export const TotalScore = styled.div`
  font-size: 36px;  
  font-weight: bold;
  margin-bottom: 10px;
`

export const ClassList = styled.ul`
`

export const ClassItem = styled.li`
  margin-bottom: 50px;
`

export const ClassTitle = styled.h5`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.gray5 };

  margin-bottom: 18px;
`

export const ReviewListBlock = styled.ul`
  color: ${({ theme }) => theme.gray3 };
`

export const ReviewItem = styled.li`
  display: flex;
  justify-content: start;
  gap: 30px;
  min-height: 30px;
  line-height: 30px;
`

export const Score = styled.ul`
  font-size: 14px;
  color: ${({ theme }) => theme.gray4 };
`
