import styled from '@emotion/styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Title from '@/components/Layout/Title'
import Modal from '@/components/Modal/Modal'
import ToastType from '@/enums/toast'
import useKakaoSearch from '@/hooks/useKakaoSearch'
import usePostTeahouse from '@/hooks/usePostTeahouse'
import { isError } from '@/libs/request'
import { userLoggedInRequired } from '@/utils/jwt'

import HeaderSubPage from '../tea-together/component/HeaderSubPage'
import { PostAddressWrapper, PostCodeButton } from '../tea-together/TeaTogetherCreatePage'

export type TeahouseType = {
  name: string;
  location: string;
  locationLocal: string;
  specificLocation: string;
  nation: string;
  city: string;
  cityLocal: string;
  subCity: string;
  subCityLocal: string;
}

const TeahouseCreatePage = () => {
  useEffect(() => {
    userLoggedInRequired('ABSOLUTE')
  }, [])
  const [modal, setModal] = useState(true)
  const [searchAble, setSearchAble] = useState(false)
  const [searched, setSearched] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [keyword, setKeyword] = useState('')
  const { mutate: postTeahouse } = usePostTeahouse()
  const navigate = useNavigate()
  const [teahouseData, setTeahouseData] = useState<TeahouseType>({
    name: '',
    location: '',
    locationLocal: '',
    specificLocation: '',
    nation: 'south korea',
    city: '',
    cityLocal: '',
    subCity: '',
    subCityLocal: ''
  })

  const updateTeahouseData = useCallback((newData: Partial<TeahouseType>) => {
    setTeahouseData((prev) => ({ ...prev, ...newData }))
  }, [setTeahouseData])

  const isAllFieldsFilled = useMemo(() => {
    return Object.values(teahouseData).every((field) => field.length > 1)
  }, [teahouseData])

  const themeObj = {
    //bgColor: "", //바탕 배경색
    searchBgColor: '#01C897', //검색창 배경색
    //contentBgColor: "", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    //pageBgColor: "", //페이지 배경색
    //textColor: "", //기본 글자색
    queryTextColor: '#FFFFFF', //검색창 글자색
    postcodeTextColor: '#01C897' //우편번호 글자색
    //emphTextColor: "", //강조 글자색
    //outlineColor: "", //테두리
  }
  function openPostcode() {
    new window.daum.Postcode({
      oncomplete: function(data: any) {
        console.log(data)
        updateTeahouseData({ locationLocal: data.address, location: data.addressEnglish, city: data.sidoEnglish, cityLocal: data.sido, subCity: data.sigunguEnglish, subCityLocal: data.sigungu })
      },
      theme: themeObj
    }).open()
  }
  function specificAddressHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeahouseData({ specificLocation: e.target.value })
  }
  function nameHandler(e: string) {
    updateTeahouseData({ name: e })
  }

  //Search
  //
  const setSearchedHandler = useCallback((newData: boolean) => {
    setSearchAble(newData)
  }, [setSearchAble])

  const searchInputHandler = useCallback((newData: string) => {
    setSearchInput(newData)
    setSearchedHandler(newData.length > 1)
    if (newData.length > 1) {
      setKeyword(newData)
    } else {
      setKeyword('')
    }
    setSearched(false)
  }, [setSearchInput, setSearchedHandler, setKeyword])

  function searchButtonHandler() {
    if (searchAble){
      setSearched(true)
    } else {
      notifyRenewal({ message: '2글자 이상 입력해야해요', type: ToastType.CAUTION })
    }
  }
  function createButtonHandler() {
    notifyRenewal({ message: '잠시만 기다려주세요', type: ToastType.CAUTION })
    if (isAllFieldsFilled) {
      postTeahouse(teahouseData, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message, type: ToastType.ERROR })
          }
        },
        onSuccess: () => {
          notifyRenewal({ message: '성공적으로 제출되었습니다', type: ToastType.INFORMATION })
          navigate('/mypage')
        }
      })
    } else {
      notifyRenewal({ message: '아직 필수정보를 모두 입력하지 않았어요', type: ToastType.CAUTION })
    }
  }
  return (
    <PageWrapper style={{ padding: '50px 20px 0px 20px', gap: '20px' }}>
      <Modal
        isVisible={modal}
        onClose={() => setModal(false)}>
        <InputTitle>찻집 이름 검색</InputTitle>
        <Input
          value={searchInput}
          onChange={(e) => searchInputHandler(e.target.value)} />
        <SearchButton
          able={searchAble}
          onClick={() => searchButtonHandler()}>검색</SearchButton>
        {(searched) && <SearchResults
          keyword={keyword}
          closeModal={() => setModal(false)}
          openPostcode={openPostcode}
          nameHandler={(e: string) => setTeahouseData((prev) => ({ ...prev, name: e }))} />}
      </Modal>
      <HeaderSubPage title='찻집 만들기' />
      <Title
        title='찻집, 차문화 공간 등록'
        subtitle='등록부터 반영까지 최소 10분에서 1일이 소요됩니다.' />
      <InputTitle>장소</InputTitle>
      <PostCodeButton
        style={{ backgroundColor: '#0055ff' }}
        onClick={() => setModal(true)}>매장명으로 검색</PostCodeButton>
      <PostCodeButton onClick={openPostcode}>우편번호 직접 입력</PostCodeButton>
      { teahouseData?.location && <PostAddressWrapper>{teahouseData?.locationLocal}</PostAddressWrapper>}
      { teahouseData?.location && <Input
        placeholder='상세주소 입력'
        onChange={specificAddressHandler} />}
      <InputTitle>찻집 이름</InputTitle>
      <Input
        value={teahouseData.name}
        onChange={(e) => nameHandler(e.target.value)} />
      <SearchButton
        able={isAllFieldsFilled}
        onClick={() => createButtonHandler()}>신청하기</SearchButton>
    </PageWrapper>
  )
}

export default TeahouseCreatePage

const InputTitle = styled.div`
  font-weight: 700;
`
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.gray2 };
  border-radius: 10px;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  height: 35px;
  padding: 20px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.mainGreen };
    outline: none; // this is optional, to remove the default browser outline on focus
  }
`
const SearchButton = styled.button<{ able: boolean }>`
border-radius: 10px;
width: 100%;
min-width: 200px;
max-width: 500px;
display: flex;
justify-content: center;
align-items: center;
background-color: ${({ able }) => able ? '#01c897' : '#ccc' };
color: #fff;
padding: 10px 0px;
`

interface SearchResultsType {
  keyword: string;
  openPostcode: () => void;
  closeModal: () => void;
  nameHandler: (e: string) => void;
}
const SearchResults = ({ keyword, openPostcode, nameHandler, closeModal }: SearchResultsType) => {
  const { data } = useKakaoSearch(keyword)
  try {

    console.log('😀', keyword)
    console.log('결과값', data)
    let results: any
    if (data.documents){
      results = data.documents
    }
    const clickHandler = async (name: string, address: string) => {
      nameHandler(name)
      await navigator.clipboard.writeText(address)
      notifyRenewal({ message: `${name}의 주소가 복사되었어요. 주소 검색에 붙여넣으세요`, type: ToastType.INFORMATION })
      openPostcode()
      closeModal()
    }
    return (
      <ResultListWrapper>
        {results.map((result: any, index: number) => {
          return (
            <ResultListItemWrapper
              key={index}
              onClick={() => clickHandler(result.place_name, result.road_address_name)}>
              <ResultTitle>{result.place_name}</ResultTitle>
              <ResultAddress>{result.road_address_name}</ResultAddress>
            </ResultListItemWrapper>
          )
        })}
      </ResultListWrapper>
    )
  } catch (e) {
    console.log(e)
    return null
  }
}

const ResultListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  max-height: 40vh;
  overflow-y: scroll;
`
const ResultListItemWrapper = styled.div`
`
const ResultTitle = styled.div`
  font-weight: 700;
`
const ResultAddress = styled.div`
`
