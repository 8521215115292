import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BusinessFooter from '@/businees/business.footer'
import { CTAButton } from '@/components/Input/CTAButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Title from '@/components/Layout/Title'

const DeleteUserPage = () => {
  const navigate = useNavigate()
  const [phone, setPhone] = useState('')
  const [handle, setHandle] = useState('')
  return (
    <PageWrapperCentered>
      <Title
        title='회원 탈퇴'
        subtitle='휴대폰 인증을 하고 회원탈퇴를 진행합니다' />
      <div style={{ height: 20 }}></div>
      <Input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder='휴대폰 번호를 입력하세요' />
      <div style={{ height: 20 }}></div>
      <Input
        value={handle}
        onChange={(e) => setHandle(e.target.value)}
        placeholder='Handle를 입력하세요' />
      <div style={{ height: 100 }}></div>
      <p>
        1. 계정 삭제를 위해서는 PhoneNumber와 Handle을 입력해서 제출합니다.
      </p>
      <p>
        2. 계정 삭제 신청 이후에는 담당자가 계정 본인이 맞는지 인증을 한 이후에 처리하여 드립니다.
      </p>
      <p>
      3. 고객의 데이터는 신청 이후에 휴면 상태가 되며, 3개월 이후에 완전히 데이터가 삭제됩니다.
      </p>
      <div style={{ height: 100 }}></div>
      <CTAButton
        text='신청하기'
        colorType='green'
        disabled={false}
        onClick={() => {
          if (phone.length > 0 && handle.length > 0){
            alert('신청 완료되었습니다. 처리 후 결과를 나중에 알려드리겠습니다.')
            navigate('/')
          } else {
            alert('입력값을 입력해야합니다.')
          }
        }} />
      <BusinessFooter />
    </PageWrapperCentered>
  )
}

export default DeleteUserPage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 20px 20px;
`

const Input = styled.input`
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid #01c897;
`
