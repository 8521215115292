import { useQuery } from 'react-query'

import { getWaitingForWithdrawalList } from '@/apis/getWaitingForWithdrawalList'

const getQueryKey = () => ['waitingForWithdrawal-list']

export default function useWaitingForWithdrawalList() {
  return useQuery(getQueryKey(), async () => {
    const settlemenetList = await getWaitingForWithdrawalList()
    return settlemenetList
  })
}
