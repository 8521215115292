import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Logo from '@/components/Layout/LogoCustom'

const CreateReviewCompletePage = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()
  const { participatorId } = useParams<{ participatorId: string }>()
  const goFestivalMain = () => {
    navigate('/festival/gyeongju/2023', { replace: true })
  }
  const goFestivalBooth = () => {
    navigate(`/festival/gyeongju/2023/participator/${participatorId}`, { replace: true })
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play()
      }
    }, 500)

    // 컴포넌트가 언마운트 될 때 타이머를 정리합니다.
    return () => {
      clearTimeout(timer)
    }
  }, [])
  return (
    <PageWrapperCentered>
      <LogoWrapper>
        <Logo
          width='58px'
          height='39px' />
      </LogoWrapper>
      <CompleteAnimation
        ref={videoRef}
        muted
        autoPlay>
        <source
          src='/mp4/complete.mp4'
          type='video/mp4' />
      </CompleteAnimation>
      <ThanksText>이번에 떠나온 차의 여정은 어떠셨나요?</ThanksText>
      <ThanksText>또 새로운 다객이 되어, 차를 찾아 떠나볼까요?</ThanksText>
      <BigButtonWrapper onClick={() => goFestivalMain()}>다른 부스 찾아보기</BigButtonWrapper>
      <BigButtonWrapper onClick={() => goFestivalBooth()}>돌아가기</BigButtonWrapper>
    </PageWrapperCentered>
  )
}

export default CreateReviewCompletePage
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const LogoWrapper = styled.div`
  position: fixed;
  top:0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`
const PageWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${((props) => props.theme.background)};
  gap: 15px;
`
const ThanksText = styled.div`
  color: ${((props) => props.theme.black)};
  opacity: 0;
  animation: ${fadeIn} 3s ease-in-out forwards; // 1초 동안 fadeIn 애니메이션 적용 
  animation-delay: 2s;
`
const BigButtonWrapper = styled.div`
  width: 80%;
  min-width: 200px;
  max-width: 400px;
  height: 40px;
  gap: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: ${((props) => props.theme.mainGreen)};
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards; // 1초 동안 fadeIn 애니메이션 적용 
  animation-delay: 3s;
  cursor: pointer;
`
const CompleteAnimation = styled.video`
  width: 150px;
  height: 150px;
`
