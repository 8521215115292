import styled from '@emotion/styled'

import { Body1, Body2 } from '../Base/Text'
import StyledHr from '../Layout/StyledHr'

interface InputBankProps {
  bank: string;
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
}
const InputBank = ({ bank, onClick }: InputBankProps) => {
  return (
    <Block onClick={() => onClick(true)}>
      <Title>은행</Title>
      <Wrapper>
        {(bank) ? <Text>{bank}</Text> : <Placeholder>클릭 후 은행 선택</Placeholder>}
        <StyledHr isFocused={false} />
      </Wrapper>
    </Block>
  )
}
export default InputBank

const Block = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
padding: 16px 24px;
gap: 12px;
max-width: 500px;
width: 100%;
`
const Wrapper = styled.div`
`
const Title = styled(Body2)`
color: ${({ theme }) => theme.black};
`
const Placeholder = styled(Body1)`
color: ${({ theme }) => theme.gray3};
`
const Text = styled(Body1)`
color: ${({ theme }) => theme.black};
`