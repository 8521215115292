
import styled from '@emotion/styled'
import { createTheme, ThemeProvider } from '@mui/material'
import { Suspense } from 'react'

import Splash from './components/Layout/Splash'
import { PageStyleBlock } from './components/Theme/PageStyleBlock'
import ErrorBoundary from './ErrorBoundary'
import PageRouter from './PageRouter'
import ErrorPage from './pages/Error'

function App() {
  const materialTheme = createTheme()
  // const [themeMode, setThemeMode] = useState('light');
  // const theme = themeMode === 'light' ? light : dark;
  // setThemeMode('유저가 선택한테마 => dark')
  // materialTheme[themeMode]
  return (
    <>
      <Underlay />
      <ThemeProvider theme={materialTheme}>
        <PageStyleBlock>
          <ErrorBoundary fallback={<ErrorPage />}>
            <Suspense fallback={<Splash />}>
              <PageRouter />
            </Suspense>
          </ErrorBoundary>
        </PageStyleBlock>
      </ThemeProvider>
    </>
  )
}

export default App

const Underlay = styled.div`
  background: ${({ theme }) => theme.white};
  z-index: -1;
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
`
