import React, { createContext } from 'react'

import TermsType from '@/enums/termsType'

export interface User {
  email?: string;
  password: string;
  nickname: string;
  phoneNumber: string;
  realName: string;
  birth: string;
  code: number;
  termCodes: TermsType[];
}

export interface SignUpContextType {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const SignUpContext = createContext<SignUpContextType>({
  user: {
    email: '',
    password: '',
    nickname: '',
    phoneNumber: '',
    realName: '',
    birth: '',
    code: 82,
    termCodes: []
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
  step: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: () => {},
  error: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setError: () => {}
})
