import { Methods, request } from '@/libs/request'

export type ClassStatusType = 'BEFORE_RECRUITING' | 'RECRUITING' | 'FINISHED_RECRUITING' | 'IN_PROGRESS' | 'FINISH_CLASS' | 'CANCELD_CLASS';
export type GetClassDetailResponseType = {
  daruClassId: number;
  classCategory: string;
  classMainName: string;
  classSubName: string;
  recruitmentTarget: string;
  teacherInformation: string; // \n 으로 구분
  maker: {
    id: number;
    nickname: string;
    profileImage: string;
    phoneNumber: string;
    rate: number;
    totalReviews: number;
  };
  address: {
    code: string;
    jibun: string;
    address: string;
    addressDetail: string;
    sido: string;
    sigungu: string;
  };
  // { "BEFORE_RECRUITING": "모집전", "RECRUITING": "모집중", "FINISHED_RECRUITING": "모집완료", "IN_PROGRESS": "클래스진행중", "FINISH_CLASS": "진행완료" }
  classStatus: ClassStatusType;
  recruitPersonnel: number;
  currentPersonnel: number;
  classProgressDate: {
    classRoundStartDate: string;
    classRoundEndDate: string;
  }[]; // 날짜로 정렬
  classDetailContent: string;
  requiredClassFee: number;
  recruitProgressDate: {
    recruitStartDate: string;
    recruitEndDate: string;
  };
  contactNumber: string; // -로 구분
  thumbnailImageURL: string;
  detailImagesURL: string[];
  tasteTeaList: string[];
}

export const getClassDetail = ({ classId }: { classId: string }) => {
  return request<GetClassDetailResponseType>({
    url: `/daru-class/${classId}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/getClassDetail.json',
    method: Methods.GET
  })
}