import styled from '@emotion/styled'
import { useRef, useState } from 'react'

import { PageWrapper } from '@/components/Layout/frame/Frame'
import Title from '@/components/Layout/Title'

const TeaTogetherCreateBannerPage = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  type FileArrayState = File | null
  const [selectedFile, setSelectedFile] = useState<FileArrayState>(null)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0])
    }
  }
  return (
    <PageWrapperCentered>
      <Title
        title='다루에 링크 연결하기'
        subtitle='기존 가지고 있는 링크로 다루에 링크를 연결할 수 있어요' />
      <InputTitle>제목</InputTitle>
      <Input />
      <InputTitle>한 줄 설명</InputTitle>
      <Input />
      <InputTitle>대표사진</InputTitle>
      <ImageInputWrapper>

        <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
        <ImageInput
          id="imageInput"
          type='file'
          onChange={handleFileChange}
          ref={fileInputRef}
          name='image' />
      </ImageInputWrapper>
      {selectedFile && <ImagePreview src={URL.createObjectURL(selectedFile)} />} {/* Check if selectedFile is not null before rendering */}
    </PageWrapperCentered>
  )
}
export default TeaTogetherCreateBannerPage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 30px 0px 30px;
  align-items: flex-start;
  gap: 10px;
`
const InputTitle = styled.div`
  font-weight: 700;
`
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.gray2 };
  border-radius: 10px;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  height: 35px;
  padding: 20px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.mainGreen };
    outline: none; // this is optional, to remove the default browser outline on focus
  }
`
const ImageInputWrapper = styled.div`
`
const ImageUploadLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #01c897;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #019e76;
  }
`
const ImageInput = styled.input`
  display: none;
`
const ImagePreview = styled.img`
  width: 200px;
  aspect-ratio: 1/1;
  border: 1px solid #01c897;
  border-radius: 20px;
  object-fit: cover;
`

