import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import authState from '@/atoms/authState'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { CTAButton } from '@/components/Input/CTAButton'
import InputDefault from '@/components/Input/InputDefault'
import InputPhoneNumber from '@/components/Input/InputPhoneNumber'
import { PageWrapperCentered } from '@/components/Layout/frame/Frame'
import Header from '@/components/Layout/Header'
import LogoCustom from '@/components/Layout/LogoCustom'
import ToastType from '@/enums/toast'
import { useSignIn } from '@/hooks/useSignIn' // usePostSignIn 훅 임포트
// Styled-components로 스타일링된 입력 필드 컴포넌트

// 로그인 페이지 컴포넌트
const LoginPage = () => {
  const {
    signIn, // usePostSignIn 훅에서 반환하는 signIn 함수
    isLoading, // usePostSignIn 훅에서 반환하는 isLoading 상태
    isError, // usePostSignIn 훅에서 반환하는 error 여부
    message
  } = useSignIn() // usePostSignIn 훅 사용

  const [code, setCode] = useState(82)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const auth = useRecoilValue(authState)
  const navigate = useNavigate()
  // 로그인 버튼 클릭 시 동작하는 핸들러
  const handleLogin = () => {
    // 로그인 요청 처리 로직
    signIn({ phoneNumber: phoneNumber, code: code, password: password }) // usePostSignIn 훅의 signIn 함수 호출
  }
  const handleButtonStatus = useMemo(() => {
    return phoneNumber == '' || password == ''
  }, [phoneNumber, password])
  const isLogined = (): boolean => {
    return auth.user
  }
  useEffect(() => {
    if (isError) notifyRenewal({ message, type: ToastType.ERROR })
    if (isLoading) notifyRenewal({ message, type: ToastType.CAUTION })
  }, [message, isLoading, isError])

  useEffect(() => {
    if (isLogined()) {
      navigate('/') // 이미 로그인한 사용자일 경우 메인 페이지로 이동하거나, 다른 페이지로 리디렉션할 수 있습니다.
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <PageWrapperCentered>
        <Header
          title="로그인"
          fadein={false}
          timing={1}
          back={true} />
        <LogoWrapper>
          <LogoCustom
            width= '78px'
            height='47.12px' />
        </LogoWrapper>
        <InputWrapper>
          <InputPhoneNumber
            title="휴대폰 번호"
            placeholder='01012341234'
            hasButton={false}
            setCode={setCode}
            setPhonenumber={setPhoneNumber} />
          <InputDefault
            title="비밀번호"
            placeholder='8자 ~ 16자 (영문, 숫자, 특수문자)'
            inputType='password'
            hasButton={false}
            setInputValue={setPassword} />
        </InputWrapper>
        <BtnWrapper>
          <CTAButton
            colorType='green'
            text="휴대폰 번호로 로그인"
            disabled={handleButtonStatus}
            onClick={handleLogin} />
          <GapText>or</GapText>
          <CTAButton
            colorType='white'
            text='회원가입'
            disabled={false}
            linked= '/account/sign-up' />
        </BtnWrapper>
        <HelpButtonWrapper>
          <HelpButton onClick={() => notifyRenewal({ message: '해당 기능은 준비 중 입니다. contact@daruteaworld.com으로 문의주세요.', type: ToastType.CAUTION })}>휴대폰 번호 분실</HelpButton>
          <Split>I</Split>
          <HelpButton onClick={() => navigate('/account/lost-password')}>비밀번호 분실</HelpButton>
        </HelpButtonWrapper>
      </PageWrapperCentered>
    </>
  )
}

export default LoginPage

const LogoWrapper = styled.div`
width: 100%;
height: 212px;
display: flex;
align-items: center;
justify-content: center;
`
const InputWrapper = styled.div`
width: 350px;
`
const BtnWrapper = styled.div`
display: flex; 
flex-direction : column;
justify-content: center;
gap: 10px;
align-items: center;
margin-top: 72px;
`
const GapText = styled.div`

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;

/* Basic/Gray2 */

color: #B4B4B4;
`
const HelpButtonWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 20px;
margin-top: 50px;
`
const HelpButton = styled.button` 
justify-content: center;
align-items: center;
padding: 0px 4px;
gap: 8px;

width: 103px;
height: 21px;

/* Body/Body 2
14/ 150%
*/
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
/* identical to box height, or 21px */
text-align: center;
/* Basic/Gray 04 */
color: ${({ theme }) => theme.gray4};
`
const Split = styled.div`
width: 12px;
height: 17px;


font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
display: flex;
justify-content: center;
align-items: center;

/* Basic/Gray3 */

color: ${({ theme }) => theme.gray1};
`
