import { useQuery } from 'react-query'

import { searchTeahouse as searchTeahouses } from '@/apis/teahouse/searchTeahouses'

const getQueryKey = (keyword: string) => [`teahouse/search?keyword=${keyword}`]

export default function useSearchTeahouses(keyword: string){
  return useQuery(
    getQueryKey(keyword),
    async () => {
      const teahouses = await searchTeahouses(keyword)
      return teahouses
    }
  )
}
