import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import icon from '@/assets/svg/icons/smile-complete.png'
import entitleMakerComplete from '@/atoms/entitleMakerCompletedState'
import { Sub1 } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { CTAButton } from '@/components/Input/CTAButton'
import { ButtonWrapper, PageWrapper } from '@/components/Layout/frame/Frame'

import NotFound from '../NotFound'

const EntitleMakerCompletePage = () => {
  const completed = useRecoilValue(entitleMakerComplete)
  return (
    completed ?
      <PageWrapper>
        <ContentWrapper>
          <Image
            alt='happy sign-up completion'
            src={icon}
            width='82px'
            height='82px'
          />
          <Title>메이커 신청이 완료되었습니다.</Title>
        </ContentWrapper>
        <ButtonWrapper>
          <Link
            to='/class/create'
            replace
          >
            <CTAButton
              disabled={false}
              text="바로 모임 생성하기"
              colorType='green'
              onClick={() => console.log('메인으로 갑니다.')}
            />
          </Link>
          <Link
            to='/'
            replace
          >
            <CTAButton
              disabled={false}
              text="메인으로"
              colorType='white'
              onClick={() => console.log('메인으로 갑니다.')}
            />
          </Link>
        </ButtonWrapper>
      </PageWrapper>
      : <NotFound />
  )
}

export default EntitleMakerCompletePage

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 23px;
  margin-top : 223px;
`

const Title = styled(Sub1)`
  color: ${({ theme }) => theme.mainGreen};
`
