import styled from '@emotion/styled'

import { convertEnglishToKorean } from '@/pages/settings/SettingPage'
import { numberWithCommas } from '@/utils/numbers'

interface MenuItemType {
  image: string;
  category: string;
  name: string;
  description: string;
  price: number;
}
const MenuItem = ({ image, name, category, description, price }: MenuItemType) => {
  return (
    <MenuItemWrapper>
      <MenuImage src={image} />
      <MenuInfoWrapper>
        <MenuCategory>{convertEnglishToKorean(category)}</MenuCategory>
        <MenuTitle>{name}</MenuTitle>
        <MenuDescription>{description}</MenuDescription>
        <MenuTitle>{numberWithCommas(price)}</MenuTitle>
      </MenuInfoWrapper>
    </MenuItemWrapper>
  )
}
export default MenuItem

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd; // 경계선 추가
  border-radius: 10px; // 모서리 둥글게
  margin-bottom: 10px; // 아래쪽 마진
  align-items: center; // 세로 중앙 정렬
  background-color: #f9f9f9; // 배경색
`

const MenuImage = styled.img`
  width: 80px; // 이미지 너비
  height: 80px; // 이미지 높이
  border-radius: 8px; // 모서리 둥글게
  margin-right: 15px; // 오른쪽 마진
  object-fit: cover; // 이미지 비율 유지
`

const MenuInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // 내용물 사이 간격
`

const MenuTitle = styled.div`
  font-size: 18px; // 글꼴 크기
  font-weight: bold; // 글꼴 굵기
  color: #333; // 글꼴 색상
`

const MenuCategory = styled.div`
  font-size: 14px; // 글꼴 크기
  color: #666; // 글꼴 색상
  margin-bottom: 5px; // 아래쪽 마진
`

const MenuDescription = styled.div`
  font-size: 12px; // 글꼴 크기
  color: #999; // 글꼴 색상
`
