import styled from '@emotion/styled'

import timeSince from '@/utils/timeSince'

const ReviewItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd; /* Add styling for the border */
  padding: 10px;
  margin-bottom: 10px;
  max-width: 500px;
`

const ReviewImage = styled.img`
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Add border-radius for rounded corners */
  margin-bottom: 10px;
`

const ReviewText = styled.p`
  font-size: 16px; /* Adjust the font size as needed */
  /* Add any other text styling here */
`
const Timestamp = styled.span`
  font-size: 12px;
  color: #777; /* Add a color for the timestamp */
  margin-top: 5px;
`
const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const ReviewerProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`

const ReviewerName = styled.span`
  font-weight: bold;
  font-size: 16px;
`

type ReviewItemType = {
  image: string;
  text: string;
  timestamp: string;
  reviewerName: string;
  reviewerImage: string;
}
const ReviewItem = ({ image, text, timestamp, reviewerName, reviewerImage }: ReviewItemType) => {
  return (
    <ReviewItemWrapper>
      <ReviewHeader>
        <ReviewerProfileImage
          src={reviewerImage}
          alt="Reviewer Profile" />
        <ReviewerName>{reviewerName}</ReviewerName>
      </ReviewHeader>
      {image && <ReviewImage
        src={image}
        alt="Review" />}
      <ReviewText>{text}</ReviewText>
      <Timestamp>{(timeSince(timestamp))}</Timestamp>
    </ReviewItemWrapper>
  )
}
export default ReviewItem
