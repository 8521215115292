import styled from '@emotion/styled'
import { useRef, useState } from 'react'

import useMount from '@/hooks/useMount'
import useUnmount from '@/hooks/useUnmount'

const END_INDEX = 10
function FullLoading() {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [show, setShow] = useState(true)
  const [text, setText] = useState('')

  useMount(() => {
    for (let i = 1; i <= END_INDEX;i++){
      (function(i){
        timerRef.current = setTimeout(() => {
          if (i === END_INDEX){
            setShow(false)
          }

          setText((state) => {
            return `${state}.`
          })

        }, 1000 * i)
      }(i))
    }
  })

  useUnmount(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  })
  return (
    show ? (<FullLoadingBlock>
      Loading {text}
    </FullLoadingBlock>) : null
  )
}

export default FullLoading

const FullLoadingBlock = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: ${({ theme }) => theme.gray4};
  opacity: 0.5;
  color: ${({ theme }) => theme.white};
  z-index: 15;

  font-weight: bold;
  font-size: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`