import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

type CTAButtonProps = {
  colorType: 'white' | 'green';
  text?: string;
  children?: ReactNode;
  linked?: string;
  disabled: boolean;
  onClick?: () => void;
}

export const CTAButton = ({ colorType, text, children, linked, disabled, onClick }: CTAButtonProps) => {
  const buttonContent = (
    <ButtonBlock
      colorType={colorType}
      disabled={disabled}
      onClick={onClick}>
      <span>{text || children}</span>
    </ButtonBlock>
  )
  if (linked) {
    return <Link to={linked}>{buttonContent}</Link>
  }
  return buttonContent
}

const ButtonBlock = styled.button<CTAButtonProps & { children?: React.ReactNode }>`
  width: 303px;
  height: 44px;
  
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props: CTAButtonProps) => props.colorType === 'white' ? '#01C897' : '#FFFFFF'};
  background: ${(props: CTAButtonProps) => props.colorType === 'green' ? '#01C897' : '#FFFFFF'};
  border: 1px solid #01C897;
  border-radius: 22px;
  ${(props: CTAButtonProps) => props.disabled && `
  background: #B4B4B4;
  color: #FFFFFF;
  border: none;
`}
`