import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { H2, PageBlock } from '@/components/Common/Styled'
import Terms from '@/components/Common/Terms'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useAgreementList from '@/hooks/useAgreementList'
import { ROUTER } from '@/router'

import ErrorPage from '../Error'

const { ACCOUNT } = ROUTER

function TermsPage() {
  const { data: agreements, isError: isAgreementsError } = useAgreementList({ category: 'SIGNUP' })

  const [termCodes, setTermCodes] = useState<string[]>([])

  const buttonDisabled = useMemo(() => {
    return !(termCodes.length > 0)
  }, [termCodes])

  if (isAgreementsError){
    return <ErrorPage />
  }
  if (!agreements){
    return null
  }

  return (
    <Layout>
      <Header
        title="회원가입"
        back
      />
      <Content>
        <PageBlock>
          <H2>약관 동의</H2>
          <div>전체 동의하기</div>

          <Terms
            agreements={agreements}
            onClickItem={(value) => {
              const isAll = Object.entries(value).every(([, value]) => value)
              const termCodes = isAll ? Object.keys(value).map((key) => (key)) : []
              setTermCodes(termCodes)
            }}
          />
        </PageBlock>
      </Content>
      <Link
        to={`${ACCOUNT}/create/complete`}
        replace
      >
        <CTAButton
          label="다음"
          disabled={buttonDisabled}
        />
      </Link>

    </Layout>
  )
}

export default TermsPage
