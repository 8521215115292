import styled from '@emotion/styled'

const StyledHr = styled.hr<{ isFocused: boolean; status?: 'error' | 'correct' | 'normal' | undefined }>`
  width: 100%;
  border-width: ${(props) => {
    if (props.status === 'error')
      return '1.5px'
    else if (props.status == 'correct' || props.isFocused == true)
      return '1.5px'
    else
      return '1px'
  }};
  border-style:solid;
  border-color: ${(props) => {
    if (props.status === 'error')
      return props.theme.error
    else if (props.status == 'correct' || props.isFocused == true)
      return props.theme.mainGreen
    else
      return props.theme.gray1
  }};
  background-color: #ccc;
  transition: bolder 1s ease-in-out;
`

export default StyledHr