import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { isUserLoggedIn } from '@/utils/jwt'

const UserProfileHeader = () => {
  const navigate = useNavigate()
  const { username } = useParams<{ username: string }>()
  return (
    <HeaderWrapper>
      <UsernameWrapper>
        {username}
      </UsernameWrapper>
      <NavWrapper>
        {isUserLoggedIn() && <MakeButton>+</MakeButton>}
        {isUserLoggedIn() && <Nav onClick={() => navigate('/settings')}>Settings</Nav>}
      </NavWrapper>
    </HeaderWrapper>
  )
}
export default UserProfileHeader

const HoverRotationEffect = css`
  transition: transform 0.3s ease ;
  &:hover {
    transform: scale(1.05) rotate(90deg); /* 호버 시 확대 효과 */
  }
`
/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  padding : 20px 20px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  z-index: 2000;
`

const UsernameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  @media (max-width: 380px) {
    & > span {
      display: none; /* Hide the text */
    }
  }
`

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
  height: 22px; 
`
const Nav = styled.span`
  color: #000;
  font-weight: 600;
  cursor: pointer;
`
const MakeButton = styled.div`
  color: #000;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 30px;
  font-weight: 500;
  ${HoverRotationEffect}
`
