import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { PostConfirmSMSRequestType } from '@/apis/ncp/postConfirmSMS'
import signUpCompletedState from '@/atoms/signUpCompletedState'
import AgreementList from '@/components/Input/AgreementsList'
import { CTAButton } from '@/components/Input/CTAButton'
import InputBirth from '@/components/Input/InputBirth'
import InputDefault, { StatusType } from '@/components/Input/InputDefault'
import InputPhoneNumber from '@/components/Input/InputPhoneNumber'
import BottomSheet from '@/components/Layout/BottomSheet'
import { ButtonWrapper, PageWrapper } from '@/components/Layout/frame/Frame'
import Header from '@/components/Layout/Header'
import ProgressBartest from '@/components/Layout/ProgressBartest'
import Title from '@/components/Layout/Title'
import Toast from '@/components/Layout/Toast'
import { SignUpContext } from '@/context/SignUpContext'
import TermsType from '@/enums/termsType'
import { useCheckUserNickName } from '@/hooks/useCheckUserNickName'
import { useConfirmSMS } from '@/hooks/useConfirmSMS'
import { usePostSignUp } from '@/hooks/usePostSignup'
import { useSendSMS } from '@/hooks/useSendSMS'
import SignUpProvider from '@/providers/SignUpProvider'
import validateEmail from '@/validators/regex/validateEmail'
import validateNickname from '@/validators/regex/validateNickname'
import validatePassword from '@/validators/regex/validatePassword'

import LoadingPage from './LoadingPage'

const SignUpPage = () => {
  return (
    <SignUpProvider>
      <Header
        title="회원가입"
        fadein={false}
        timing={1}
        back={true} />
      <SignUpPages />
    </SignUpProvider>
  )
}
export default SignUpPage

const SignUpPages = () => {
  const { step } = useContext(SignUpContext)
  const totalPages = 4
  return (
    <>
      <ProgressBartest
        step= {step}
        totalPages={totalPages} />
      <PageWrapper>
        {step === 0 && <BasicInfoPage />}
        {step === 1 && <PasswordPage />}
        {step === 2 && <NickNamePage />}
        {step === 3 && <EmailPage />}
      </PageWrapper>
    </>
  )
}

//이름, 생년월일, 휴대폰 인증
const BasicInfoPage = () => {
  const { setUser, setStep } = useContext(SignUpContext)
  //Input
  const [name, setName] = useState('')
  const [birth, setBirth] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState(82)
  const [validateNumber, setValidateNumber] = useState('')
  //const [status, setStatus] = useState(false)
  //Toast (수정필요, App 메인 상단으로 이동)
  //const [toast, setToast] = useState<{ message: string; type: 'information' | 'error' | 'caution' } | null>(null)
  //Send SMS, Expiresin Time
  const [remainingTime, setRemainingTime] = useState(3000)
  //SendSMS 여부
  const [triedValidation, setTriedValidation] = useState(false)
  //입력 여부 체크
  const isFilled = useMemo(() => {
    return name !== '' && birth !== '' && phoneNumber !== '' && code !== null && validateNumber !== '' && triedValidation == true && remainingTime > 0
  }, [name, birth, phoneNumber, code, validateNumber, triedValidation, remainingTime])
  //SMS 보내기 버튼 클릭 핸들러
  const { loading, sendSMS, expireIn, errorMessage } = useSendSMS()
  useEffect(() => {
    //setStatus(loading)
    if (expireIn) setRemainingTime(expireIn)
  }, [loading, expireIn])
  const handleSend = async () => {
    try {
      await sendSMS({ code, phoneNumber })
      setTriedValidation(true)
    } catch (error) {
      console.error('Failed to validate phone number', error)
      if (error) {
        //setToast({ message: '휴대폰 번호 인증에 실패했습니다.', type: 'error' })
      }
    }
  }
  //Validate Confirm
  // validateNumber를 number로 변환하여 사용하기 위한 변수 선언
  const validateNumberInNumber = useMemo(() => parseInt(validateNumber.replace(/-/g, '')), [validateNumber])
  // confirmSMSData에 validateNumberInNumber 사용
  const confirmSMSData: PostConfirmSMSRequestType = { code, validationNumber: validateNumberInNumber, phoneNumber }
  const handleConfirm = async () => {
    setUserdata()
    try {
      await confirmSMS(confirmSMSData)
    } catch (error) {
      console.error(error)
    }
  }
  //Set Context
  const setUserdata = () => {
    setUser((prevUser) => ({ ...prevUser, realName: name, birth: birth, phoneNumber: phoneNumber, code: code }))
  }
  //Passed
  const { passed, error, confirmSMS } = useConfirmSMS(confirmSMSData)
  useEffect(() => {
    if (passed) setStep(1)
  }, [passed, setStep])
  return (
    <>
      <Title
        title="회원가입"
        subtitle="다루에서는 휴대폰 번호를 아이디로 사용하고 있습니다." />
      <InputDefault
        title="이름"
        placeholder='이름 입력'
        inputType='text'
        hasButton={false}
        setInputValue={setName} />
      <InputBirth
        title="생년월일"
        setInputValue={setBirth}></InputBirth>
      <InputPhoneNumber
        title="휴대폰 번호"
        placeholder='01012341234'
        hasButton={true}
        onClick={handleSend}
        setCode={setCode}
        setPhonenumber={setPhoneNumber} />
      <InputDefault
        title="인증번호"
        placeholder='숫자 6자리'
        inputType='number'
        hasButton={true}
        buttonName='재요청'
        //onClick = {handleSend}
        onClick= { () => console.log(test) }
        setInputValue={setValidateNumber} />
      <ButtonWrapper>
        {(errorMessage) && <Toast
          message={errorMessage.message}
          type='error' />}
        {(error) && <Toast
          message={error}
          type='error' />}
        <CTAButton
          disabled={!(isFilled)}
          onClick={handleConfirm}
          colorType='green'>인증번호 확인</CTAButton>
      </ButtonWrapper>
    </>
  )
}
//비밀번호, 비밀번호 확인
const PasswordPage = () => {
  const { setUser, setStep } = useContext(SignUpContext)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const SameError = '두 비밀번호가 일치하지 않습니다.'
  const SameCorrect = '두 비밀번호가 일치합니다.'
  //Password 형식에 따른 Input1 Info Handler
  const error: StatusType = 'error'
  const normal: StatusType = 'normal'
  const correct: StatusType = 'correct'
  const handleInfo1 = useMemo(() => {
    if (password !== '' && !validatePassword(password))
      return { type: error, message: '비밀번호의 형식이 맞지 않습니다.(영문, 숫자, 특수문자 1개 반드시 포함 8자리~16자리)' }
    else if (validatePassword(password))
      return { type: correct, message: '사용할 수 있는 비밀번호입니다.' }
    else
      return { type: normal, message: '영문, 숫자, 특수문자 포함 8~16자리를 입력해주세요.' }
  }, [password])
  //Password 형식에 따른 Input2 Info Handler
  const handleInfo2 = useMemo(() => {
    if (password !== '' && password2 !== '')
      return (password === password2) ? { type: correct, message: SameCorrect } : { type: error, message: SameError }
    else
      return { type: normal, message: '위의 번호와 동일한 비밀번호를 입력해주세요' }
  }, [password, password2])
  //Password 동일성과 Filled여부, Validation을 확인하여 다음 버튼을 활성화합니다.
  const isFilled = useMemo(() => {
    const validated = validatePassword(password)
    return password !== '' && password2 !== '' && (password == password2) && validated
  }, [password, password2])

  const handleButton = () => {
    setUser((prevUser) => ({ ...prevUser, password: password }))
    setStep(2)
  }
  return (
    <>
      <Header
        title="회원가입"
        fadein={false}
        timing={1}
        back={true} />
      <Title
        title="비밀번호 설정"
        subtitle="사용할 비밀번호를 입력해주세요." />
      <InputDefault
        title="비밀번호 입력"
        placeholder='비밀번호를 입력해주세요'
        info={ handleInfo1?.message }
        status={ handleInfo1?.type }
        inputType='password'
        hasButton={false}
        setInputValue={setPassword} />
      <InputDefault
        title="비밀번호 확인"
        placeholder='위에 입력한 비밀번호를 다시 입력해주세요'
        info={ handleInfo2.message }
        status={ handleInfo2.type}
        inputType='password'
        hasButton={false}
        setInputValue={setPassword2} />
      <ButtonWrapper>
        <CTAButton
          onClick={handleButton}
          disabled={!isFilled}
          colorType='green'>다음</CTAButton>
      </ButtonWrapper>
    </>
  )
}
//별명 설정
const NickNamePage = () => {
  const { setUser, setStep } = useContext(SignUpContext)
  const [nickName, setNickName] = useState('')
  const [status, setStatus] = useState<StatusType>('normal')
  const [message, setMessage] = useState('')

  // 상태 변경 예시
  const setErrorStatus = () => {
    setStatus('error')
    setMessage('올바르지 않은 형식입니다.한글/영어/숫자/_’를 혼합하여 띄어쓰기 없이 2글자 이상 15글자 이내로 입력해주세요.')
  }

  const setCorrectStatus = () => {
    setStatus('correct')
    setMessage('올바른 별명 형태입니다.')
  }

  const setNormalStatus = () => {
    setStatus('normal')
    setMessage('‘한글/영어/숫자/_’를 혼합하여 띄어쓰기 없이 2글자 이상 15글자 이내로 입력해주세요.')
  }
  //check duplicity
  const { duplicated, loading, error, checkUserNickName } = useCheckUserNickName()
  const handleButton = async() => {
    try {
      setUser((prevUser) => ({ ...prevUser, nickname: nickName }))
      await checkUserNickName(nickName)
    } catch (error){
      alert(error)
    }
  }
  const isFilled = useMemo(() => {
    const validated = validateNickname(nickName)
    if (validated) setCorrectStatus()
    if (!validated) setErrorStatus()
    if (nickName.length < 1) setNormalStatus()
    return nickName !== '' && validated
  }, [nickName])
  useEffect(() => {
    if (!duplicated) {
      setStep(3)
    }
  }, [duplicated, nickName, setStep])
  return (
    <>
      <Title
        title="별명 정하기"
        subtitle="별명은 다루 사람들에게 노출되는 이름과 같은 역할이에요." />
      <InputDefault
        title='별명'
        placeholder='최소 2글자 ~ 15글자'
        info={message}
        status={status}
        inputType='text'
        hasButton={false}
        setInputValue={setNickName} />
      <ButtonWrapper>
        {error && <Toast
          message={error}
          type='error' />}
        {loading && <Toast
          message='처리 중 입니다'
          type='caution' />}
        <CTAButton
          disabled={!isFilled}
          onClick={handleButton}
          colorType='green'>다음</CTAButton>
      </ButtonWrapper>
    </>
  )
}
const EmailPage = () => {
  const [signUpCompleted, setSignUpCompleted] = useRecoilState(signUpCompletedState)
  const { user, setUser } = useContext(SignUpContext)
  const [email, setEmail] = useState('') // For Email Input
  const [isOpen, setIsOpen] = useState(false) //모달 오픈 State
  const [termsCodes, setTermsCodes] = useState<TermsType[]>([])//TermsCodes
  const [isAllChecked, setIsAllChecked] = useState(false) //모달 동의여부 Check
  const navigate = useNavigate()
  //SignUp Hook
  const { isLoading, userId, signUp } = usePostSignUp()
  //Check Agreement Validation
  const isFilled = useMemo(() => {
    const validated = validateEmail(email)
    if (email == '')
      return true
    if (email !== '')
      return validated
  }, [email])
  //Handle Email Button page
  const handleButton = () => {
    setUser((prevUser) => ({ ...prevUser, email: email }))
    setIsOpen(!isOpen)
  }
  //Handle Submit Button page
  const handleSubmit = async() => {
    try {
      await signUp(user)
      //navigate('/account/login')
    } catch (error) {
      console.log(error)
      //for lint error
      console.log(signUpCompleted)
      alert(error)
    }
  }
  useEffect(() => {
    setUser((prev) => ({ ...prev, termCodes: termsCodes }))
  }, [setUser, termsCodes])
  useEffect(() => {
    if (userId > 0){
      setSignUpCompleted(true)
      navigate('/account/sign-up/complete')
    }
  }, [navigate, setSignUpCompleted, userId])
  return (
    <>
      {isLoading && <LoadingPage />}
      {!isLoading &&
      <>
        <Title
          title="이메일 등록하기"
          subtitle="다루에서 진행되는 혜택이나 이벤트를 쉽게 누리고싶다면 입력하세요. 필수는 아니에요!" />
        <InputDefault
          title="이메일"
          placeholder='이메일 입력'
          inputType='text'
          hasButton={false}
          setInputValue={setEmail} />
        <ButtonWrapper>
          <CTAButton
            disabled={!isFilled}
            onClick={handleButton}
            colorType='green'>다음</CTAButton>
        </ButtonWrapper>
        <BottomSheet
          isOpen={isOpen}
          height='462px'
          withinWidth='360px'
          onClose={() => setIsOpen(false)}>
          <Title
            title="약관동의"
            subtitle="개인정보와 이용 권리 안전하게 보호해드릴게요" />
          <AgreementList
            category='signup'
            setTermsCode={setTermsCodes}
            isAllchecked={setIsAllChecked} />
          <ButtonWrapper>
            <CTAButton
              disabled={!isAllChecked}
              onClick={handleSubmit}
              colorType='green'>다음</CTAButton>
          </ButtonWrapper>
        </BottomSheet>
      </>
      }
    </>
  )
}
