import { Methods, request } from '@/libs/request'

import { PostConfirmSMSRequestType, PostConfirmSMSResponseType } from './postConfirmSMS'

export const postConfirmSMSForReset = async ({
  phoneNumber,
  validationNumber,
  code
}: PostConfirmSMSRequestType) => {
  try {
    const response = await request<PostConfirmSMSResponseType>({
      url: 'ncp/validation/phone-number/reset-password',
      method: Methods.POST,
      data: {
        phoneNumber,
        validationNumber,
        code
      }
    })
    return response
  } catch (error) {
    console.error('Failed to confirm', error)
  }
}
