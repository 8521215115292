import { Methods, request } from '@/libs/request'

export type PostPreRegisterRequestType = {
  phoneNumber: string;
  code: number;
  email: string;
  note: string;
  name: string;
}

export const postPreRegister = async({
  phoneNumber,
  code,
  email,
  note,
  name
}: PostPreRegisterRequestType) => {
  try {
    await request<void>({
      url: 'marketing/pre-register',
      method: Methods.POST,
      data: {
        phoneNumber,
        code,
        email,
        note,
        name
      }
    })
  } catch (e) {
    console.log(e)
    throw e
  }
}
