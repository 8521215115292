import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserReservationType } from '@/apis/user/getMyInfo'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Logo from '@/components/Layout/LogoCustom'
import LoginModal from '@/components/Modal/LoginModal'
import Modal from '@/components/Modal/Modal'
import { ReservationCard } from '@/components/mypage/MyReservationCard'
import useMyInfo from '@/hooks/useMyInfo'
//import useMyTeaTogethers from '@/hooks/useMyTeaTogethers'
import theme from '@/styles/theme'
import { isUserLoggedIn } from '@/utils/jwt'
import { convertISOToKoreanDate } from '@/utils/text'

// import ImageRotator from './component/ImageRotator'

type QueryParams = {
  mode: string;
}
function getQueryParams(): QueryParams {
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get('mode') || 'user' // Default to "user"
  return { mode }
}

const MyPage = () => {
  if (isUserLoggedIn()){
    return MyPageLoggined()
  } else {
    return MyPageNotLoggined()
  }
}

const MyPageNotLoggined = () => {
  return (
    <PageWrapperCentered>
      <MyPageHeader />
      <Title>이 페이지는 로그인 이후에 이용할 수 있어요</Title>
      <LoginModal />
    </PageWrapperCentered>
  )
}

const MyPageLoggined = () => {
  const navigate = useNavigate()
  const { data: info } = useMyInfo()
  console.log('🅰️', info)
  const [currentMode, setCurrentMode] = useState('user')
  const [modal, setModal] = useState(false)
  const [canceledReservation, setCanceledReservation] = useState<{ id: number; title: string; count: number; startAt: string }>({ id: 0, title: '', count: 0, startAt: '' })
  const user = { userNickname: '하노노', profile: '/images/thumbnail.png' }
  const maker = { makerNickname: '어어티하우스', profile: '/images/default-image.png', verified: true, firstMaker: false }
  const isMaker = (currentMode == 'creater')
  //모드 전환
  const modeHandler = () => {
    if (!isMaker) {
      setCurrentMode('creater')
      navigate('/mypage?mode=creater')
    }
    if (isMaker) {
      setCurrentMode('user')
      navigate('/mypage?mode=user')
    }
  }
  const createHandler = () => {
    if (!isMaker) {
      navigate('/tea-together')
    }
    if (isMaker) {
      navigate('/tea-together/create')
    }
  }
  const navigateHandler = (id: number) => {
    navigate(`/tea-together/${id}`)
  }
  const cancelHandler = (reservation: UserReservationType) => {
    setCanceledReservation({ id: reservation.id, title: reservation.reservationTitle, count: reservation.count, startAt: reservation.times[0].startAt })
    setModal(true)
  }

  const data = (isMaker) ? {
    name: maker.makerNickname,
    mainColor: theme.dark.information,
    profile: maker.profile,
    guide: '내가 참여하는 모임/클래스 확인하려면!',
    reservationTitle: '개설 목록'
  } : {
    name: user.userNickname,
    mainColor: theme.dark.mainGreen,
    profile: user.profile,
    guide: '내가 만든 모임/클래스 확인하려면!',
    reservationTitle: '나의 예약 목록'
  }
  useEffect(() => {
    setCurrentMode(getQueryParams().mode)
  }, [])

  return (
    <PageWrapperCentered>
      <LoginModal />
      { modal && <Modal
        isVisible={modal}
        onClose={() => setModal(false)}>
        <ModalWrapper>
          <ModalTitle>취소하시겠습니까?</ModalTitle>
          <ModalContent>[{canceledReservation.title}]</ModalContent>
          <ModalContent>😀 {canceledReservation.count}명</ModalContent>
          <ModalContent>🗓️ {convertISOToKoreanDate(canceledReservation.startAt)} 시작</ModalContent>
          <ModalButtonWrapper>
            <YesButton onClick={() => setModal(false)}>아니요</YesButton>
            <CancelButton onClick={() => {
              alert(canceledReservation.id)
              setModal(false)
              setCanceledReservation({ id: 0, title: '', count: 0, startAt: '' })
            }}>네, 예약 취소할게요</CancelButton>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>}
      <MyPageHeader />
      {(info?.isMaker == false) && <ChangeButton
        onClick={() => modeHandler()}
        maker={isMaker}>{isMaker ? '🍵 참여자 화면으로 가기' : '😀 내가 만든 모임/수업'}</ChangeButton>}
      {/*<ImageRotator imageUrls={['/images/default-image.png', '/images/thumbnail.png', '/images/gyeongju-tea-map.png']} />*/}
      {/* Profile */}

      {(isMaker) && <SettleButton onClick={() => navigate('/mypage/settlement')}>정산하기</SettleButton>}
      {/* 예약목록 */}
      {(!isMaker || !maker.firstMaker) &&
      <MyReservationBlockWrapper>
        {/* { ((isMaker) && !!(teaTogethers?.filter((teaTogether) => teaTogether.approvementType != 'APPROVED').length)) && <Title>승인대기 및 반려</Title>}
        { (isMaker) && teaTogethers?.filter((teaTogether) => teaTogether.approvementType != 'APPROVED').map((teaTogether, index) => {
          return (
            <TeaTogetherNotApproved
              key={index}
              teaTogether={teaTogether} />
          )
        })
        } */}
        <Title>{data.reservationTitle}</Title>
        <ReservationWrapper>
          { (!isMaker) && info?.reservations.map((reservation) => ReservationCard({ reservation, onClick: navigateHandler, onCancel: () => cancelHandler(reservation) }))}
          {/* { (!isMaker) && info?.reservations.filter((reservation) => reservation.progressType == progressStatus).map((reservation) => ReservationCard({ ...reservation, maker: isMaker }))} */}
          {/* { (isMaker) && teaTogethers?.filter((teaTogether) => (teaTogether.status == progressStatus) && (teaTogether.approvementType == 'APPROVED')).map((teaTogether, index) => {
            return (
              <TeaTogetherCard
                key={index}
                teaTogether={teaTogether} />
            )
          })
          } */}
        </ReservationWrapper>
      </MyReservationBlockWrapper>}
      {(isMaker && maker.firstMaker) && <>
      어서오시죠(Hero)</>}
      {/* 여백 */}
      <div style={{ height: '200px' }}></div>
      {/* Bottom 버튼 */}
      <ButtonWrapper>
        <CTAButton
          onClick={ () => createHandler()}
          maker={isMaker}>{isMaker ? '차모임/수업 만들기' : '차모임/수업 찾아보러가기'}</CTAButton>
      </ButtonWrapper>
    </PageWrapperCentered>
  )
}
export default MyPage
const HoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05); /* 호버 시 확대 효과 */
    background: rgba(1,200,151,0.8);

    > div {
      color: #fff;
    }
  }
`
const ChangeButtonHoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05); /* 호버 시 확대 효과 */
    background: rgba(255,255,255,0.8);

    > div {
      color: #fff;
    }
  }
`
/* Page Layout */
const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px; 
  align-items: center;
  gap: 10px;
`
/* Default Style */
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.black)};
`
/* Modal */
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center
`
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${((props) => props.theme.caution)};
`
const ModalContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${((props) => props.theme.gray4)};
`
const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  aling-items: center;
  justify-content: center;
`
const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  min-width: 130px;
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  background-color: ${({ theme }) => theme.gray1};
  ${HoverEffect}
`
const YesButton = styled(CancelButton)`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.information};
`

/* Page Component */

const flipAnimation = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
`
const flipAnimationReverse = keyframes`
  0% {
    transform: rotateX(360deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
`
const rotateAnimation = css`
  animation: ${flipAnimation} 1s ease;
`
const rotateAnimationReverse = css`
  animation: ${flipAnimationReverse} 1s ease;
`
/* Change Button */
const ChangeButton = styled.div<{ maker: boolean }>`
  border: 1px solid ${({ theme, maker }) => maker ? theme.information : theme.mainGreen};
  border-radius: 5px;
  color: ${({ maker, theme }) => maker ? theme.information : theme.mainGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  width: 90%;
  font-weight: 500;
  padding: 5px 10px;
  cursor: pointer;
  ${({ maker }) => (maker ? rotateAnimation : rotateAnimationReverse)};
  ${ChangeButtonHoverEffect}
`
/* 정산 */
const SettleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  width: 90%;
  font-weight: 500;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.information};
  border-radius: 5px;
  color: ${({ theme }) => theme.information};
  background-color: ${({ theme }) => theme.white};
  ${rotateAnimation};
  ${ChangeButtonHoverEffect};
`
/* MyReservation */
const MyReservationBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`
const ReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 10px;
  gap: 10px;
  @media (min-width: 1000px) {
    flex-direction: column;
    align-items: center;
    min-width: 0px;
    justify-content: flex-start;
  }
`
/* Bottom 버튼 */
const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 5vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CTAButton = styled.div<{ maker: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 10px 20px;
  width: 90%;
  min-width: 120px;
  max-width: 600px;
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme, maker }) => maker ? theme.information : theme.mainGreen};
`

/* Header */
const MyPageHeader = () => {
  const navigate = useNavigate()
  return (
    <HeaderWrapper>
      <Logo
        width='58px'
        height='35px' />
      <NavWrapper>
        <Nav
          onClick={() => navigate('/')}
          clicked={false}>Main</Nav>
        <Nav
          clicked={true}>MyPage</Nav>
      </NavWrapper>
    </HeaderWrapper>
  )
}
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  z-index: 1000;
  top: 0;
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 20px;
  height: 22px; 
`
const Nav = styled.span<{ clicked: boolean; maker?: boolean }>`
  color: ${(props) => (props.clicked ? (props.maker ? props.theme.information : props.theme.mainGreen) : '#000000')};
  font-weight: ${(props) => (props.clicked ? '700' : '300')};
  cursor: pointer;
`
