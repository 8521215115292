import { useState } from 'react'

import { PostSendSMSRequestType } from '@/apis/ncp/postSendSMS'
import { postSendSMSForReset } from '@/apis/ncp/postSendSMSForResetPassword'

export const useSendSMS = () => {
  const [success, setSuccess] = useState<boolean | null>(null)
  const [expireIn, setExpireIn] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<Error | null>(null)

  const sendSMS = async ({ code, phoneNumber }: PostSendSMSRequestType) => {
    setLoading(true)
    setErrorMessage(null)

    try {
      const response = await postSendSMSForReset({ code, phoneNumber })
      setSuccess(response.success)
      setExpireIn(response.expireIn)
    } catch (error) {
      console.error('Failed to request SMS', error)
      setErrorMessage(new Error('Failed to request SMS'))
    } finally {
      setLoading(false)
    }
  }

  return {
    success,
    expireIn,
    loading,
    errorMessage,
    sendSMS
  }
}

