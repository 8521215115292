import { useQuery } from 'react-query'

import { getSettlementList } from '@/apis/getSettlementList'

const getQueryKey = () => ['settlement-list']

export default function useSettlementList() {
  return useQuery(getQueryKey(), async () => {
    const settlemenetList = await getSettlementList()
    return settlemenetList
  })
}
