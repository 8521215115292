import styled from '@emotion/styled'

export const Display = styled.span`
  font-weight : 700;
  font-size: 56px;
  line-height: 70px;
`
export const H1 = styled.span`
  font-weight : 700;
  font-size: 48px;
  line-height: 60px;
`
export const H2 = styled.span`
  font-weight : 700;
  font-size: 44px;
  line-height: 55px;
`
export const H3 = styled.span`
  font-weight : 700;
  font-size: 36px;
  line-height: 45px;
`
export const H4 = styled.span`
  font-weight : 700;
  font-size: 32px;
  line-height: 40px;
`
export const H5 = styled.span`
  font-weight : 700;
  font-size: 28px;
  line-height: 36.4px;
`
export const Sub1 = styled.span`
  font-weight : 700;
  font-size: 24px;
  line-height: 150%;
`
export const Sub2 = styled.span`
  font-weight : 700;
  font-size: 20px;
  line-height: 150%;
`
export const Sub3 = styled.span`
  font-weight : 700;
  font-size: 18px;
  line-height: 150%;
`
export const Body1 = styled.span`
  font-weight : 500;
  font-size: 16px;
  line-height: 150%;
`
export const Body1Bold = styled.span`
  font-weight : 700;
  font-size: 16px;
  line-height: 150%;
`
export const Body2 = styled.span`
  font-weight : 500;
  font-size: 14px;
  line-height: 150%;
`
export const Body2Bold = styled.span`
  font-weight : 700;
  font-size: 14px;
  line-height: 150%;
`
export const ButtonText = styled.span`
  font-weight : 500;
  font-size: 14px;
  line-height: 20px;
`
export const CaptionMedium = styled.span`
  font-weight : 500;
  font-size: 14px;
  line-height: 16px;
`
export const CaptionBold = styled.span`
  font-weight : 700;
  font-size: 12px;
  line-height: 16px;
`