import { useQuery } from 'react-query'

import { getParticipatorInfo } from '@/apis/festival/getParticipatorInfo'

const getQueryKey = (festivalId: number, participatorId: number) => ['participatorInfo', festivalId, participatorId]

export default function useParticipatorInfo(festivalId: number, participatorId: number) {
  return useQuery(
    getQueryKey(festivalId, participatorId),
    async () => {
      const participator = await getParticipatorInfo({ festivalId, participatorId })
      return participator
    })
}
