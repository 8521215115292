import { useState } from 'react'

import { postConfirmBusiness, PostConfirmBusinessRequestType, PostConfirmBusinessResponseType } from '@/apis/auth/postConfirmBusiness'

export type UseConfirmBusinessReturnType = {
  isLoading: boolean;
  message: string;
  isError: boolean;
  success: boolean;
  confirm: (data: PostConfirmBusinessRequestType) => Promise<void>;
}

export const useConfirmBusiness = (): UseConfirmBusinessReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [success, setSuccess] = useState(false)
  const confirm = async(data: PostConfirmBusinessRequestType): Promise<void> => {
    try {
      setIsLoading(true)
      setIsError(false)
      const response = await postConfirmBusiness(data)
      const { success, message }: PostConfirmBusinessResponseType = response
      setMessage(message)
      setSuccess(success)
    } catch (error) {
      setIsError(true)
      setMessage('오류 발생')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    message,
    isError,
    success,
    confirm
  }
}