import 'leaflet/dist/leaflet.css'

import L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet'

import { Region } from '@/pages/tea-together/component/RegionTag'

import { CustomMapContainerProps, CustomTileLayerProps } from './StaticStreetMap'
import { DaruType, TeaMapMarker } from './TeaMapMarker'

interface OpenStreetMapComponentType {
  lat: number;
  lon: number;
  teahouseId: number;
  type: DaruType;
  region: Region;
  name: string;
  location: string;
}
const OpenStreetMapComponent = ({ lat, lon, teahouseId, type, region, name, location }: OpenStreetMapComponentType) => {

  let centerPosition: L.LatLngExpression
  if (lat && lon) {
    centerPosition = [lat, lon] //위도, 경도
  } else {
    centerPosition = [36.87712967204818, 127.73987517692152]
  }

  const mapContainerProps: CustomMapContainerProps = {
    center: centerPosition,
    zoom: 14,
    scrollWheelZoom: true,
    style: { height: '40vh', width: '100%' }
  }

  const tileLayerProps: CustomTileLayerProps = {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  return (
    <MapContainer {...mapContainerProps}>
      <TileLayer {...tileLayerProps} />
      <TeaMapMarker
        teahouseId={teahouseId}
        name={name}
        type={type}
        position={centerPosition}
        region={region}
        location={location} />
    </MapContainer>
  )
}
export default OpenStreetMapComponent
