import { useQuery } from 'react-query'

import { getNumberOfPreRegistrations } from '@/apis/marketing/getNumberOfPreRegistrations'

const getQueryKey = () => ['marketing/pre-register']

export default function useNumberOfPreRegistrations(){
  return useQuery(
    getQueryKey(),
    async () => {
      const response = await getNumberOfPreRegistrations()
      return response
    }
  )
}
