import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Sub1, Sub2, Sub3 } from '@/components/Base/Text'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcArrowLeft } from '@/components/Icons'
import { CTAButton } from '@/components/Input/CTAButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import ToastType from '@/enums/toast'
import useParticipatorInfo from '@/hooks/useParticipatorInfo'
import useUpdateParticipator from '@/hooks/useUpdateParticipator'
import { isError } from '@/libs/request'

const ParticipatorRevisePage = () => {
  const navigate = useNavigate()
  //Get PathVariable and Get Participator
  const { participatorId } = useParams<{ participatorId: string }>()
  const { data: participator } = useParticipatorInfo(1, Number(participatorId))
  useEffect(() => {
    if (participator) setDiscription(participator?.description)
    if (participator) setHandledTea(participator?.handleTea)
  }, [participator])
  const { mutate: updateParticipator } = useUpdateParticipator()
  const [description, setDiscription] = useState('')
  const [handledTea, setHandledTea] = useState('')

  const handleChange1 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDiscription(e.target.value)
  }
  const handleChange2 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHandledTea(e.target.value)
  }
  const clickHandler = () => {
    if (participatorId) updateParticipator({ festivalId: 1, participatorId: Number(participatorId), description, handledTea }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '성공적으로 변경되었습니다', type: ToastType.INFORMATION })
        navigate(-1)
      }
    })
  }

  return (
    <PageWrapperCentered>
      <Header />
      <InfoWrapper>
        <InfoTitle>나의 현재 정보</InfoTitle>
        <SubTitle>부스번호</SubTitle>
        <ContentDescription>{participator?.boothNumber}</ContentDescription>
        <SubTitle>나의 이름</SubTitle>
        <ContentDescription>{participator?.name}</ContentDescription>
        <SubTitle>저희를 소개합니다</SubTitle>
        <ContentDescription>{participator?.description}</ContentDescription>
        <SubTitle>오늘 내어드릴 차</SubTitle>
        <ContentDescription>{participator?.handleTea}</ContentDescription>
      </InfoWrapper>
      <ContentWrapper>
        <ContentTitle>저희를 소개합니다</ContentTitle>
        <ContentDescription>{description}</ContentDescription>
        <TextArea
          value={description}
          onChange={handleChange1}></TextArea>
        <ContentTitle>오늘 내어드릴 차는 다음과 같아요</ContentTitle>
        <ContentDescription>{handledTea}</ContentDescription>
        <TextArea
          value={handledTea}
          onChange={handleChange2}></TextArea>
      </ContentWrapper>
      <ButtonWrapper>
        <CTAButton
          colorType="green"
          disabled={false}
          onClick={clickHandler}
          text='수정하기' />
      </ButtonWrapper>
    </PageWrapperCentered>
  )
}
export default ParticipatorRevisePage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px; 
  align-items: center;
`
const Header = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>부스 수정하기</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}

/* 헤더 */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`
/* 정보 보여주기 */

const InfoWrapper = styled.div`
  border: 1px solid #01c897;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  padding: 20px 20px;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const InfoTitle = styled(Sub1)`
  color: ${((props) => props.theme.mainGreen)};
`
const SubTitle = styled(Sub2)`
  color: ${((props) => props.theme.black)};
`
/* 소개글, 텍스트 */
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  gap: 20px;
  margin-bottom: 200px;
  width: 90%;
  max-width: 400px;
`
const ContentTitle = styled(Sub1)`
  color: ${((props) => props.theme.mainGreen)};
`
const ContentDescription = styled.pre`
  color: ${((props) => props.theme.black)};
  font-weight: 400;
  white-space: pre-wrap; 
`
/* 수정 인풋 */
const TextArea = styled.textarea`
  padding: 20px 10px;
  border: 1px solid #01c897;
  border-radius: 10px;
`
const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  z-index: 20; 
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
