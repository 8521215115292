import { useQuery } from 'react-query'

import { getMyInfo } from '@/apis/user/getMyInfo'

const getQueryKey = () => ['user/mypage']

export default function useMyInfo() {
  return useQuery(
    getQueryKey(),
    async () => {
      const info = await getMyInfo()
      return info
    }
  )
}
