import { Methods, request } from '@/libs/request'

import { ClassStatusType } from './getClassDetail'

export type GetClassDetailResponseType = {
  id: number;
  category: string;
  title: string;
  name: string;
  target: string;
  description: string;
  address: {
    code: string;
    jibun: string;
    address: string;
    addressDetail: string;
    sido: string;
    sigungu: string;
  };
  classStatus: ClassStatusType;
  recruitPersonnel: number;
  currentPersonnel: number;
  classProgressDate: {
    start: string;
    end: string;
  }[]; // 날짜로 정렬
  classDetailContent: string;
  price: number;
  teacher: string;
  recruitProgressDate: {
    start: string;
    end: string;
  };
  contactNumber: string;
  account: string;
  thumbnailImageURL: string;
  images: string[];
  tasteTeaList: string[];
}

export const getClassDetailByMaker = ({ classId }: { classId: string }) => {

  console.log(classId)

  return request<GetClassDetailResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    url: '/mocks/api/getClassDetailByMaker.json',
    method: Methods.GET
  })
}