import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcCheveronRight, IcLink } from '@/components/Icons'
import Logo from '@/components/Layout/LogoCustom'
import ToastType from '@/enums/toast'

const TeaTogetherCreateCompletePage = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()
  const { teaTogetherId } = useParams<{ teaTogetherId: string }>()
  const copyShareLink = async() => {
    try {
      await navigator.clipboard.writeText(`${window.origin}/tea-together/${teaTogetherId}`)
      notifyRenewal({ message: '성공적으로 복사했습니다.', type: ToastType.INFORMATION })
    } catch (e) {
      notifyRenewal({ message: '복사가 잘 이루어지지 않네요. 잠시후 다시 시도하세요', type: ToastType.ERROR })
    }
  }
  const goMyPage = () => {
    navigate('/mypage', { replace: true })
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play()
      }
    }, 500)
    // 컴포넌트가 언마운트 될 때 타이머를 정리합니다.
    return () => {
      clearTimeout(timer)
    }
  }, [])
  return (
    <PageWrapperCentered>
      <LogoWrapper>
        <Logo
          width='58px'
          height='39px' />
      </LogoWrapper>
      <CompleteAnimation
        ref={videoRef}
        muted
        autoPlay>
        <source
          src='/mp4/complete.mp4'
          type='video/mp4' />
      </CompleteAnimation>
      <ThanksText>축하드립니다. 성공적으로 개설하였네요.</ThanksText>
      <ThanksText>많은 분들이 볼 수 있게 공유하세요!</ThanksText>
      <BigButtonWrapper onClick={() => copyShareLink()}>
        참여링크 공유하기
        <IcLink
          fill='#fff'
          width={24}
          height={24} />
      </BigButtonWrapper>
      <BigButtonWrapper onClick={() => goMyPage()}>
        나의 모임 확인하기
        <IcCheveronRight
          fill='#fff'
          width={24}
          height={24} />
      </BigButtonWrapper>
    </PageWrapperCentered>
  )
}

export default TeaTogetherCreateCompletePage
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`
const PageWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${((props) => props.theme.background)};
  gap: 15px;
`
const ThanksText = styled.div`
  color: ${((props) => props.theme.black)};
  opacity: 0;
  animation: ${fadeIn} 3s ease-in-out forwards; // 1초 동안 fadeIn 애니메이션 적용 
  animation-delay: 2s;
`
const BigButtonWrapper = styled.div`
  width: 80%;
  min-width: 200px;
  max-width: 400px;
  height: 40px;
  gap: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: ${((props) => props.theme.mainGreen)};
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out forwards; // 1초 동안 fadeIn 애니메이션 적용 
  animation-delay: 3s;
  cursor: pointer;
`
const CompleteAnimation = styled.video`
  width: 100px;
  height: 100px;
`
