import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { HeaderHeight } from '../Layout/Header'

export const Notice = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.gray5};
`

// export const Error = styled.span`
//   font-size: 0.8rem;
//   color: ${({ theme }) => theme.error};
//   display: inline-block;
//   margin-left: 10px;
// `

export const ScrollBlock = styled.div<{ height: number }>`
  overflow-y: scroll;
  overscroll-behavior: contain;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  
  ${({ height }) => css`
    height: calc(100vh - (
      env(safe-area-inset-top) +
      env(safe-area-inset-bottom) +
      ${HeaderHeight + height}px
      ));
  `}
`

export const ScrollBlockX = styled.div`
  overflow-x: scroll;
  overscroll-behavior: contain;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export const PageImage = styled.img`
  margin-bottom: 52px;
`

export const PageBlock = styled.div`
  padding: 30px 15px;
`

export const PageBlockCenter = styled(PageBlock)`
  text-align: center;
`

export const H1 = styled.h1`
  margin: 10px 0 22px;
  font-size: 20px;
`

export const H2 = styled.h2`
  margin: 44px 0 10px;
  font-size: 18px;

  
  white-space: pre-wrap;
  line-height: 26px;

  &:first-of-type{
    margin-top: 0;
  }

  + p{  
    color: ${({ theme }) => theme.gray3};  
    font-size: 12px;

    margin-bottom: 54px;
  }

`
export const H3 = styled.h3`
  margin: 44px 0 10px;
  font-size: 15px;
`
export const Sub = styled.div`
  font-size: 14
  color: ${({ theme }) => theme.gray3}
`

export const Info = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.gray3};
  font-size: 12px;

  &::before{
    content: '*';
    margin-right: 2px;
    vertical-align: middle;
  }
`
export const Error = styled(Info)`
  color: ${({ theme }) => theme.error};
`

export const Label = styled.label`
  font-size: 17px;
  font-weight: bold;

  display: block;
  margin-top: 34px;
  margin-bottom: 10px;
`
