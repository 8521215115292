import styled from '@emotion/styled'
import { ChangeEvent } from 'react'

import { IcCheckmark } from '../Icons'

function CheckBox({ checked, name, label, onChange }: { checked: boolean; name: string; label: string; onChange: ({ checked, name }: { checked: boolean; name: string }) => void }) {
  const handleChange = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ checked, name })
  }
  return (
    <CheckBoxBlock>
      <Input
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <LabelBlock
        htmlFor={name}
        className="button"
      >
        <CheckBoxIcBlock>
          <IcCheckmark />
        </CheckBoxIcBlock>
        <span>{label}</span>
      </LabelBlock>
    </CheckBoxBlock>
  )
}

export default CheckBox

const CheckBoxBlock = styled.div`
  width: 100%;
  > label{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    margin-right: 8px; 
    fill: ${({ theme }) => theme.black};
  }
`

const CheckBoxIcBlock = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-right: 8px;

  border-radius: 4px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.black};

  > svg {
    display: block;
    fill: ${({ theme }) => theme.black};
  }
`

const LabelBlock = styled.label`
  margin-left: 4px;
`

const Input = styled.input`
  &[type="checkbox"] {
    display:none;
    &:checked{
      ~ label div {
        background: ${({ theme }) => theme.mainGreen};
        border: 1px solid ${({ theme }) => theme.mainGreen};
        > svg {
          fill: ${({ theme }) => theme.white};
        }
      }
      ~ label {
        font-weight: 700;
      }
    }
  }
`
