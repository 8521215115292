import { useQuery } from 'react-query'

import { getMyHandle } from '@/apis/user/getMyHandle'

const getQueryKey = () => ['user/handle']

export default function useMyHandle() {
  return useQuery(
    getQueryKey(),
    async () => {
      const data = await getMyHandle()
      return data
    }
  )
}
