import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import BottomSheet from '@/components/Common/BottomSheet'
import Image from '@/components/Common/Image'
import Share from '@/components/Common/Share'
import { PageBlockCenter } from '@/components/Common/Styled'
import TabSlider from '@/components/Common/TabSlider'
import { IcCheveronDown } from '@/components/Icons'
import Button from '@/components/Input/Button'
import Content from '@/components/Layout/Content'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import Logo from '@/components/Layout/Logo'
import Splash from '@/components/Layout/Splash'
import useCategoryList from '@/hooks/useCategoryList'
import useClassList from '@/hooks/useClassList'
import useLocationList from '@/hooks/useLocationList'
import useMakerInfo from '@/hooks/useMakerInfo'
import { alertBetaService, getClassStatus } from '@/meta'
import { ROUTER } from '@/router'
import { numberWithCommas } from '@/utils/numbers'
import { dateText } from '@/utils/text'

const locationAtom = atom<{
  mainRegionName: string;
}>({
  key: 'locationAtom',
  default: { mainRegionName: '' }
})

const { ACCOUNT } = ROUTER
function ListPage() {
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)
  const { data: locationList } = useLocationList()
  const { data: categoryList } = useCategoryList()
  const { data: makerInfo } = useMakerInfo()
  const setLocationState = useRecoilState(locationAtom)[1]

  useEffect(() => {
    setLocationState(() => ({ mainRegionName: selectedLocation }))
  }, [setLocationState, selectedLocation])

  const handleLocationTextClick = () => {
    setBottomSheetOpen(true)
  }

  const handleLocationChange = (locationName: string) => {
    setBottomSheetOpen(false)
    setSelectedLocation(locationName)
  }

  if (!locationList || !categoryList){
    return <Splash />
  }

  return (
    <>
      <Layout>
        <Header
          appendLeft={<Logo />}
          appendRight={
            makerInfo ? (
              <AppendRightBlock>
                {/* FIXME: 임시 코드 */}
                <span onClick={alertBetaService}>My Page</span>
                <span onClick={alertBetaService}>개설하기</span>
                {/*
              <Link to={`${MAKER}/${makerInfo.id}/my`}>My Page</Link>
              <Link to={`${MAKER}/${makerInfo.id}/class/create`}>개설하기</Link>
              */}
              </AppendRightBlock>
            )
              : (<Link to={`${ACCOUNT}/login`}>로그인</Link>)
          }
        />
        <Content>
          <IntroBlock>
            <Title>🍵 이번 겨울, 차 한잔 어때요</Title>
            <Description>😀 다루에서 열리는 차모임을 확인해보세요</Description>

            <LocationBlock>
              <>
                <LocationText onClick={handleLocationTextClick}>
                  <span>{selectedLocation || '전체'}</span>
                  {locationList.length > 0 && <IcCheveronDown />}
                </LocationText>

                <BottomSheet
                  // title="지역선택"
                  open={bottomSheetOpen}
                  onClose={() => {
                    setBottomSheetOpen(false)
                  }}
                >
                  <LocationList>
                    {[{ mainRegionName: '' }, ...locationList].map(({ mainRegionName }, index) => {
                      return (
                        <LocationListItem
                          selected={selectedLocation === mainRegionName}
                          key={index}
                          onClick={() => { handleLocationChange(mainRegionName) }}
                        >
                          <span>{mainRegionName || '전체'}</span>
                        </LocationListItem>
                      )
                    })}
                  </LocationList>
                </BottomSheet>
              </>
            </LocationBlock>
          </IntroBlock>
          <ClassListTab />
        </Content>
      </Layout>
    </>
  )
}

export default ListPage

const AppendRightBlock = styled.div`
  > * {
    margin-left: 10px;
  }
`

const IntroBlock = styled.div`
  margin: 40px 12px 24px;

`

const Title = styled.h1`  
  font-size: 28px;
  color: ${({ theme }) => theme.mainGreen};
`
const Description = styled.p`
  margin-top: 10px;
  font-size: 20px;
  white-space: pre-line;

  line-height: 26px;
`

const LocationBlock = styled.div`
  display: none;
  margin-top: 4px;
  padding: 4px 14px;

  border-radius: 34px;
  background: ${({ theme }) => theme.mainGreen};
  color: ${({ theme }) => theme.white};
  width: 100px;
`

const LocationText = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  > svg {
    fill: ${({ theme }) => theme.white};
  }
`

const LocationList = styled.ul`
  font-size: 18px;

  height: 160px;
  overflow-y: scroll;
  overscroll-behavior: contain;
`

const LocationListItem = styled.li<{ selected: boolean }>`
  height: 34px;
  line-height: 34px;

  text-align: center;

  ${({ selected, theme }) => selected && css`
    color: ${theme.white};
    background: ${theme.mainGreen};
    border-radius: 8px;
    font-weight: 500;
  `}
`

function ClassListTab(){
  const { data: categoryList } = useCategoryList()
  if (!categoryList){
    return null
  }
  return (
    <TabSlider
      sticky
      items={[{ key: '', value: '전체' }, ...categoryList].map(({ key, value }) => ({
        title: `#${value}`,
        content: <ClassList classCategory={key} />
      }))}
    />
  )
}

function ClassList({ classCategory }: { classCategory: string }){
  const [page, setPage] = useState(1)
  const [location, setLocation] = useState('')
  const { mainRegionName } = useRecoilValue(locationAtom)

  const { data: classList } = useClassList({
    classCategory, mainRegionName,
    page: mainRegionName === location ? page : 1
  })

  const target = useRef<HTMLDivElement>(null)

  const [items, setItems] = useState<{
    [location: string]: Exclude<typeof classList, null | undefined>;
  }>({})

  useEffect(() => {
    setLocation(mainRegionName)
    setItems({ [mainRegionName]: [] })
    setPage(1)
  }, [mainRegionName])

  useEffect(() => {
    if (classList && classList.length > 0){
      setItems((state) => {

        if (!Object.prototype.hasOwnProperty.call(state, mainRegionName)){
          state[mainRegionName] = []
        }

        const classListByRegion = state[mainRegionName]

        return { [mainRegionName]: classListByRegion.length > 0 ? [...classListByRegion, ...classList] : classList }
      })
    }
  }, [classList, mainRegionName])

  // 옵저버 등록
  useEffect(() => {
    if (!classList || classList.length === 0){
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        /*
        관찰할 대상(Target)이 등록되거나
        가시성(Visibility, 보이는지 보이지 않는지)에 변화가 생기면
        관찰자는 콜백(Callback)을 실행.

        콜백은 2개의 인수(entries, observer)를 가진다.
      */

        entries.forEach(async (entry) => {
          // 관찰 대상이 루트 요소와 교차 상태로 들어가거나(true) 교차 상태에서 나가는지(false) 여부를 나타내는 값(Boolean)입니다.
          if (entry && entry.isIntersecting) {
            // 등록된 event 핸들러로 값을 보냄

            setPage((state) => {
              const newState = state + 1
              return newState
            })
          }
        })
      },
      {
        // root: body // 대상 객체의 가시성을 확인할 때 사용되는 뷰포트 요소. 기본값 브라우저 뷰포트
        /*
        observer의 콜백이 실행될 대상 요소의 가시성 퍼센티지를 나타내는 단일 숫자.
        1.0 은 대상 요소가 root 에 지정된 요소 내에서 100% 보여질 때 콜백이 호출될 것을 의미
        */
        threshold: 0.9
      }
    )

    if (target.current) observer.observe(target.current)
    return () => observer.disconnect()
  }, [classList])

  /* FIXME: refetch할때 마다 classList 가 없음. 확인필요 */
  // if (!classList || isClassListLoading){
  //   /* 클래스 목록을 가져오는 중입니다. */
  //   return <PageBlockCenter>...</PageBlockCenter>
  // }

  if (page === 1 && classList && classList.length === 0){
    return <PageBlockCenter>개설된 클래스가 없습니다.</PageBlockCenter>
  }

  return (
    <>
      <Grid>
        {items && items[mainRegionName]?.map(({ daruClassId, classCategory, classMainName, maker: { nickname, profileImage = '' }, address, classSubName, recruitProgressDate: { recruitStartDate: startDate, recruitEndDate: endDate }, classStatus, classProgressDate, recruitPersonnel, currentPersonnel, requiredClassFee, thumbnailImageURL }) => (
          <Link
            key={daruClassId}
            to={`/class/${daruClassId}`}>
            <ClassBloack>

              <CardImageBlock>
                <CardImage
                  alt={`${classMainName}-대표이미지`}
                  src={thumbnailImageURL}
                  height={210}
                  width="100%"
                />
                <ClassStartDate>시작날짜 <span>{dateText(classProgressDate[0].classRoundStartDate)}</span></ClassStartDate>
              </CardImageBlock>

              <Top>
                <ButtonGroup>
                  <Button
                    label={address.sido}
                    isRadius
                    inline
                    ghost />
                  <Button
                    label={getClassStatus(classStatus).text}
                    color={getClassStatus(classStatus).color}
                    isRadius
                    inline />
                </ButtonGroup>
                <Share
                  title={classMainName}
                  imageUrl={thumbnailImageURL}
                  path={`/class/${daruClassId}`}
                />
              </Top>

              <Name>
                {profileImage && (<ProfilImage
                  src={profileImage}
                  alt="profile-image" />)}
                <span>{nickname}</span>
              </Name>
              <Label>
                <h4>{classMainName}</h4>
                <div>{classSubName}</div>
              </Label>
              <Category>#{classCategory}</Category>

              <ClassInfo>
                <ClassInfoItem>
                  <div><strong>총 진행횟수</strong><span>{classProgressDate.length}회 진행</span></div>
                  <div><strong>현재인원/모집인원</strong><span>{`${currentPersonnel}/${recruitPersonnel}`}</span></div>
                </ClassInfoItem>
                <ClassInfoItem>
                  <div><strong>모집 시작 날짜</strong><span>{dateText(startDate)}</span></div>
                  <div><strong>모집 마감 날짜</strong><span>{dateText(endDate)}</span></div>
                </ClassInfoItem>
              </ClassInfo>
              <Price>{numberWithCommas(requiredClassFee, '원')} <span>(1인)</span></Price>
            </ClassBloack>
          </Link>
        ))}
      </Grid>
      <div ref={target}></div>
    </>
  )
}

const ClassStartDate = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;

  padding: 5px;
  text-align: center;
  width: 172px;
  border-radius: 40px;  
  opacity: 0.59;
  background: #1e1e1e;
  color: ${({ theme }) => theme.caution};  
  font-weight: 500;
  > span{
    color: #fff;
  }

`

const Grid = styled.div`
/* 

[그리드 크기]
- 블록 좌우여백 2px 
- 컨텐츠 350px

총합 4+350=354px

참고) 스와이프 좌우여백 10px 
*/
  display: grid;
  grid-template-columns: repeat(auto-fill, 358px);
  justify-content: center;
  gap: 14px;
  margin-top: 16px;
`

const ClassBloack = styled.div`
  padding: 2px;

  font-size: 12px;
  margin-bottom: 26px;
  /* TODO: active 스타일 */
  /* &:active{
    background: ${({ theme }) => theme.gray1};  
    border-radius: 8px;
  }  */
`

export const ProfilImage = styled.img`
  display: inline-block !important;

  vertical-align: middle;
  margin-right: 6px;
  width: 22px;
  height: 22px;
`

const Name = styled.div`
  height: 34px;
  margin-top: 20px;
  color: ${({ theme }) => theme.gray4};  
`

const Label = styled.div`
  margin-bottom: 28px;
  > h4{
    font-size: 15px;
    height: 26px;
  }
`

export const ButtonGroup = styled.div`
  > *{
    margin-right: 4px;
  }
`

const Category = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.mainGreen};  
  font-weight: 600;
`

const CardImageBlock = styled.div`
  position: relative;
  text-align: center;
`
const CardImage = styled(Image)`
  border-radius: 10px;
  margin-bottom: 30px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ClassInfo = styled.div`
  margin-top: 18px;
  margin-bottom: 24px;
  padding: 12px 0;
  border-top: 1px solid ${({ theme }) => theme.gray1};
  border-bottom: 1px solid ${({ theme }) => theme.gray1};
  display: flex;
  justify-content: space-between;
`

const ClassInfoItem = styled.div`
  line-height: 34px;

  > * {
    line-height: 34px;
  }
  strong{
    margin-right: 10px;
  }
  span{
    font-size: 12px;
    color: ${({ theme }) => theme.gray4};
  }
`

const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
  > span{
    color: ${({ theme }) => theme.gray3}; 
    font-size: 12px;
  } 
`

