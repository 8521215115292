import { Methods, request } from '@/libs/request'

export type GetNumberOfPreRegistrationsResponseType = { count: number };

export const getNumberOfPreRegistrations = () => {
  return request<GetNumberOfPreRegistrationsResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/marketing/pre-register',
    method: Methods.GET
  })
}
