import styled from '@emotion/styled'
import { ChangeEvent, FormEvent, useState } from 'react'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import ToastType from '@/enums/toast'
import useNumberOfPreRegistrations from '@/hooks/marketing/useNumberOfPreRegistrations'
import usePostPreRegister from '@/hooks/marketing/usePostPreRegister'
import { isError } from '@/libs/request'

interface FormData {
  name: string;
  phoneNumber: string;
  nationalCode: string;
  email: string;
  job: string;
  space: string;
  expectations: string[];
}

const jobOptions = [
  'お茶生産者(茶農家)', 'お茶愛好家', '学生', '茶屋・カフェオーナー', '茶道教室・お茶体験講師', '同好会・サークル運営', 'その他'
]

const spaceOptions = [
  { value: 'yes', label: 'はい' },
  { value: 'no', label: 'いいえ' }
]

const expectationsOptions = [
  '茶屋・カフェ情報閲覧', 'お茶について話せるコミュニティ', 'お茶の最新トレンド情報'
]

const PreRegistrationPage = () => {
  const [success, setSuccess] = useState(false)
  const { mutate: postPreRegister } = usePostPreRegister()
  const { data } = useNumberOfPreRegistrations()
  const [formData, setFormData] = useState<FormData>({
    name: '',
    phoneNumber: '',
    nationalCode: '81',
    email: '',
    job: '',
    space: '',
    expectations: []
  })

  const [showForm, setShowForm] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const newExpectations = checked
      ? [...formData.expectations, name]
      : formData.expectations.filter((expectation) => expectation !== name)
    setFormData({ ...formData, expectations: newExpectations })
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const note = `
      직업: ${formData.job}
      공간 소유 여부: ${formData.space === 'yes' ? '예' : '아니오'}
      기대하는 바: ${formData.expectations.join(', ')}
    `
    await postPreRegister({
      phoneNumber: formData.phoneNumber,
      code: Number(formData.nationalCode),
      name: formData.name,
      email: formData.email,
      note
    }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: 'Success Enrollment!', type: ToastType.INFORMATION })
        //TODO : 결과 추가하기 data.count 를 표시
        setSuccess(true)
      }
    })
  }

  return (
    <PageWrapper>
      <Header>
        <h1>「DARU」事前登録フォーム</h1>
      </Header>
      <InfoBox>
        {(success) ? <SuccessMessage>
          <h2>ご登録ありがとうございます！</h2>
          <p>あなたは {data?.count} 番目の登録者です。</p>
        </SuccessMessage> : <>
          <Description>
            <h2>お茶専門プラットフォーム「DARU」へようこそ！</h2>
            <p style={{ textAlign: 'left' }}>「DARU」はお茶愛好家、生産者・茶屋のオーナーなどお茶を飲み、作り、楽しむ様々な人が集い情報を交換する「お茶好きによるお茶好きのためのお茶コミュニティ」です。</p>
            <p>事前登録をするとこんな特典が！</p>
            <ul>
              <li>お茶の最新トレンドをいち早くお知らせ</li>
              <li>茶屋・カフェ情報＆レビュー</li>
              <li>茶道体験など各種体験コースの最新情報</li>
            </ul>
          </Description>
          {!showForm ? (
            <ButtonWrapper>
              <ShowFormButton onClick={() => setShowForm(true)}>事前登録</ShowFormButton>
            </ButtonWrapper>
          ) : (
            <FormWrapper onSubmit={handleSubmit}>
              <InputWrapper>
                <Label htmlFor="name">ニックネーム:</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="phoneNumber">電話番号:(090~, 010~, 070~, 080~)</Label>
                <Input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="nationalCode">国番号:</Label>
                <Input
                  type="text"
                  id="nationalCode"
                  name="nationalCode"
                  value={formData.nationalCode}
                  onChange={handleChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="email">メールアドレス:</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>職業:</Label>
                <RadioGroup>
                  {jobOptions.map((job) => (
                    <RadioLabel key={job}>
                      <RadioInput
                        type="radio"
                        name="job"
                        value={job}
                        checked={formData.job === job}
                        onChange={handleRadioChange}
                        required
                      />
                      <span>{job}</span>
                    </RadioLabel>
                  ))}
                </RadioGroup>
              </InputWrapper>
              <InputWrapper>
                <Label>お茶に関する空間(茶室・茶屋・カフェ)を保有・運営していますか？:</Label>
                <RadioGroup>
                  {spaceOptions.map((option) => (
                    <RadioLabel key={option.value}>
                      <RadioInput
                        type="radio"
                        name="space"
                        value={option.value}
                        checked={formData.space === option.value}
                        onChange={handleRadioChange}
                        required
                      />
                      <span>{option.label}</span>
                    </RadioLabel>
                  ))}
                </RadioGroup>
              </InputWrapper>
              <InputWrapper>
                <Label>使ってみたいアプリ内サービス：</Label>
                <CheckboxGroup>
                  {expectationsOptions.map((expectation) => (
                    <CheckboxLabel key={expectation}>
                      <CheckboxInput
                        type="checkbox"
                        name={expectation}
                        checked={formData.expectations.includes(expectation)}
                        onChange={handleCheckboxChange}
                      />
                      <span>{expectation}</span>
                    </CheckboxLabel>
                  ))}
                </CheckboxGroup>
              </InputWrapper>
              <SubmitButton type="submit">送信</SubmitButton>
            </FormWrapper>
          )}</>}

      </InfoBox>
    </PageWrapper>
  )
}

export default PreRegistrationPage

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('/images/pre-register-background.png'); // 여기에 제공된 이미지의 URL을 입력하세요
  background-size: cover;
  background-position: center;
  padding: 20px;
`

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: rgba(1, 200, 151, 0.9);
  color: white;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const InfoBox = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin-top: 60px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 80px;
  }
`

const Description = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
  }

  ul li {
    margin-bottom: 5px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ShowFormButton = styled.button`
  background-color: #01c897;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  &:hover {
    background-color: #019a7a;
  }
`

const FormWrapper = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 15px;
    display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 6px;
    font-size: 14px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
`

const RadioInput = styled.input`
  margin-right: 10px;

  &:checked + span {
    font-weight: bold;
    color: #01c897;
  }
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const CheckboxInput = styled.input`
  margin-right: 10px;

  &:checked + span {
    font-weight: bold;
    color: #01c897;
  }
`

const SubmitButton = styled.button`
  background-color: #01c897;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;

  &:hover {
    background-color: #019a7a;
  }
`
const SuccessMessage = styled.div`
  text-align: center;
  h2 {
    color: #333;
  }
  p {
    color: #333;
  }
`
