import styled from '@emotion/styled'

import Button from '../Input/Button'

function CTAButton({ label, onClick, disabled = true }: { label: string; onClick?: () => void; disabled?: boolean }) {
  return (
    <CTABlock>
      <Button
        label={label}
        disabled={disabled}
        onClick={onClick}
      />
    </CTABlock>
  )
}

export default CTAButton

export const CTAHeight = 50

const CTABlock = styled.div`
  /* margin: 0 10px; */
  height: ${CTAHeight}px;
`
