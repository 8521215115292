import styled from '@emotion/styled'

interface RegionType {
  city: string;
  subCity: string;
}
const Region = ({ city, subCity }: RegionType) => {
  return (
    <RegionWrapper>
      {city}
      {' '}
      {subCity}
    </RegionWrapper>
  )
}
export default Region
const RegionWrapper = styled.div`
  background-color: #aaaaaa;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
`
