import styled from '@emotion/styled'

type ParticipatorReviewItemType = {
  data: { subject: string; value: number }[];
  title: string;
  description: string;
  icon: string;
  createdAt: string;
  user: string;
}
const ParticipatorReviewItem = ({ title, description, icon, createdAt }: ParticipatorReviewItemType) => {
  return (
    <ItemWrapper>
      <RowWrapper>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
        <Date>{createdAt}</Date>
      </RowWrapper>
      <RowWrapper>
        <Content>{description}</Content>
      </RowWrapper>
    </ItemWrapper>
  )
}
export default ParticipatorReviewItem

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
const RowWrapper = styled.div`
display: flex;
gap: 10px;
flex-direction: row;
align-items: center;
`
const Content = styled.pre`
`
const Date = styled.div`
  color: #aaa;
  font-weight: 600;
`
const Title = styled.div`
  color: #01c897;
`
const Icon = styled.div`
`

{ /* <RadarChartTastingNoteItem
key={index}
data={tasteData}
tea={tastingNote.title}
description={tastingNote.content}
image={tastingNote.image}
createdAt={timeSince(tastingNote.createdAt)}
user={user}
/> */ }
