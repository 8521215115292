import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { PostLikeRequestType } from '@/apis/likes/postLike'
import ToastType from '@/enums/toast'
import useLikes from '@/hooks/useLikes'
import usePostLike from '@/hooks/usePostLike'
import { isError } from '@/libs/request'

import { notifyRenewal } from '../Common/ToastRenewal'
import { IcHeart } from '../Icons'

interface LikeButtonType {
  type: 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER';
  targetId: number;
}
const LikeButton = React.memo(({ type, targetId }: LikeButtonType) => {
  const { data } = useLikes(targetId, type)
  const { mutate: postLike } = usePostLike()
  const [likes, setLikes] = useState<number>(0)
  const [liked, setLiked] = useState<boolean>(false)
  const likeRequestData: PostLikeRequestType = { like: liked ? 0 : 1, type, targetId }
  useEffect(() => {
    if (data?.likes) setLikes(data.likes)
    if (data?.isThisUserLiked) setLiked(data.isThisUserLiked)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const likeHandler = async() => {
    await postLike(likeRequestData, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        if (!liked) {
          notifyRenewal({ message: '좋아하는 찻집에 추가되었습니다', type: ToastType.INFORMATION })
          setLikes(likes + 1)
          setLiked(!liked)
        } else {
          setLikes(likes - 1)
          setLiked(!liked)
        }
      }
    })
  }
  return (
    <ButtonWrapper>
      <IcHeart
        onClick={likeHandler}
        width={20}
        height={20}
        fill= { data?.isThisUserLiked ? '#01c897' : '#ccc'} />
      {data?.likes}
    </ButtonWrapper>
  )
})
LikeButton.displayName = 'Like'

export default LikeButton

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`
