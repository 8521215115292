import styled from '@emotion/styled'
import { useState } from 'react'

import { Body1Bold } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Header from '@/components/Layout/Header'
import Title from '@/components/Layout/Title'

interface MyObject {
  key: string;
  value: string;
}

const categoryList: MyObject[] = [
  { key: 'TEA_CLASS', value: '티클래스' },
  { key: 'TEA_SOCIALING', value: '다회(차회)/차모임' },
  { key: 'CERTIFICATE', value: '자격증' },
  { key: 'TEA_PAIRING', value: '티페어링/티코스' },
  { key: 'MEDITATION', value: '요가/명상' },
  { key: 'FESTIVAL', value: '축제/행사' }
]

const MakerClassCreateSelectPage = () => {
  const [clicked, setClicked] = useState(false)
  return (
    <PageWrapper>
      <Header
        title="티클래스 개설하기"
        fadein={false}
        timing={1}
        back={true} />
      <Title
        title='클래스 분류를 선택해주세요'
        subtitle='이번 클래스가 어떤 종류에 속하나요?' />
      <ButtonWrapper>
        {categoryList.map((e, index) => (<SelectButtonBlock
          key={index}
          onClick={() => setClicked(!clicked)}>
          <SelectButtonContentBlock><CategoryImage
            clicked={clicked}
            src={`/categoryIcon/${e.key}.svg`} /><Text clicked={clicked}>{e.value}</Text></SelectButtonContentBlock>
        </SelectButtonBlock>))}
      </ButtonWrapper>
    </PageWrapper>
  )
}
export default MakerClassCreateSelectPage

const ButtonWrapper = styled.div`
display: flex;
flex-wrap: wrap;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 23px;
`
const SelectButtonBlock = styled.div`
display: flex;
width: 152px;
height: 124px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 10px;
border: 1px solid ${({ theme }) => theme.gray2};
`
const SelectButtonContentBlock = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
width: auto;
`
const CategoryImage = styled(Image)<{ clicked: boolean }>`
color: ${(props) => props.clicked ? props.theme.mainGreen : props.theme.gray1 };
`
const Text = styled(Body1Bold)<{ clicked: boolean }>`
color: ${(props) => props.clicked ? props.theme.mainGreen : props.theme.gray1 };
`
