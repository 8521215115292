import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PostLikeRequestType = {
  type: 'USER' | 'TEAHOUSE' | 'TEA-TOGETHER';
  targetId: number;
  like: number;
}

export const postLike = async (postLikeRequestType: PostLikeRequestType) => {
  return request<void>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `like/${postLikeRequestType.type.toLowerCase()}/${postLikeRequestType.targetId}`,
    data: postLikeRequestType,
    method: Methods.POST,
    headers: { 'Authorization': `Bearer ${getAccessToken()}` }
  })
}
