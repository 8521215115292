import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import NotificationModal from '@/components/Modal/NotificationModal'

import NewTeahouse from './components/NewTeahouse'
import RecommendedTeahouse from './components/RecommendedTeahouse'
import SearchBar from './components/SearchBar'
import OpenStreetMap from './components/StaticStreetMap'
import TeaMapHeader from './components/TeaMapHeader'

const TeaMapPage: React.FC = () => {
  // const [searchParams] = useSearchParams()
  // const lat = searchParams.get('lat')
  // const lon = searchParams.get('lon')
  const [mapCenter, setMapCenter] = useState([35.87712967204818, 127.73987517692152])
  const [mapZoom, setMapZoom] = useState(7)

  const [createModalOpened, setCreateModalOpened] = useState(false)
  const [loginModalOpened, setLoginModalOpened] = useState(false)
  const [notificationModalOpened, setNotificationModalOpened] = useState(false)

  const mapMoveHandler = (lat: number, lon: number, zoom: number) => {
    setMapCenter([lat, lon])
    setMapZoom(zoom)
  }
  // Attach the click event listener to the map
  return (
    <PageWrapper>
      <SearchWrapper>
        <SearchBar mapMoveHandler={mapMoveHandler} />
      </SearchWrapper>
      <TeaMapHeader
        setCreateModalOpened={setCreateModalOpened}
        setOpenNotificationModalOpened={setNotificationModalOpened}
        setLoginModalOpened={() => setLoginModalOpened(!loginModalOpened)} />
      {(notificationModalOpened) && <NotificationModal
        isVisible={notificationModalOpened}
        onClose={() => setNotificationModalOpened(false)} />}
      {(createModalOpened) && <></>}
      <BottomButtonWrapper>
        <BottomSlider />
      </BottomButtonWrapper>
      <OpenStreetMap
        lat={mapCenter[0]}
        lon={mapCenter[1]}
        zoom={mapZoom}
      >
      </OpenStreetMap>
    </PageWrapper>
  )
}

export default TeaMapPage

const PageWrapper = styled.div`
  padding: 67px 0px 0px 0px;
`
const SearchWrapper = styled.div`
  position: fixed;
  top: 67px;
  left: 0px;
  right: 0px;
  z-index: 2000;
`
const moveLeftRight = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Adjust the distance to move */
  }
  100% {
    transform: translateX(0);
  }
`
const BottomButtonWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  gap: 10px;
  height: 20vh;
  z-index: 1000;
  background-color: #fff;
`
const BottomContentBlock = styled.div`
  padding: 10px 20px;
`
const TeahouseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
`
const BottomTitle = styled.div`
  font-weight: 700;
  color: #000;
  font-size: 20px;
  width: 90%;
  flex-direction: row;
  justify-content: flex-start;
  animation: ${moveLeftRight} 2s infinite;
`

const BottomSlider = () => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <Slider {...settings}>
      <SubBottomTab1 />
      <SubBottomTab2 />
      <AdContent
        image='/images/banner-partner.png'
        endpoint='/' />
      <AdContent
        image='/images/banner1.png'
        endpoint='/business' />
    </Slider>
  )
}

const SubBottomTab1 = () => {
  return (
    <BottomContentBlock>
      <BottomTitle>🔥 요즘 인기 찻집</BottomTitle>
      <TeahouseWrapper>
        <RecommendedTeahouse
          rank={1}
          name='다루 티하우스'
          id={1} />
        <RecommendedTeahouse
          rank={2}
          name='티하우스 TEAHOUSE TEA'
          id={2} />
        <RecommendedTeahouse
          rank={3}
          name='티룸'
          id={3} />
      </TeahouseWrapper>
    </BottomContentBlock>
  )
}
const SubBottomTab2 = () => {
  return (
    <BottomContentBlock>
      <BottomTitle>👀 새로 등록된 찻집</BottomTitle>
      <TeahouseWrapper>
        <NewTeahouse
          region='서울'
          name='다루 티하우스'
          id={1} />
        <NewTeahouse
          region='전북'
          name='티하우스 TEAHOUSE TEA'
          id={2} />
        <NewTeahouse
          region='부산'
          name='티룸'
          id={3} />
        <NewTeahouse
          region='서울'
          name='다루 티하우스'
          id={1} />
        <NewTeahouse
          region='전북'
          name='티하우스 TEAHOUSE TEA'
          id={2} />
        <NewTeahouse
          region='부산'
          name='티룸'
          id={3} />
      </TeahouseWrapper>
    </BottomContentBlock>
  )
}

interface AdContentType {
  image: string;
  endpoint: string;
}
const AdContent = ({ image, endpoint }: AdContentType) => {
  const navigate = useNavigate()
  return (
    <BottomContentBlock onClick={() => navigate(endpoint)}>
      <AdImage src={image} />
    </BottomContentBlock>
  )
}
const AdImage = styled.img`
  width: 100%;
  max-height: 150px;
  object-fit: contain;
`
