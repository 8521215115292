import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Sub3 } from '@/components/Base/Text'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcArrowLeft } from '@/components/Icons'
import ToastType from '@/enums/toast'

interface HeaderCreateTeaTogetherPageType {
  title: string;
  contentFilled: boolean[];
  create: () => void;
}
const HeaderCreateTeaTogetherPage = ({ title, contentFilled, create }: HeaderCreateTeaTogetherPageType) => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  const able = contentFilled[0] && contentFilled[1] && contentFilled[2] && contentFilled[3] && contentFilled[4]
  const onClickHandler = () => {
    if (able) create()
    else notifyRenewal({ message: '아직 입력을 완료하지 않았습니다', type: ToastType.CAUTION })
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>{title}</HeaderTitle>
      </LeftWrapper>
      <RightWrapper>
        <CreateButton
          able={able}
          onClick={onClickHandler}>만들기</CreateButton>
      </RightWrapper>
    </HeaderWrapper>
  )
}

export default HeaderCreateTeaTogetherPage

/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const RightWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const CreateButton = styled.div<{ able: boolean }>`
  background-color: ${({ able }) => able ? '#01c897' : '#aaa' };
  color : #fff;
  padding : 5px 10px;
  font-weight: 700;
  border-radius: 5px;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`
