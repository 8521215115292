
import { useState } from 'react'

import { postCheckMakerNickName, PostCheckMakerNickNameResponseType } from '@/apis/maker/postCheckMakerNickname'

export const useCheckMakerNickname = () => {
  const [duplicated, setDuplicated] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('')
  const check = async (nickname: string) => {
    try {
      setIsLoading(true)
      setMessage('잠시만 기다려주세요.')
      const response = await postCheckMakerNickName({ nickName: nickname })
      const { duplicated }: PostCheckMakerNickNameResponseType = response
      setDuplicated(duplicated)
      setIsError(false)
    } catch (error) {
      setIsError(true)
      setMessage('별명이 중복됩니다')
    } finally {
      setIsLoading(false)
    }
  }
  return { duplicated, isLoading, isError, message, check }
}