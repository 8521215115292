import { Methods, request } from '@/libs/request'

export type PostCheckUserNickNameRequestType = {
  nickName: string;
}
export type PostCheckUserNickNameResponseType = {
  duplicated: boolean;
}
///api/v1/user/nickname/validation/{nickname}
export const postCheckUserNickName = async({ nickName }: PostCheckUserNickNameRequestType) => request<PostCheckUserNickNameResponseType>({
  url: `user/nickname/validation/${ nickName }`,
  method: Methods.GET
})
