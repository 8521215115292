import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { H2, PageBlock } from '@/components/Common/Styled'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import { ROUTER } from '@/router'

const { ACCOUNT } = ROUTER

function JoinPasswordPage() {
  const [field, setField] = useState<{
    password: string;
    repassword: string;
  }>({
    password: '',
    repassword: ''
  })
  const buttonDisabled = useMemo(() => {
    const { password, repassword } = field
    return !(!!password && !!repassword)
  }, [field])

  return (
    <Layout>
      <Header
        title="회원가입"
        back
      />
      <Content>
        <PageBlock>
          <H2>비밀번호 설정</H2>
          <p>사용할 비밀번호를 설정해주세요.</p>

          <Input
            label="비밀번호"
            type='password'
            placeholder="비밀번호 입력"
            info='영문, 숫자, 특수문자를 조합하여 8-16자로 입력해주세요.'
            value={field.password}
            onChange={(password) => {
              setField((state) => ({ ...state, password }))
            }}
          />

          <Input
            label="비밀번호 확인"
            type='password'
            placeholder="비밀번호 재입력"
            value={field.repassword}
            onChange={(repassword) => {
              setField((state) => ({ ...state, repassword }))
            }}
          />

        </PageBlock>
      </Content>

      <Link
        to={`${ACCOUNT}/create/account`}
      >
        <CTAButton
          disabled={buttonDisabled}
          label="다음"
        />
      </Link>
    </Layout>
  )
}

export default JoinPasswordPage
