import { useMutation } from 'react-query'

import { postApprovePayment, postApprovePaymentRequestType } from './../apis/payment/postApprovePayment'

export default function usePostApprovePayment() {
  return useMutation<void, unknown, postApprovePaymentRequestType>(
    async({ paymentKey, orderId, amount, order, orderName, customerName, teaTogetherId }) => {
      return await postApprovePayment({ paymentKey, orderId, amount, order, orderName, customerName, teaTogetherId })
    }
  )
}
