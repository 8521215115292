import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import { createRoot } from 'react-dom/client'

import icon from '@/assets/svg/icons/error.svg'
import ToastType from '@/enums/toast'

const BACKGROUND_SELECTOR_ID = 'toast_background'
const MESSAGE_SELECTOR_ID = 'toast_message'
const ICON_SELECTOR_ID = 'toast_icon'
const ToastRenewal = () => {
  return (
    <ToastWrapper id={BACKGROUND_SELECTOR_ID}>
      <IconBlock
        id={ICON_SELECTOR_ID}
        src={icon}
        alt='toast' />
      <ToastBlock id={ MESSAGE_SELECTOR_ID }></ToastBlock>
    </ToastWrapper>
  )
}
export default ToastRenewal
export type MessageType = {
  message: string;
  type: ToastType;
}

export const notifyRenewal = ({ message, type }: MessageType) => {
  if (typeof window !== 'undefined' && document) {
    //외부 색깔 바꾸기
    const selectorBackground = document.getElementById(BACKGROUND_SELECTOR_ID) as HTMLElement
    const styleSelect = () => {
      if (type == ToastType.ERROR) return '#ED4F4F'
      else if (type == ToastType.CAUTION) return '#F7C859'
      else if (type == ToastType.INFORMATION) return '#4B8AF7'
      else return '#4B8AF7'
    }
    selectorBackground.style.background = styleSelect()
    //내용 바꾸기
    const selectorMessage = document.getElementById(MESSAGE_SELECTOR_ID) as HTMLElement
    const root = createRoot(selectorMessage)
    const portal = createPortal(<Message>
      {message}
    </Message>, selectorMessage)
    selectorBackground.style.display = 'flex'
    root.render(portal)
    setTimeout(() => {
      selectorBackground.style.display = 'none'
      selectorMessage.innerHTML = ''
    }, (TIMING) * 1000)
  }
}
const ToastWrapper = styled.div`
position: fixed;
bottom: 46px;
height: 50px;
left: 50%;
transform: translate(-50%, -50%);

display: none;
align-items: center;
justify-content: flex-start;
gap: 12px;

width: 327px;
min-height: 55px;
max-height: 100px;
height: auto;
max-width: 350px;
min-width: 260px;
padding: 17px; 16px;

border-radius: 10px;
z-index: 10000000;
`

const ToastBlock = styled.div`
  display:inherit;
  align-items: center;
  justify-content: center;
`
const IconBlock = styled.img`
display:inherit;
width: 16px;
height: 16px;

`

const Message = styled.div`
display: flex;
justify-content: center; /* 수평 정렬 */
align-items: center; /* 수직 정렬 */
width: 100%;
height: 100%;
color: #fff;
`

const TIMING = 2.2 // 각각의 duration 합
