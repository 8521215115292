
import styled from '@emotion/styled'

import Content from '@/components/Layout/Content'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useReviewListByMaker from '@/hooks/useReviewListByMaker'

function ReviewListPage() {
  const { data: review } = useReviewListByMaker({ makerId: 8 })

  if (!review){
    return <>!review</>
  }

  const { reviews } = review
  const name = 'name'
  const avgSocre = '1'
  const totalCount = 10
  return (
    <Layout>
      <Header
        title="리뷰보기"
        appendRight={name}
        back
      />
      <Content>
        <div>나의 클래스/모임에 참여한 손님들의 생생한 후기를 볼 수 있어요.</div>

        <ClassInfoTable>
          <thead>
            <tr>
              <th>전체 리뷰수</th>
              <th>평균 평점</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalCount}</td>
              <td>{avgSocre}</td>
            </tr>
          </tbody>
        </ClassInfoTable>
        {reviews.map(({ classMainName: className, startDate: date, reviews: datas }, index) => {
          return (
            <ReviewBlock key={index}>
              <div><h4>{date} {className}</h4></div>
              <ReviewListBloack>
                {datas.length > 0 ? datas.map(({ reviewRate: score, reviewContent: text }, index) => {
                  return (
                    <ClassListItem key={index}>{score}: {text}</ClassListItem>
                  )
                }) :
                  <NoData>
                    <div>작성된 리뷰가 없습니다.</div>
                  </NoData>
                }
              </ReviewListBloack>
            </ReviewBlock>
          )
        })}
      </Content>
    </Layout>
  )
}

export default ReviewListPage

const ClassInfoTable = styled.table`
  margin: 20px 0;

  text-align: center;
  border-spacing: 4px;
  width: 100%;
  th {
    width: 50%;
    font-weight: 700;
  }
`

const ReviewBlock = styled.div`
  margin: 40px 0;
`

const ReviewListBloack = styled.ul`
  padding: 0 10px;
`

const ClassListItem = styled.li`
  line-height: 20px;
`

const NoData = styled.div`
  text-align: center;
  margin: 20px;
  padding: 40px 0;
  background:${({ theme }) => theme.gray5};
  color:${({ theme }) => theme.white};

`

