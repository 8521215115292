
import styled from '@emotion/styled'
import { useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { H2, Label, PageBlock } from '@/components/Common/Styled'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import { ROUTER } from '@/router'

const { ACCOUNT } = ROUTER

function JoinFormPage() {
  const inputRef = useRef<HTMLInputElement[]>([])

  const [field, setField] = useState({
    birth: '',
    phone: '',
    sex: ''
  })

  const buttonDisabled = useMemo(() => {
    const { birth, phone, sex } = field
    return !(!!birth && !!phone && !!sex)
  }, [field])

  return (
    <Layout>
      <Header
        title="회원가입"
        back
      />
      <Content>

        <PageBlock>
          <H2>핸드폰 가입</H2>
          <p>다루에서는 휴대폰 번호를 아이디로  사용됩니다.</p>

          <Label>생년월일 및 성별</Label>
          <InputBlock>
            <Input
              type='number'
              placeholder='YYMMDD'
              maxLength={6}
              value={field.birth}
              onChange={(birth) => {
                setField((state) => ({ ...state, birth }))
                if (birth.length === 6){
                  if (inputRef.current && inputRef.current.length > 0){
                    inputRef.current[0].focus()
                  }
                }
              }}
            />
            <span>-</span>
            <Input
              ref={(el) => {
                if (el && inputRef.current){
                  return inputRef.current[0] = el
                }
              }}
              type='number'
              placeholder='0'
              maxLength={1}
              value={field.sex}
              onChange={(sex) => {
                setField((state) => ({ ...state, sex }))
                if (sex.length === 1){
                  if (inputRef.current && inputRef.current.length > 0){
                    inputRef.current[1].focus()
                  }
                }
              }}
              right={<Masking>●●●●●●</Masking>}
            />
          </InputBlock>
          <Input
            ref={(el) => {
              if (el && inputRef.current){
                return inputRef.current[1] = el
              }
            }}
            label="휴대폰 번호"
            type='number'
            placeholder='01012345678'
            value={field.phone}
            onChange={(phone) => {
              setField((state) => ({ ...state, phone }))
            }}
          />
        </PageBlock>
      </Content>

      <Link
        to={`${ACCOUNT}/create/password`}
      >
        <CTAButton
          disabled={buttonDisabled}
          label="다음"
        />
      </Link>
    </Layout>
  )
}

export default JoinFormPage

const Masking = styled.div`
  letter-spacing: 8px;
`

const InputBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  text-align: center;
  > * {
    flex-grow: 1;
  }
  > span{
    flex-grow: 0;
  }
`