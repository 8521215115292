import { useQuery } from 'react-query'

import { getTastingNotesOfParticipator } from '@/apis/festival/getTastingNotesOfParticipator'

const getQueryKey = (festivalId: number, participatorId: number) => ['tastingNotes', festivalId, participatorId]

export default function useTastingNoteOfParticipator(festivalId: number, participatorId: number) {
  return useQuery(
    getQueryKey(festivalId, participatorId),
    async () => {
      const tastingNotes = await getTastingNotesOfParticipator({ festivalId, participatorId })
      return tastingNotes
    })
}
