import styled from '@emotion/styled'

export const PageWrapper = styled.div`
  display: flex; 
  overflow-x: hidden;
  overflow-y: scroll; 
  flex-direction : column;
  align-items: flex-start;
  gap: 0px;
`
export const PageWrapperCentered = styled.div`
  display: flex; 
  overflow-x: hidden;
  overflow-y: scroll; 
  flex-direction : column;
  align-items: center;
  gap: 0px;
`

export const ButtonWrapper = styled.div`
  bottom: 46px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
`
