import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { ThemeMode } from '../Theme/ThemeSwitch'

function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <LayoutBloack>
        {children}
      </LayoutBloack>

      <ThemeMode />
    </>
  )
}

export default Layout

export const MarginBottom = 0
const LayoutBloack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: calc(${MarginBottom}px + env(safe-area-inset-bottom));

`
