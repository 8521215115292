import styled from '@emotion/styled'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PostConfirmSMSRequestType } from '@/apis/ncp/postConfirmSMS'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { CTAButton } from '@/components/Input/CTAButton'
import InputDefault from '@/components/Input/InputDefault'
import { StatusType } from '@/components/Input/InputDefault'
import InputPhoneNumber from '@/components/Input/InputPhoneNumber'
import Header from '@/components/Layout/Header'
import ProgressBartest from '@/components/Layout/ProgressBartest'
import Title from '@/components/Layout/Title'
import Toast from '@/components/Layout/Toast'
import { ResetPasswordContext } from '@/context/LostPasswordContext'
import ToastType from '@/enums/toast'
import { useConfirmSMS } from '@/hooks/useConfirmSMSForResetPassword'
import { usePostResetPassword } from '@/hooks/useResetPassword'
import { useSendSMS } from '@/hooks/useSendSMSForResetPassword'
import ResetPasswordProvider from '@/providers/ResetPasswordProvider'
import validatePassword from '@/validators/regex/validatePassword'
const LostPasswordPage = () => {
  return (
    <>
      <ResetPasswordProvider>
        <Pages />
      </ResetPasswordProvider>
    </>
  )
}
export default LostPasswordPage
const Pages = () => {
  const { step } = useContext(ResetPasswordContext)
  const totalPages = 2
  return (
    <>
      <Header back={true} />
      <ProgressBartest
        step= {step}
        totalPages={totalPages} />
      <>
        {step === 0 && <CheckPhoneNumberPage />}
        {step === 1 && <ResetPage />}
      </>
    </>
  )
}
const CheckPhoneNumberPage = () => {
  const { setData, setStep } = useContext(ResetPasswordContext)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState(82)
  const [validateNumber, setValidateNumber] = useState('')
  const errortype: StatusType = 'error'
  //const [status, setStatus] = useState(false)
  //Toast (수정필요, App 메인 상단으로 이동)
  //const [toast, setToast] = useState<{ message: string; type: 'information' | 'error' | 'caution' } | null>(null)
  //Send SMS, Expiresin Time
  const [remainingTime, setRemainingTime] = useState(3000)
  //SendSMS 여부
  const [triedValidation, setTriedValidation] = useState(false)
  //입력 여부 체크
  const isFilled = useMemo(() => {
    return phoneNumber !== '' && code !== null && validateNumber !== '' && triedValidation == true && remainingTime > 0
  }, [phoneNumber, code, validateNumber, triedValidation, remainingTime])
  //SMS 보내기 버튼 클릭 핸들러
  const { loading, sendSMS, expireIn, errorMessage } = useSendSMS()
  useEffect(() => {
    //setStatus(loading)
    if (expireIn) setRemainingTime(expireIn)
  }, [loading, expireIn])
  const handleSend = async () => {
    try {
      await sendSMS({ code, phoneNumber })
      setTriedValidation(true)
    } catch (error) {
      console.error('Failed to validate phone number', error)
      if (error) {
        //setToast({ message: '휴대폰 번호 인증에 실패했습니다.', type: 'error' })
      }
    }
  }
  //Validate Confirm
  // validateNumber를 number로 변환하여 사용하기 위한 변수 선언
  const validateNumberInNumber = useMemo(() => parseInt(validateNumber.replace(/-/g, '')), [validateNumber])
  // confirmSMSData에 validateNumberInNumber 사용
  const confirmSMSData: PostConfirmSMSRequestType = { code, validationNumber: validateNumberInNumber, phoneNumber }
  const handleConfirm = async () => {
    setUserdata()
    try {
      await confirmSMS(confirmSMSData)
    } catch (error) {
      console.error(error)
    }
  }
  //Set Context
  const setUserdata = () => {
    setData((prevUser) => ({ ...prevUser, phoneNumber: phoneNumber, code: code }))
  }
  //Passed
  const { passed, error, confirmSMS } = useConfirmSMS(confirmSMSData)
  useEffect(() => {
    if (passed) setStep(1)
  }, [passed, setStep])
  return (
    <PageWrapper>
      <TitleWrapper>
        <Title
          title="비밀번호 분실"
          subtitle="휴대폰 인증을 진행하여 비밀번호를 재설정해요." />
      </TitleWrapper>
      <InputPhoneNumber
        title="휴대폰 번호"
        placeholder='01012341234'
        hasButton={true}
        onClick={handleSend}
        setCode={setCode}
        setPhonenumber={setPhoneNumber} />
      <InputDefault
        title="인증번호"
        placeholder='숫자 6자리'
        inputType='text'
        hasButton={true}
        buttonName='재요청'
        onClick = {handleSend}
        setInputValue={setValidateNumber} />
      <ButtonWrapper marginTop='200px'>
        {(errorMessage) && <Toast
          message={errorMessage.message}
          type={errortype} />}
        {(error) && <Toast
          message={error}
          type={errortype} />}
        <CTAButton
          disabled={!(isFilled)}
          onClick={handleConfirm}
          colorType='green'>인증번호 확인</CTAButton>
      </ButtonWrapper>
    </PageWrapper>
  )
}
const ResetPage = () => {
  const navigate = useNavigate()
  const errortype: StatusType = 'error'
  const normal: StatusType = 'normal'
  const correct: StatusType = 'correct'
  const { error, success, resetPassword } = usePostResetPassword()
  const { data, setData } = useContext(ResetPasswordContext)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const SameError = '두 비밀번호가 일치하지 않습니다.'
  const SameCorrect = '두 비밀번호가 일치합니다.'
  //Password 형식에 따른 Input1 Info Handler
  const handleInfo1 = useMemo(() => {
    if (password !== '' && !validatePassword(password))
      return { type: errortype, message: '비밀번호의 형식이 맞지 않습니다.(영문, 숫자, 특수문자 1개 반드시 포함 8지리~16자리)' }
    else if (validatePassword(password))
      return { type: correct, message: '사용할 수 있는 비밀번호입니다.' }
    else
      return { type: normal, message: '영문, 숫자, 특수문자 포함 8~16자리를 입력해주세요.' }
  }, [password])
  //Password 형식에 따른 Input2 Info Handler
  const handleInfo2 = useMemo(() => {
    if (password !== '' && password2 !== '')
      return (password === password2) ? { type: correct, message: SameCorrect } : { type: errortype, message: SameError }
    else
      return { type: normal, message: '위의 번호와 동일한 비밀번호를 입력해주세요' }
  }, [password, password2])
  //Password 동일성과 Filled여부, Validation을 확인하여 다음 버튼을 활성화합니다.
  const isFilled = useMemo(() => {
    const validated = validatePassword(password)
    setData((prevData) => ({ ...prevData, password: password, passwordConfirm: password2 }))
    return password !== '' && password2 !== '' && (password == password2) && validated
  }, [password, password2, setData])

  const handleButton = async() => {
    try {
      await resetPassword(data)
    } catch (error) {
      notifyRenewal({ message: '오류가 발생하였습니다.', type: ToastType.ERROR })
    }
  }
  useEffect(() => {
    if (success) {
      alert('비밀번호 재설정이 완료되었습니다')
      if (error == '') navigate('/account/login')
    }
  }, [error, navigate, success])
  return (
    <>
      <PageWrapper>
        <Header
          title="회원가입"
          fadein={false}
          timing={1}
          back={true} />
        <TitleWrapper>
          <Title
            title="비밀번호 설정"
            subtitle="사용할 비밀번호를 입력해주세요." />
        </TitleWrapper>
        <InputWrapper>
          <InputDefault
            title="비밀번호 입력"
            placeholder='비밀번호를 입력해주세요'
            info={ handleInfo1?.message }
            status={ handleInfo1?.type }
            inputType='password'
            hasButton={false}
            setInputValue={setPassword} />
          <InputDefault
            title="비밀번호 확인"
            placeholder='위에 입력한 비밀번호를 다시 입력해주세요'
            info={ handleInfo2.message }
            status={ handleInfo2.type }
            inputType='password'
            hasButton={false}
            setInputValue={setPassword2} />
        </InputWrapper>
        <ButtonWrapper marginTop='86px'>
          <CTAButton
            onClick={handleButton}
            disabled={!isFilled}
            colorType='green'>다음</CTAButton>
        </ButtonWrapper>
      </PageWrapper>
    </>
  )
}
// const SuccessPage = () => {
//   return (
//     <>
//     </>
// )
//}

const PageWrapper = styled.div`
  display: flex; 
  flex-direction : column;
  align-items: center;
  `
const TitleWrapper = styled.div`
  width: 303px;
  display: flex;
  align-item: center;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`
const ButtonWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin-top: ${(props) => props.marginTop}
`