import { ReactNode, useState } from 'react'

import { EntitleMakerContext, Maker } from '@/context/EntitleMakerContext'

const EntitleMakerProvider = ({ children }: { children?: ReactNode }) => {
  const [maker, setMaker] = useState<Maker>({
    makerName: '',
    bankName: '',
    bankAccountNumber: ''
  })
  const [step, setStep] = useState(0)
  const [isBusiness, setIsBusiness] = useState(false)
  return (
    <EntitleMakerContext.Provider value={{ maker, setMaker, step, setStep, isBusiness, setIsBusiness }}>
      { children }
    </EntitleMakerContext.Provider>
  )
}
export default EntitleMakerProvider