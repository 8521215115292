
// 스크롤 동작 없애기

// .box {
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */
// }
// .box::-webkit-scrollbar {
//     display: none; /* Chrome, Safari, Opera*/
// }

const global = `
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

:root{
  --color-light-bg: #fff;
  --color-dark-bg: #161616;
}

/* 시스템 테마에 따른 root 배경색상 지정. 텍스트는 하위 페이지 테마에서 관장 */
@media (prefers-color-scheme: light) {
  :root { background: var(--color-light-bg); }
}
@media (prefers-color-scheme: dark) {
  :root { background: var(--color-dark-bg); }
}

html {
  box-sizing: border-box;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  /* 롱터치 long touch 막기 */
  -webkit-touch-callout: none;
  /* touch 시 나오는 음영 제거 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  // overflow:hidden;
  // height: 100%;
  min-height: calc(100vh + env(safe-area-inset-bottom));

  min-width: 320px;
  width: 100%;
  font-family: -apple-system, 'SUIT', sans-serif;
  //font-size: 12px;
  font-stretch: normal;


  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

body {
  > div{


    height: 100%;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
}


input, textarea, button, select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  outline-style:none;

  border: none;
  background-color: inherit;
  color: inherit;
}


a{
  text-decoration: none;
  &:-webkit-any-link{
    text-decoration: none;
  }
}

ol,li{
  width: 100%;
}

// SEE: react-slick
.slick-dots{
  bottom: 20px;
}
.slick-dots li {
  margin: 0;
}
.slick-slide img {
  display: inline-block;
}
`

export default global