import { useMutation } from 'react-query'

import { postVisitorLog, PostVisitorLogRequestType } from '@/apis/festival/postVisitorLog'

export default function usePostVisitorLog() {
  return useMutation<void, unknown, PostVisitorLogRequestType>(
    async({ festivalId, content }) => {
      return await postVisitorLog({ festivalId, content })
    }
  )
}
