import './index.css'

import { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'

import App from './App'
import AppDownloadBanner from './components/Common/AppDownloadBanner'
import Toast from './components/Common/Toast'
import ToastRenewal from './components/Common/ToastRenewal'
import Splash from './components/Layout/Splash'
import AuthModal from './components/Modal/AuthModal'
import ThemeProvider from './providers/ThemeProvider'
import reportWebVitals from './reportWebVitals'
import KakaoPostCode from './scripts/KakaoPostCode'
import VConsoleScript from './scripts/VConsoleScript'
import SentryContainer from './SentryContainer'
import GlobalStyle from './styles/GlobalStyle'

function Root() {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false
      }
    }
  }))
  return (
    <SentryContainer>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider>
            {process.env.REACT_APP_ENV !== 'production' && <VConsoleScript />}
            <KakaoPostCode />
            <GlobalStyle />
            <Splash />
            <App />
            <AppDownloadBanner />
            <ToastRenewal />
            <Toast />
            <AuthModal />
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </SentryContainer>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Root />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
