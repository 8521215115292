import { useRef } from 'react'

import useMount from './useMount'

export default function useUnmount(fn: () => any) {
  const fnRef = useRef(fn)

  fnRef.current = fn

  useMount(() => () => fnRef.current())
}
