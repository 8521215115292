import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BusinessFooter from '@/businees/business.footer'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import useBestTeaTogethers from '@/hooks/tea-together/useBestTeaTogethers'
import useTeaTogethersForCity from '@/hooks/useTeaTogethersForCity'

import MainHeader from './component/MainHeader'
import RegionSelectBox from './component/RegionSelect'
import TeaTogetherBestSlider from './component/TeaTogetherBestSlider'
import TeaTogetherItem from './component/TeaTogetherItem'

export const TeaTogetherMainPage = () => {

  const [region, setRegion] = useState<{ key: string; cityName: string }>({ key: 'seoul', cityName: '서울' })

  const navigate = useNavigate()
  const { data: teaTogethers } = useTeaTogethersForCity(region.key, '', 20, 1)
  const { data: bestTeaTogethers } = useBestTeaTogethers()
  return (
    <PageWrapperCentered>
      <TeaTogetherBestSlider teaTogethers={bestTeaTogethers} />
      <FloatingButton
        src='/images/floating-button-1.png'
        onClick={() => navigate('/@l_ha_no')} />
      <MainHeader />
      <RegionButtonSection>
        <RegionSelectBox
          region={region}
          setRegion={setRegion} />
        {/* <SearchButton>
          찾아보기
          <IcSearch
            width={16}
            height={16}
            fill='#fff' />
        </SearchButton> */}
      </RegionButtonSection>
      <ListWrapper>
        {
          teaTogethers?.map((teaTogether, index) => (
            <TeaTogetherItem
              key={index}
              teaTogether={teaTogether} />
          ))
        }
        <ListItemWrapper>
          {(!teaTogethers?.length) && <NotFoundSection>
            아직 {region.cityName} 지역에는 아무도 차모임/티클래스를 열지 않았네요🥲
            <LongButton onClick={() => navigate('/tea-together/create')}>{`지금 ${region.cityName} 지역에서 첫 번째로 만들어보기`}</LongButton>
          </NotFoundSection>}
        </ListItemWrapper>
      </ListWrapper>
      <BusinessFooter />
    </PageWrapperCentered>
  )
}

const HoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1); /* 호버 시 확대 효과 */
    
  }
`
//Layout
const PageWrapperCentered = styled(PageWrapper)`
  padding: 78px 0px 200px 0px; 
  gap: 10px;
  position: relative;
`

const RegionButtonSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
`
const LongButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01c897;
  font-weight: 800;
  color: #fff;
  max-width: 500px;
  width: 90%;
  padding: 10px 20px;
  border-radius: 10px;
  gap: 20px;
  ${HoverEffect}
`

// const SideScrollViewWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   gap: 5px;
//   padding: 10px;
//   overflow-x: auto;  // 가로 스크롤 활성화
//   white-space: nowrap;  // 한 줄에 표시하도록 설정
// `
const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  width: 100%;
  flex-wrap: wrap;
`
const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
`
const NotFoundSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const FloatingButton = styled.img`
  z-index: 100;
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #01c897;
  border: 2px solid #01c897;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  ${HoverEffect}
  cursor: pointer;
`
