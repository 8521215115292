import { Methods, request } from '@/libs/request'

export type GetProfileResponseType = {
  id: number;
  handle: string;
  nickname: string;
  description: string;
  profileImage: string;
  ownedTeahouses: {
    id: number;
    city: string;
    subCity: string;
    name: string;
    location: string;
    specificLocation: string;
    latitude: number;
    longitude: number;
  }[];
  foundTeahouses: {
    id: number;
    city: string;
    subCity: string;
    name: string;
    location: string;
    specificLocation: string;
    latitude: number;
    longitude: number;
  }[];
  likeTeahouses: {
    id: number;
    city: string;
    subCity: string;
    name: string;
    location: string;
    specificLocation: string;
    latitude: number;
    longitude: number;
  }[];
}

export const getProfile = (handle: string) => {
  return request<GetProfileResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/user/profile?handle=${handle}`,
    method: Methods.GET
  })
}
