import { useMutation } from 'react-query'

import { cancelReservation } from '@/apis/reservation/cancelReservation'

export default function useCancelReservation() {
  return useMutation<void, unknown, {
    classId: string;
    applicantName: string;
    applicantPhoneNumber: string;
  }>(
    async ({
      classId,
      applicantName,
      applicantPhoneNumber
    }) => {
      await cancelReservation({
        classId,
        applicantName,
        applicantPhoneNumber
      })
    })
}
