import { useState } from 'react'

import { postResetPassword, PostResetPasswordRequestType } from '@/apis/auth/postResetPassword'

export const usePostResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const resetPassword = async (data: PostResetPasswordRequestType): Promise<void> => {
    try {
      setIsLoading(true)
      setError('')
      const response = await postResetPassword(data)
      if (response) setSuccess(response.success)
      console.log(response)
    } catch (error){
      setError('비밀번호 변경에 실패하였습니다.')
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  return { isLoading, error, success, resetPassword }
}