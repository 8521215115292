import { useQuery } from 'react-query'

import { getTeaTogether } from '@/apis/tea-together/getTeaTogether'

const getQueryKey = (teaTogetherId: number) => ['teaTogether', teaTogetherId]

export default function useTeaTogether(teaTogetherId: number){
  return useQuery(
    getQueryKey(teaTogetherId),
    async () => {
      const teaTogether = await getTeaTogether({ teaTogetherId })
      return teaTogether
    }
  )
}
