import { Methods, request } from '@/libs/request'

import { GetTeaTogethersForCityResponseType } from './getTeaTogethersForCity'

export const getBestTeaTogethers = () => {
  return request<GetTeaTogethersForCityResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/tea-together/best',
    method: Methods.GET
  })
}
