import { Methods, request } from '@/libs/request'

export type GetWaitingForWithdrawalListResponseType = {
  id: string;
  date: string;
  title: string;
  description: string;
  price: number;
}[]

export const getWaitingForWithdrawalList = () => {
  return request<GetWaitingForWithdrawalListResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    url: '/mocks/api/getWaitingForWithdrawalList.json',
    method: Methods.GET
  })
}