import 'leaflet/dist/leaflet.css'

import L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet'

import { CustomMapContainerProps, CustomTileLayerProps } from './StaticStreetMap'

interface OpenStreetMapComponentType {
  lat: number;
  lon: number;
  zoom: number;
  children?: JSX.Element[];
}
const MyTeaMapComponent = ({ lat, lon, zoom, children }: OpenStreetMapComponentType) => {

  let centerPosition: L.LatLngExpression
  if (lat && lon) {
    centerPosition = [lat, lon] //위도, 경도
  } else {
    centerPosition = [35.87712967204818, 127.73987517692152]
  }

  const mapContainerProps: CustomMapContainerProps = {
    center: centerPosition,
    zoom,
    scrollWheelZoom: true,
    style: { height: '60vh', width: '100%' }
  }

  const tileLayerProps: CustomTileLayerProps = {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  return (
    <MapContainer {...mapContainerProps}>
      <TileLayer {...tileLayerProps} />
      {children}
    </MapContainer>
  )
}
export default MyTeaMapComponent
