import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { H1 } from '@/components/Common/Styled'
import { Label } from '@/components/Common/Styled'
import Terms from '@/components/Common/Terms'
import { notify } from '@/components/Common/Toast'
import Input from '@/components/Input/Input'
import RadioBox from '@/components/Input/RadioBox'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useAgreementList from '@/hooks/useAgreementList'
import useClassDetail from '@/hooks/useClassDetail'
import usePostReservation from '@/hooks/usePostReservation'
import { isError } from '@/libs/request'
import { 클래스예약_위약금_정책, 클래스예약_위약금_정책_참고사항 } from '@/meta'
import ErrorPage from '@/pages/Error'
import { ROUTER } from '@/router'
import { numberWithCommas } from '@/utils/numbers'
import { dateTextForPeriod } from '@/utils/text'

function ReservationPage() {
  const { classId } = useParams()
  if (!classId){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <Reservation classId={classId} />
}
const { CLASS } = ROUTER

function Reservation({ classId }: { classId: string }) {
  const navigate = useNavigate()

  const { data: agreements, isError: isAgreementsError } = useAgreementList({ category: 'RESERVATION' })

  const { data: classDetail, isError: isClassDetailError } = useClassDetail({ classId })
  const { mutate: postReservation, isLoading } = usePostReservation()

  const [field, setField] = useState<{
    name: string;
    phone: string;
    sex: 'MALE' | 'FEMALE' | 'NONE' | '';
    member: number;
    termCodes: string[];
  }>({
    name: '',
    phone: '',
    sex: '',
    member: 0,
    termCodes: []
  })

  const buttonDisabled = useMemo(() => {
    const { name, phone, sex, member, termCodes } = field
    return !(!!name && !!phone && !!sex && !!member && termCodes.length > 0)
  }, [field])

  const handleReservationClick = () => {
    /* TOOD: validation */

    const {
      name: applicantName,
      phone: applicantPhoneNumber,
      sex: gender,
      member: requestPersonnel,
      termCodes
    } = field
    if (!gender){
      return notify('성별을 입력해주세요.')
    }

    if (termCodes.length === 0){
      return notify('약관을 동의 해주세요.')
    }

    postReservation({
      classId,
      applicantName,
      applicantPhoneNumber,
      gender,
      requestPersonnel,
      termCodes
    }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notify(message)
        }
      },
      onSuccess: () => {
        /* TODO: 결제씬 들어오면 경로변경  */
        navigate(`${CLASS}/${classId}/reservation/complete`, { state: { applicantPhoneNumber }, replace: true })
      }
    })
  }

  if (isAgreementsError || isClassDetailError){
    return <ErrorPage />
  }
  if (!agreements || !classDetail){
    return null
  }

  const { classMainName, requiredClassFee, classProgressDate } = classDetail

  return (
    <>
      {isLoading && <FullLoading />}
      <Layout>
        <Header
          title="예약하기"
          back
        />
        <Content>
          <Block>
            <H1>예약정보</H1>
            <InfoList>
              <InfoListItem>
                <InfoListItemName>클래스명</InfoListItemName>
                <div>{classMainName}</div>
              </InfoListItem>
              <InfoListItem>
                <InfoListItemName>일시</InfoListItemName>
                {classProgressDate.map(({ classRoundStartDate: start, classRoundEndDate: end }, index) => (
                  <div key={index}>{index + 1}회차 {dateTextForPeriod(start, end)}</div>
                ))}
              </InfoListItem>
            </InfoList>

            <H1>신청자 정보 입력</H1>
            <Input
              label="신청자 이름(대표자)"
              type='text'
              placeholder="홍길동"
              value={field.name}
              onChange={(name) => {
                setField((state) => ({ ...state, name }))
              }}
            />

            {/* TODO: Validation */}
            <Input
              label='휴대폰 번호(-빼고 입력)'
              type='number'
              placeholder="01032456154"
              value={field.phone}
              onChange={(phone) => {
                setField((state) => ({ ...state, phone }))
              }}
              info="휴대폰 번호를 잘못 입력한 경우, 예약정보를 확인하기 어렵습니다."
            />

            <>
              <Label>성별</Label>
              <RadioBlock>
                {
                  [['MALE', '남자'], ['FEMALE', '여자'], ['NONE', '선택안함']].map(([sex, label]) => (
                    <RadioBox
                      key={sex}
                      name="sex"
                      value={sex}
                      label={label}
                      checked={field.sex === sex}
                      onChange={({ value: sex }) => {
                      /* TODO: Type assertion */
                        setField((state) => ({ ...state, sex: sex as 'MALE' | 'FEMALE' | 'NONE' }))
                      }}
                    />
                  ))
                }
              </RadioBlock>
            </>

            <Input
              label='예약인원'
              type='number'
              placeholder="0"
              value={field.member.toString()}
              onChange={(member) => {
                setField((state) => ({ ...state, member: Number(member) }))
              }}
              info="휴대폰 번호를 잘못 입력한 경우, 예약정보를 확인하기 어렵습니다."
            />

            <Notice>예약정보는 대표자 1인에게 전달됩니다. </Notice>

            <Price>
              <span>결제금액</span>
              <strong>{numberWithCommas(requiredClassFee * field.member)}</strong>
            </Price>

            <Info>
              <>
                <strong>위약금 정책</strong>
                <ul>
                  {클래스예약_위약금_정책.map((text, index) => {
                    return (
                      <li key={index}>
                        {text}
                      </li>
                    )
                  })}
                </ul>
              </>

              <InfoNotice>
                <strong>참고사항</strong>
                <ul>
                  {클래스예약_위약금_정책_참고사항.map((text, index) => {
                    return (
                      <li key={index}>
                        {text}
                      </li>
                    )
                  })}
                </ul>
              </InfoNotice>
            </Info>
          </Block>
          <Terms
            agreements={agreements}
            onClickItem={(value) => {
              const isAll = Object.entries(value).every(([, value]) => value)
              const termCodes = isAll ? Object.keys(value).map((key) => (key)) : []
              setField((state) => ({ ...state, termCodes }))
            }}
          />
        </Content>
        <CTAButton
          label='예약 신청 하기'
          disabled={buttonDisabled}
          onClick={handleReservationClick}
        />
      </Layout>
    </>
  )
}

export default ReservationPage

const Block = styled.div`
  padding: 12px 20px;
`

const InfoList = styled.ul`
  margin: 16px 0 40px; 
  padding: 44px 0; 
  border-radius: 14px;
  background: ${({ theme }) => theme.information};  
  /* opacity: 0.16; */
  text-align: center;

  font-size: 12px;
  font-weight: 500;
  /* line-height: 18px; */
`
const InfoListItem = styled.li`
  margin-bottom: 28px;
  
  &:last-of-type{
    margin-bottom: 0;
  }
`

const InfoListItemName = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
`

const RadioBlock = styled.div`
  display: flex;
  > *{
    margin-right: 24px;
  }
`

const Notice = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};

  margin-top: 32px;
  margin-bottom: 36px;
`

const Price = styled.div`
  border-radius: 14px;
  background: ${({ theme }) => theme.information};

  font-size: 15px;
  font-weight: 500;

  height: 71px;
  padding: 18px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  > strong{
    font-weight: bold;
  }
  margin-bottom: 26px;
`

const Info = styled.div`
  border-radius: 14px;
  background: ${({ theme }) => theme.white};
  padding: 28px 24px;

  li{
    line-height: 24px;
  }
  
  strong{  
    font-size: 15px;
    font-weight: bold;

    display: block;
    margin-bottom: 10px;
  }
`

const InfoNotice = styled.div`
  margin-top: 20px;
  ul{
    list-style-type: disc;
    padding-left: 14px;
    /* color: ${({ theme }) => theme.gray5}; */
    li{
      /* color: ${({ theme }) => theme.gray3}; */

    }
  }
`
