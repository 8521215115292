import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'

import { GetMySettingsType, MakerSettingType, TeahouseSettingType, TeahousesSettingType, UserSettingType } from '@/apis/user/getMySettings'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import LoginModal from '@/components/Modal/LoginModal'
import ToastType from '@/enums/toast'
import useMySettings from '@/hooks/useMySettings'
import usePatchMaker from '@/hooks/usePatchMaker'
import usePatchTeahouse from '@/hooks/usePatchTeahouse'
import usePatchTeahouseMenus from '@/hooks/usePatchTeahouseMenus'
import usePatchTeahouseTimes from '@/hooks/usePatchTeahouseTimes'
import usePatchUser from '@/hooks/usePatchUser'
import usePostUploadFiles from '@/hooks/useUploadFiles'
import { isError } from '@/libs/request'
import { numberWithCommas } from '@/utils/numbers'
import { Weekday } from '@/utils/timeSince'

import HeaderSubPage from '../tea-together/component/HeaderSubPage'

enum SettingNavEnum {
  USER = 'user',
  MAKER = 'maker',
  TEAHOUSE = 'teahouse'
}

const SettingsPage = () => {
  const { data: info, isLoading } = useMySettings()
  const [data, setData] = useState(info)
  const [selectedNavItem, setSelectedNavItem] = useState(SettingNavEnum.USER)
  console.log('data revised', data)
  const handleNavItemClick = (itemName: React.SetStateAction<SettingNavEnum>) => {
    setSelectedNavItem(itemName)
  }
  const mutateData = (newData: GetMySettingsType) => {
    console.log('Trigger MutateData', newData)
    setData(newData)
  }
  useEffect(() => {
    console.log('useEffect data', info)
    setData(info)
  },
  [info])

  if (info?.user == undefined || data?.user == undefined || isLoading) return (<></>)

  return (
    <PageWrapper>
      <LoginModal />
      <button onClick={() => console.log(data)}>Data</button>
      <HeaderSubPage title='정보 수정' />
      <NavigationWrapper>
        <NavigationItem
          onClick={() => handleNavItemClick(SettingNavEnum.USER)}
          selected={selectedNavItem === SettingNavEnum.USER}
        >
          기본 정보
        </NavigationItem>
        { data.maker && <NavigationItem
          onClick={() => handleNavItemClick(SettingNavEnum.MAKER)}
          selected={selectedNavItem === SettingNavEnum.MAKER}
        >
          운영자 정보
        </NavigationItem>}
        { (data.ownedTeahouses.length > 0) && <NavigationItem
          onClick={() => handleNavItemClick(SettingNavEnum.TEAHOUSE)}
          selected={selectedNavItem === SettingNavEnum.TEAHOUSE}
        >
          찻집 관리
        </NavigationItem>}
      </NavigationWrapper>
      <ContentWrapper>
        {selectedNavItem === SettingNavEnum.USER && <UserSettingPage
          data={data}
          mutateData={mutateData}
          user={data?.user} />}
        {selectedNavItem === SettingNavEnum.MAKER && <MakerSettingPage
          data={data}
          mutateData={mutateData}
          maker={data?.maker} />}
        {selectedNavItem === SettingNavEnum.TEAHOUSE && <TeahouseSettingPage
          data={data}
          mutateData={mutateData}
          teahouses={data?.ownedTeahouses} />}
      </ContentWrapper>
    </PageWrapper>
  )
}
export default SettingsPage

const PageWrapper = styled.div`
  padding: 50px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 20px;
  @media (min-width: 800px) {
    border-right: 1px solid #ccc;
    flex-direction: column;
    height: 95vh;
    gap: 10px;
  }
`

const NavigationItem = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#01c897' : '#000')};
  font-weight: ${({ selected }) => (selected ? 700 : 300)};
  cursor: pointer;
  @media (min-width: 800px) {
    font-size: 20px;
    padding: 20px;
    min-width: 250px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  width: 100%;
  max-width: 900px;
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #000
`
interface UserSettingPageType {
  user: UserSettingType;
  data: GetMySettingsType;
  mutateData: (newData: GetMySettingsType) => void;
}
const UserSettingPage = ({ user, data, mutateData }: UserSettingPageType) => {
  const [editable, setEditable] = useState(false)
  const [userOnInput, setUserOnInput] = useState(user)
  const { mutate: patchUser } = usePatchUser()
  const { mutate: postUploadFiles } = usePostUploadFiles()
  const handleEditable = () => {
    setEditable(!editable)
  }
  const handleNicknameInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setUserOnInput({ ...userOnInput, nickname: newText })
  }
  const handleDescriptionInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setUserOnInput({ ...userOnInput, description: newText })
  }
  const handleHandleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setUserOnInput({ ...userOnInput, handle: newText })
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const changeNickname = () => {
    const newUser = { ...user, nickname: userOnInput.nickname }
    const newData = { ...data, user: newUser }
    patchUser({ nickname: userOnInput.nickname }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '이름이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeHandle = () => {
    const newUser = { ...user, handle: userOnInput.handle }
    const newData = { ...data, user: newUser }
    patchUser({ handle: userOnInput.handle }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '핸들이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeDescription = () => {
    const newUser = { ...user, description: userOnInput.description }
    const newData = { ...data, user: newUser }
    patchUser({ description: userOnInput.description }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '설명이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files){
      const imageFiles = Array.from(event.target.files)
      if (imageFiles) {
        postUploadFiles(imageFiles, {
          onError: (error) => {
            if (isError(error)){
              const message = error?.response?.data?.message || 'Error'
              notifyRenewal({ message, type: ToastType.ERROR })
            }
          },
          onSettled: () => {
            notifyRenewal({ message: '이미지를 업로드하고 있습니다', type: ToastType.CAUTION })
          },
          onSuccess: (response) => {
            notifyRenewal({ message: '이미지가 업로드되었습니다', type: ToastType.INFORMATION })
            const newUser = { ...user, profileImage: response.images[0] }
            const newData = { ...data, user: newUser }
            patchUser({ profileImage: response.images[0] }, {
              onError: (error) => {
                if (isError(error)){
                  const message = error?.response?.data?.message || 'Error'
                  notifyRenewal({ message, type: ToastType.ERROR })
                }
              },
              onSuccess: () => {
                notifyRenewal({ message: '이미지가 수정되었습니다', type: ToastType.INFORMATION })
                mutateData(newData)
              }
            })
          }
        })
      }
    }
  }

  return (
    <ContentWrapper>
      <ContentTitle>기본 정보</ContentTitle>
      {(editable) && <div>정보 수정 후에, 파란색 버튼을 클릭하여야 수정이 완료됩니다</div>}
      <ChangeEditableButton
        editable={editable}
        onClick={handleEditable}>{editable ? '수정 모드 종료' : '수정하기'}</ChangeEditableButton>
      <SectionWrapper>
        <SectionTitle>이름</SectionTitle>
        <Content
          onInput={handleNicknameInput}
          contentEditable={editable}
          editable={editable}>{user.nickname}</Content>
        <ButtonWrapper>
          {(editable) && <ChangeNameButton onClick={changeNickname}>이름 바꾸기</ChangeNameButton>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[이름] 이름은 하나의 별명으로 사람들에게 노출되는 이름입니다.</div>}
      <SectionWrapper>
        <SectionTitle>Handle</SectionTitle>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px' }}>
        @
          <Content
            contentEditable={editable}
            onInput={handleHandleInput}
            editable={editable}>{user.handle}</Content>
        </div>
        <ButtonWrapper>
          {(editable) && <ChangeNameButton onClick={changeHandle}>핸들러 바꾸기</ChangeNameButton>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[핸들러] 핸들러는 다른 사람들과 구분되는 개인 고유의 식별자로 공백없이 영문, 숫자, _ 로 구성되어야 합니다. </div>}
      <SectionWrapper>
        <SectionTitle>프로필 이미지</SectionTitle>
        <ProfileImage
          src={user.profileImage || '/hero/hero-carousel-1.png'}
          alt="Profile" />
        <ButtonWrapper>
          {(editable) && <ImageInputWrapper>
            <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
            <ImageInput
              id="imageInput"
              type="file"
              onChange={changeProfileImage}
              name='images'
              ref={fileInputRef} />
          </ImageInputWrapper>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[이미지] 자신을 잘 표현하는 프로필 이미지로 변경하여 보세요</div>}
      <SectionWrapper>
        <SectionTitle>설명</SectionTitle>
        <Content
          contentEditable={editable}
          onInput={handleDescriptionInput}
          editable={editable}>{user.description || '(없음)'}</Content>
        <ButtonWrapper>
          {(editable) && <ChangeNameButton onClick={changeDescription}>설명 바꾸기</ChangeNameButton>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[설명] 설명을 통해 나를 표현해보세요</div>}
    </ContentWrapper>
  )
}
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #000;
  min-width: 100px;
`
const Content = styled.div<{ editable: boolean }>`
  white-space: pre-line;
  padding: 10px 5px;
  border: ${(props) => props.editable ? '1px solid #ccc' : null};
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #01c897;
    border-width: 2px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
`

const ChangeEditableButton = styled.button<{ editable: boolean }>`
  background-color: ${(props) => props.editable ? '#ff3333' : '#3498db'};
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ editable }) => editable ? '#cc0000' : '#2980b9'};
  }
`

const ChangeNameButton = styled.button`
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff; /* Add border color */
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: #01c897; /* Add hover border color */
    transform: scale(1.05); /* Add zoom effect */
  }
`
const ImageInput = styled.input`
  display: none;
`
const ImageUploadLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #01c897;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #019e76;
  }
`
const ImageInputWrapper = styled.div`
`
interface MakerSettingPageType {
  maker: MakerSettingType;
  data: GetMySettingsType;
  mutateData: (newData: GetMySettingsType) => void;
}
const MakerSettingPage = ({ maker, data, mutateData }: MakerSettingPageType) => {
  const [editable, setEditable] = useState(false)
  const [makerOnInput, setMakerOnInput] = useState(maker || { nickname: '', id: 0, profileImage: '/hero/hero-carousel-1.png' })
  const { mutate: patchMaker } = usePatchMaker()
  const { mutate: postUploadFiles } = usePostUploadFiles()
  const fileInputRef = useRef<HTMLInputElement>(null)
  if (maker == null) {
    return <></>
  }
  const handleEditable = () => {
    setEditable(!editable)
  }
  const handleNicknameInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setMakerOnInput({ ...makerOnInput, nickname: newText })
  }
  const changeNickname = () => {
    const newMaker = { ...maker, nickname: makerOnInput.nickname }
    const newData = { ...data, maker: newMaker }
    patchMaker({ nickname: makerOnInput.nickname }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '이름이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files){
      const imageFiles = Array.from(event.target.files)
      if (imageFiles) {
        postUploadFiles(imageFiles, {
          onError: (error) => {
            if (isError(error)){
              const message = error?.response?.data?.message || 'Error'
              notifyRenewal({ message, type: ToastType.ERROR })
            }
          },
          onSettled: () => {
            notifyRenewal({ message: '이미지를 업로드하고 있습니다', type: ToastType.CAUTION })
          },
          onSuccess: (response) => {
            notifyRenewal({ message: '이미지가 업로드되었습니다', type: ToastType.INFORMATION })
            const newMaker = { ...maker, profileImage: response.images[0] }
            const newData = { ...data, maker: newMaker }
            patchMaker({ profileImage: response.images[0] }, {
              onError: (error) => {
                if (isError(error)){
                  const message = error?.response?.data?.message || 'Error'
                  notifyRenewal({ message, type: ToastType.ERROR })
                }
              },
              onSuccess: () => {
                notifyRenewal({ message: '이미지가 수정되었습니다', type: ToastType.INFORMATION })
                mutateData(newData)
              }
            })
          }
        })
      }
    }
  }
  return (
    <ContentWrapper>
      <ContentTitle>모임/클래스 운영자 정보</ContentTitle>
      {(editable) && <div>정보 수정 후에, 파란색 버튼을 클릭하여야 수정이 완료됩니다</div>}
      <ChangeEditableButton
        editable={editable}
        onClick={handleEditable}>{editable ? '수정 모드 종료' : '수정하기'}</ChangeEditableButton>
      <SectionWrapper>
        <SectionTitle>이름</SectionTitle>
        <Content
          contentEditable={editable}
          onInput={handleNicknameInput}
          editable={editable}>{maker?.nickname}</Content>
        <ButtonWrapper>
          {(editable) && <ChangeNameButton onClick={changeNickname}>이름 바꾸기</ChangeNameButton>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[이름] 이름은 하나의 별명으로 사람들에게 노출되는 이름입니다.</div>}
      <SectionWrapper>
        <SectionTitle>프로필 이미지</SectionTitle>
        <ProfileImage
          src={maker?.profileImage || '/hero/hero-carousel-1.png'}
          alt="Profile" />
        <ButtonWrapper>
          {(editable) && <ImageInputWrapper>
            <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
            <ImageInput
              id="imageInput"
              type="file"
              onChange={changeProfileImage}
              name='images'
              ref={fileInputRef} />
          </ImageInputWrapper>}
        </ButtonWrapper>
      </SectionWrapper>
      {(editable) && <div>[이미지] 자신을 잘 표현하는 프로필 이미지로 변경하여 보세요</div>}
    </ContentWrapper>
  )
}

interface TeahouseSettingPageType {
  teahouses: TeahousesSettingType;
  data: GetMySettingsType;
  mutateData: (newData: GetMySettingsType) => void;
}
const TeahouseSettingPage = ({ teahouses, data, mutateData }: TeahouseSettingPageType) => {
  const [selectedNavItem, setSelectedNavItem] = useState(0)
  const [teahouseOnInput, setTeahouseOnInput] = useState(teahouses[selectedNavItem] || {})
  const { mutate: patchTeahouse } = usePatchTeahouse(teahouses[selectedNavItem].id)
  const { mutate: patchTeahouseTimes } = usePatchTeahouseTimes(teahouses[selectedNavItem].id)
  const { mutate: patchTeahouseMenus } = usePatchTeahouseMenus(teahouses[selectedNavItem].id)
  const [editable, setEditable] = useState(false)
  useEffect(() => {
    setTeahouseOnInput(teahouses[selectedNavItem])
  }, [selectedNavItem, teahouses])
  const navHandler = (index: number) => {
    setSelectedNavItem(index)
  }
  const handleEditable = () => {
    setEditable(!editable)
  }
  const handleNameInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setTeahouseOnInput({ ...teahouseOnInput, name: newText })
  }
  const handleDescriptionInput = (e: React.FormEvent<HTMLDivElement>) => {
    const newText = (e.target as HTMLDivElement).innerText
    setTeahouseOnInput({ ...teahouseOnInput, description: newText })
  }
  const changeName = () => {
    const newTeahouse = { ...teahouses[selectedNavItem], name: teahouseOnInput.name }
    const newTeahouses = [...teahouses] // 기존 teahouses 배열을 복사하여 새로운 배열 생성
    newTeahouses[selectedNavItem] = newTeahouse
    const newData = { ...data, teahouses: newTeahouses }
    patchTeahouse({ name: teahouseOnInput.name }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '이름이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeDescription = () => {
    const newTeahouse = { ...teahouses[selectedNavItem], description: teahouseOnInput.name }
    const newTeahouses = [...teahouses] // 기존 teahouses 배열을 복사하여 새로운 배열 생성
    newTeahouses[selectedNavItem] = newTeahouse
    const newData = { ...data, teahouses: newTeahouses }
    patchTeahouse({ description: teahouseOnInput.description }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '설명이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeTimes = () => {
    const newTeahouse = { ...teahouses[selectedNavItem], times: teahouseOnInput.times }
    const newTeahouses = [...teahouses]
    newTeahouses[selectedNavItem] = newTeahouse
    const newData = { ...data, teahouses: newTeahouses }
    patchTeahouseTimes(teahouseOnInput.times, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '운영시간이 수정되었습니다', type: ToastType.INFORMATION })
        mutateData(newData)
      }
    })
  }
  const changeMenus = () => {
    patchTeahouseMenus(teahouseOnInput.menus, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        notifyRenewal({ message: '메뉴가 수정되었습니다', type: ToastType.INFORMATION })
        const newTeahouse = { ...teahouses[selectedNavItem], menus: teahouseOnInput.menus }
        const newTeahouses = [...teahouses]
        newTeahouses[selectedNavItem] = newTeahouse
        const newData = { ...data, ownedTeahouses: newTeahouses }
        mutateData(newData)
      }
    })
  }
  const addMenu = () => {
    const newMenuList = [...teahouseOnInput.menus]
    newMenuList.push({ id: -1, category: 'main', name: '새로운 메뉴 입력', description: '설명 입력', price: 1000, image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcw4JMnh95eogCiN8zLmcFBmKXnkbJI7a3gw&usqp=CAU' })
    setTeahouseOnInput({ ...teahouseOnInput, menus: newMenuList })
  }

  return (
    <TeahouseContentWrapper2>
      <ContentTitle>찻집 관리</ContentTitle>
      <ChangeEditableButton
        editable={editable}
        onClick={handleEditable}>{editable ? '수정 모드 종료' : '수정하기'}</ChangeEditableButton>
      <TeahouseContentWrapper>
        <TeahouseNavigationWrapper>
          {
            teahouses.map((teahouse, index) => {
              return (
                <TeahouseNavigationItem
                  key={index}
                  selected={selectedNavItem === index}
                  onClick={() => navHandler(index)}>{teahouse.name}</TeahouseNavigationItem>
              )
            })
          }
        </TeahouseNavigationWrapper>
        <StaticColumn>
          <SectionWrapper>
            <SectionTitle>이름</SectionTitle>
            <Content
              contentEditable={false}
              onInput={handleNameInput}
              editable={editable}>{teahouses[selectedNavItem].name}</Content>
            <ButtonWrapper>
              {(editable) && <ChangeNameButton onClick={changeName}>이름 바꾸기</ChangeNameButton>}
            </ButtonWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>주소</SectionTitle>
            <Content editable={editable}>{teahouses[selectedNavItem].location + teahouses[selectedNavItem].specificLocation}</Content>
            <ButtonWrapper>
            </ButtonWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>찻집 설명</SectionTitle>
            <Content
              contentEditable={editable}
              onInput={handleDescriptionInput}
              editable={editable}>{teahouses[selectedNavItem].description}</Content>
            <ButtonWrapper>
              {(editable) && <ChangeNameButton onClick={changeDescription}>설명 바꾸기</ChangeNameButton>}
            </ButtonWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>메뉴</SectionTitle>
            <MenuWrapper>
              {teahouseOnInput.menus.map((menu, index) => {
                return (
                  <MenuItem
                    id={menu.id}
                    index={index}
                    teahouseOnInput={teahouseOnInput}
                    setTeahouseOnInput={setTeahouseOnInput}
                    editable={editable}
                    price={menu.price}
                    key={index}
                    image={menu.image}
                    name={menu.name}
                    description={menu.description}
                    category={menu.category} />
                )
              })}
              {(teahouses[selectedNavItem].menus.length == 0) && '아직 메뉴가 없습니다'}
            </MenuWrapper>
            <ButtonWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              {(editable) && <ChangeNameButton onClick={changeMenus}>메뉴 바꾸기</ChangeNameButton>}
              {(editable) && <ChangeNameButton
                style={{ backgroundColor: '#01c897' }}
                onClick={addMenu}>메뉴 추가 +</ChangeNameButton>}
            </ButtonWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>운영 시간</SectionTitle>
            <TeahouseTimetable
              teahouseOnInput={teahouseOnInput}
              setTeahouseOnInput={setTeahouseOnInput}
              times={teahouses[selectedNavItem].times}
              editable={editable} />
            <ButtonWrapper>
              {(editable) && <ChangeNameButton onClick={changeTimes}>운영시간 바꾸기</ChangeNameButton>}
            </ButtonWrapper>
          </SectionWrapper>
        </StaticColumn>
      </TeahouseContentWrapper>
    </TeahouseContentWrapper2>
  )
}
const TeahouseContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
  max-width: 900px;
`
const TeahouseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  width: 100%;
  max-width: 900px;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`
const TeahouseNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 20px;
  @media (min-width: 800px) {
    border-right: 1px solid #ccc;
    flex-direction: column;
    height: 95vh;
    gap: 10px;
    max-width: 200px;
  }
`
const StaticColumn = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`

const TeahouseNavigationItem = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#01c897' : '#000')};
  font-weight: ${({ selected }) => (selected ? 700 : 300)};
  cursor: pointer;
  @media (min-width: 800px) {
    font-size: 20px;
    padding: 20px;
    min-width: 250px;
  }
`
const MenuWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap: 5px;
`

interface MenuItemType {
  id: number;
  index: number;
  image: string;
  category: string;
  name: string;
  price: number;
  description: string;
  editable: boolean;
  teahouseOnInput: TeahouseSettingType;
  setTeahouseOnInput: React.Dispatch<React.SetStateAction<TeahouseSettingType>>;
}
export function convertEnglishToKorean(category: string) {
  switch (category) {
    case 'tea':
      return '차(tea)'
    case 'main':
      return '메인'
    case 'drinks':
      return '음료'
    case 'variation':
      return '베리에이션'
    case 'dessert':
      return '디저트'
    default:
      return category
  }
}
const MenuItem = ({ image, name, category, description, index, editable, price, teahouseOnInput, setTeahouseOnInput }: MenuItemType) => {
  const { mutate: postUploadFiles } = usePostUploadFiles()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCategory = e.target.value
    const newMenus = [...teahouseOnInput.menus]
    newMenus[index].category = newCategory
    setTeahouseOnInput({ ...teahouseOnInput, menus: newMenus })
  }
  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value
    const newMenus = [...teahouseOnInput.menus]
    newMenus[index].name = newName
    setTeahouseOnInput({ ...teahouseOnInput, menus: newMenus })
  }
  const handleDescriptionInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = e.target.value
    const newMenus = [...teahouseOnInput.menus]
    newMenus[index].description = newDescription
    setTeahouseOnInput({ ...teahouseOnInput, menus: newMenus })
  }
  const handlePriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = e.target.value
    const newMenus = [...teahouseOnInput.menus]
    newMenus[index].price = Number(newPrice)
    setTeahouseOnInput({ ...teahouseOnInput, menus: newMenus })
  }
  const changeMenuImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files){
      const imageFiles = Array.from(event.target.files)
      if (imageFiles) {
        postUploadFiles(imageFiles, {
          onError: (error) => {
            if (isError(error)){
              const message = error?.response?.data?.message || 'Error'
              notifyRenewal({ message, type: ToastType.ERROR })
            }
          },
          onSettled: () => {
            notifyRenewal({ message: '이미지를 업로드하고 있습니다', type: ToastType.CAUTION })
          },
          onSuccess: (response) => {
            notifyRenewal({ message: '이미지가 업로드되었습니다', type: ToastType.INFORMATION })
            const newMenu = { ...teahouseOnInput.menus[index], image: response.images[0] }
            const newMenus = [...teahouseOnInput.menus]
            newMenus[index] = newMenu
            setTeahouseOnInput({ ...teahouseOnInput, menus: newMenus })
          }
        })
      }
    }
  }
  return (
    <MenuItemWrapper key={index}>
      <MenuImageWrapper>
        <MenuImage src={image} />
        {(editable) && <ImageInputWrapper>
          <ImageUploadLabel
            htmlFor={`image-input-${index}`}
            style={{ padding: '5px 10px', fontSize: '14px' }}>사진 수정</ImageUploadLabel>
          <ImageInput
            id={`image-input-${index}`}
            type="file"
            onChange={changeMenuImage}
            name='images'
            ref={fileInputRef} />
        </ImageInputWrapper>}
      </MenuImageWrapper>
      <MenuInfoWrapper>
        {(!editable) && <MenuCategory>{convertEnglishToKorean(category)}</MenuCategory>}
        {(editable) &&
        <CategorySelect
          onChange={(e) => handleCategorySelect(e) }>
          <option value={category}>{convertEnglishToKorean(category)}</option>
          <option value='tea'>차(tea)</option>
          <option value='main'>메인</option>
          <option value='drinks'>음료</option>
          <option value='variation'>베리에이션</option>
          <option value='dessert'>디저트</option>
        </CategorySelect>}
        {(!editable) && <MenuTitle editable={editable}>{name}</MenuTitle>}
        {(editable) && <MenuTitleRevised
          type="text"
          value={name}
          onChange={handleNameInput}
          disabled={!editable}
          placeholder="메뉴 이름" />}
        {(!editable) && <MenuDescription
          onChange={handleDescriptionInput}
          contentEditable={editable}
          editable={editable}>{teahouseOnInput.menus[index].description}</MenuDescription>}
        {(editable) && <MenuDescriptionRevised
          type="text"
          value={description}
          onChange={handleDescriptionInput}
          disabled={!editable}
          placeholder="메뉴 설명"
        />}
        {(!editable) && <MenuTitle editable={editable}>{numberWithCommas(price)}</MenuTitle>}
        {(editable) && <MenuTitleRevised
          type="number"
          value={price}
          onChange={handlePriceInput}
          disabled={!editable}
          placeholder="가격 입력" />}
      </MenuInfoWrapper>
    </MenuItemWrapper>
  )
}

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd; // 경계선 추가
  border-radius: 10px; // 모서리 둥글게
  margin-bottom: 10px; // 아래쪽 마진
  align-items: center; // 세로 중앙 정렬
  background-color: #f9f9f9; // 배경색
`

const MenuImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`

const MenuImage = styled.img`
  width: 80px; // 이미지 너비
  height: 80px; // 이미지 높이
  border-radius: 8px; // 모서리 둥글게
  object-fit: cover; // 이미지 비율 유지
`

const MenuInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between; // 내용물 사이 간격
`

const MenuTitle = styled.div<{ editable: boolean }>`
  font-size: 18px; // 글꼴 크기
  font-weight: bold; // 글꼴 굵기
  color: #333; // 글꼴 색상
  border: ${(props) => props.editable ? '1px solid #666' : null};
  border-radius: 3px;
  padding: 3px 2px;
`
const MenuTitleRevised = styled.input`
  font-size: 18px; // 글꼴 크기
  font-weight: bold; // 글꼴 굵기
  color: #333; // 글꼴 색상
  border: 1px solid #666;
  border-radius: 3px;
  padding: 3px 2px;
`

const MenuCategory = styled.div`
  font-size: 14px; // 글꼴 크기
  color: #666; // 글꼴 색상
  margin-bottom: 5px; // 아래쪽 마진
`

const MenuDescription = styled.div<{ editable: boolean }>`
  font-size: 12px; // 글꼴 크기
  color: #999; // 글꼴 색상
  border: ${(props) => props.editable ? '1px solid #666' : null};
  border-radius: 3px;
  padding: 3px 2px;
`
const MenuDescriptionRevised = styled.input`
  font-size: 12px; // 글꼴 크기
  color: #999; // 글꼴 색상
  border: 1px solid #666;
  border-radius: 3px;
  padding: 3px 2px;
`
const CategorySelect = styled.select`
font-size: 14px; // 글꼴 크기
color: #666; // 글꼴 색상
margin-bottom: 5px; // 아래쪽 마진
border: 1px solid #666;
border-radius: 5px;
padding: 2px 3px;
`

export type TeahouseTimetableType = {
  times: TimetableType;
  editable: boolean;
  teahouseOnInput: TeahouseSettingType;
  setTeahouseOnInput: React.Dispatch<React.SetStateAction<TeahouseSettingType>>;
}
export type TimetableType = {
  day: Weekday;
  openedAt: number;
  closedAt: number;
  breakStartAt: number;
  breakEndAt: number;
}[];
const TeahouseTimetable = ({ times, editable, teahouseOnInput, setTeahouseOnInput }: TeahouseTimetableType) => {
  const handleOpenedAtHourInput = (e: React.FormEvent<HTMLDivElement>, index: number) => {
    const newText = (e.target as HTMLDivElement).innerText
    let newHour = parseInt(newText)
    if (isNaN(newHour)) {
      (e.target as HTMLDivElement).innerText = ''
    } else if (newHour < 0 || newHour > 24) {
      newHour = Math.min(24, Math.max(0, newHour))
    }
    const newTimes = teahouseOnInput.times
    newTimes[index] = { ...newTimes[index], openedAt: (newHour + (newTimes[index].openedAt % 1)) }
    setTeahouseOnInput({ ...teahouseOnInput, times: newTimes })
  }
  const handleOpenedAtMinuteInput = (e: React.FormEvent<HTMLDivElement>, index: number) => {
    const newText = (e.target as HTMLDivElement).innerText
    let newMinute = parseInt(newText)

    if (isNaN(newMinute)) {
      (e.target as HTMLDivElement).innerText = ''
    } else if (newMinute < 0 || newMinute > 59) {
      newMinute = Math.min(59, Math.max(0, newMinute))
    }
    const newTimes = teahouseOnInput.times
    newTimes[index] = { ...newTimes[index], openedAt: Math.floor(newTimes[index].openedAt) + (newMinute / 60) }
    setTeahouseOnInput({ ...teahouseOnInput, times: newTimes })
  }
  const handleClosedAtHourInput = (e: React.FormEvent<HTMLDivElement>, index: number) => {
    const newText = (e.target as HTMLDivElement).innerText
    let newHour = parseInt(newText)

    if (isNaN(newHour)) {
      (e.target as HTMLDivElement).innerText = ''
    } else if (newHour < 0 || newHour > 24) {
      newHour = Math.min(24, Math.max(0, newHour))
    }
    const newTimes = teahouseOnInput.times
    newTimes[index] = { ...newTimes[index], closedAt: (newHour + (newTimes[index].closedAt % 1)) }
    setTeahouseOnInput({ ...teahouseOnInput, times: newTimes })
  }
  const handleClosedAtMinuteInput = (e: React.FormEvent<HTMLDivElement>, index: number) => {
    const newText = (e.target as HTMLDivElement).innerText
    let newMinute = parseInt(newText)

    if (isNaN(newMinute)) {
      (e.target as HTMLDivElement).innerText = ''
    } else if (newMinute < 0 || newMinute > 59) {
      newMinute = Math.min(59, Math.max(0, newMinute))
    }
    const newTimes = teahouseOnInput.times
    newTimes[index] = { ...newTimes[index], closedAt: Math.floor(newTimes[index].closedAt) + (newMinute / 60) }
    setTeahouseOnInput({ ...teahouseOnInput, times: newTimes })
  }

  return (
    <TimeTableWrapper>
      <TimetableListWrapper>
        {
          times.map((time, index) => {
            return (
              <TimetableItemWrapper key={index}>
                <DayText>{time.day}</DayText>
                <TimeTextWrapper>
                  <TimeText
                    onInput={(e) => handleOpenedAtHourInput(e, index)}
                    contentEditable={editable}
                    editable={editable}>
                    {numberTimeToHourText(teahouseOnInput.times[index].openedAt)}
                  </TimeText>
                  :
                  <TimeText
                    onInput={(e) => handleOpenedAtMinuteInput(e, index)}
                    contentEditable={editable}
                    editable={editable}>
                    {numberTimeToMinuteText(teahouseOnInput.times[index].openedAt)}
                  </TimeText>
                </TimeTextWrapper>
                <div>~</div>
                <TimeTextWrapper>
                  <TimeText
                    onInput={(e) => handleClosedAtHourInput(e, index)}
                    contentEditable={editable}
                    editable={editable}>
                    {numberTimeToHourText(teahouseOnInput.times[index].closedAt)}
                  </TimeText>
                  :
                  <TimeText
                    onInput={(e) => handleClosedAtMinuteInput(e, index)}
                    contentEditable={editable}
                    editable={editable}>
                    {numberTimeToMinuteText(teahouseOnInput.times[index].closedAt)}
                  </TimeText>
                </TimeTextWrapper>
              </TimetableItemWrapper>
            )
          })
        }
      </TimetableListWrapper>
    </TimeTableWrapper>
  )
}
function numberTimeToHourText(numTime: number): string {
  const hour = Math.floor(numTime)
  const hourText = hour < 10 ? `0${hour}` : `${hour}`
  return hourText
}
function numberTimeToMinuteText(numTime: number): string {
  const minute = Math.round((numTime % 1) * 60)
  const minuteText = minute < 10 ? `0${minute}` : `${minute}`
  return minuteText
}

const TimeTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`
const TimetableListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 768px) {
    gap: 5px;
    flex-direction: column;
  }
`

const TimetableItemWrapper = styled.div`
  border: '1px solid #ccc';
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 300px;
    gap: 5px;
    flex-direction: row;
    align-items: center;
  }
`
const DayText = styled.div`
  font-weight: 800;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0px;
  }
`
const TimeText = styled.div<{ editable: boolean }>`
  font-size: 12px;
  padding: 2px 3px;
  border: ${(props) => props.editable ? '2px solid #ccc' : null};
  border-radius: 3px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const TimeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
