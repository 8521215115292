import { useMutation } from 'react-query'

import { updateParticipator, updateParticipatorRequestType } from '@/apis/festival/updateParticipator'

export default function useUpdateParticipator() {
  return useMutation<void, unknown, updateParticipatorRequestType>(
    async({ festivalId, participatorId, name, description, tag, boothNumber, handledTea, country }) => {
      return await updateParticipator({ festivalId, participatorId, name, description, tag, boothNumber, handledTea, country })
    }
  )
}
