import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { IcNotification } from '@/components/Icons'
import Logo from '@/components/Layout/LogoCustom'
import useMyHandle from '@/hooks/useMyHandle'
import { isUserLoggedIn } from '@/utils/jwt'
interface HeaderType {
  setCreateModalOpened: (modalOpened: boolean) => void;
  setOpenNotificationModalOpened: (opened: boolean) => void;
  setLoginModalOpened: () => void;
}
const TeaMapHeader = ({ setCreateModalOpened, setLoginModalOpened, setOpenNotificationModalOpened }: HeaderType) => {
  const navigate = useNavigate()
  const { data } = useMyHandle()
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Logo
          width='58px'
          height='35px' />
        <span>TEA MAP</span>
      </LogoWrapper>
      <NavWrapper>
        {isUserLoggedIn() && <MakeButton onClick={() => setCreateModalOpened(true)}>+</MakeButton>}
        {isUserLoggedIn() && <IcNotification
          width='24px'
          height='24px'
          fill='#01c897'
          onClick={() => setOpenNotificationModalOpened(true)} />}
        {isUserLoggedIn() && <Nav onClick={() => navigate(`/@${data?.handle}`)}>Profile</Nav>}
        {isUserLoggedIn() && <Nav onClick={() => navigate('/mypage')}>MyPage</Nav>}
        {!isUserLoggedIn() && <Nav onClick={() => setLoginModalOpened()}>Login</Nav> }
      </NavWrapper>
    </HeaderWrapper>
  )
}
export default TeaMapHeader

/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  padding : 10px 20px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  z-index: 2000;
  background-color: #fff;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
  color: #fff;
  & > span {
    font-style: GmarketSans;
    font-weight: 800;
    font-size: 20px;
    color: #01c897;
  }
  @media (max-width: 380px) {
    & > span {
      display: none; /* Hide the text */
    }
  }
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
`
const Nav = styled.span`
  color: #01c897;
  font-weight: 600;
  cursor: pointer;
`
const MakeButton = styled.div`
  color: #01c897;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 30px;
  font-weight: 500;
`
