import { useQuery } from 'react-query'

import { getDecryption, getEncryption } from '@/apis/crypto/getEncryptionDecryption'

const getQueryKeyForEncription = (value: string) => [`/crypto/encryption/${value}`]
const getQueryKeyForDecription = (value: string) => [`/crypto/decryption/${value}`]

export function useEncryption({ value, enabled }: { value: string; enabled: boolean }) {
  return useQuery(getQueryKeyForEncription(value), async () => {
    return await getEncryption(value)
  }, { enabled })
}

export function useDecryption({ value, enabled }: { value: string; enabled: boolean }) {
  return useQuery(getQueryKeyForDecription(value), async () => {
    return await getDecryption(value)
  }, { enabled })
}
