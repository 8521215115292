
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { H2, PageBlockCenter } from '@/components/Common/Styled'
import { TAB_INDEX } from '@/components/Common/TabSlider'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useMakerInfo from '@/hooks/useMakerInfo'
import usePostSettleIncome from '@/hooks/usePostSettleIncome'
import { BANK_CODE } from '@/meta'
import { ROUTER } from '@/router'
import { numberWithCommas } from '@/utils/numbers'

const { MAKER } = ROUTER

function TransferCompletePage() {
  const { data: makerInfo } = useMakerInfo()
  const { state } = useLocation() as { state: { requestPrice: number } }

  const { mutate: postSettleIncome } = usePostSettleIncome()
  const [postSettleIncomeResult, setPostSettleIncomeResult] = useState<{
    requestPrice: number;
    date: string;
  }>()

  useEffect(() => {
    if (state && state.requestPrice){
      postSettleIncome({ requestPrice: state.requestPrice }, {
        onSuccess: (res) => {
          setPostSettleIncomeResult(res)
        }
      })
    }
  }, [postSettleIncome, state])

  if (!makerInfo){
    return <>!makerInfo</>
  }
  if (!postSettleIncomeResult){
    return <FullLoading />
  }

  const { requestPrice, date } = postSettleIncomeResult
  const { bankCode, accountNumber } = makerInfo
  return (
    <Layout>
      <Header />
      <Content>
        <PageBlockCenter>
          <H2>정산이 완료되었습니다.</H2>
          <ListBlock>
            <li>계좌번호 : {BANK_CODE[bankCode]?.name } {accountNumber}</li>
            <li>정산 요청 금액 : {numberWithCommas(requestPrice)}</li>
            <li>정산 일시 :{date}</li>
          </ListBlock>
        </PageBlockCenter>
      </Content>
      <Link
        to={`${MAKER}/${makerInfo.id}/my?${TAB_INDEX}=2`}
        replace>
        <CTAButton
          disabled={false}
          label="확인"
        />
      </Link>
    </Layout>
  )
}

export default TransferCompletePage

const ListBlock = styled.ul`
  margin-top: 10px;
  > li{
    line-height: 30px;
  }
`