import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'

import usePostDetail from '@/hooks/post/usePostDetail'
import { CommentModel } from '@/models/post/commentModel'
import { convertISOToKoreanDate } from '@/utils/text'

import NotFound from '../NotFound'
import HeaderSubPage from '../tea-together/component/HeaderSubPage'

const PostDetailPage = () => {
  const { id } = useParams<{ id: string }>()

  // Get PostDetail API Handler
  const { data: post, isLoading } = usePostDetail(Number(id))

  if (isLoading) {
    return <CircularProgress />
  } else if (post) {
    return (
      <PageWrapper>
        <HeaderSubPage title={post?.title} />
        <PostImageWrapper>
          <Slider {...sliderSettings}>
            {post.images.map((image, index) => (
              <PostImage
                key={index}
                src={image}
                alt='post detail image' />
            ))}
          </Slider>
        </PostImageWrapper>
        <PostInfoWrapper>
          <UserImageWrapper>
            <UserImage
              src={post?.writer.profileImage}
              alt='writer profile' />
          </UserImageWrapper>
          <UserDetails>
            <UserNick>{post?.writer.nickname}</UserNick>
            <PostTime>{convertISOToKoreanDate(post.createdAt)}</PostTime>
          </UserDetails>
        </PostInfoWrapper>
        <PostTitle>{post?.title}</PostTitle>
        <PostContent>{post?.content}</PostContent>
        <CommentsSection>
          <CommentTitle>댓글</CommentTitle>
          {post?.comments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment} />
          ))}
        </CommentsSection>
      </PageWrapper>
    )
  } else {
    return <NotFound />
  }
}

export default PostDetailPage

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 20px 20px 20px;
  max-width: 800px;
  margin: 0 auto;
  gap: 20px;
`

const PostImageWrapper = styled.div`
  margin-bottom: 20px;
`

const PostImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
`

const PostInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`

const UserImageWrapper = styled.div`
  flex-shrink: 0;
`

const UserImage = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

const UserDetails = styled.div`
  display: flex
  flex-direction: column;
`

const UserNick = styled.div`
  font-weight: 700;
  font-size: 18px;
`

const PostTime = styled.div`
  font-size: 14px;
  color: #888;
`

const PostTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 10px;
`

const PostContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
`
const CommentsSection = styled.div`
  margin-top: 40px;
`

type CommentProps = {
  comment: CommentModel;
}
const Comment = ({ comment }: CommentProps) => {
  return (
    <CommentWrapper key={comment.id}>
      <CommentContent>{comment.content}</CommentContent>
      <CommentInfo>
        <CommentWriterProfile
          src={comment.writer.profileImage}
          alt={`${comment.writer.nickname}'s profile`} />
        <CommentWriterNick>{comment.writer.nickname}</CommentWriterNick>
      </CommentInfo>
      <SelectButtonWrapper>
        <SelectButton>
          답글 작성
        </SelectButton>
      </SelectButtonWrapper>
      <ReplySection>
        {comment.replys?.map((reply) => {
          return (
            <ReplyItem
              key={reply.id}
              reply={reply} />
          )
        })}
      </ReplySection>
    </CommentWrapper>
  )
}

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  background: #f9f9f9;
  border-radius: 10px;
`
const CommentTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
`

const CommentContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`

const CommentInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const CommentWriterProfile = styled.img`
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`

const CommentWriterNick = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const SelectButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const SelectButton = styled.button`
  padding: 8px 16px;
  color: #01c897;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffffff;
  }
`

const ReplySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
`

type ReplyProps = {
  reply: CommentModel;
}
const ReplyItem = ({ reply }: ReplyProps) => {
  return (
    <ReplyWrapper>
      <ReplyContent>{reply.content}</ReplyContent>
      <ReplyInfo>
        <ReplyWriterProfile
          src={reply.writer.profileImage}
          alt={`${reply.writer.nickname}'s profile`}
        />
        <ReplyWriterNick>{reply.writer.nickname}</ReplyWriterNick>
      </ReplyInfo>
    </ReplyWrapper>
  )
}

const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
`

const ReplyContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
`

const ReplyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ReplyWriterProfile = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`

const ReplyWriterNick = styled.div`
  font-size: 12px;
  font-weight: 600;
`
