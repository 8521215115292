import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'

import { IcTeahouseFound, IcTeahouseLike } from '@/components/Icons'
import useProfile from '@/hooks/useProfile'

import MyTeaMapComponent from '../map/components/MyTeaMapComponent'
import { TeaMapMarker } from '../map/components/TeaMapMarker'
import NotFound from '../NotFound'
import { koreanRegionToEnglish, Region } from '../tea-together/component/RegionTag'
//import MenuItem from '../teahouse/components/MenuItem'
import UserProfileHeader from './components/UserProfileHeader'

const UserProfilePage = () => {
  const { username } = useParams<{ username: string }>()
  const navigate = useNavigate()
  if (!username) return <NotFound />
  console.log(username)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useProfile(username)
  if (!data) return <NotFound />
  const { handle, nickname, description, foundTeahouses, ownedTeahouses, likeTeahouses } = data
  return (
    <PageWrapper>
      <UserProfileHeader />
      <ProfileText>
        <ProfileImage
          src={data.profileImage || '/hero/hero-carousel-1.png'}
          alt="Profile" />
        <ProfileName>{`${nickname}(${handle})`}</ProfileName>
        <ProfileDescription>
          {description}
        </ProfileDescription>
        <TeahouseWrapper>
          {ownedTeahouses?.map((teahouse, index) => {
            return (
              <Teahouse
                key={index}
                onClick={() => navigate(`/teahouse/${teahouse.id}`)}>🏡 {teahouse.name}</Teahouse>
            )
          })}
        </TeahouseWrapper>
        <BadgeWrapper>
          { (foundTeahouses?.length > 30) && <Badge className='teaExpert'>👑 찻집 전문가</Badge>}
          { (likeTeahouses.length > 20) && <Badge className='teaLover'>❤️ 찻집 애호가</Badge>}
        </BadgeWrapper>
      </ProfileText>
      {/* <Title>요즘 마시는 차</Title>
      <MenuItem
        image=''
        name='정산소종'
        description='훈연향이 나면서 끝에는 은은한 단내가 난다'
        category='홍차' /> */}
      <Title>@{handle} TEA MAP</Title>
      <StatusWrapper>
        <StatusButton className='one'>
          <IcTeahouseFound
            width={24}
            height={24}
            fill='#fff' />
          <StatusText>발굴 찻집</StatusText>
          {foundTeahouses.length}개
        </StatusButton>
        <StatusButton className='one'>
          <IcTeahouseLike
            width={24}
            height={24}
            fill='#fff' />
          <StatusText>좋아하는 찻집</StatusText>
          {likeTeahouses.length || 0}개
        </StatusButton>
      </StatusWrapper>
      <MapWrapper>
        <MyTeaMapComponent
          zoom={6.5}
          lat={36.87712967204818}
          lon={127.73987517692152}
        >
          { likeTeahouses.map((teahouse, index) => {
            const teahousePosition: L.LatLngExpression = [Number(teahouse.latitude), Number(teahouse.longitude)]
            return (
              <TeaMapMarker
                key={index}
                position={teahousePosition}
                name={teahouse.name}
                location={teahouse.location + ' ' + teahouse.specificLocation}
                region={koreanRegionToEnglish(teahouse.city) as Region}
                teahouseId={teahouse.id}
                type='TEAHOUSE' />
            )
          }) }
        </MyTeaMapComponent>
      </MapWrapper>
    </PageWrapper>
  )
}

export default UserProfilePage

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 70px 20px 20px 20px;
`
const ProfileName = styled.div`
  font-weight: 800;
  font-size: 20px;
  color: #000;
`
const ProfileDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #aaa;
  white-space: pre-line;
`
const TeahouseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`
const Teahouse = styled.button`
  font-size: 14px;
  color: #01c897;
`
const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
  color: #000;
`

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff; /* Add border color */
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: #01c897; /* Add hover border color */
    transform: scale(1.05); /* Add zoom effect */
  }
`

const ProfileText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`
const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const Badge = styled.div`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;

  /* 찻집 애호가 뱃지 스타일 */
  &.teaLover {
    background-color: #64b98e; /* 녹색 */
    color: #fff; /* 흰색 */
  }

  /* 찻집 전문가 뱃지 스타일 */
  &.teaExpert {
    background-color: #ffd700; /* 황토색 */
    color: #5c4021;; /* 금색 */
  }
`
const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
`
const StatusButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  min-width: 160px;
  padding: 10px 30px;
  border-radius: 10px;

  &.one {
    background-color: #01c897;
    color: #fff;; 
  }
`
const StatusText = styled.div`
  color: #fff;
  font-size: 12px;
  @media (max-width: 600px) {
    display: none;
  }
`

const MapWrapper = styled.div`
`
