
import { useQuery } from 'react-query'

import { getAgreementList } from '@/apis/terms/getAgreementList'

const getQueryKey = ({ category }: { category: string }) => [`agreementList/${category}`]

export default function useAgreementList({ category }: { category: string }) {
  return useQuery(getQueryKey({ category }), async () => {
    const classDetail = await getAgreementList({ category })
    return classDetail
  })
}