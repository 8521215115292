import { Methods, request } from '@/libs/request'

export type postTastingNoteOfParticipatorRequestType = {
  festivalId: number;
  participatorId: number;
  title: string;
  content: string;
  image: string;
  sweetness: number;
  bitterness: number;
  sourness: number;
  nuttiness: number;
  spicy: number;
  aspringency: number;
  fresh: number;
}
export type PostSignUpResponseType = {
  tastingNoteId: number;
}

export const postTastingNoteOfParticipator = async({ festivalId, participatorId, title, content, image, sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh }: postTastingNoteOfParticipatorRequestType) => request<PostSignUpResponseType>({
  url: `/festival/${festivalId}/participator/${participatorId}/tasting-note`,
  method: Methods.POST,
  data: { title, content, image, sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh }
})
