import { useMutation } from 'react-query'

import { patchTeahouseMenus, PatchTeahouseMenusRequestType } from '@/apis/teahouse/patchTeahouseMenus'

export default function usePatchTeahouseMenus(teahouseId: number) {
  return useMutation<void, unknown, PatchTeahouseMenusRequestType>(
    async (menus) => {
      await patchTeahouseMenus(teahouseId, menus)
    }
  )
}
