import { useQuery } from 'react-query'

import { getPosts } from '@/apis/post/getPosts'

const getQueryKey = (skip: number, limit: number) => [`post/${skip}/${limit}`]

export default function usePosts(page: number, limit: number){
  return useQuery(
    getQueryKey(page, limit),
    async () => {
      const posts = await getPosts({ page, limit })
      return posts
    }
  )
}
