import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'

import { regions } from './RegionTag'

interface RegionSelectBoxType {
  setRegion: React.Dispatch<React.SetStateAction<{ key: string; cityName: string }>>;
  region: { key: string; cityName: string };
}

const RegionSelectBox = ({ region, setRegion }: RegionSelectBoxType) => {

  const [clicked, setClicked] = useState(false)
  return (
    <TotalWrapper>
      <Title>지역 선택</Title>
      <RegionSelectWrapper>
        <SelectedRegion onClick={() => setClicked(!clicked)}>
          {region.cityName}
        </SelectedRegion>
        <SelectedListSection
          clicked={clicked}
          onClick={() => setClicked(!clicked)}>
          <SelectedListWrapper>
            {regions.map((region, index) => {
              return (
                <RegionItem
                  key={index}
                  onClick={() => setRegion(region)}>
                  {region.cityName}
                </RegionItem>
              )
            })}
          </SelectedListWrapper>
        </SelectedListSection>
      </RegionSelectWrapper>
    </TotalWrapper>
  )
}

export default RegionSelectBox

const HoverEffect = css`
  transition: transform 0.3s ease ;
  transition: background-color 0.5s ease;
  &:hover {
    transform: scale(1.05); /* 호버 시 확대 효과 */
    background-color: #01c897;
    color: #fff;
  }
`
const TotalWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
width: 90%;
max-width: 500px;
z-index: 10;
`
const Title = styled.div`
font-family: Gmarket Sans;
font-weight: 700;
font-size: 16px;
color:${({ theme }) => theme.mainGreen};
`
const RegionSelectWrapper = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
`
const SelectedRegion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #01c897;
  color: #01c897;
  font-weight: 700;
  ${HoverEffect}
`
const SelectedListSection = styled.div<{ clicked: boolean }>`
  display: ${({ clicked }) => clicked ? 'flex' : 'none' };
  flex-direction: column; 
  position: relative;
`
const SelectedListWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  height: 20vh;
`
const RegionItem = styled.div`
  font-family: Gmarket Sans;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  ${HoverEffect}
`
