import { useQuery } from 'react-query'

import { getClassDetailByMaker } from '@/apis/class/getClassDetailByMaker'

const getQueryKey = ({ classId }: { classId: string }) => [`classDetail/${classId}`]

export default function useClassDetail({ classId }: { classId: string }) {
  return useQuery(getQueryKey({ classId }), async () => {
    const classDetail = await getClassDetailByMaker({ classId })
    return classDetail
  })
}
