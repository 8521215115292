import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// import Slider from 'react-slick'
import { Body1Bold, Sub2, Sub3 } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { IcSearch } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Logo from '@/components/Layout/LogoCustom'
import useParticipatorOfFestival from '@/hooks/useParticipatorOfFestival'

const FestivalMainPage = () => {
  const navigate = useNavigate()

  return (
    <PageWrapperCentered>
      <HeaderWrapper>
        <Logo
          width='58px'
          height='35px' />
        <NavWrapper>
          <Nav
            onClick={() => navigate('/')}
            clicked={false}>Main</Nav>
          <Nav
            onClick={() => window.location.href = 'https://www.instagram.com/daruteaworld'}
            clicked={false}>SNS</Nav>
          <Nav
            onClick={() => navigate('/mypage')}
            clicked={false}>MyPage</Nav>
          <Nav clicked={true}>2023 경주</Nav>
        </NavWrapper>
      </HeaderWrapper>
      <Banner>축제가 종료되었어요</Banner>
      <ImageWrapper>
        <Notification
          title='❤️ 경주 세계 차문화 축제 영상 보러가기 📹'
          onClick={ () => window.location.href = 'https://www.youtube.com/@daruteaworld' } />
        <Notification
          title='✍🏻 행사는 끝났지만, 방명록을 남겨봐요'
          onClick={ () => navigate('visitor') } />
        { false && <Notification
          title='🍵 매일매일 차를 주제로 이야기하는 수다(秀茶)방'
          onClick={ () => window.location.href = 'https://open.kakao.com/o/gZ98wMcf' } />}
        <Image
          src='/images/gyeongju-tea-map.png'
          width='80%'
          height='auto' />
      </ImageWrapper>
      <Title>제 6회 경주 세계 차문화 축제</Title>
      <Search />
    </PageWrapperCentered>
  )
}

export default FestivalMainPage
/* Page */
const PageWrapperCentered = styled(PageWrapper)`
  padding: 50px 0px 0px 0px; 
  align-items: center;
`
/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 20px;
  height: 22px; 
`
const Nav = styled.span<{ clicked: boolean }>`
  color: ${(props) => (props.clicked ? '#01c897' : '#000000')};
  font-weight: ${(props) => (props.clicked ? '700' : '300')};
  cursor: pointer;
`
/* 알림 */
interface NotificationProps {
  title: string;
  link?: string;
  onClick?: () => void;
}
export const Notification = ({ title, link, onClick }: NotificationProps) => {
  const navigate = useNavigate()
  const clickHandler = () => {
    if (link) navigate(link)
    if (onClick) onClick()
  }
  return (
    <NotificatioonWrapper onClick={() => clickHandler() }>
      <NotificationText>
        {title}
      </NotificationText>
    </NotificatioonWrapper>
  )
}

const NotificatioonWrapper = styled.div`
  width: 90%;
  max-width: 400px;
  height: auto;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #01c897;
  border-radius: 5px;
  background:#01c897; 
  cursor: pointer;
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  color: #fff;
`
const NotificationText = styled(Body1Bold)`
  
`

const Banner = styled.div`
  background-color: ${((props) => props.theme.gray4)};
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
`

/* Image Map */
const ImageWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
const Title = styled(Sub2)`
  color: #01c897;
  margin-left: 20px;
  margin-top: 10px;
`
/* Search Wrapper */
const Search = () => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const setSearch = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword)
  }
  return (
    <SearchWrapper>
      <SearchTitle>부스 이름을 검색하세요</SearchTitle>
      <SearchInputWrapper>
        <IcSearch
          width='24px'
          height='24px' />
        <SearchInput
          placeholder='부스 이름 검색하기(Search)'
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)} />
        <SearchButton onClick={() => setSearch(searchKeyword)}>검색</SearchButton>
      </SearchInputWrapper>
      <SearchOutcomes searchKeyword={searchKeyword} />
    </SearchWrapper>
  )
}
const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 8px 16px;
`
const SearchTitle = styled(Sub3)`
  color: ${((props) => props.theme.gray4)};
  font-weight: 300;
`
const SearchInputWrapper = styled.div`
  height: auto;
  border: 2px solid #01c897;
  border-radius: 8px;
  background: #F8FFFD;
  width: 100%;
  min-width: 280px;
  max-width: 800px;

  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
const SearchInput = styled.input`
  width: 250px;
`
const SearchButton = styled.button`
  background: #01c897;
  border-radius: 4px;
  
  display: flex;
  padding: 4px 8px;
  align-items: center;
  width: 60px;
  justify-content: center;
  color: white;
  font-size: 14px;
`
/* Search Outcome Wrapper */
type SearchOutcomesProps = {
  searchKeyword: string;
}
const SearchOutcomes = ({ searchKeyword }: SearchOutcomesProps) => {
  const { data: participators } = useParticipatorOfFestival(1)
  const filteredParticipators = participators?.filter((participator) => participator.name.includes(searchKeyword)
  )
  const sortedParticipators = filteredParticipators?.sort((a, b) => a.boothNumber - b.boothNumber)

  return (
    <SearchOutcomeListWrapper>
      {
        sortedParticipators?.map((participator, index) => {
          return (
            <SearchOutcomeItem
              key={index}
              number={participator.boothNumber}
              nation={participator.nation}
              name={participator.name}
              tea={participator.handledTea} />
          )
        })
      }
    </SearchOutcomeListWrapper>
  )
}
const SearchOutcomeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
interface SearchOutcomeItem {
  number: number;
  nation: string;
  name: string;
  tea: string;
}
const SearchOutcomeItem = ({ number, nation, name, tea }: SearchOutcomeItem) => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(`participator/${number}`)
  }
  const nationFlag = () => {
    if (nation == 'CN') return '🇨🇳'
    else if (nation == 'LK') return '🇱🇰'
    else if (nation == 'JP') return '🇯🇵'
    else if (nation == 'TW') return '🇹🇼'
    else return '🇰🇷'
  }
  return (
    <SearchOutcomeListItemWrapper onClick={() => clickHandler()}>
      <ItemText>{number}</ItemText>
      <ItemNation>{nationFlag()}</ItemNation>
      <ItemText>{name}</ItemText>
      <ItemText>{tea}</ItemText>
    </SearchOutcomeListItemWrapper>
  )
}

const SearchOutcomeListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  background: #fff;
  align-items: center;
  cursor: pointer;
  border: 1px solid #01c897;
  border-radius: 5px;
  padding: 10px;
`
const ItemText = styled.div`
  font-size: 16px;
  color: black;
  font-weight: 700;
`
const ItemNation = styled.span`
  font-size: 20px;
  color: black;
`

/* Map Carousel */
// const SliderWrapper = styled.div`
//   flex-direction: row;
//   display: flex;
// `

// const SimpleSlider = () => {
//   // Slick 설정
//   const settings = {
//     dots: true, // 하단에 동그라미
//     infinite: true, // 무한 반복
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1
//   }

//   return (
//     <div>
//       <h2>안녕하세요</h2>
//       <Slider {...settings}>
//         <div>
//           <img
//             src="/images/default-image.png"
//             alt="Slide 1" />
//         </div>
//         <div>
//           <img
//             src="https://daru-image-storage.s3.ap-northeast-2.amazonaws.com/1693331867108.png"
//             alt="Slide 2" />
//         </div>
//         <div>
//           <img
//             src="https://daru-image-storage.s3.ap-northeast-2.amazonaws.com/1693331867041.png"
//             alt="Slide 3" />
//         </div>
//       </Slider>
//     </div>
//   )
// }
