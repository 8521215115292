
import { Link } from 'react-router-dom'

import { H2, PageBlockCenter } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'

function LeaveCompletePage() {
  return (
    <Layout>
      <Header />
      <Content>
        <PageBlockCenter>
          <H2>계정삭제가 완료되었습니다.</H2>
        </PageBlockCenter>
      </Content>
      <Link
        to='/'
        replace>
        <CTAButton
          disabled={false}
          label="메인으로 가기"
        />
      </Link>
    </Layout>
  )
}

export default LeaveCompletePage
