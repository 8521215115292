import styled from '@emotion/styled'
import { useState } from 'react'

import { Body2Bold } from '@/components/Base/Text'

interface RadioBoxListProps {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  valueList: number[];
}

const RadioBoxList = ({ setValue, valueList }: RadioBoxListProps) => {
  const [selectedValue, setSelectedValue] = useState<number | null>(null)

  return (
    <RadioBoxListWrapper>
      {valueList.map((value) => (
        <RadioBoxListItem
          key={value} // key prop 추가
          value={value}
          clicked={value === selectedValue}
          onClick={() => {
            setSelectedValue(value)
            setValue(value)
          }}
        />
      ))}
    </RadioBoxListWrapper>
  )
}

export default RadioBoxList

const RadioBoxListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

const RadioBoxListItem = ({ value, clicked, onClick }: { value: number; clicked: boolean; onClick: () => void }) => {
  return (
    <RadioBoxItemWrapper onClick={onClick}>
      <RadioBoxCircleOutside clicked={clicked}>
        <RadioBoxCircleInside clicked={clicked} />
      </RadioBoxCircleOutside>
      <RadioBoxValueText clicked={clicked}>{value}</RadioBoxValueText>
    </RadioBoxItemWrapper>
  )
}

const RadioBoxItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`
const RadioBoxCircleOutside = styled.div<{ clicked: boolean }>`
  width: 14px; 
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.clicked ? '#01c897' : '#000')};
  display: flex;
  align-items: center;
  justify-content: center;
`
const RadioBoxCircleInside = styled.div<{ clicked: boolean }>`
  width: 8px; 
  height: 8px;
  border-radius: 50%;
  background: #01c897;
  display: ${(props) => (props.clicked ? '' : 'none')};
`
const RadioBoxValueText = styled(Body2Bold)<{ clicked: boolean }>`
  color: ${(props) => (props.clicked ? '#01c897' : '#000')};
`
