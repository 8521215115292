import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Slider from 'react-slick'

import { HeaderHeight } from '../Layout/Header'

/* TODO: tabIndex URL 상태관리 */
/* SEE: https://react-slick.neostack.com/docs/api */
export const TAB_INDEX = 'tabIndex'
function TabSlider({ items, sticky = false, sameWidth = false }: { items: { title: string; content: ReactNode }[]; sticky?: boolean; sameWidth?: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState<number>()
  const slider = useRef<Slider>(null)
  const titleElements = useRef<HTMLButtonElement[]>([])

  const defaultTabIndex = useMemo(() => {
    return Number(searchParams.get(TAB_INDEX)) || 0
  }, [searchParams])

  const settings = useMemo(() => {
    return {
      initialSlide: defaultTabIndex,
      arrows: false,
      afterChange: async (index: number) => {
        setTabIndex(index)
        setSearchParams(new URLSearchParams({ [TAB_INDEX]: index.toString() }), { replace: true })

        window.scrollTo({
          top: 52 + 64 + 14,
          behavior: 'smooth'
        })
        titleElements.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
      }
    }
  }, [defaultTabIndex, setSearchParams])

  useEffect(() => {
    // init
    setTabIndex(defaultTabIndex)

    if (slider.current){
      slider.current.slickGoTo(defaultTabIndex)
    }
  }, [defaultTabIndex])

  const handleTitleClick = (index: number) => {
    if (slider.current) {
      slider.current.slickGoTo(index)
    }
  }

  if (Number.isNaN(tabIndex)){
    return <>!tabIndex</>
  }

  return (
    <>
      <TabHeader
        sticky={sticky}
        sameWidth={sameWidth}>
        {items.map(({ title }, index) => {
          return (
            <TabHeaderTitle
              key={index}
              ref={(el) => {
                if (el && titleElements.current) {
                  titleElements.current[index] = el
                }
              }}
              selected={index === tabIndex}
              onClick={() => { handleTitleClick(index) }}
            >
              {title}
            </TabHeaderTitle>
          )
        })}
      </TabHeader>
      <Slider
        {...settings}
        ref={slider}
      >
        {
          items.map(({ content }, index) => (
            <SwipeItem key={index}>
              {content}
            </SwipeItem>
          ))
        }
      </Slider>
    </>
  )
}

const TabHeader = styled.div<{ sticky: boolean; sameWidth: boolean }>`
  ${({ sticky }) => sticky && css`
    position: sticky;
    

    top: ${HeaderHeight}px;
    z-index: 1;

    `
}

  background-color: ${({ theme }) => theme.white };
  overflow-x: scroll;
  overscroll-behavior: contain;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  /* border-bottom: 2px solid ${({ theme }) => theme.gray1}; */
  display: flex;
  > *{
    ${({ sameWidth }) => sameWidth ? css`
        flex: 1 1 0px;
        width: 0;
    `
    :
    css`
        flex: 1;
    `
}
  }
`
const TabHeaderTitle = styled.button<{ selected?: boolean }>`
  white-space: pre;
  /* margin: 0 4px; */
  padding: 10px 14px;

  color: ${({ selected, theme }) => selected ? theme.caution : theme.gray5};

  border-bottom: 1px solid ${({ selected, theme }) => selected ? theme.caution : theme.gray1};
  /* ${({ theme, selected }) => selected && css`
    border-bottom: 2px solid ${theme.caution}
  `} */


  &:first-of-type{
    padding-left: 10px;
  }
  &:last-of-type{
    padding-right: 10px;
  }
`

const SwipeItem = styled.div`
  padding: 10px;
  min-height: calc(100vh - 52px - 20px);

  /* min-height: 200px; */
  /* min-height: 200vh; */
  /* max-height: 90vh;
  overflow-y: scroll; */
  background-color: ${({ theme }) => theme.white };
  color: ${({ theme }) => theme.gray5 };
`

export default TabSlider
