import { useMutation } from 'react-query'

import { patchMaker, PatchMakerRequestType } from '@/apis/maker/patchMaker'

export default function usePatchMaker() {
  return useMutation<void, unknown, PatchMakerRequestType>(
    async (maker) => {
      await patchMaker(maker)
    }
  )
}
