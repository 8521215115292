
import { ThemeProvider as StyledThemeProvider } from '@emotion/react'
import React, { ReactNode } from 'react'

import useTheme, { ThemeType } from '@/hooks/useTheme'

import theme from '../styles/theme'

type Props = {
  children: ReactNode;
};

const defaultValue = {
  theme: 'light' as ThemeType,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeTheme: () => {}
}

export const ThemeContext = React.createContext(defaultValue)

function ThemeProvider({ children }: Props) {
  const themeProps = useTheme()
  return (
    <ThemeContext.Provider value={themeProps}>
      <StyledThemeProvider theme={themeProps.theme === 'light' ? theme.light : theme.light}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
