import { useQuery } from 'react-query'

import { getTeahouse } from '@/apis/teahouse/getTeahouse'

const getQueryKey = (id: number) => ['teahouse', id]

export default function useTeahouse(id: number) {
  return useQuery(
    getQueryKey(id),
    async () => {
      const data = await getTeahouse(id)
      return data
    }
  )
}
