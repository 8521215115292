import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import appLink from '@/businees/app_link'
import isMobile from '@/utils/mobile'

const AppDownloadBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const dismissed = localStorage.getItem('dismissedAppDownloadBanner')
    if (!dismissed && isMobile()) {
      setIsVisible(true)
    }
  }, [])

  const handleClose = () => {
    localStorage.setItem('dismissedAppDownloadBanner', 'true')
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <BannerContainer>
      <BannerContent onClick={() => window.open(appLink)}>
        <BannerText>다루 App에서 차에 대해 더 빠져봐요</BannerText>
        <StoreImages>
          <StoreImage
            src="/images/store/app-store.png"
            alt="App Store" />
          <StoreImage
            src="/images/store/play-store.png"
            alt="Play Store" />
        </StoreImages>
      </BannerContent>
      <CloseButton onClick={handleClose}>닫기</CloseButton>
    </BannerContainer>
  )
}

export default AppDownloadBanner

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 500;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`

const BannerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
`

const BannerText = styled.p`
  margin: 0;
  font-size: 0.5rem;
  color: #333;
  font-weight: bold;
  flex: 2;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.2rem;
  }
`

const StoreImages = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`

const StoreImage = styled.img`
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`

const CloseButton = styled.button`
  background: none;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: white;
    color: #01c897;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`
