import TermsType from '@/enums/termsType'
import { Methods, request } from '@/libs/request'

export type PostSignUpRequestType = {
  email?: string;
  password: string;
  nickname: string;
  phoneNumber: string;
  realName: string;
  birth: string;
  code: number;
  termCodes: TermsType[];
}
export type PostSignUpResponseType = {
  userId: number;
}

export const postSignUp = async({ email, password, nickname, phoneNumber, realName, birth, code, termCodes }: PostSignUpRequestType) => request<PostSignUpResponseType>({
  url: '/auth/sign-up',
  method: Methods.POST,
  data: {
    email,
    password,
    nickname,
    phoneNumber,
    realName,
    birth,
    code,
    termCodes
  }
})
