import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { postSignIn, PostSignInRequestType, PostSignInResponseType } from '@/apis/auth/postSignIn'
import authState from '@/atoms/authState'
import { storeAccessToken, storeRefreshToken } from '@/utils/jwt'

type useSignInReturnType = {
  isLoading: boolean;
  isError: boolean;
  message: string;
  signIn: (signInData: PostSignInRequestType) => void;
}
export const useSignIn = (): useSignInReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [auth, setAuth] = useRecoilState(authState)
  const navigate = useNavigate()
  //const [data, setData] = useState<PostSignInResponseType | null>(null)
  const signIn = async (signInData: PostSignInRequestType) => {
    try {
      setIsLoading(true)
      setMessage('기다려주세요')
      const response = await postSignIn(signInData)
      console.log('로그인 시도 :', response)
      // 로그인 성공 시 access token과 refresh token 저장
      const { accessToken, refreshToken }: PostSignInResponseType = response
      //쿠키를 활용하여 토큰 관리
      storeAccessToken(accessToken)
      storeRefreshToken(refreshToken)
      // 로컬 스토리지 등을 이용하여 토큰 관리
      // localStorage.setItem('accessToken', accessToken)
      // localStorage.setItem('refreshToken', refreshToken)
      if (accessToken) {
        setAuth((prev) => {
          return { ...prev, user: !!accessToken, maker: true }
        })
      }
      navigate(auth.redirect)
      //setData(response.data) (현재는 로그인 이후 내려오는 데이터가 없어서 제외)
    } catch (error) {
      setIsError(true)
      setMessage('로그인에 실패했습니다. 회원정보를 다시 확인해주세요.')
      console.log(auth)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isError,
    //data,
    message,
    signIn
  }
}

