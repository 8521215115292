import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Sub3 } from '@/components/Base/Text'
import { IcArrowLeft } from '@/components/Icons'

interface HeaderSubPageType {
  title: string;
}
const HeaderSubPage = ({ title }: HeaderSubPageType) => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>{title}</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}

export default HeaderSubPage

/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  z-index: 100;
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`
