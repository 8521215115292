import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { UserReservationType } from '@/apis/user/getMyInfo'
import { convertISOToKoreanDate } from '@/utils/text'

interface ReservationCardType {
  reservation: UserReservationType;
  onClick: (id: number) => void;
  onCancel: () => void;
}
export function ReservationCard ({ reservation, onClick, onCancel }: ReservationCardType) {
  // const navigate = useNavigate()
  const { id, type, reservationTitle, targetId, note, status, count, times } = reservation
  return (
    <ReservationCardWrapper key={id}>
      <CardTitle onClick={() => onClick(targetId)}>{reservationTitle}</CardTitle>
      <Status status={status}>{reservationStatusToText(status)}</Status>
      <ReservationCategory>{type}</ReservationCategory>
      {
        times.map((time, index) => {
          return (
            <>
              <ReservationContent>[{index + 1}회차]</ReservationContent>
              <ReservationContent>⏰ {convertISOToKoreanDate(time.startAt)}</ReservationContent>
            </>
          )
        })
      }
      <ReservationContent>😀 {count}명</ReservationContent>
      <Note>{note}</Note>
      <CancelButtonWrapper>
        <UserInfoChangeButton onClick={() => onCancel()}>취소</UserInfoChangeButton>
      </CancelButtonWrapper>
    </ReservationCardWrapper>
  )
}
const HoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05); /* 호버 시 확대 효과 */
    background: rgba(1,200,151,0.8);

    > div {
      color: #fff;
    }
  }
`
const ButtonHoverEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05); /* 호버 시 확대 효과 */
    background: rgba(255,255,255,0.8);

    > div {
      color: #fff;
    }
  }
`

const UserInfoChangeButton = styled.button`
  border: 1px solid ${({ theme }) => theme.gray4};
  border-radius: 5px;
  color: ${({ theme }) => theme.gray4};
  font-weight: 500;
  padding: 5px 10px;
  z-index: 5;
  ${ButtonHoverEffect}
`

const ReservationCardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.black};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  ${HoverEffect}
`
const ReservationCategory = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.mainGreen };
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    min-width: 0px;
    justify-content: center;
  }
`
const Status = styled.div<{ status: string }>`
  color: ${({ theme, status }) => (status == 'CANCELED') ? theme.error : theme.mainGreen};
`

const CardTitle = styled.div`
  font-weight: 800;
  color: ${({ theme }) => theme.black};
  min-width: 100px;
  max-width: 250px;
  @media (min-width: 1000px) {
    min-width: 250px;
    max-width: 350px;
  }
`
const ReservationContent = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.black};
  @media (min-width: 1000px) {
    min-width: 100px;
    max-width: 200px;
    font-size: 14px;
  }
`
const CancelButtonWrapper = styled.div`
width: 100%;
display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (min-width: 1000px) {
    width: auto;
  }
`

const Note = styled.div`
  font-size: 12px;
  width: auto;
  @media (min-width: 1000px) {
    width: 100px;
  }
`

function reservationStatusToText(status: string) {
  if (status == 'COMPLETED') {
    return '예약 완료'
  } else if (status == 'PENDING') {
    return '승인 대기중'
  } else if (status == 'CANCELED') {
    return '취소됨'
  } else {
    return ''
  }
}
