
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Error, H2, Notice, PageBlock } from '@/components/Common/Styled'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'

function PasswordPage() {
  const [password, setPassword] = useState('')
  const buttonDisabled = useMemo(() => {
    return !password
  }, [password])

  return (
    <Layout>
      <Header
        back
      />
      <Content>
        <PageBlock>
          <H2>기존 비밀번호 입력</H2>
          <TextBlock>기존 비밀번호를 입력하세요.</TextBlock>
          <ListBlock>
            <ListItem>
              <h4>비밀번호 <Error>비밀번호가 올바르지 않습니다.</Error>
              </h4>
              <Input
                type='password'
                placeholder="비밀번호 입력"
                value={password}
                onChange={(password) => {
                  setPassword(password)
                }}
              />
              <Notice>영문, 숫자, 특수문자를 조합하여 8-16자</Notice>
            </ListItem>
          </ListBlock>
        </PageBlock>
      </Content>
      <Link
        to='re'
      >
        <CTAButton
          disabled={buttonDisabled}
          label="다음"
        />
      </Link>
    </Layout>
  )
}

export default PasswordPage

const TextBlock = styled.div`
  padding: 10px 0;
`

const ListBlock = styled.ul`
  margin-top: 30px;
`

const ListItem = styled.li`
  margin-bottom: 28px;
  line-height: 28px;
`