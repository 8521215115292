import { useMutation } from 'react-query'

import { postSettleIncome, PostSettleIncomeResponseType } from '@/apis/postSettleIncome'

export default function usePostSettleIncome() {
  return useMutation<PostSettleIncomeResponseType, null, { requestPrice: number }>(async ({ requestPrice }) => {
    const res = await postSettleIncome({ requestPrice })
    return res
  })
}
