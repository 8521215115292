import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { H2, H3, PageBlock, PageBlockCenter, PageImage } from '@/components/Common/Styled'
import { notify } from '@/components/Common/Toast'
import RadioBox from '@/components/Input/RadioBox'
import TextArea from '@/components/Input/TextArea'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import Splash from '@/components/Layout/Splash'
import useClassDetail from '@/hooks/useClassDetail'
import usePostReview from '@/hooks/usePostReview'
import useValidateReview from '@/hooks/useValidateReview'
import { isError } from '@/libs/request'
import { Error } from '@/pages/Error'
import { ROUTER } from '@/router'

const { CLASS } = ROUTER

/*
CASE
1. 잘못된 접근
2. 이미 작성
(3. 정상 리뷰 작성 폼)
*/
enum ErrorCode {
  잘못된_요청 = 'BAD_REQUEST',
  이미_작성된_리뷰 = 'ALREADY_MAKE_REVIEW'
}

function CreatePage() {
  const { classId, phone } = useParams()
  if (!classId || !phone){
    return <Error />
  }

  return (
    <CreateContent
      classId={classId}
      applicantPhoneNumber={phone} />
  )
}

function CreateContent({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  /* TODO: API 요청이 2번 발생함. 확인필요 */
  const { data: validated, error: validatedError, isLoading } = useValidateReview({ classId, applicantPhoneNumber })

  const errorElement = useMemo(() => {
    if (isLoading) {
      return (
        <Splash />
      )
    }

    /* TODO: 조건 확인 */
    if ((validated && validated.validated && !!validated.applicantPhoneNumber) || !validatedError) {
      return (
        null
      )
    }

    if (!isError(validatedError)){
      return <Error />
    }

    if (validatedError.response?.status !== 400){
      return <Error />
    }

    if (validatedError.response.data.code === ErrorCode.잘못된_요청){
      return (
        <>
          <PageImage
            alt=""
            src='/images/alert.png'
            width={70}
            height={70}
          />
          <H2>잘못된 접근입니다.</H2>
        </>
      )

    }
    if (validatedError.response.data.code === ErrorCode.이미_작성된_리뷰){
      return (
        <>
          <PageImage
            alt=""
            src='/images/calendar.png'
            width={70}
            height={70}
          />
          <H2>{'이미 작성된 리뷰입니다.\n 감사합니다.'}</H2>
        </>
      )
    }
    return (
      <Error />
    )
  }, [validated, isLoading, validatedError])

  if (errorElement){
    return (
      <Layout>
        <Header />
        <Content>
          <PageBlockCenter>
            {errorElement}
          </PageBlockCenter>
        </Content>
        <Link
          to='/'
          replace>
          <CTAButton
            disabled={false}
            label="메인으로 가기"
          />
        </Link>
      </Layout>
    )
  }

  if (!validated) {
    return (
      null
    )
  }

  return (
    <ReviewForm
      classId={classId}
      applicantPhoneNumber={validated.applicantPhoneNumber}
    />
  )
}

function ReviewForm({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  const navigate = useNavigate()

  const { data: classDetail } = useClassDetail({ classId })

  const { mutate: postReview, isLoading } = usePostReview()

  const [field, setField] = useState({
    score: '',
    text: ''
  })

  const buttonDisabled = useMemo(() => {
    const { score, text } = field
    return !score || !text
  }, [field])

  if (!classDetail){
    return null
  }

  const { classMainName } = classDetail

  /* TODO: 리뷰작성 요청하고 로딩!! */
  const handleCreateReviewButton = () => {
    const { score, text } = field
    postReview({ classId, score: Number(score), text, applicantPhoneNumber }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notify(message)
        }
      },
      onSuccess: () => {
        navigate(`${CLASS}/${classId}/review/create/complete`, { replace: true })
      }
    })
  }

  return (
    <>
      {isLoading && <FullLoading />}

      <Layout>
        <Header
          title="리뷰하기"
          back
        />
        <Content>
          <PageBlock>
            <H2>참여한 티클래스/다회</H2>
            <p>{classMainName}</p>
            <H2>리뷰 작성하기</H2>
            <p>참여한 모임 및 클래스에 대한 참여를 솔직하게 작성해주세요.</p>

            <H3>평점입력</H3>

            <RadioBlock>
              {
                new Array(10).fill(0).map((_, index) => {
                  const label = (index + 1).toString()
                  return (
                    <RadioBox
                      isBlock
                      key={label}
                      name='score'
                      value={label}
                      label={label}
                      checked={field.score === label}
                      onChange={({ value: score }) => {
                        setField((state) => ({ ...state, score }))
                      }}
                    />
                  ) })
              }
            </RadioBlock>

            <RadioBlock>
              <span>매우 안좋음</span>
              <span>보통</span>
              <span>매우 좋음</span>
            </RadioBlock>

            <H3>리뷰 입력(최대 100자)</H3>
            <TextArea
              rows={10}
              maxLength={100}
              value={field.text}
              onChange={(text) => {
                setField((state) => ({ ...state, text }))
              }} />
          </PageBlock>
        </Content>
        <CTAButton
          onClick={handleCreateReviewButton}
          disabled={buttonDisabled}
          label="완료"
        />
      </Layout>
    </>
  )
}

export default CreatePage

const RadioBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  margin-bottom: 10px;
  > span{
    color: ${({ theme }) => theme.gray3};  
    font-size: 12px;
  }
`