import { useMutation } from 'react-query'

import { postTeaTogether, postTeaTogetherType } from '@/apis/tea-together/postTeaTogether'

export default function usePostTeaTogether() {
  return useMutation<{ id: number }, unknown, postTeaTogetherType>(
    async(teaTogetherData: postTeaTogetherType) => {
      const response = await postTeaTogether(teaTogetherData)
      return { id: response.id }
    }
  )
}
