import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChangeEvent, ReactNode } from 'react'

import { IcCheckmarkOutline } from '../Icons'

function RadioBox({ isBlock = false, checked, name, value, label, onChange }: { isBlock?: boolean; checked: boolean; name: string; value: string; label: string | ReactNode; onChange: ({ checked, value }: { checked: boolean; value: string }) => void }) {
  const handleChange = ({ target: { checked, value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ checked, value })
  }

  return (
    <CheckBoxBlock isBlock={isBlock}>
      <Input
        type="checkbox"
        name={name}
        id={value}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <LabelBlock
        htmlFor={value}
        className="button"
      >
        <CheckBoxIcBlock>
          <IcCheckmarkOutline />
        </CheckBoxIcBlock>
        {label}
      </LabelBlock>
    </CheckBoxBlock>
  )
}

export default RadioBox

const CheckBoxBlock = styled.div<{ isBlock: boolean }>`
  color: ${({ theme }) => theme.gray5};

  > label{
    display: flex;
  }

  ${({ isBlock }) => isBlock ? css`
    > label{
      flex-direction: column-reverse;
    }
    svg {
      margin-top: 8px; 
    }
  ` : css`
    > label{
      flex-direction: row;
      align-items: center;
    }
    svg {
      margin-right: 8px; 
    }
  `};
`

const CheckBoxIcBlock = styled.div`
  text-align: center;

  > svg {
    display: block;
    fill: ${({ theme }) => theme.gray4};
  }
`

const LabelBlock = styled.label`
  width: 100%;
`

const Input = styled.input`
  &[type="checkbox"] {
    display:none;
    &:checked{
      ~ label div {
        > svg {
          fill: ${({ theme }) => theme.mainGreen};
        }
      }
      ~ label {
        font-weight: 700;
      }
    }
  }
`
