import { Methods, request } from '@/libs/request'

export type GetMakerResponseType = {
  id: number;
  image: string;
  nickname: string;
  phone: string;
  bankCode: string;
  accountNumber: string;
  classCount: number;
  reviewCount: number;
  score: number;
  income: number;
}

export const getMakerInfo = () => {
  return request<GetMakerResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    url: '/mocks/api/getMakerInfo.json',
    method: Methods.GET
  })
}