import * as React from "react";
import { SVGProps } from "react";

const SvgTeahouseLike = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 20h5v-6h4v6h5V10h3L10 0 0 10h3v10ZM9.756 6.048l.244.24.244-.244a2.2 2.2 0 0 1 3.112 3.108L10 12.512 6.644 9.156a2.2 2.2 0 0 1 3.112-3.108Z"
    />
  </svg>
);

export default SvgTeahouseLike;
