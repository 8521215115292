import { useQuery } from 'react-query'

import { getReviewListByMaker } from '@/apis/review/getReviewListByMaker'

const getQueryKey = ({ makerId }: { makerId: number }) => ['review', makerId]

export default function useReviewListByMaker({ makerId }: { makerId: number }) {
  return useQuery(getQueryKey({ makerId }), async () => {

    const review = await getReviewListByMaker({ makerId })
    return review
  })
}
