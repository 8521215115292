import { useMutation } from 'react-query'

import { postReview } from '@/apis/review/postReview'

export default function usePostReview() {
  return useMutation<void, unknown, {
    classId: string;
    score: number;
    text: string;
    applicantPhoneNumber: string;
  }>(
    async ({
      classId,
      score,
      text,
      applicantPhoneNumber
    }) => {
      await postReview({ classId, reviewRate: score, reviewContent: text, applicantPhoneNumber })
    })
}
