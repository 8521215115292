import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

const PaymentCompletePage = () => {
  const navigate = useNavigate()
  return (
    <PaymentPageWrapper>
      <PaymentTitle>결제가 완료되었습니다.</PaymentTitle>
      <GetBackButton onClick={ () => navigate('/') }>돌아가기</GetBackButton>
    </PaymentPageWrapper>
  )
}
export default PaymentCompletePage

const PaymentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 50px;
`
const PaymentTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: black;
`
const GetBackButton = styled.div`
  font-size: 24px;
  color: white;
  font-weight: 700;
  padding: 10px 10px;
  background-color: ${({ theme }) => theme.mainGreen};
  border-radius: 10px;
`
