import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PostSignInRequestType = {
  password: string;
  phoneNumber: string;
  code: number;
}
export type PostSignInResponseType = {
  accessToken: string;
  refreshToken: string;
}

export const postSignIn = async({ password, phoneNumber, code }: PostSignInRequestType) => request<PostSignInResponseType>({
  url: 'auth/sign-in',
  method: Methods.POST,
  data: {
    password,
    phoneNumber,
    code
  },
  withCredentials: true, // withCredentials 설정
  headers: { Authorization: `Bearer ${getAccessToken()}` }
})
