import { Methods, request } from '@/libs/request'

type GetMakerResponseType = {
  nickname: string;
  rate: number;
  totalLength: number;
}
export type GetReviewResponseType = {
  maker: GetMakerResponseType;
  reviews: {
    classId: string;
    classMainName: string;
    startDate: string;
    reviews:
    {
      reviewRate: number;
      reviewContent: string;
    }[];
  }[];
}

export const getReviewListByMaker = ({ makerId }: { makerId: number }) => {
  return request<GetReviewResponseType>({
    url: 'review/maker',
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/getReviewListByMaker.json',
    method: Methods.GET,
    params: { makerId }
  })
}
