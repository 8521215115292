import { useState } from 'react'

import { postSignUp, PostSignUpRequestType, PostSignUpResponseType } from '@/apis/auth/postSignup'

export type UsePostSignUpReturnType = {
  isLoading: boolean;
  error: string;
  userId: number;
  signUp: (signUpData: PostSignUpRequestType) => Promise<void>;
}

export const usePostSignUp = (): UsePostSignUpReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [userId, setUserId] = useState(0)
  const signUp = async (signUpData: PostSignUpRequestType): Promise<void> => {
    try {
      setIsLoading(true)
      setError('')
      const response = await postSignUp(signUpData)
      const { userId }: PostSignUpResponseType = response
      setUserId(userId)
    } catch (error) {
      setError('회원가입에 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }
  return { isLoading, error, userId, signUp }
}