import { Methods, request } from '@/libs/request'

export type PostConfirmBusinessRequestType = {
  businessNumber: string;
  ownerName: string;
  businessStartDate: string;
}
export type PostConfirmBusinessResponseType = {
  success: boolean;
  message: string;
}
export const postConfirmBusiness = async({ businessNumber, ownerName, businessStartDate }: PostConfirmBusinessRequestType) => request<PostConfirmBusinessResponseType>({
  url: '/api/auth/business-confirm',
  method: Methods.POST,
  data: {
    businessNumber,
    ownerName,
    businessStartDate
  }
})
