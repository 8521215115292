import { useQuery } from 'react-query'

import { getClassDetail } from '@/apis/class/getClassDetail'

const getQueryKey = ({ classId }: { classId: string }) => [`classDetail/${classId}`]

export default function useClassDetail({ classId }: { classId: string }) {
  return useQuery(getQueryKey({ classId }), async () => {
    const classDetail = await getClassDetail({ classId })
    return classDetail
  })
}
