import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import LogoWhite from '@/components/Layout/LogoWhite'
import { toggleAuthModal } from '@/components/Modal/AuthModal'
import { isUserLoggedIn } from '@/utils/jwt'

const MainHeader = () => {
  const navigate = useNavigate()
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <LogoWhite
          width='58px'
          height='35px' />
      </LogoWrapper>
      <NavWrapper>
        <NavigationWrapper>
          <NavButton onClick={() => navigate('/')}>예약</NavButton>
        </NavigationWrapper>
        <NavigationWrapper>
          <NavButton onClick={() => navigate('/post')}>소식</NavButton>
        </NavigationWrapper>
      </NavWrapper>
      <NavWrapper>
        {isUserLoggedIn() && <Nav onClick={() => navigate('/mypage')}>MyPage</Nav>}
        {!isUserLoggedIn() && <Nav onClick={() => toggleAuthModal('ABSOLUTE')}>Login</Nav> }
      </NavWrapper>
    </HeaderWrapper>
  )
}
export default MainHeader

const sway = keyframes`
0% {
  transform: rotate(0deg);
}
50% {
  transform: rotate(10deg);
}
100% {
  transform: rotate(0deg);
}
`

export const HoverRotationEffect = css`
  transition: transform 0.3s ease ;
  &:hover {
    transform: scale(1.05) rotate(90deg); /* 호버 시 확대 효과 */
  }
`
const HoverRingEffect = css`
  transition: transform 0.3s ease;
  &:hover {
    animation: ${sway} 1s infinite; /* 좌우로 왔다갔다 하는 애니메이션 */
    transform: scale(1.1);
  }
`
const NavigationWrapper = styled.div`
  displat: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  ${HoverRingEffect}
`
const NavButton = styled.span`
  color: ${({ theme }) => theme.mainGreen};
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
`
/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  padding : 20px 20px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  z-index: 10;
  background-color: #01c897;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
  color: #fff;
  @media (max-width: 380px) {
    & > span {
      display: none; /* Hide the text */
    }
  }
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 20px;
  height: 22px; 
`
const Nav = styled.span`
  color: #fff;
  font-weight: 600;
  cursor: pointer;
`
