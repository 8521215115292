import { Methods, request } from '@/libs/request'

export type postApprovePaymentRequestType = {
  paymentKey: string;
  orderId: string;
  orderName: string;
  customerName: string;
  amount: number;
  order: { roomId: number; count: number };
  teaTogetherId: number;
}

export const postApprovePayment = async({ paymentKey, orderId, orderName, amount, order, customerName, teaTogetherId }: postApprovePaymentRequestType) => request<void>({
  url: `/reservation/tea-together/${teaTogetherId}/payment/approve`,
  method: Methods.POST,
  data: { paymentKey, orderId, orderName, customerName, amount, order }
})
