import React, { createContext } from 'react'

import { PostResetPasswordRequestType } from '@/apis/auth/postResetPassword'

export interface ResetPasswordContextType {
  data: PostResetPasswordRequestType;
  setData: React.Dispatch<React.SetStateAction<PostResetPasswordRequestType>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const ResetPasswordContext = createContext<ResetPasswordContextType>({
  data: {
    phoneNumber: '',
    code: 82,
    password: '',
    passwordConfirm: ''
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setData: () => {},
  step: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: () => {}
})