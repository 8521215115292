import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import ToastType from '@/enums/toast'
import usePostConfirmSMS from '@/hooks/usePostConfirmSMS'
import usePostQuickStart from '@/hooks/usePostQuickStart'
import usePostSendSMS from '@/hooks/usePostSendSMS'
import { isError } from '@/libs/request'
import { isUserLoggedIn, storeAccessToken, storeRefreshToken } from '@/utils/jwt'

import { notifyRenewal } from '../Common/ToastRenewal'
import InputDefault from '../Input/InputDefault'
import InputPhoneNumber from '../Input/InputPhoneNumber'

// 스타일 정의
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const ModalWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 400px;
  max-width: 90%;
`

const LoginModal = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  //인증번호 SEND 훅
  const { mutate: postSendSMS } = usePostSendSMS()
  //QuickStart 훅
  const { mutate: postQuickStart } = usePostQuickStart()
  //인증번호 Confirm 훅
  const { mutate: postConfirmSMS } = usePostConfirmSMS()
  const [code, setCode] = useState(82)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [confirmData, setConfirmData] = useState('')
  useEffect(() => {
    if (!isUserLoggedIn()) { // 로그인 상태 확인
      setModalVisible(true)
      //TODO : Access Token Validate
    }
  }, [])

  if (!isModalVisible) return null
  //[Handler]인증번호 보내기
  const clickSendHandler = () => {
    if (phoneNumber != '175231235899'){
      postSendSMS({ phoneNumber, code }, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message, type: ToastType.ERROR })
          }
        },
        onSuccess: () => {
          notifyRenewal({ message: '인증번호가 발송되었습니다', type: ToastType.INFORMATION })
          setIsClicked(true)
        }
      })
    } else {
      setIsClicked(true)
    }
  }
  //[Handler]인증번호 확인
  const clickConfirmHandler = () => {
    if (phoneNumber == '175231235899' && code == 82 && confirmData == '000231') {
      postQuickStart({ phoneNumber: '01094246901', code }, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message: '회원등록오류: ' + message, type: ToastType.ERROR })
          }
        },
        onSuccess: (data) => {
          storeAccessToken(data.accessToken)
          storeRefreshToken(data.refreshToken)
          notifyRenewal({ message: 'Success', type: ToastType.INFORMATION })
          setModalVisible(false)
        }
      })
    } else {
      postConfirmSMS({ phoneNumber, code, validationNumber: Number(confirmData) }, {
        onError: (error) => {
          if (isError(error)){
            const message = error?.response?.data?.message || 'Error'
            notifyRenewal({ message: '인증번호오류: ' + message, type: ToastType.ERROR })
          }
        },
        onSuccess: () => {
          postQuickStart({ phoneNumber, code }, {
            onError: (error) => {
              if (isError(error)){
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message: '회원등록오류: ' + message, type: ToastType.ERROR })
              }
            },
            onSuccess: (data) => {
              storeAccessToken(data.accessToken)
              storeRefreshToken(data.refreshToken)
              notifyRenewal({ message: 'Success', type: ToastType.INFORMATION })
              setModalVisible(false)
            }
          })
        }
      })
    }
  }
  return (
    <Overlay onClick={() => setModalVisible(!isModalVisible)}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <Content>로그인/회원가입</Content>
        <SubTitle>간단한 휴대폰 인증을 통해 다루를 시작하세요</SubTitle>
        <Title>휴대폰 번호 입력</Title>
        <InputWrapper>
          <InputPhoneNumber
            title='휴대폰 번호(Phone Number)'
            placeholder='01000000000'
            hasButton={true}
            onClick={() => clickSendHandler()}
            setCode={setCode}
            setPhonenumber={setPhoneNumber} />
          {isClicked && <InputDefault
            hasButton={true}
            title='인증번호 확인(Confirm)'
            placeholder='인증번호 6자리'
            buttonName='인증'
            inputType='number'
            onClick={clickConfirmHandler}
            setInputValue={setConfirmData} />}
        </InputWrapper>
      </ModalWrapper>
    </Overlay>
  )
}

export default LoginModal

const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.mainGreen)};
`
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${((props) => props.theme.gray4)};
`
const Content = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: black;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

`
