import { Methods, request } from '@/libs/request'

type getAgreementListResponseType = {
  termsId: string;
  termsCode: string;
  url: string;
  title: string;
  content: string;
  required: boolean;
  termsCategory: string;
}[]

export const getAgreementList = ({ category }: { category: string }) => {
  return request<getAgreementListResponseType>({
    url: `terms/category/${category}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/getAgreementList.json',
    method: Methods.GET
  })
}
