import { Methods, request } from '@/libs/request'
import { Weekday } from '@/utils/timeSince'

interface LikeUser {
  id: string;
  handle: string;
  nickname: string;
  profileImage: string;
}

export interface TeahouseMenuType {
  id: string;
  name: string;
  image: string;
  description: string;
  price: number;
  category: string;
}

export interface TeahouseTimeType {
  day: Weekday;
  openedAt: number;
  closedAt: number;
  breakStartAt: number;
  breakEndAt: number;
}

export interface TeaTogetherType {
  id: number;
  title: string;
  summary: string;
  location: string;
  specificLocation: string;
  participationFee: number;
  city: string;
  cityLocal: string;
  images: string[];
  maker: {
    id: number;
    nickname: string;
    profileImage: string;
  };
}

export type GetTeahouseResponseType = {
  id: number;
  name: string;
  description: string;
  location: string;
  specificLocation: string;
  city: string;
  subCity: string;
  latitude: number;
  longitude: number;
  owner: {
    id: string;
    nickname: string;
    handle: string;
    profileImage: string;
  };
  finder: {
    id: string;
    nickname: string;
    handle: string;
    profileImage: string;
  };
  likes: {
    count: number;
    users: LikeUser[];
  };
  menus: TeahouseMenuType[];
  times: TeahouseTimeType[];
  teaTogethers: TeaTogetherType[];
}

export const getTeahouse = (id: number) => {
  return request<GetTeahouseResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/teahouse/${id}`,
    method: Methods.GET
  })
}
