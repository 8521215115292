import { Methods, request } from '@/libs/request'

export type PostReviewRequestType = {
  classId: string;
  reviewRate: number;
  reviewContent: string;
  applicantPhoneNumber: string;
}
export const postReview = async ({ classId, reviewRate, reviewContent, applicantPhoneNumber }: PostReviewRequestType) => {
  return request<void>({
    url: `review/${classId}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api.json',
    method: Methods.POST,
    data: {
      reviewRate,
      reviewContent,
      applicantPhoneNumber
    }
  })
}
