import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Image from '../Common/Image'

function Logo() {
  return (
    <>
      <Link to="/">
        <LogoImage
          alt="daru logo"
          src='/logo.png'
          width={55}
          height={33}
        />
      </Link>
    </>
  )
}

export default Logo

const LogoImage = styled(Image)`
`
