import { useMutation } from 'react-query'

import { postTeahouse, postTeahouseType } from '@/apis/teahouse/postTeahouse'

export default function usePostTeahouse() {
  return useMutation<{ id: number }, unknown, postTeahouseType>(
    async(teahouseData: postTeahouseType) => {
      const response = await postTeahouse(teahouseData)
      return { id: response.id }
    }
  )
}
