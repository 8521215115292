import { Methods, request } from '@/libs/request'

export type GetTeaTogethersForCityResponseType = TeaTogetherItemModel[]

export type TeaTogetherItemModel = {
  id: number;
  title: string;
  city: string;
  makerName: string;
  summary: string;
  participationFee: number;
  profileImage: string;
}

export const getTeaTogethersForCity = (city: string, category: string, limit: number, page: number) => {
  return request<GetTeaTogethersForCityResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/tea-together/?city=${city}&category=${category}&limit=${limit}&page=${page}`,
    method: Methods.GET
  })
}
