import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'

import { IcCheveronRight } from '@/components/Icons'
import CheckBox from '@/components/Input/CheckBox'

/* TODO: 전체 동의하기 기능 추가 */
export type AgreementType = {
  termsCode: string;
  url: string;
  title: string;
  required: boolean;
}
export type AgreementListType = AgreementType[]

function Terms({
  agreements,
  onClickItem
}: {
  agreements: AgreementListType;
  onClickItem: (value: { [ code: string ]: boolean }) => void;
}){
  const [agreementCheckedState, setAgreementCheckedState] = useState({})

  useEffect(() => {
    setAgreementCheckedState(() => {
      return agreements.reduce<Record<string, false>>((data, { termsCode }) => {
        data[termsCode] = false
        return data
      }, {})
    })
  }, [agreements])

  useEffect(() => {
    onClickItem(agreementCheckedState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementCheckedState])

  const handleItemUpdate = (checked: boolean, code: string) => {
    setAgreementCheckedState((state) => {
      return { ...state, [code]: checked }
    })
  }

  const checkedItems = useMemo(() => {
    return Object.entries(agreementCheckedState).filter(([, value]) => value).map(([key]) => (key))
  }, [agreementCheckedState])

  return (
    <>
      <ContentBlock>
        <CheckBoxList
          items={agreements}
          checkedItems={checkedItems}
          updateItem={(checked, code) => { handleItemUpdate(checked, code) }}
        />
      </ContentBlock>
    </>
  )
}
export default Terms

/* TODO: required 필수 텍스트 노출 */
const ContentBlock = styled.div`
  border-top: 1px solid ${({ theme }) => theme.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.gray2};
  padding: 18px 4px;
  margin: 30px 8px 34px;
`

type CheckBoxListProps = {
  items: {
    termsCode: string;
    title: string;
    url: string;
  } [];
  checkedItems: string[];
  updateItem: (checked: boolean, items: string) => void;
}

function CheckBoxList({ items, checkedItems, updateItem }: CheckBoxListProps) {
  const [checkedItemsState, setCheckedItemsState] = useState<string[]>([])

  useEffect(() => {
    setCheckedItemsState(checkedItems)
  }, [checkedItems])

  const handleItemUpdate = ({ checked, name }: { checked: boolean; name: string }) => {
    setCheckedItemsState((state) => {
      const newState = checkedItemsState.includes(name) ?
        state.filter((value) => value !== name)
        : [...state, name]
      return newState
    })
    updateItem(checked, name)
  }

  return (
    <>
      {items.map(({ title, url, termsCode }) => (
        <CheckboxBlock key={termsCode}>
          <CheckBox
            name={termsCode}
            label={title}
            checked={checkedItemsState.includes(termsCode)}
            onChange={handleItemUpdate}
          />
          <ShowLink
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <IcCheveronRight />
          </ShowLink>
        </CheckboxBlock>
      ))}
    </>
  )
}

const CheckboxBlock = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ShowLink = styled.a`
  color: ${({ theme }) => theme.gray5};
  flex-shrink: 0;
  width: 26px;
  margin-left: 24px;

  > svg{
    fill: ${({ theme }) => theme.gray4};
  }
`
