import { Methods, request } from '@/libs/request'

export type PostReservationRequestType = {
  classId: string;
  applicantName: string;
  applicantPhoneNumber: string;
  gender: 'MALE' | 'FEMALE' | 'NONE';
  requestPersonnel: number;
  /* TODO: 약관코드타입 정의 필요 */
  termCodes: string[]; // 약관코드
}
export const postReservation = async ({
  classId,
  applicantName,
  applicantPhoneNumber,
  gender,
  requestPersonnel,
  termCodes
}: PostReservationRequestType) => {
  return request<void>({
    url: 'reservation',
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/getSettlement.json',
    method: Methods.POST,
    data: {
      classId,
      applicantName,
      applicantPhoneNumber,
      gender,
      requestPersonnel,
      termCodes
    }
  })
}
