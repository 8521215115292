import styled from '@emotion/styled'
import { ImgHTMLAttributes, SyntheticEvent } from 'react'

const DEFAULT_IMG = '/images/default-image.png'
function Image({ className, alt, src, height, width }: ImgHTMLAttributes<HTMLImageElement>) {
  const handleError = ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
    currentTarget.src = DEFAULT_IMG
  }
  return (
    <CommonImage
      className={className}
      alt={alt}
      src={src || DEFAULT_IMG}
      height={height}
      width={width}
      onError={handleError}
    />
  )
}
export default Image

const CommonImage = styled.img`
`