import styled from '@emotion/styled'
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'

import { Body2 } from '../Base/Text'
import StyledHr from '../Layout/StyledHr'

export type StatusType = 'error' | 'correct' | 'normal'
//타이틀, 버튼, 텍스트 | 넘버 | 이메일 , 설명 문구 넣어주기
type InputDefaultType = {
  title?: string;
  placeholder?: string;
  inputType?: 'text' | 'number' | 'password';
  info?: string;
  status?: StatusType;
  buttonName?: string;
  hasButton: boolean;
  onClick?: () => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const InputDefault = forwardRef<HTMLInputElement, InputDefaultType>(({
  title,
  placeholder,
  inputType,
  info,
  hasButton,
  status,
  buttonName,
  onClick,
  setInputValue
}, ref) => {
  useEffect(() => {
    setStatusIn(status)
  }, [status])
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [statusIn, setStatusIn] = useState(status)
  const [value, setValue] = useState('')
  const [isFilled, setIsFilled] = useState(false)
  const handleInputFocus = () => {
    setIsInputFocused(true)
  }
  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (inputType === 'number'){
      const newValue = inputValue.replace(/[^0-9]/g, '')
      setValue(newValue)
      setInputValue(inputValue)
    } else {
      setValue(inputValue)
      setInputValue(inputValue)
    }
    if (e.target.value.length > 0) setIsFilled(true)
    if (e.target.value.length == 0) setIsFilled(false)
  }
  return (
    <ContentBlock>
      <TitleSection>
        <Body2>
          {title}
        </Body2>
      </TitleSection>
      <MiddleSection>
        <InputSection>
          <Input
            type={(inputType === 'password') ? 'password' : 'text'}
            placeholder={placeholder}
            onChange={handleInput}
            onFocus={handleInputFocus}
            value={value}
            onBlur={handleInputBlur}
            ref={ref} />
          <BtnShowOff />
          <StyledHr
            isFocused={isInputFocused}
            status={ statusIn } />
          <Info status={statusIn}>{info}</Info>
        </InputSection>
        <Button
          hasButton={hasButton}
          isFilled={isFilled}
          onClick={onClick}>{buttonName}</Button>
      </MiddleSection>
    </ContentBlock>
  )
})
InputDefault.displayName = 'InputDefault'
export default InputDefault

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 6px;
  max-width: 500px;
  width: 100%;
  height: auto;
`
const TitleSection = styled.div`
/* 휴대폰 번호 */
left: 0%;
right: 77.56%;
top: 0%;
bottom: 69.23%;

/* Basic/Black */
color: #191919;
`
const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
`
const InputSection = styled.div`
display: block;
flex-direction: column;
flex: 2;
align-items: center;
`

const Input = styled.input`
width: 100%
`
const BtnShowOff = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`

const Info = styled.div<{ status: 'error' | 'correct' | 'normal' | undefined }>`
width: 303px;
height: auto;


font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 21px;
color: ${(props) => {
    if (props.status === 'error')
      return props.theme.error
    else if (props.status == 'correct')
      return props.theme.mainGreen
    else
      return props.theme.gray4
  }};
`

//버튼
const Button = styled.button<{ hasButton: boolean; isFilled?: boolean }>`
  display: ${(props) => (props.hasButton ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  width: 48px;
  height: 29px;
  margin-left: 18px;

  /* Basic/Gray3 */
  color: ${(props) => props.isFilled ? '#fff' : '#333'};
  background: ${(props) => props.isFilled ? props.theme.mainGreen : '#EAEAEA'};
  border-radius: 5px;
`
