import { useMutation } from 'react-query'

import { postConfirmSMS, PostConfirmSMSRequestType, PostConfirmSMSResponseType } from '@/apis/auth/postConfirmSMS'

export default function usePostConfirmSMS() {
  return useMutation<PostConfirmSMSResponseType, unknown, PostConfirmSMSRequestType>(
    async({ phoneNumber, code, validationNumber }) => {
      return await postConfirmSMS({ phoneNumber, code, validationNumber })
    }
  )
}
