
import { MouseEvent } from 'react'

import { IcLink } from '../Icons'
import { notify } from './Toast'

function CopyLink({ path = '', onClick }: { path?: string; onClick?: () => void }){
  const handleLinkCopyButtonClick = (e: MouseEvent) => {
    e.preventDefault()

    onClick && onClick()

    const targetUrl = path ? `${window.location.origin}${path}` : window.location.href
    const copyInput = document.createElement('input')
    copyInput.style.cssText = 'position:fixed;left:-9999px;top:-9999px;'
    document.body.appendChild(copyInput)
    copyInput.value = targetUrl
    copyInput.focus()
    copyInput.select()

    try {
      document.execCommand('copy')
      notify('링크가 복사되었습니다.')
    } catch (error) {
      notify('복사에 실패하였습니다. 잠시 후 다시 시도해주세요.')
    } finally {
      copyInput.remove()
    }
  }
  return (
    <IcLink onClick={handleLinkCopyButtonClick} />
  )
}

export default CopyLink
