import { useMutation } from 'react-query'

import { patchTeahouse, PatchTeahouseRequestType } from '@/apis/teahouse/patchTeahouse'

export default function usePatchTeahouse(teahouseId: number) {
  return useMutation<void, unknown, PatchTeahouseRequestType>(
    async (teahouse) => {
      await patchTeahouse(teahouse, teahouseId)
    }
  )
}
