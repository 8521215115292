export const ROUTER = {
  MAIN: '/',
  ACCOUNT: '/account',
  MYPAGE: '/mypage',
  CLASS: '/class',
  MAKER: '/maker',
  PAYMENT: '/payment',
  POST: '/post',
  MAP: '/map',
  SETTINGS: '/settings',
  USER: '/@:username',
  /* 테스트 버전 */
  TEA: '/tea',
  TEAHOUSE: '/teahouse',
  TEATOGETHER: '/tea-together',
  TASTINGNOTE: '/tasting-note',
  SHOPPING: '/shopping',
  FESTIVAL: '/festival',
  /* 개발 환경 */
  DEV: '/dev'
}
