
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BottomSheet from '@/components/Common/BottomSheet'
import { H2, PageBlock } from '@/components/Common/Styled'
import { TAB_INDEX } from '@/components/Common/TabSlider'
import { notify } from '@/components/Common/Toast'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useMakerInfo from '@/hooks/useMakerInfo'
import { BANK_CODE } from '@/meta'
import { ROUTER } from '@/router'

const { MAKER } = ROUTER

function AccountPage() {
  const navigate = useNavigate()
  const { data: makerInfo } = useMakerInfo()

  const [account, setAccount] = useState('')
  const [bank, setBank] = useState<{ bankCode: string; bankName: string }>()

  const buttonDisabled = useMemo(() => {
    return !(account && bank)
  }, [account, bank])

  if (!makerInfo){
    return <>!makerInfo</>
  }
  return (
    <Layout>
      <Header
        back
      />
      <Content>
        <PageBlock>
          <H2>계좌 인증</H2>
          <TextBlock>다회와 티클래스를 개최하고 참여비, 수업비를 입금받을 계좌를 입력하세요.</TextBlock>
          <ListBlock>
            <ListItem>
              <h4>계좌번호</h4>
              <Input
                type='text'
                placeholder="계좌번호입력"
                value={account}
                onChange={(account) => {
                  setAccount(account)
                }}
              />
            </ListItem>
            <ListItem>
              <h4>은행선택</h4>
              <BankInput
                bankCode={bank?.bankName || ''}
                onChange={({ bankCode, bankName }) => {
                  setBank({ bankCode, bankName })
                }} />
            </ListItem>
          </ListBlock>
        </PageBlock>
      </Content>
      <CTAButton
        disabled={buttonDisabled}
        label="인증"
        onClick={() => {
          notify('인증!')
          navigate(`${MAKER}/${makerInfo.id}/my?${TAB_INDEX}=2`, { replace: true })
        }}
      />
    </Layout>
  )
}

export default AccountPage

const TextBlock = styled.div`
  padding: 10px 0;
`
const ListBlock = styled.ul`
  margin-top: 30px;
`

const ListItem = styled.li`
  margin-bottom: 30px;
  line-height: 30px;
`

function BankInput({ bankCode, onChange }: { bankCode: string; onChange: ({ bankCode, bankName }: { bankCode: string; bankName: string }) => void }) {
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)

  const handleBankInputClick = () => {
    setBottomSheetOpen(true)
  }
  const handleBankIconClick = (bankCode: string, bankName: string) => {
    setBottomSheetOpen(false)
    onChange({ bankCode, bankName })
  }
  return (
    <>
      <Input
        type='text'
        placeholder="은행"
        value={bankCode}
        onFocus={handleBankInputClick}
        readonly
      />

      <BottomSheet
        title="은행선택"
        open={bottomSheetOpen}
        onClose={() => {
          setBottomSheetOpen(false)
        }}
      >
        {Object.entries(BANK_CODE).map(([key, { name, imageFileName }]) => {
          return (
            <button
              key={key}
              onClick={() => { handleBankIconClick(key, name) }}>
              <img
                alt={`${name}로고`}
                src={`/bankIcon/${imageFileName}.png`} />
              {name}
            </button>
          )
        })}
      </BottomSheet>
    </>
  )
}