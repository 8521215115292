import styled from '@emotion/styled'
import { ChangeEvent, Dispatch, forwardRef, SetStateAction, useMemo, useState } from 'react'

import nations from '@/assets/nations/nations.json'
import icon from '@/assets/svg/icons/arrow-dropdown.svg'

import { Body1, Body2, ButtonText } from '../Base/Text'
import StyledHr from '../Layout/StyledHr'

type InputPhoneNumberType = {
  title?: string;
  placeholder?: string;
  hasButton: boolean;
  onClick?: () => void;
  setCode: React.Dispatch<React.SetStateAction<number>>;
  setPhonenumber: React.Dispatch<React.SetStateAction<string>>;
}

const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneNumberType>(({
  title,
  placeholder,
  hasButton,
  onClick,
  setPhonenumber,
  setCode
}, ref) => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isSelecboxClicked, setIsSelectboxClicked] = useState(false)
  const [isInputLong, setIsInputLong] = useState(false)
  const [nationCode, setNationCode] = useState('+ 82')
  const [value, setValue] = useState('')
  const nationCodeInMemo = useMemo(() => {
    return nationCode
  }, [nationCode])
  const isSelectedboxClickedInMemo = useMemo(() => {
    return isSelecboxClicked
  }, [isSelecboxClicked])

  const handleSeletBoxClick = () => {
    setIsSelectboxClicked(!isSelectedboxClickedInMemo)
  }
  const handleInputFocus = () => {
    setIsInputFocused(true)
  }
  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const newValue = inputValue.replace(/[^0-9]/g, '')
    setValue(newValue)
    setPhonenumber(e.target.value)
    setIsInputLong(newValue.length >= 5)
  }
  return (
    <Block isSelecboxClicked={isSelectedboxClickedInMemo}>
      <ContentBlock>
        <TitleSection>
          <Body2>
            {title}
          </Body2>
        </TitleSection>
        <InputSection>
          <InputWrapper width='67px'>
            <SelectBoxBlock onClick={handleSeletBoxClick}>
              <NationNumber><Body1>{nationCodeInMemo}</Body1></NationNumber>
              <Icon
                src={icon}
                alt='dropdownIcon' />
            </SelectBoxBlock>
            {!isSelectedboxClickedInMemo && (<StyledHr isFocused={false} />)}
          </InputWrapper>
          <Split>I</Split>
          <InputAndButtonWrapper>
            <InputWrapper width='100%'>
              <Input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={handleInput}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref= {ref} />
              {!isSelectedboxClickedInMemo && (<StyledHr isFocused={isInputFocused} />)}
            </InputWrapper>
            {hasButton && <Button
              onClick={onClick}
              longInput={isInputLong}><ButtonText>보내기</ButtonText></Button>}
          </InputAndButtonWrapper>
        </InputSection>
      </ContentBlock>
      {isSelectedboxClickedInMemo && (<NationItems
        setNationNumber={setNationCode}
        setIsSelectboxClicked={setIsSelectboxClicked}
        setNationCode={setCode} />)}
    </Block>
  )
})
InputPhoneNumber.displayName = 'InputPhoneNumber'
export default InputPhoneNumber

interface NationItemsProps {
  setNationNumber: Dispatch<SetStateAction<string>>;
  setIsSelectboxClicked: Dispatch<SetStateAction<boolean>>;
  setNationCode: Dispatch<SetStateAction<number>>;
}
const NationItems = ({ setNationNumber, setIsSelectboxClicked, setNationCode }: NationItemsProps) => {
  const handleNationNumber = (itemValue: string) => {
    setNationNumber(itemValue)
    setIsSelectboxClicked(false)
  }
  return (
    <ScrollableNationWrapper>
      {nations.nations.map((nation, index) => (
        <NationItem
          key={index}
          value={nation.nationNumber }
          onClick={() => {
            handleNationNumber(`+ ${nation.nationNumber}`)
            setNationCode(nation.nationNumber)
          }}>
          <FlagSpan>{nation.nationIcon}</FlagSpan>
          <CountrySpan>{nation.nationName}</CountrySpan>
          <CountrySpan>{`+${nation.nationNumber}`}</CountrySpan>
        </NationItem>
      ))}
    </ScrollableNationWrapper>
  )
}

const Block = styled.div<{ isSelecboxClicked: boolean }>`
  /* Auto layout */

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
  max-width: 500px;
  height: ${(props) => (props.isSelecboxClicked ? '210px' : '83px')};
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 5px;

  width: 303px;
  height: 65px;
  overflow: visible;
`

const TitleSection = styled.div`
/* 휴대폰 번호 */
left: 0%;
right: 77.56%;
top: 0%;
bottom: 69.23%;

/* Content1_medium */

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;

/* Basic/Black */

color: #191919;
`
/*인풋 섹션 지정*/
const InputSection = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 12px;
`
const InputWrapper = styled.div<{ width?: string }>`
display: block;
flex-direction: column;
flex: 2;
align-items: flex-start;
width: ${(props) => (props.width)};
`
const InputAndButtonWrapper = styled.div`
display:flex;
gap: 12px;
width: 200px;
`
/*Select Box*/
const SelectBoxBlock = styled.div`
width: inherit;
height: 24px;
background-color: #fff;
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
cursor: pointer;

padding: 0px;
gap: 1px;
`
const NationNumber = styled.div`
width: 44px;
height: 17px;

font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 23px;
text-align: center;

/*Focus : Primary/G1_Main, Not Focus : #000 */
color: #000000;
`
const Icon = styled.img<{ isRotated?: boolean }>`
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease-in-out
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0)')};
`
/*Line Split*/
const Split = styled.div`
width: 14px;
height: 24px;

font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 17px;
display: flex;
justify-content: center;
align-items: center;

/* Basic/Gray3 */

color: #EAEAEA;
`
/*휴대폰 번호 입력*/
const Input = styled.input`
  width: 140px;
  height: 23px; 
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  type: number;
  display: flex;
  justify-content: center;
  align-items: center;
`

/*Button 보내기 */
const Button = styled.button<{ longInput?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  width: 48px;
  height: 29px;

  /* Basic/Gray3 */
  color: ${(props) => props.longInput ? '#fff' : '#333'};
  background: ${(props) => props.longInput ? props.theme.mainGreen : '#EAEAEA'};
  border-radius: 5px;
`

const ScrollableNationWrapper = styled.div`
  width: 303px;
  height: 156px;
  max-height: 156px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
  border: 1px solid #01C897;
  display: block;
  margin-top: -px
`

const NationItem = styled.button`
  width: 303px;
  height: 28px;
  background-color: #fff; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* hover 시에 변경될 스타일 */
  &:hover {
    background: #B4B4B4;
    opacity: 0.3;
  }
`
const FlagSpan = styled.span`
  width: 24px;
  height: 24px;
  
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-left: 14px;
  margin-right: 12px;
`
const CountrySpan = styled.span`
  width: auto;
  height: 17px;
  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #191919;
  margin-right: 12px;
`
