import styled from '@emotion/styled'

export type Region =
  | 'Seoul'
  | 'Busan'
  | 'Daegu'
  | 'Incheon'
  | 'Gwangju'
  | 'Daejeon'
  | 'Ulsan'
  | 'Sejong-si'
  | 'Gyeonggi-do'
  | 'Gangwon-do'
  | 'Chungcheongbuk-do'
  | 'Chungcheongnam-do'
  | 'Jeollabuk-do'
  | 'Jeollanam-do'
  | 'Gyeongsangbuk-do'
  | 'Gyeongsangnam-do'
  | 'Jeju-do'

interface RegionTagType {
  region: Region;
}
const RegionTag = ({ region }: RegionTagType) => {
  console.log('💎', region)
  return (
    <_RegionTag region={region}>{regionToText(region)}</_RegionTag>
  )
}
export default RegionTag

const _RegionTag = styled.div<{ region: Region }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.white };
  background-color: ${ (props) => regionToColorHexcode(props.region)};
  font-size: 14px;
  font-weight: 500;
  padding: 2px 2px;
  border-radius: 5px;
  width: 35px;
`

function regionToColorHexcode(region: Region): string {
  const colorMap: { [key in Region]: string } = {
    'Seoul': '#01c897',
    'Busan': '#1D79FF',
    'Daegu': '#f7cf25',
    'Incheon': '#84e7d9',
    'Gwangju': '#D26500',
    'Daejeon': '#e17dab',
    'Ulsan': '#8c96e8',
    'Sejong-si': '#83a164',
    'Gyeonggi-do': '#FF6565',
    'Gangwon-do': '#BBE939',
    'Chungcheongbuk-do': '#fcd14a',
    'Chungcheongnam-do': '#bc019e',
    'Jeollabuk-do': '#cccc00',
    'Jeollanam-do': '#fff68f',
    'Gyeongsangbuk-do': '#bada55',
    'Gyeongsangnam-do': '#c0d6e4',
    'Jeju-do': '#FFB23E'
  }
  return colorMap[region] || '#eeeeee'
}

function regionToText(region: Region): string {
  const textMap: { [key in Region]: string } = {
    'Seoul': '서울',
    'Busan': '부산',
    'Daegu': '대구',
    'Incheon': '인천',
    'Gwangju': '광주',
    'Daejeon': '대전',
    'Ulsan': '울산',
    'Sejong-si': '세종',
    'Gyeonggi-do': '경기',
    'Gangwon-do': '강원',
    'Chungcheongbuk-do': '충북',
    'Chungcheongnam-do': '충남',
    'Jeollabuk-do': '전북',
    'Jeollanam-do': '전남',
    'Gyeongsangbuk-do': '경북',
    'Gyeongsangnam-do': '경남',
    'Jeju-do': '제주'
  }

  return textMap[region] || '한국'
}

export function koreanRegionToEnglish(koreanRegion: string): Region | null {
  const regionMap: { [koreanName: string]: Region } = {
    '서울': 'Seoul',
    '부산': 'Busan',
    '대구': 'Daegu',
    '인천': 'Incheon',
    '광주': 'Gwangju',
    '대전': 'Daejeon',
    '울산': 'Ulsan',
    '세종': 'Sejong-si',
    '경기': 'Gyeonggi-do',
    '강원특별자치도': 'Gangwon-do',
    '충북': 'Chungcheongbuk-do',
    '충남': 'Chungcheongnam-do',
    '전북': 'Jeollabuk-do',
    '전남': 'Jeollanam-do',
    '경북': 'Gyeongsangbuk-do',
    '경남': 'Gyeongsangnam-do',
    '제주': 'Jeju-do'
  }

  return regionMap[koreanRegion] || null
}

export const regions = [
  { key: 'seoul', cityName: '서울' },
  { key: 'busan', cityName: '부산' },
  { key: 'daegu', cityName: '대구' },
  { key: 'incheon', cityName: '인천' },
  { key: 'gwangju', cityName: '광주' },
  { key: 'daejeon', cityName: '대전' },
  { key: 'ulsan', cityName: '울산' },
  { key: 'sejong-si', cityName: '세종' },
  { key: 'gyeonggi-do', cityName: '경기' },
  { key: 'gangwon-do', cityName: '강원' },
  { key: 'chungcheongbuk-do', cityName: '충북' },
  { key: 'chungcheongnam-do', cityName: '충남' },
  { key: 'jeollabuk-do', cityName: '전북' },
  { key: 'jeollanam-do', cityName: '전남' },
  { key: 'gyeongsangbuk-do', cityName: '경북' },
  { key: 'gyeongsangnam-do', cityName: '경남' },
  { key: 'jeju-do', cityName: '제주' }
]

