import styled from '@emotion/styled'

import Modal from '@/components/Modal/Modal'
import { numberWithCommas } from '@/utils/numbers'

interface SettlementModalType {
  visible: boolean;
  teaTogetherId: number;
  onClose: () => void;
}
const SettlementModal = ({ visible, teaTogetherId, onClose }: SettlementModalType) => {
  //정산정보 조회
  console.log(teaTogetherId)

  return (
    <Modal
      isVisible={visible}
      onClose={onClose}>
      <ModalTitle>정산 정보</ModalTitle>
      <ModalContentWrappper>
        <Subtitle>모임/클래스 명</Subtitle>
        <SubContent>차린이를 위한 티클래스</SubContent>
        <Subtitle>일시 및 인원</Subtitle>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableRow>
              <TableHeaderItem>날짜</TableHeaderItem>
              <TableHeaderItem>최종 예약 인원</TableHeaderItem>
              <TableHeaderItem>취소 인원</TableHeaderItem>
            </TableRow>
          </TableHeaderWrapper>
          <TableBody>
            <TableRow>
              <TableItem>2023년 11월 12일 14시 00분</TableItem>
              <TableItem>5명</TableItem>
              <TableItem>0명</TableItem>
            </TableRow>
            <TableRow>
              <TableItem>2023년 11월 12일 14시 00분</TableItem>
              <TableItem>2명</TableItem>
              <TableItem>1명</TableItem>
            </TableRow>
          </TableBody>
        </TableWrapper>
        <div style={{ border: '1px solid #ccc', width: '100%' }} />
        <Subtitle>총 수익금액</Subtitle>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableRow>
              <TableHeaderItem>항목</TableHeaderItem>
              <TableHeaderItem>값</TableHeaderItem>
            </TableRow>
          </TableHeaderWrapper>
          <TableBody>
            <TableRow>
              <TableItem bold={true}>총 예약인원</TableItem>
              <TableItem>7명</TableItem>
            </TableRow>
            <TableRow>
              <TableItem bold={true}>참여비</TableItem>
              <TableItem highlight={true}>{numberWithCommas(30000)}</TableItem>
            </TableRow>
            <TableRow>
              <TableItem bold={true}>취소 수수료</TableItem>
              <TableItem>{numberWithCommas(3000)}</TableItem>
            </TableRow>
            <TableRow>
              <TableItem bold={true}>결제 수수료</TableItem>
              <TableItem>3.3%</TableItem>
            </TableRow>
            <TableRow>
              <TableItem bold={true}>서비스 운영비</TableItem>
              <TableItem>4.5%</TableItem>
            </TableRow>
          </TableBody>
        </TableWrapper>
        <SettlementRequestButton>{numberWithCommas(30000 * 7 * (1 - 0.033 - 0.045) + 3000)} 정산하기</SettlementRequestButton>
      </ModalContentWrappper>
    </Modal>
  )
}

export default SettlementModal

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.information};
  font-weight: 800;
  font-size: 20px;
`
const ModalContentWrappper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const Subtitle = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
width: 100%;
font-size: 16px;
font-weight: 700;
gap: 10px;
color: ${({ theme }) => theme.black};
`
const SubContent = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
width: 100%;
margin-left: 10px;
font-weight: 400;
font-size: 12px;
gap: 10px;
color: ${({ theme }) => theme.black};
`
const TableWrapper = styled.table`
  border-spacing: 5px;
  border-collapse: collapse;
`
const TableHeaderWrapper = styled.thead`
`
const TableHeaderItem = styled.th`
  font-size: 12px;
  padding: 5px 5px;
  min-width: 30%;
  color: ${({ theme }) => theme.information};
`
const TableRow = styled.tr`
`
const TableBody = styled.tbody`
`
const TableItem = styled.td<{ bold?: boolean; highlight?: boolean }>`
  text-align: center;
  font-weight: ${({ bold }) => bold ? '800' : '400' };
  color: ${({ theme, highlight }) => highlight ? theme.information : theme.black};
  font-size: 10px;
`
const SettlementRequestButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  padding: 5px;
  width: 90%;
  color: ${({ theme }) => theme.information};
  border: 1px solid ${({ theme }) => theme.information};
  border-radius: 5px;
`
