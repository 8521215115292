import styled from '@emotion/styled'

function Switch({ checked, onChange }: { checked: boolean; onChange: () => void }) {
  return (
    <SwitchBlock>
      <CheckBox
        type="checkbox"
        id="switch"
        checked={checked}
        onChange={onChange}
      />
      <Label htmlFor="switch">
        <Ball />
      </Label>
    </SwitchBlock>
  )
}

export default Switch

const SwitchBlock = styled.div`
`

const CheckBox = styled.input`
  position: absolute;
  visibility: hidden;
  :checked + label {
    background: ${({ theme }) => theme.mainGreen};
    border: 2px solid ${({ theme }) => theme.mainGreen};
    &:hover {
      background: ${({ theme }) => theme.subGreen01};
    }

    span {
      left: 34px;
      background: ${({ theme }) => theme.white};
      box-shadow: 1px 2px 3px #00000020;
    }

  }
`

const Label = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 60px;
  height: 30px;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.subGreen01};
  border-radius: 20px;
  transition: 0.2s;
  /* &:hover {
    background: ${({ theme }) => theme.white};
  } */
`

const Ball = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background: ${({ theme }) => theme.subGreen01};
  transition: 0.2s;
`

