import styled from '@emotion/styled'

import { classNameFromCategoryCode, hostNameFromCategoryCode } from '../TeaTogetherCreatePage'

interface HostIntroductionType {
  category: string;
  hostName: string;
  hostDescription: string;
  hostContact: string;
}
const HostIntroduction = ({ category, hostName, hostDescription }: HostIntroductionType) => {
  return (
    <HostIntroductionWrapper>
      <Title>{`이번 ${classNameFromCategoryCode(category)}을 진행하는 ${hostNameFromCategoryCode(category)}`}</Title>
      <HostName>{hostName}</HostName>
      <Description>{hostDescription}</Description>
    </HostIntroductionWrapper>
  )
}
export default HostIntroduction
const HostIntroductionWrapper = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;  
  box-shadow: 0px 8px 20px 0px rgba(12, 14, 25, 0.12), 0px 0px 2px 0px rgba(12, 14, 25, 0.12);
  border-radius: 16px;
  width: 80%;
  max-width: 800px;
`
const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  color: ${({ theme }) => theme.black};
`
const HostName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  color: ${({ theme }) => theme.mainGreen};
`
const Description = styled.div`
font-size: 18px;
font-weight: 400;
white-space: pre-wrap;
border: 1px solid #eee;
border-radius: 10px;
padding: 10px 10px;
`
