declare module '@emotion/react' {
  export interface Theme {
    mainGreen: string;
    lightGreen01: string;
    lightGreen02: string;
    lightGreen03: string;
    subGreen01: string;
    subGreen02: string;
    subGreen03: string;
    subGreen04: string;
    subGreen05: string;
    subGreen06: string;
    subGreen07: string;
    subGreen08: string;
    subGreen09: string;
    subGreen10: string;
    subGreen11: string;
    subGreen12: string;
    white: string;
    gray1: string;
    gray2: string;
    gray3: string;
    gray4: string;
    gray5: string;
    black: string;
    caution: string;
    error: string;
    information: string;
  } // Theme 타입 정의
}

const theme = {
  light: {
    mainGreen: '#01C897',
    lightGreen01: '#E5F6F0',
    lightGreen02: '#B0D9CF',
    lightGreen03: '#64D9BC',
    subGreen01: '#59C49A',
    subGreen02: '#55B58E',
    subGreen03: '#50A583',
    subGreen04: '#4A9577',
    subGreen05: '#45866B',
    subGreen06: '#407760',
    subGreen07: '#3B6855',
    subGreen08: '#355B4A',
    subGreen09: '#304C40',
    subGreen10: '#2A3F36',
    subGreen11: '#25312B',
    subGreen12: '#1F2522',
    white: '#FFFFFF',
    gray1: '#EAEAEA',
    gray2: '#CCCCCC',
    gray3: '#B4B4B4',
    gray4: '#666666',
    gray5: '#333333',
    black: '#191919',
    caution: '#F7C859',
    error: '#ED4F4F',
    information: '#4B8AF7'
  },
  dark: {
    mainGreen: '#01C897',
    lightGreen01: '#E5F6F0',
    lightGreen02: '#B0D9CF',
    lightGreen03: '#64D9BC',
    subGreen01: '#59C49A',
    subGreen02: '#55B58E',
    subGreen03: '#50A583',
    subGreen04: '#4A9577',
    subGreen05: '#45866B',
    subGreen06: '#407760',
    subGreen07: '#3B6855',
    subGreen08: '#355B4A',
    subGreen09: '#304C40',
    subGreen10: '#2A3F36',
    subGreen11: '#25312B',
    subGreen12: '#1F2522',
    white: '#191919',
    gray1: '#333333',
    gray2: '#666666',
    gray3: '#B4B4B4',
    gray4: '#CCCCCC',
    gray5: '#EAEAEA',
    black: '#FFFFFF',
    caution: '#F7C859',
    error: '#ED4F4F',
    information: '#4B8AF7'
  }
}
// const [themeMode, setThemeMode] = useState('light');
// const theme = (themeMode === 'light') ? light : dark;
// setThemeMode('light')
export default theme
