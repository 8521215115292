import { useMutation } from 'react-query'

import { postQuickStart, PostQuickStartRequestType, PostQuickStartResponseType } from '@/apis/auth/postQuickStart'

export default function usePostQuickStart() {
  return useMutation<PostQuickStartResponseType, unknown, PostQuickStartRequestType>(
    async({ phoneNumber, code }) => {
      return await postQuickStart({ phoneNumber, code })
    }
  )
}
