import styled from '@emotion/styled'
import React from 'react'

// 스타일 정의
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`

const ModalWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 400px;
  max-width: 90%;
  z-index: 3000;
`

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}
//
/*
  시간 : 방금 전, 10분 전, 2023.11.09. 11:00
  내용
    - [승인] '일리에 ILYE' 찻집이 등록되었습니다. | 찻집 보러가기 | 방금 전
      {
        type: 'APPROVE' | 'ORDER' | ''
        badgeImage: ''
        message: '일리에 ILYE 찻집이 등록 승인되었습니다'
        action:
      }
    - [거절] '일리에 ILYE' 찻집이 거절되었습니다. | 거절 사유보기 | 방금 전
    - [승인] '왕초보를 위한 티클래스' 수업이 승인되었습니다. | 수업 보러가기 | 방금 전
    - [거절] '일리에 ILYE' 찻집이 거절되었습니다. | 거절 사유보기 | 방금 전
    - [조회] 오늘 30명의 사람들이 여러분의 프로필을 조회했어요 | 방금 전
    - [좋아요] '000님이 나의 찻집에 좋아요를 눌렀어요' | 방금 전
    - [반응]

*/

const NotificationModal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  if (!isVisible) return null

  return (
    <Overlay onClick={onClose}>
      <ModalWrapper onClick={((e) => {
        e.stopPropagation()
        console.log('ListWrapper')
      }
      )}>
        <Head>알림</Head>
        <NotificationListWrapper onClick={(() => {
          console.log('ModalWrapper')
        })}>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>방금 전</Time>
                <NotificationCategory type='TEAHOUSE'>{categoryText('TEAHOUSE')}</NotificationCategory>
              </Row>
              <Title>차연 티하우스</Title>
              <Message><b>홍길동</b> 님이 좋아요를 눌렀어요.</Message>
            </Column>
          </NotificationItemWrapper>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>10:11</Time>
                <NotificationCategory type='RESERVATION'>{categoryText('RESERVATION')}</NotificationCategory>
              </Row>
              <Title>왕초보를 위한 티클래스</Title>
              <Message><b>홍길동</b> 님이 <b>2명</b> 예약 신청했어요.</Message>
            </Column>
          </NotificationItemWrapper>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>10:11</Time>
                <NotificationCategory type='ACTIVITY'>{categoryText('ACTIVITY')}</NotificationCategory>
              </Row>
              <Title>차연 티하우스</Title>
              <Message><b>홍길동</b> 님이 찻집에 <b>새로운 소식</b>을 추가했어요.</Message>
            </Column>
          </NotificationItemWrapper>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>10:11</Time>
                <NotificationCategory type='ACTIVITY'>{categoryText('ACTIVITY')}</NotificationCategory>
              </Row>
              <Title>차연 티하우스</Title>
              <Message><b>홍길동</b> 님이 찻집에 <b>반응</b>을 했어요.</Message>
            </Column>
          </NotificationItemWrapper>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>10:11</Time>
                <NotificationCategory type='TEAHOUSE'>{categoryText('TEAHOUSE')}</NotificationCategory>
              </Row>
              <Title>차연 티하우스</Title>
              <Message><b>홍길동</b>의 Favorite 찻집에 <b>추가</b>되었어요.</Message>
            </Column>
          </NotificationItemWrapper>
          <NotificationItemWrapper>
            <BadgeImage src='/logo.png'></BadgeImage>
            <Column>
              <Row>
                <Time>10:11</Time>
                <NotificationCategory type='TEAHOUSE'>{categoryText('TEAHOUSE')}</NotificationCategory>
              </Row>
              <Title>차연 티하우스</Title>
              <Message><b>홍길동</b>의 Favorite 찻집에 <b>추가</b>되었어요.</Message>
            </Column>
          </NotificationItemWrapper>
        </NotificationListWrapper>
      </ModalWrapper>
    </Overlay>
  )
}

export default NotificationModal

const Head = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${((props) => props.theme.mainGreen)};
`
const NotificationListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 0;
  height: 400px;
  overflow-y: scroll;
  z-index: 10000;
  -webkit-overflow-scrolling: touch;
`
const NotificationItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
`
const NotificationCategory = styled.div<{ type: string }>`
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: 800;
  background-color: ${({ type }) => categoryColor(type)};
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const Row = styled(Column)`
  flex-direction: row; 
  align-items: center;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.black};
`
const Message = styled.div`
`
const BadgeImage = styled.img`
width: 24px;
height: 24px;
border-radius: 12px;
object-fit: contain;
`
const Time = styled.div`
font-size: 12px;
`

const categoryColor = (type: string) => {
  switch (type) {
    case 'TEAHOUSE':
      return '#12ddaa'
    case 'RESERVATION':
      return '#3355ff'
    case 'ACTIVITY':
      return '#888888'
    default:
      return '#cccccc'
  }
}
const categoryText = (type: string) => {
  switch (type) {
    case 'TEAHOUSE':
      return '찻집'
    case 'RESERVATION':
      return '예약'
    case 'ACTIVITY':
      return '활동'
    default:
      return '알림'
  }
}
