import { useMutation } from 'react-query'

import { postTastingNoteOfParticipator } from '@/apis/festival/postTastingNoteOfParticipator'

export default function usePostTastingNoteOfParticipator() {
  return useMutation<void, unknown, {
    festivalId: number;
    participatorId: number;
    title: string;
    content: string;
    image: string;
    sweetness: number;
    bitterness: number;
    sourness: number;
    nuttiness: number;
    spicy: number;
    aspringency: number;
    fresh: number;
  }>(
    async ({ festivalId, participatorId, title, content, image, sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh }) => {
      await postTastingNoteOfParticipator({ festivalId, participatorId, title, content, image, sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh })
    }
  )
}
