import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PatchTeahouseRequestType = {
  name?: string;
  note?: string;
  description?: string;
}

export const patchTeahouse = async(teahouse: PatchTeahouseRequestType, teahouseId: number) => request<void>({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  url: `teahouse/${teahouseId}`,
  method: Methods.PATCH,
  data: teahouse,
  headers: { 'Authorization': `Bearer ${getAccessToken()}` }
})
