import styled from '@emotion/styled'

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.mainGreen };
  color: ${({ theme }) => theme.white };
  font-size: 12px;
  font-weight: 800;
  padding: 2px 0px;
  border-radius: 5px;
  width: 35px;
`

export default Tag
