import styled from '@emotion/styled'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import Slider from 'react-slick'

import { ClassStatusType } from '@/apis/class/getClassDetail'
import { BoxShadowBottom } from '@/components/Common/BoxShadow'
import Image from '@/components/Common/Image'
import Share from '@/components/Common/Share'
import { PageBlockCenter } from '@/components/Common/Styled'
import { IcCheckmark } from '@/components/Icons'
import Button from '@/components/Input/Button'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout, { MarginBottom } from '@/components/Layout/Layout'
import useClassDetail from '@/hooks/useClassDetail'
import { getClassStatus } from '@/meta'
import { numberWithCommas } from '@/utils/numbers'
import { dateText, dateTextForPeriod } from '@/utils/text'

import { ButtonGroup, ProfilImage } from './ListPage'

function DetailPage() {
  const { classId } = useParams()
  if (!classId){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <Detail classId={classId} />
}

function Detail({ classId }: { classId: string }) {
  const { data: classDetail } = useClassDetail({ classId })

  if (!classDetail){
    return null
  }

  const { classStatus, classMainName, thumbnailImageURL } = classDetail

  if (classStatus === 'CANCELD_CLASS'){
    return (
      <Layout>
        <Header />
        <Content>
          <PageBlockCenter>
            <H2>삭제된 클래스 입니다.</H2>
          </PageBlockCenter>
        </Content>
        <Link to='/'>
          <CTAButton
            disabled={false}
            label="메인으로 가기"
          />
        </Link>
      </Layout>
    )
  }
  return (
    <Layout>
      <Header
        title="상세페이지"
        appendRight={
          <Share
            title={classMainName}
            imageUrl={thumbnailImageURL}
          />
        }
        back
      />
      <ClassDetailContent data={classDetail as ClassCreateFieldType} />

    </Layout>
  )
}

export default DetailPage

export type ClassCreateFieldType = {
  classCategory: string;
  classMainName: string;
  classSubName: string;
  recruitmentTarget: string;
  teacherInformation: string;
  maker: {
    nickname: string;
    profileImage: string;
    phoneNumber: string;
    rate: number;
    totalReviews: number;
  };
  targetEtc?: string;
  contactNumber: string;
  classStatus: ClassStatusType;
  recruitPersonnel: number;
  currentPersonnel?: number;
  requiredClassFee: number;
  tasteTeaList: string[];
  address: {
    code: string;
    jibun: string;
    address: string;
    addressDetail: string;
    sido: string;
    sigungu: string;
  };
  recruitProgressDate: {
    recruitStartDate: string;
    recruitEndDate: string;
  };
  classProgressDate: {
    classRoundStartDate: string;
    classRoundEndDate: string;
  }[];
  classDetailContent: string;
  thumbnailImageURL: string;
  detailImagesURL: string[];
  terms?: boolean[];
}
export function ClassDetailContent({ data }: { data: ClassCreateFieldType }){
  const isDisabled = useMemo(() => {
    // 모집중 상태를 제외하고는 비활성화
    return data.classStatus !== 'RECRUITING'
  }, [data.classStatus])
  const { maker: { rate, totalReviews, nickname = '-', profileImage = '' }, classProgressDate, classCategory, classStatus, classMainName, recruitmentTarget, address, classSubName, contactNumber, recruitProgressDate: { recruitStartDate: startDate, recruitEndDate: endDate }, recruitPersonnel, currentPersonnel = 0, requiredClassFee, detailImagesURL, teacherInformation, classDetailContent, tasteTeaList } = data

  /* TODO: 날짜형식
  const d = new Date(new Date().toISOString());
  d.toLocaleString('ko-KR').replace(/\.\s/g, '-')
*/
  return (
    <>
      <Content>
        <Swipe images={detailImagesURL} />

        <Block>
          <ButtonGroup>
            <Button
              label={address.sido}
              inline
              ghost />

            <Button
              label={getClassStatus(classStatus).text}
              color={getClassStatus(classStatus).color}
              isRadius
              inline />
            <Category>#{classCategory}</Category>
          </ButtonGroup>
          <Title>{classMainName}</Title>

          <NameBlock>
            <Name>
              {profileImage && (<ProfilImage src={profileImage} />)}
              <span>{nickname}</span>
            </Name>
            <span>
              {/* {new Array(rate || 0).fill(0).map((_, index) => (<IcStarFull key={index} />))}
              {new Array(5 - rate).fill(0).map((_, index) => (<IcStarGrayBlock key={index}><IcStarFull /></IcStarGrayBlock>))} */}
              <Link to="review/list">
                <ReviewText>{rate || 0}점 | {totalReviews}명의 평가</ReviewText>
              </Link>
            </span>
          </NameBlock>
          <Hr />

          <Intro>
            <H2>어떤 클래스인가요?</H2>
            <Description>{classSubName}</Description>

            <IntroImage
              alt="detail-2"
              src={detailImagesURL[1]}
              width="auto"
              height={210}
            />
          </Intro>

          <InfoList>
            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>대상</h3>
                <Description>{recruitmentTarget}</Description>
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>강사정보</h3>
                {teacherInformation.split('\n').map((text, index) => (
                  <Description key={index}>{text}</Description>
                ))}
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>모집기간</h3>
                <Description>{dateText(startDate)} ~ {dateText(endDate)}</Description>
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>일시</h3>
                {classProgressDate.map(({ classRoundStartDate: start, classRoundEndDate: end }, index) => (
                  <Description key={index}>{index + 1}회차 {dateTextForPeriod(start, end)}</Description>
                ))}
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>모집인원</h3>
                <Description>{recruitPersonnel}명</Description>
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>연락처</h3>
                {/* TODO: - */}
                <Description>
                  <a href={`tel:+${contactNumber}`}>{contactNumber}</a>
                </Description>
              </InfoListItemName>
            </InfoListItem>

            <InfoListItem>
              <IcCheckmark
                width={30}
                height={30} />
              <InfoListItemName>
                <h3>장소</h3>
                <Description>{address.address} ({address.jibun})</Description>
              </InfoListItemName>
            </InfoListItem>
          </InfoList>

          {classDetailContent && (<Intro>
            <H2>수업 상세 내용</H2>
            <IntroImage
              alt="detail-2"
              src={detailImagesURL[2]}
              width="auto"
              height={210}
            />

            {classDetailContent && (<>
              {classDetailContent.split('\n').map((text, index) => (
                <Description key={index}>{text}</Description>
              ))}
            </>)}
          </Intro>)}

          <TasteListBlock>
            <InfoListItemName>
              <h3>시음목록</h3>
            </InfoListItemName>
            <TasteList>
              {tasteTeaList.length > 0 && (
                tasteTeaList.map((text, index) => (
                  <li key={index}><Number>{index + 1}</Number> <span>{text}</span> </li>
                ))
              )}
            </TasteList>
          </TasteListBlock>
        </Block>
      </Content>
      <Bottom>
        <BoxShadowBottom>
          <BoxShadowContent>
            <Top>
              <AvailableMember>
                <label>신청 가능 인원</label> <span>{recruitPersonnel - currentPersonnel}명</span>
              </AvailableMember>
              <PriceTitle>수업료</PriceTitle>
            </Top>
            <Price>{numberWithCommas(requiredClassFee, '원')} <span>(1인)</span></Price>
          </BoxShadowContent>
          {/* FIXME: a 링크 인라인블록 / 블록 */}
          <Button
            link='reservation'
            label="신청하기"
            isRadius
            disabled={isDisabled}
          />
        </BoxShadowBottom>
      </Bottom>
    </>
  )
}

// const IcStarGrayBlock = styled.span`
//   svg{
//     fill: ${({ theme }) => theme.text3};
//   }
// `

const ReviewText = styled.span`
  color: ${({ theme }) => theme.gray5};
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
  font-weight: 600;
`

const Block = styled.div`
  padding: 12px;
  /* margin-bottom: 10px; */
  /* margin-bottom: calc(44px + env(safe-area-inset-bottom)); */
`

const Title = styled.div`
  margin-top: 22px;
  height: 26px;
  font-size: 18px;
  font-weight: 500;
`

const NameBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg{
    fill: ${({ theme }) => theme.caution};  
  }
`
const Category = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.mainGreen};
  font-weight: 600;
  margin-left: 12px;
`

const Name = styled.div`
  height: 34px;
  margin-top: 20px;
  color: ${({ theme }) => theme.gray3};  
`

const Hr = styled.hr` 
  margin: 26px -12px;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.gray1};  
`

const Intro = styled.div`
  margin-top: 50px;
  text-align: center;
`

const H2 = styled.h2` 
  color: ${({ theme }) => theme.gray5};  
  font-size: 20px;
  /* height: 50px;
  line-height: 50px; */
`

const Description = styled.div`
  color: ${({ theme }) => theme.text};  
  font-size: 12px;
  line-height: 20px;

  > a{
    color: inherit;
  }
`

const IntroImage = styled(Image)`
  margin: 28px 0; 
  border-radius: 10px;

  background-color: aliceblue;
`

const InfoList = styled.ul`
  margin: 10px 0 60px; 
  padding: 28px 24px;
  border-radius: 10px;
  background: ${({ theme }) => theme.white};  
`

const InfoListItem = styled.li`
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  > svg{
    fill: ${({ theme }) => theme.mainGreen};  
    margin-right: 10px;
    flex-shrink: 0;
  }

  margin-bottom: 26px;
  :last-child{
    margin-bottom: 0;
  }
`

const InfoListItemName = styled.div`
  > h3{
    font-size: 15px;
    margin-bottom: 10px;
  }
`

const TasteListBlock = styled.div`
  margin: 30px 0; 
  margin-bottom: 180px;
  padding: 30px;
  border-radius: 10px;
  background: ${({ theme }) => theme.white};
`

const TasteList = styled.ul`
  color: ${({ theme }) => theme.gray5};
  > li{
    display: block;
    line-height: 20px;
  }
`
const Number = styled.span`
  display: inline-block;
  text-align: center;
  background: ${({ theme }) => theme.mainGreen};
  color: #fff;
  border-radius: 10px;
  font-size: 12px;

  width: 12px;
  height: 12px;
  line-height: 12px;
`

export const ClassDetailBlock = styled.ul`
`
export const ClassListItem = styled.li`
  line-height: 18px;
  padding: 10px 0;
  > * {
    display: block;
    margin-bottom: 4px;
  }
`

const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  > div{
    padding-bottom: calc(34px + ${MarginBottom}px + env(safe-area-inset-bottom));

  }
  margin-bottom: calc(-1 * (env(safe-area-inset-bottom) + ${MarginBottom}px));
`

const BoxShadowContent = styled.div`
  padding: 14px 0;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 8px;
`
const AvailableMember = styled.div`
  > label{
    color: ${({ theme }) => theme.gray5}; 
    font-size: 12px;  
    font-weight: bold;
    margin-right: 18px;
  }
  > span{
    color: ${({ theme }) => theme.gray3}; 
    font-size: 12px;
  } 
`
const PriceTitle = styled.div`
  color: ${({ theme }) => theme.gray3}; 
  font-size: 12px;
`

const Price = styled.div`  
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  > span{
    display:inline-block;
    margin-left: 10px;
    color: ${({ theme }) => theme.gray3}; 
    font-size: 12px;
  } 
`

export function Swipe({ images }: { images: string[] }){
  const settings = {
    arrows: false,
    fade: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    dots: true
  }
  return (
    <SwipeBlock {...settings}>
      {
        images.map((image, index) => (
          <SwipeItem key={index}>
            <Image
              width="auto"
              height="100%"
              src={image}
              alt={`상세이미지-${index}`}
            />
          </SwipeItem>
        ))
      }
    </SwipeBlock>
  )
}

const SwipeBlock = styled(Slider)`
  margin-bottom: 30px;
`

const SwipeItem = styled.div`
  text-align: center;
  outline: none;
  height: 262px;
  background-color: ${({ theme }) => theme.white };
  color: ${({ theme }) => theme.white }
`
