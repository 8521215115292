import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

export type ProgressBarProps = {
  totalPages: number;
  step: number;
};

const ProgressBar = ({ totalPages, step }: ProgressBarProps) => {
  const [widthRate, setWidthRate] = useState('0%')
  const [prevStep, setPrevStep] = useState(step)

  useEffect(() => {
    setWidthRate(`${(step / totalPages) * 100}%`)
    setPrevStep(step)
  }, [step, totalPages])

  return (
    <>
      <ProgressBarWrapper>
        <ProgressBarInside
          widthRate={widthRate}
          step={step}
          prevStep={prevStep} />
      </ProgressBarWrapper>
    </>
  )
}
export default ProgressBar

const ProgressBarWrapper = styled.div`
  height: 5px;
  width: 100%;
  background: #EAEAEA;
`

const ProgressBarInside = styled.div<{ widthRate: string; step: number; prevStep: number }>`
  height: 5px;
  background: #01C897;
  border-radius: 10px;
  left: 0px;
  top: 0px;
  width: ${(props) => props.widthRate};
  transition: width 0.5s linear;
`
