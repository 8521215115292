
/**
 * 숫자로 변형 시도 후, 안 되면 기본값 적용 하여 반환
 */
export function toSafeNumber(value: any, defaultValue = 0): number {
  return isNaN(Number(value)) ? defaultValue : Number(value)
}

export function numberWithCommas(
  number?: string | number | null,
  suffix = '원',
  prefix = ''
): string {
  if (number === null || number === undefined) return `-${suffix}`
  return `${prefix}${toSafeNumber(number)?.toLocaleString()}${suffix}`
}