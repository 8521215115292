import { Methods, request } from '@/libs/request'

type validateReviewRequestType = {
  classId: string;
  applicantPhoneNumber: string;
}
type validateReviewResponseType = {
  validated: boolean;
  applicantPhoneNumber: string;
}

export const validateReview = async ({ classId, applicantPhoneNumber }: validateReviewRequestType) => {
  return request<validateReviewResponseType>({
    url: 'review/validation',
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/validateReview.json',
    method: Methods.GET,
    params: { classId, applicantPhoneNumber }
  })
}
