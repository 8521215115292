
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Error, Notice } from '@/components/Common/Styled'
import CheckBox from '@/components/Input/CheckBox'
import Input from '@/components/Input/Input'
import RadioBox from '@/components/Input/RadioBox'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useMakerInfo from '@/hooks/useMakerInfo'
import { ROUTER } from '@/router'

const { MAKER } = ROUTER

const LEAVE_BECAUS_LIST = [
  '이제 더 이상 티클래스를 운영할 이유가 없어서',
  '서비스에 대한 퀄리티가 마음에 들지 않아서',
  '다루의 서비스를 활용할 필요가 없어서'
]
function LeavePage() {
  const navigate = useNavigate()
  const { data: makerInfo } = useMakerInfo()
  const [checked, setChecked] = useState(false)

  const [field, setField] = useState({
    password: '',
    because: '',
    becauseEtc: ''
  })

  const buttonDisabled = useMemo(() => {
    const { password, because, becauseEtc } = field
    return !(password && checked) || !(because || becauseEtc)
  }, [field, checked])

  if (!makerInfo){
    return <>!makerInfo</>
  }

  const handleLeaveButtonClick = () => {
    navigate(`${MAKER}/${makerInfo.id}/leave/complete`, { replace: true })

  }
  return (
    <Layout>
      <Header
        title="계정 삭제"
        back
      />
      <Content>
        <InfoListBlock>
          <InfoListItem>
        계정 삭제를 하기 위해서는 현재 진행 중이거나, 모집 중인 티클래스/다회가 없어야 합니다.
          </InfoListItem>
          <InfoListItem>
        계정 삭제 이후에는 정산받지 못한 금액은 자동 삭제가 되며, 돌려받을 수 없습니다.
          </InfoListItem>
          <InfoListItem>
        위 사례에 동의한다면 비밀번호를 입력 후, 계정 삭제를 진행하세요.
          </InfoListItem>
          <InfoListItem>
        계정 삭제 1달 이후, 계정 정보는 완전하게 삭제 되며, 그 사이 동안 동일한 계정으로 접속하게 될 시 계정이 정상 복구가 됩니다.
          </InfoListItem>
        </InfoListBlock>
        <div>계정삭제 이유</div>

        <RadioBlock>
          <>
            {
              LEAVE_BECAUS_LIST.map((label, index) => (
                <RadioBox
                  key={index}
                  name='because'
                  value={label}
                  label={label}
                  checked={field.because === label}
                  onChange={({ value: because }) => {
                    setField((state) => ({ ...state, because }))
                  }}
                />
              ))
            }
            <RadioBox
              name="because"
              value="기타"
              label={
                <Input
                  type='text'
                  placeholder="기타사유입력(최대 100자)"
                  value={field.becauseEtc}
                  onChange={(becauseEtc) => {
                    setField((state) => ({ ...state, becauseEtc }))
                    setField((state) => ({ ...state, because: '기타' }))
                  }}
                  onFocus={() => {
                    setField((state) => ({ ...state, because: '기타' }))
                  }}
                />
              }
              checked={field.because === '기타'}
              onChange={({ value: because }) => {
                setField((state) => ({ ...state, because }))
              }}
            />
          </>
        </RadioBlock>

        <ListBlock>
          <ListItem>
            <h4>비밀번호 <Error>비밀번호가 올바르지 않습니다.</Error>
            </h4>
            <Input
              type='password'
              placeholder="비밀번호 입력"
              value={field.password}
              onChange={(password) => {
                setField((state) => ({ ...state, password }))
              }}
            />
            <Notice>영문, 숫자, 특수문자를 조합하여 8-16자</Notice>
          </ListItem>
        </ListBlock>
        <CheckBox
          checked={checked}
          onChange={({ checked }) => {
            setChecked(checked)
          }}
          name="agreement"
          label="위 사항에 동의하며, 계정을 삭제하겠습니다." />
      </Content>
      <CTAButton
        disabled={buttonDisabled}
        label="다음"
        onClick={handleLeaveButtonClick}
      />
    </Layout>
  )
}

export default LeavePage

const InfoListBlock = styled.ul`
  margin-bottom: 100px;
`
const InfoListItem = styled.li`
  margin-bottom: 12px;
  line-height: 20px;
`

const ListBlock = styled.ul`
`

const ListItem = styled.li`
  margin-bottom: 28px;
  line-height: 28px;
`

const RadioBlock = styled.div`
`
