import { useEffect, useState } from 'react'

// sdk 로드되면서 늘어나는 카카오 객체 타입들 확장
type KakaoType = {
  init: any;
  isInitialized: () => boolean;
  maps: any;
  Share: {
    sendDefault(settings: {
      objectType: 'feed';
      content: {
        title: string;
        description: string;
        imageUrl: string;
        imageWidth?: number;
        imageHeight?: number;
        link: {
          mobileWebUrl: string;
          webUrl: string;
        };
      };
      buttons: [
        {
          title: string;
          link: {
            mobileWebUrl: string;
          };
        }
      ];
      installTalk: boolean;
    }): void;

  };
}
declare global {
  interface Window {
    Kakao?: KakaoType; // common
    kakao?: KakaoType; // map
  }
}

const SDK_SRC = 'https://developers.kakao.com/sdk/js/kakao.min.js'
function useKakaoSDK(url: string = SDK_SRC): KakaoType | undefined {
  const [kakaoObj, setKakaoObj] = useState<KakaoType>()

  useEffect(() => {
    if (!kakaoObj && url) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onload = () => {
        if (window.Kakao){
          if (!window.Kakao.isInitialized()){
            window.Kakao.init(process.env.REACT_APP_KAKAO_SDK_JS_KEY)
            setKakaoObj(window.Kakao)
          }
        } else if (window.kakao){
          /* FIXME: rerender 되지 않음 */
          setKakaoObj(window.kakao)
        }
      }
      window.document.body.appendChild(script)
    }
  }, [kakaoObj, url])

  return kakaoObj
}
export default useKakaoSDK
