
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import BottomSheet from '@/components/Common/BottomSheet'
import { H2, PageBlock } from '@/components/Common/Styled'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import { BANK_CODE } from '@/meta'
import { ROUTER } from '@/router'

const { ACCOUNT } = ROUTER

function BankAccountPage() {
  const [account, setAccount] = useState('')
  const [bank, setBank] = useState<{ bankCode: string; bankName: string }>({
    bankCode: '',
    bankName: ''
  })

  const buttonDisabled = useMemo(() => {
    return !(!!account && !!bank.bankCode)
  }, [account, bank])

  return (
    <Layout>
      <Header
        title="회원가입"
        back
      />
      <Content>

        <PageBlock>
          <H2>계좌 인증</H2>
          <p>다회와 티클래스를 개최하고 참여비, 수업비를 입금받을 계좌를 입력하세요.</p>
          <Input
            label='계좌번호'
            type='text'
            placeholder="계좌번호입력"
            value={account}
            onChange={(account) => {
              setAccount(account)
            }}
          />
          <BankInput
            bankName={bank.bankName}
            bankCode={bank.bankCode}
            onChange={({ bankCode, bankName }) => {
              setBank({ bankCode, bankName })
            }} />
        </PageBlock>
      </Content>

      <Link
        to={`${ACCOUNT}/create/terms`}
      >
        <CTAButton
          disabled={buttonDisabled}
          /* TODO: 계좌인증 */
          label="다음"
        />
      </Link>
    </Layout>
  )
}

export default BankAccountPage

function BankInput({ bankName, bankCode, onChange }: { bankName: string; bankCode: string; onChange: ({ bankCode, bankName }: { bankCode: string; bankName: string }) => void }) {
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)

  const handleBankInputClick = () => {
    setBottomSheetOpen(true)
  }
  const handleBankIconClick = (bankCode: string, bankName: string) => {
    setBottomSheetOpen(false)
    onChange({ bankCode, bankName })
  }
  return (
    <>
      <Input
        label='은행 선택'
        type='text'
        placeholder="은행"
        value={bankName}
        onFocus={handleBankInputClick}
        readonly
      />

      <BottomSheet
        title="은행선택"
        open={bottomSheetOpen}
        onClose={() => {
          setBottomSheetOpen(false)
        }}
      >
        <Grid>
          {Object.entries(BANK_CODE).map(([key, { name, imageFileName }]) => {
            return (
              <GridItem
                key={key}
                selected={key === bankCode}
                onClick={() => { handleBankIconClick(key, name) }
                }>
                <img
                  alt={`${name}로고`}
                  src={`/bankIcon/${imageFileName}.png`} />
                <div>{name}</div>
              </GridItem>
            )
          })}
        </Grid>
      </BottomSheet>
    </>
  )
}

const Grid = styled.div`
  /* overflow: hidden; */
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.gray1};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

const GridItem = styled.button<{ selected?: boolean }>`
  margin: 0;
  border-right: 1px solid ${({ theme }) => theme.gray1};
  border-bottom: 1px solid ${({ theme }) => theme.gray1};
  padding: 12px 6px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => props.selected
    && css`
      pointer-events: none;
      font-weight: 700;
      background-color: ${props.theme.information};
    `};

  &:active {
    background-color: ${({ theme }) => theme.gray1};
  }


`
