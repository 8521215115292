import { useQuery } from 'react-query'

import { validateReview } from '@/apis/review/validateReview'

const getQueryKey = ({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) => [`validateReview/${classId}/${applicantPhoneNumber}`]

export default function useValidateReview({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) {
  return useQuery(getQueryKey({ classId, applicantPhoneNumber }), async () => {
    const isValidated = await (await validateReview({ classId, applicantPhoneNumber }))
    return isValidated
  })
}
