import { Methods, request } from '@/libs/request'

export type PostSendSMSRequestType = {
  phoneNumber: string;
  code: number;
}
export const postSendSMS = async({ phoneNumber, code }: PostSendSMSRequestType) => request<void>({
  url: '/ncp/send-sms/validation/phone-number/sign-up',
  method: Methods.POST,
  data: { phoneNumber, code }
})
