
import styled from '@emotion/styled'
import { MouseEvent, useState } from 'react'
import ReactDOM from 'react-dom'

import { IcShareAlt } from '../Icons'
import BottomSheet from './BottomSheet'
import CopyLink from './CopyLink'
import KakaoTalksharing from './KakaoTalksharing'

function Share({ title, imageUrl, path }: { title?: string;imageUrl?: string;path?: string }) {
  const [open, setOpen] = useState(false)

  const handleBottomSheetOpen = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    setOpen(true)
  }
  const handleBottomSheetClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ShareBlock onClick={handleBottomSheetOpen}>
        <IcShareAlt />
      </ShareBlock>
      {open && <ShareBottomSheet
        title={title}
        imageUrl={imageUrl}
        path={path}
        onClose={handleBottomSheetClose} />}
    </>
  )
}

export default Share

const ShareBlock = styled.div`
  > span{
    display: inline-block;
    margin-right: 4px
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  > img {
    width: 30px !important;
    height: 30px !important;
  }

  > svg {
    width: 22px !important;
    height: 22px !important;

    fill: ${({ theme }) => theme.gray3}; 
  }
`

function ShareBottomSheet({ title, imageUrl, path, onClose }: { title?: string; imageUrl?: string; path?: string; onClose: () => void }) {
  return ReactDOM.createPortal(
    <BottomSheet
      title='공유하기'
      open
      onClose={onClose}
    >
      <Content>
        <KakaoTalksharing
          title={title}
          imageUrl={imageUrl}
          path={path}
          onClick={onClose} />
        <CopyLink
          path={path}
          onClick={onClose} />
      </Content>
    </BottomSheet>
    , document.body)

}
