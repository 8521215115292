import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import icon from '@/assets/svg/icons/smile-complete.png'
import signUpCompletedState from '@/atoms/signUpCompletedState'
import { Sub1 } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { CTAButton } from '@/components/Input/CTAButton'

import NotFound from '../NotFound'

const SignUpCompletePage = () => {
  const signUpCompleted = useRecoilValue(signUpCompletedState)
  return (
    signUpCompleted ?
      <PageWrapper>
        <ContentWrapper>
          <Image
            alt='happy sign-up completion'
            src={icon}
            width='82px'
            height='82px'
          />
          <Title>회원가입이 완료되었습니다.</Title>
        </ContentWrapper>
        <ButtonWrapper marginTop='100px'>
          <Link
            to='/'
            replace
          >
            <CTAButton
              disabled={false}
              text="바로 모임 생성하기"
              colorType='green'
              onClick={() => console.log('메인으로 갑니다.')}
            />
          </Link>
          <Link
            to='/'
            replace
          >
            <CTAButton
              disabled={false}
              text="메인으로"
              colorType='white'
              onClick={() => console.log('메인으로 갑니다.')}
            />
          </Link>
        </ButtonWrapper>
      </PageWrapper>
      : <NotFound />
  )
}

export default SignUpCompletePage

const PageWrapper = styled.div`
  display: flex; 
  flex-direction : column;
  align-items: center;
  justify-content: center;
  `

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 23px;
  margin-top : 223px;
`
const ButtonWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  display: flex;
  padding: 12px 24px;
  gap: 20px;
  margin-top: ${(props) => props.marginTop}
`
const Title = styled(Sub1)`
  color: ${({ theme }) => theme.mainGreen};
`
