import { useMutation } from 'react-query'

import { patchTeahouseTimes, PatchTeahouseTimesRequestType } from '@/apis/teahouse/patchTeahouseTimes'

export default function usePatchTeahouseTimes(teahouseId: number) {
  return useMutation<void, unknown, PatchTeahouseTimesRequestType>(
    async (times) => {
      await patchTeahouseTimes(teahouseId, times)
    }
  )
}
