import styled from '@emotion/styled'
import { useState } from 'react'

import { BANK_CODE } from '@/meta'

import { Body2 } from '../Base/Text'
import Title from '../Layout/Title'

type BankListType = {
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setToggled: React.Dispatch<React.SetStateAction<boolean>>;
}
const BankList = ({ setCode, setToggled }: BankListType) => {
  return (
    <>
      <Background onClick={() => setToggled(false)}>
        <BottomSheet>
          <TopPart>
            <Title
              title='은행'
              subtitle='정산하실 계좌번호의 은행을 선택해주세요' />
          </TopPart>
          <ButtonList
            setCode={setCode}
            setToggled={setToggled} />
        </BottomSheet>
      </Background>
    </>
  )
}
export default BankList

interface BankButtonProps {
  src: string;
  bank: string;
  bankName: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setToggled: React.Dispatch<React.SetStateAction<boolean>>;
}
const BankButton = ({ src, bank, bankName, setCode, setToggled }: BankButtonProps) => {
  const [clicked, setClicked] = useState(false)
  return (
    <ButtonWrapper onClick={() => {
      setClicked(!clicked)
      setCode(bank)
      setToggled(false)
    }}>
      <Image
        alt={bank}
        src={src}
        width='24px'
        height='24px'
      />
      <Text clicked={clicked}>{bankName}</Text>
    </ButtonWrapper>
  )
}
interface ButtonListProps {
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setToggled: React.Dispatch<React.SetStateAction<boolean>>;
}
const ButtonList = ({ setCode, setToggled }: ButtonListProps) => {
  return (
    <ListWrapper>
      {Object.values(BANK_CODE).map((bank, index) => {
        const { imageFileName, name } = bank
        const imagePath = `/bankIcon/${imageFileName}.png`
        return (
          <BankButton
            key={index}
            src={ imagePath }
            setCode={ setCode }
            setToggled={ setToggled }
            bankName={ name }
            bank={imageFileName} />
        )
      })}
    </ListWrapper>
  )
}
const Background = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.30);
  z-index: 1;
`
const BottomSheet = styled.div`
  position: absolute;
  bottom: -30%;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.25);
`
const TopPart = styled.div`
  padding: 36px 24px;
  display: flex;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    color: ${(props) => props.theme.mainGreen };
  }
`
const Text = styled(Body2)<{ clicked: boolean }>`
  color: ${(props) => props.clicked ? props.theme.mainGreen : props.theme.gray4};
  width: 118px;
  &:active {
    color: ${(props) => props.theme.mainGreen };
  }
`
const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 24px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`
const Image = styled.img`
`