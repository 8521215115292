import { Methods, request } from '@/libs/request'

export type PostReservationRequestType = {
  classId: string;
  applicantName: string;
  applicantPhoneNumber: string;
}
export const cancelReservation = async ({
  classId,
  applicantName,
  applicantPhoneNumber
}: PostReservationRequestType) => {
  return request<void>({
    url: `reservation/cancel/${classId}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api.json',
    method: Methods.POST,
    data: {
      classId,
      applicantName,
      applicantPhoneNumber
    }
  })
}
