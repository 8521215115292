import { useState } from 'react'

import { postCheckUserNickName, PostCheckUserNickNameRequestType, PostCheckUserNickNameResponseType } from '@/apis/user/postCheckUserNickname'

export const useCheckUserNickName = () => {
  const [duplicated, setDuplicated] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const checkUserNickName = async (nickName: string) => {
    try {
      setLoading(true)
      const response = await postCheckUserNickName({ nickName } as PostCheckUserNickNameRequestType)
      const { duplicated }: PostCheckUserNickNameResponseType = response
      console.log('in useChecUserNicknme - duplicated : ', duplicated)
      setDuplicated(duplicated)
      setError('')
    } catch (error) {
      setError('닉네임 중복 검사에 실패하였습니다.')
    } finally {
      setLoading(false)
    }
  }

  return { duplicated, loading, error, checkUserNickName }
}
