import { Methods, request } from '@/libs/request'

export type GetParticipatorOfFestivalResponseType = {
  id: number;
  name: string;
  boothNumber: number;
  handledTea: string;
  nation: string;
}[];

export const getParticipatorOfFestival = ({ festivalId }: { festivalId: number }) => {
  return request<GetParticipatorOfFestivalResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}/participator`,
    method: Methods.GET
  })
}
