import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Sub1, Sub3 } from '@/components/Base/Text'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcArrowLeft } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import ToastType from '@/enums/toast'
import usePostVisitorLog from '@/hooks/usePostVisitorLog'
import useVisitorLogs from '@/hooks/useVisitorLog'
import { isError } from '@/libs/request'
import timeSince from '@/utils/timeSince'

const FestivalVisitorPage = () => {
  const { data: visitorLogs } = useVisitorLogs(1)
  const { mutate: postVisitorLog } = usePostVisitorLog()
  const [content, setContent] = useState('')
  const visitorLogsSorted = visitorLogs?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  return (
    <PageWrapperCentered>
      <Header />
      <ContentTitle>행사가 끝났지만, 찾아주셨군요!</ContentTitle>
      <ContentTitle>방명록을 작성해볼까요? </ContentTitle>
      <ContentDescription>방명록을 작성해요.</ContentDescription>
      <Input
        placeholder='ex) 축제가 기다려져요'
        value={content}
        onChange={(e) => setContent(e.target.value)} />
      <ButtonWrapper>
        { true && <BigButtonWrapper onClick = { () => { if (content.length > 5){
          postVisitorLog({ festivalId: 1, content }, {
            onError: (error) => {
              if (isError(error)){
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message, type: ToastType.ERROR })
              }
            },
            onSuccess: () => {
              window.location.reload()
            }
          })
        } else {
          alert('너무 짧아요. 5글자 이상 작성해주세요')
        }
        }}>
          <BigButtonText> 방명록 작성하기 </BigButtonText>
        </BigButtonWrapper>
        }
      </ButtonWrapper>
      <ReviewTitle>방명록</ReviewTitle>
      <VisitorLogCardWrapper>
        { visitorLogsSorted?.map((visitorLog, index) => {
          return (
            <VisitorLogCardBlock key={index}>
              <pre>
                {visitorLog.content}
              </pre>
              <div>
                {timeSince(visitorLog.createdAt)}
              </div>
            </VisitorLogCardBlock>
          )
        })}
      </VisitorLogCardWrapper>
    </PageWrapperCentered>
  )
}

export default FestivalVisitorPage

const Header = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>방명록</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}
const PageWrapperCentered = styled(PageWrapper)`
  padding: 50px 20px 0px 20px; 
  align-items: center;
  gap: 20px;
`
/* 헤더 */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const HeaderWrapper = styled.div`
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0px 10px; 
`
const BigButtonWrapper = styled.div`
  width: 100%;
  min-width: 150px;
  max-width: 400px;
  height: 40px;
  gap: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${((props) => props.theme.mainGreen)};
  cursor: pointer;
`
const BigButtonText = styled(Sub3)`
  color: #fff;
`
// const SmallButton2 = styled.div`
//   background: ${((props) => props.theme.information)};
//   color: #fff;
//   cursor: pointer;
//   display: flex;
//   border-radius: 10px;
//   width:100%;
//   max-width: 400px;
//   min-width: 150px;
//   justify-content: center;
//   align-items: center;
//   padding: 20px 20px;
//   height: 30px;
// `
/* 소개글, 텍스트 */
const ContentTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${((props) => props.theme.mainGreen)};
`
const ContentDescription = styled.div`
  color: ${((props) => props.theme.gray4)};
  font-weight: 400;
  width: 100%;
  white-space: pre-line;
  text-align: center;
`
const Input = styled.textarea`
  border-radius: 10px;
  border: 1px solid #01c897;
  width: 100%;
  max-width: 400px;
  padding: 10px 10px;
`

/* 후기 및 사진 */
const ReviewTitle = styled(Sub1)`
  margin-bottom: 30px;
  color: ${((props) => props.theme.information)};
`
const VisitorLogCardWrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  max-width: 600px;
  gap : 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const VisitorLogCardBlock = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid 10px;
  width: 100%;
  aling-items: center;
  justyfy-content: flex-end;
  gap: 20px;
`
