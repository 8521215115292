import styled from '@emotion/styled'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { TAB_INDEX } from '@/components/Common/TabSlider'
import { notify } from '@/components/Common/Toast'
import Button from '@/components/Input/Button'
import Input from '@/components/Input/Input'
import ThemeSwitch from '@/components/Theme/ThemeSwitch'
import { useDecryption, useEncryption } from '@/hooks/useEncryptionDecryption'
import { ROUTER } from '@/router'

import NotFound from '../NotFound'

const { DEV, MAIN, CLASS, ACCOUNT, MAKER, PAYMENT } = ROUTER
const CLASS_ID = '45'
const MAKER_ID = '7'

const PLAIN_TEXT = '01099998888'
const CYPHER_TEXT = '5a5018ea651a316405e899c797f134d0'

function DevPage() {
  const [request, setRequest] = useState({
    plaintext: false,
    cyphertext: false
  })

  const [field, setField] = useState({
    plaintext: PLAIN_TEXT,
    cyphertext: CYPHER_TEXT
  })
  const { data: cyphertext = CYPHER_TEXT } = useEncryption({ value: field.plaintext, enabled: request.plaintext })
  const { data: plaintext = PLAIN_TEXT } = useDecryption({ value: field.cyphertext, enabled: request.cyphertext })

  const handleCopytext = (target: 'Cyphertext' | 'Plaintext') => {
    const text = target === 'Cyphertext' ? cyphertext : plaintext

    const copyInput = document.createElement('input')
    copyInput.style.cssText = 'position:fixed;left:-9999px;top:-9999px;'
    document.body.appendChild(copyInput)
    copyInput.value = text
    copyInput.focus()
    copyInput.select()

    try {
      document.execCommand('copy')
      notify(`복사되었습니다. (${text})`)
    } catch (error) {
      notify('복사에 실패하였습니다. 잠시 후 다시 시도해주세요.')
    } finally {
      copyInput.remove()
    }
  }

  const handleRequestText = (target: 'Cyphertext' | 'Plaintext') => {
    if (target === 'Cyphertext'){

      setRequest((state) => ({
        ...state,
        plaintext: true
      }))
    }
    if (target === 'Plaintext'){

      setRequest((state) => ({
        ...state,
        cyphertext: true
      }))
    }
  }

  if (process.env.REACT_APP_ENV === 'production'){
    return <NotFound />
  }

  return (
    <PageBlock>
      <ThemeSwitch title="테마변경" />
      <Info> {/* 암호화, 복호화, 클래스, 메이커 ID */}
        <ul>
          <li>클래스 ID: {CLASS_ID}</li>
          <li>메이커 ID: {MAKER_ID}</li>
        </ul>
        <hr />
        <Input
          type='text'
          label='Plaintext'
          value={field.plaintext}
          onChange={(value) => {
            setField((state) => ({
              ...state,
              plaintext: value
            }))
          }}
        />
        <Button
          label='암호화'
          onClick={() => { handleRequestText('Cyphertext') }} />
        <Result
          onClick={() => { handleCopytext('Cyphertext') }}>암호화: {field.plaintext} ➡️ <span>{cyphertext}</span></Result>
        <hr />
        <Input
          type='text'
          label='Cyphertext'
          value={field.cyphertext}
          onChange={(value) => {
            setField((state) => ({
              ...state,
              cyphertext: value
            }))
          }}
        />
        <Button
          label='복호화'
          onClick={() => { handleRequestText('Plaintext') }} />
        <Result
          onClick={() => { handleCopytext('Plaintext') }}
        >복호화: {field.cyphertext} ➡️ <span>{plaintext}</span></Result>
      </Info>

      <UnListBlock>
        <ListBlock>
          <h1>API 테스트</h1>
          <nav>
            <Link to={`${DEV}/apitest`}>
              <span>API 테스트</span>
              <span>(URL: {`${DEV}/apitest`})</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock>{/* 결제, 지도, 에러 */}
          <h1>테스트</h1>
          <nav>
            <Link to={PAYMENT}>
              <span>결제하기(토스페이이먼츠 연동)</span>
              <span>(URL: {PAYMENT})</span>
            </Link>
            <Link to={`${DEV}/kakao/chanel`}>
              <span>카카오톡 채널</span>
              <span>(URL: {`${DEV}/kakao/chanel`})</span>
            </Link>
            <Link to={`${DEV}/map`}>
              <span>지도앱 연동</span>
              <span>(URL: {`${DEV}/map`})</span>
            </Link>
            <Link to="/error">
              <span>에러 발생</span>
              <span>(URL: /error)</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock>{/* 메인 */}
          <h1>메인</h1>
          <nav>
            <Link to={MAIN}>
              <span>메인</span>
              <span>(URL: {MAIN})</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock> {/* 클래스 */}
          <h1>클래스</h1>
          <nav>
            <Link to={`${CLASS}/list`}>
              <span>클래스 목록</span>
              <span>(URL: {`${CLASS}/list`})</span>
            </Link>
            <Link to={`${CLASS}/${CLASS_ID}`}>
              <span>클래스 상세</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}`})</span>
            </Link>
            <Link to={`${CLASS}/${CLASS_ID}/reservation`}>
              <span>클래스 예약</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/reservation`})</span>
            </Link>
            {/* <Link to={`${CLASS}/${CLASS_ID}/reservation/complete?orderId=alksjflkj9&paymentKey=avNA96Bjgq7XZYkKL4MrjgMEjmJxW80zJwlEWR52xydGPnOQ&amount=320000`}>
              <span>클래스 예약 완료</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/reservation/complete?orderId=alksjflkj9&paymentKey=avNA96Bjgq7XZYkKL4MrjgMEjmJxW80zJwlEWR52xydGPnOQ&amount=320000`})</span>
            </Link> */}
            <Link to={`${CLASS}/${CLASS_ID}/review/list`}>
              <span>메이커의 클래스전체 리뷰 목록</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/review/list`})</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock>{/* 단일페이지 - 클래스 예약, 취소, 리뷰 */}
          <h1>단일페이지(링크 바로 접근)</h1>
          <nav>
            <Link to={`${CLASS}/${CLASS_ID}/reservation/cancel/${cyphertext}`}>
              <span>클래스 예약 취소</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/reservation/cancel/${cyphertext}`})</span>
            </Link>
            <Link to={`${CLASS}/${CLASS_ID}/reservation/cancel/complete`}>
              <span>클래스 예약 취소 완료</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/reservation/cancel/complete`})</span>
            </Link>
            <hr />
            <Link to={`${CLASS}/${CLASS_ID}/review/create/${cyphertext}`}>
              <span>클래스 리뷰 작성</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/review/create/${cyphertext}`})</span>
            </Link>
            <Link to={`${CLASS}/${CLASS_ID}/review/create/complete`}>
              <span>클래스 리뷰 작성 완료</span>
              <span>(URL: {`${CLASS}/${CLASS_ID}/review/create/complete`})</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock>{/* 회원가입, 로그인 */}
          <h1>회원가입/로그인</h1>
          <nav>
            <Link to={`${ACCOUNT}/login`}>
              <span>로그인</span>
              <span>(URL: {`${ACCOUNT}/login`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create`}>
              <span>가입(법인/개인)</span>
              <span>(URL: {`${ACCOUNT}/create`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/form`}>
              <span>핸드폰 가입(휴대폰 점유인증)</span>
              <span>(URL: {`${ACCOUNT}/create/form`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/password`}>
              <span>비빌번호 설정</span>
              <span>(URL: {`${ACCOUNT}/create/password`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/account`}>
              <span>계좌 인증</span>
              <span>(URL: {`${ACCOUNT}/create/account`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/nickname`}>
              <span>별명 설정 (확인필요 / ?)</span>
              <span>(URL: {`${ACCOUNT}/create/nickname`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/terms`}>
              <span>약관 동의</span>
              <span>(URL: {`${ACCOUNT}/create/terms`})</span>
            </Link>
            <Link to={`${ACCOUNT}/create/complete`}>
              <span>가입완료</span>
              <span>(URL: {`${ACCOUNT}/create/complete`})</span>
            </Link>
          </nav>
        </ListBlock>
        <ListBlock> {/* 마이페이지 */}
          <h1>메이커(개설자)</h1>
          <nav>
            <Link to={`${MAKER}/${MAKER_ID}/my`}>
              <span>내 정보(개설목록/회원정보수정/수익금정산)</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/my`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/my?${TAB_INDEX}=1`}>
              <span>내 정보(1번째 탭 이동)</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/my?${TAB_INDEX}=1`})</span>
            </Link>
            <hr />
            <Link to={`${MAKER}/${MAKER_ID}/review`}>
              <span>리뷰목록(전체 클래스)</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/review`})</span>
            </Link>
            <hr />
            <Link to={`${MAKER}/${MAKER_ID}/class/create`}>
              <span>개설</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/class/create`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/class/create/complete`}>
              <span>개설 완료</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/class/create/complete`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/class/${CLASS_ID}`}>
              <span>개설 클래스 상세</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/class/${CLASS_ID}`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/class/${CLASS_ID}/edit`}>
              <span>개설 클래스 수정</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/class/${CLASS_ID}/edit`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/class/${CLASS_ID}/reservation`}>
              <span>개설 클래스 세부 현황</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/class/${CLASS_ID}/reservation`})</span>
            </Link>
            <hr />
            <Link to={`${MAKER}/${MAKER_ID}/account`}>
              <span>계좌 변경</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/account`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/transfer`}>
              <span>수익금 정산(출금)</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/transfer`})</span>
            </Link>
            <Link
              to={`${MAKER}/${MAKER_ID}/transfer/complete`}
              state={{ requestPrice: 12000 }}>
              <span>수익금 정산 완료</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/transfer/complete`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/settlement`}>
              <span>정산 내역</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/settlement`})</span>
            </Link>
            <hr />
            <Link to={`${MAKER}/${MAKER_ID}/password`}>
              <span>비밀번호</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/password`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/password/re`}>
              <span>비밀번호 확인</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/password/re`})</span>
            </Link>
            <hr />
            <Link to={`${MAKER}/${MAKER_ID}/nickname/edit`}>
              <span>별명 수정</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/nickname/edit`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/leave`}>
              <span>계정 삭제</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/leave`})</span>
            </Link>
            <Link to={`${MAKER}/${MAKER_ID}/leave/complete`}>
              <span>계정 삭제 완료</span>
              <span>(URL: {`${MAKER}/${MAKER_ID}/leave/complete`})</span>
            </Link>
          </nav>
        </ListBlock>
      </UnListBlock>
    </PageBlock>
  )
}

export default DevPage

const PageBlock = styled.div`
  margin-left: 10px;
  overflow: scroll;
`
const UnListBlock = styled.ul`
  padding: 20px;
`
const ListBlock = styled.li`
  padding-bottom: 20px;
  > h1{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 4px;
    margin-bottom: 10px;
  }
  > nav {
    margin-left: 14px;
    > a{
      display: block;
      height: 28px;
      white-space: pre;
    }
  }
`

const Info = styled.div`
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 3px solid ${({ theme }) => theme.gray3};

  > label{
    font-size: 12px;
  }
  > input{
    margin-bottom: 4px;
  }
  > button{
    height: 30px;
  }
  > ul > li{
    line-height: 30px;
  }
`

const Result = styled.div`
  font-size: 15px;
  margin: 10px 0;

  > span{
    background-color: ${({ theme }) => theme.gray3};
    color: ${({ theme }) => theme.white};
  }
`