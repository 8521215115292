import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import close from '@/assets/svg/icons/close_toast.svg'
import icon from '@/assets/svg/icons/error.svg'
export type ToastProps = {
  message: string;
  type: 'information' | 'error' | 'caution';
}

const Toast = ({
  message,
  type
}: ToastProps) => {
  return (
    <>
      {type === 'information' && <Information message={message} />}
      {type === 'error' && <Error message={message} />}
      {type === 'caution' && <Caution message={message} />}
    </>
  )
}
export default Toast

const Information = ({ message }: { message: string }) => {
  return (
    <ToastBlock>
      <ContentBlock>
        <Icon
          src={icon}
          alt='toast' />
        <Info>{message}</Info>
        <Icon
          src={close}
          alt='close-toast' />
      </ContentBlock>
    </ToastBlock>
  )
}
const Error = ({ message }: { message: string }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(true)
  }, [])
  const handleToast = () => {
    setIsOpen(false)
  }
  return (
    <>
      { isOpen &&
        <ToastBlockError>
          <ContentBlock>
            <Icon
              src={icon}
              alt='toast' />
            <Info>{message}</Info>
            <Icon
              src={close}
              alt='close-toast'
              onClick={handleToast} />
          </ContentBlock>
        </ToastBlockError>
      }
    </>
  )
}
const Caution = ({ message }: { message: string }) => {
  return (
    <ToastBlockCaution>
      <ContentBlock>
        <Icon
          src={icon}
          alt='toast' />
        <Info>{message}</Info>
        <Icon
          src={close}
          alt='close-toast' />
      </ContentBlock>
    </ToastBlockCaution>
  )
}

const ToastBlock = styled.div`
  width: 303px;
  height: auto;
  background: ${({ theme }) => theme.information};
  border-radius: 10px;
  padding: 17px 17px;
`
const ToastBlockError = styled.div`
  width: 303px;
  height: auto;
  background: ${({ theme }) => theme.error};
  border-radius: 10px;
  padding: 17px 17px;
`
const ToastBlockCaution = styled.div`
  width: 303px;
  height: auto;
  background: ${({ theme }) => theme.caution};
  border-radius: 10px;
  padding: 17px 17px;
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 13px;
  width: 100%;
  height: auto;
`
const Icon = styled.img`
  width: 14px;
  height: 14px;
`
const Info = styled.div`
  width: 215px;
  height: auto;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`