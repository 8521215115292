import styled from '@emotion/styled'

import { Body2, H5 } from '../Base/Text'
export type TitleProps = {
  title: string;
  subtitle: string;
}

const Title = ({
  title,
  subtitle
}: TitleProps) => {
  return (
    <ContentSection>
      <Head>{title}</Head>
      <Sub>{subtitle}</Sub>
    </ContentSection>
  )
}
export default Title

const ContentSection = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
const Head = styled(H5)`
  color: ${({ theme }) => theme.black};
`
const Sub = styled(Body2)`
  color: ${({ theme }) => theme.gray4};
`
