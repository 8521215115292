import { useQuery } from 'react-query'

import { getMySettings } from '@/apis/user/getMySettings'

const getQueryKey = () => ['user/setting']

export default function useMySettings() {
  return useQuery(
    getQueryKey(),
    async () => {
      const data = await getMySettings()
      return data
    }
  )
}
