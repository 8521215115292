import 'leaflet/dist/leaflet.css'

import L from 'leaflet'
import { ReactNode, useEffect, useState } from 'react'
import { MapContainer, MapContainerProps, TileLayer, TileLayerProps, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import { GetTeaHousesResponseType } from '@/apis/teahouse/getTeahouses'
import useTeahouses from '@/hooks/useTeahouses'
import { koreanRegionToEnglish, Region } from '@/pages/tea-together/component/RegionTag'

import { TeaMapMarker } from './TeaMapMarker'

export interface CustomMapContainerProps extends MapContainerProps {
  center: L.LatLngExpression;
  zoom: number;
  scrollWheelZoom: boolean;
  style: { height: string; width: string };
}

export interface CustomTileLayerProps extends TileLayerProps {
  attribution: string;
  url: string;
}

export interface OpenStreetMapType {
  lat: number;
  lon: number;
  zoom: number;
  children?: ReactNode;
}
const OpenStreetMap = ({ lat, lon, zoom, children }: OpenStreetMapType) => {
  const { data } = useTeahouses()
  const [teahouses, setTeahouses] = useState<GetTeaHousesResponseType>()

  const mapContainerProps: CustomMapContainerProps = {
    center: [lat, lon],
    zoom,
    scrollWheelZoom: true,
    style: { height: '100vh', width: '100%' },
    zoomControl: false
  }

  const tileLayerProps: CustomTileLayerProps = {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }
  useEffect(() => {
    if (data) setTeahouses(data)
  }, [data])

  return (
    <MapContainer {...mapContainerProps}>
      <TileLayer {...tileLayerProps} />
      <MarkerClusterGroup>
        {teahouses && PositioningFromTeahouses({ teahouses })}
      </MarkerClusterGroup>
      <MapUpdater
        lat={lat}
        lon={lon}
        zoom={zoom} />
      {children}
    </MapContainer>
  )
}

export default OpenStreetMap

interface PositioningFromTeahousesType {
  teahouses: GetTeaHousesResponseType;
}
const PositioningFromTeahouses = ({ teahouses }: PositioningFromTeahousesType): ReactNode[] => {

  return teahouses?.map((teahouse, index) => {
    const teahousePosition: L.LatLngExpression = [Number(teahouse.latitude), Number(teahouse.longitude)]
    return (
      <TeaMapMarker
        key={index}
        position={teahousePosition}
        name={teahouse.name}
        location={teahouse.location + ' ' + teahouse.specificLocation}
        region={koreanRegionToEnglish(teahouse.city) as Region}
        teahouseId={teahouse.id}
        type='TEAHOUSE' />
    )
  })

}
interface MapUpdaterProps {
  lat: number;
  lon: number;
  zoom: number;
}

const MapUpdater = ({ lat, lon, zoom }: MapUpdaterProps) => {
  const map = useMap()
  useEffect(() => {
    map.setView([lat, lon], zoom)
  }, [lat, lon, map, zoom])

  return null
}
