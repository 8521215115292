import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'
import { Weekday } from '@/utils/timeSince'

export type PatchTeahouseTimesRequestType = {
  day: Weekday;
  openedAt: number;
  closedAt: number;
  breakStartAt: number;
  breakEndAt: number;
}[];

export const patchTeahouseTimes = async(teahouseId: number, times: PatchTeahouseTimesRequestType) => request<void>({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  url: `teahouse/${teahouseId}/timetable`,
  method: Methods.PATCH,
  data: times,
  headers: { 'Authorization': `Bearer ${getAccessToken()}` }
})
