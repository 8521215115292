import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from 'recharts'

import { Sub3 } from '@/components/Base/Text'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcArrowLeft } from '@/components/Icons'
import { CTAButton } from '@/components/Input/CTAButton'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import ToastType from '@/enums/toast'
import usePostTastingNoteOfParticipator from '@/hooks/usePostTastingNoteOfParticipator'
// import usePostUploadFiles from '@/hooks/useUploadFiles'
import { isError } from '@/libs/request'

import RadioBoxList from './RadioBox'

const CreateReviewPage = () => {
  const { participatorId } = useParams<{ participatorId: string }>()
  const { mutate: postTastingNoteOfParticipator } = usePostTastingNoteOfParticipator()
  //const { mutate: postUploadFiles } = usePostUploadFiles()
  // 파일 업로드
  const fileInputRef = useRef<HTMLInputElement>(null)
  type FileListState = FileList | null;
  const [selectedFiles, setSelectedFiles] = useState<FileListState>(null)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(event.target.files)
  }

  // Input State
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [sweetness, setSweetness] = useState(0) //단 맛
  const [bitterness, setBitterness] = useState(0) //쓴 맛
  const [sourness, setSourness] = useState(0) //신 맛
  const [aspringency, setAspringency] = useState(0) //떫은 맛
  const [nuttiness, setNuttiness] = useState(0) //고소함
  const [spicy, setSpicy] = useState(0) //알싸함
  const [fresh, setFresh] = useState(0) //상큼함
  const [emotion, setEmotion] = useState('😀')
  const [enterTaste, setEnterTaste] = useState(false)
  //그래프 데이터
  const data = [
    { subject: '단 맛', value: sweetness },
    { subject: '쓴 맛', value: bitterness },
    { subject: '신 맛', value: sourness },
    { subject: '고소함', value: nuttiness },
    { subject: '알싸함', value: spicy },
    { subject: '떫음', value: aspringency },
    { subject: '상큼함', value: fresh }
  ]
  //인풋 핸들러
  const titleInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }
  const contentInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value)
  }
  //이미지 업로드 버튼 핸들러
  const clickHandler = () => {
    postTastingNoteOfParticipator({ festivalId: 1, participatorId: Number(participatorId), title, content: (emotion + '' + content), image: '', sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: () => {
        navigate('complete', { replace: true })
      }
    })
    /*

    if (fileInputRef.current && fileInputRef.current.files) {
      try {
        postUploadFiles(fileInputRef.current.files,
          {
            onError: (error) => {
              if (isError(error)){
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message, type: ToastType.ERROR })
              }
            },
            onSuccess: (data) => {
              console.log('image: ', data.images[0])
              postTastingNoteOfParticipator({ festivalId: 1, participatorId: Number(participatorId), title, content, image: String(data.images[0]), sweetness, bitterness, sourness, nuttiness, spicy, aspringency, fresh }, {
                onError: (error) => {
                  if (isError(error)){
                    const message = error?.response?.data?.message || 'Error'
                    notifyRenewal({ message, type: ToastType.ERROR })
                  }
                },
                onSuccess: () => {
                  navigate('complete', { replace: true })
                }
              })
            }
          }
        )

      } catch (error) {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      }
    }
    */
  }

  const navigate = useNavigate()

  return (
    <PageWrapperCentered>
      <Header />
      <Title>어떤 차를 드셨나요?</Title>
      <InputWrapper>
        <Input
          placeholder='보이숙차'
          value={title}
          onChange={titleInputHandler} />
      </InputWrapper>
      <Title>간단하게 설명을 해주세요</Title>
      <TextArea
        placeholder='이렇게 느껴졌어요'
        value={content}
        onChange={(e) => contentInputHandler(e)} />
      <Title>느낀 점을 눌러주세요</Title>
      <EmotionButtonWrapper>
        {['😀', '🥰', '🧐', '👍🏻'].map((item, index) => {
          return (
            <EmotionButton
              key={index}
              isSelected={emotion === item}
              onClick={() => setEmotion(item)}>{item}</EmotionButton>
          )
        })}
      </EmotionButtonWrapper>
      { false && <ImageInputWrapper>
        <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
        <ImageInput
          id="imageInput"
          type="file"
          onChange={handleFileChange}
          name='images'
          multiple
          ref={fileInputRef} />
      </ImageInputWrapper>}
      {/* 이미지 인풋파일 테스트 */}
      {selectedFiles && Array.from(selectedFiles).map((file, index) => (
        <ImagePreview
          key={index}
          src={URL.createObjectURL(file)}
          alt={`Preview ${index + 1}`}
        />
      ))}
      <TasteToggle onClick={() => setEnterTaste(!enterTaste)}>내가 느낀 맛도 기록하기</TasteToggle>
      { enterTaste && <TasteWrapper>
        <TasteSelectWrapper>
          <TasteName>[단 맛]</TasteName>
          <RadioBoxList
            setValue={setSweetness}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>[쓴 맛]</TasteName>
          <RadioBoxList
            setValue={setBitterness}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>[신 맛]</TasteName>
          <RadioBoxList
            setValue={setSourness}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>[고소함]</TasteName>
          <RadioBoxList
            setValue={setNuttiness}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>[알싸함]</TasteName>
          <RadioBoxList
            setValue={setSpicy}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>[떫음]</TasteName>
          <RadioBoxList
            setValue={setAspringency}
            valueList={[0, 1, 2, 3, 4, 5]} />
          <TasteName>상큼함</TasteName>
          <RadioBoxList
            setValue={setFresh}
            valueList={[0, 1, 2, 3, 4, 5]} />
        </TasteSelectWrapper>
      </TasteWrapper>}
      {!(bitterness || sweetness || sourness || nuttiness || spicy || aspringency || fresh) || <BottomSheet>
        <GraphWrapper>
          {/* <GraphTitle>이렇게 느껴졌어요</GraphTitle> */}
          <TastingNoteGraph graphData={data} />
        </GraphWrapper>
      </BottomSheet>}
      <ButtonWrapper>
        <CTAButton
          colorType="green"
          disabled={false}
          onClick={clickHandler}
          text='등록하기' />
      </ButtonWrapper>
    </PageWrapperCentered>
  )
}

export default CreateReviewPage

const Header = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <IcArrowLeft
          width='24px'
          height='24px'
          onClick={() => clickHandler()} />
        <HeaderTitle>방명록 작성하기</HeaderTitle>
      </LeftWrapper>
    </HeaderWrapper>
  )
}

/* Page */
const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px; 
  align-items: center;
  gap: 10px;
`
/* Header */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const HeaderWrapper = styled.div` 
  position: fixed; // 상단에 고정 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 50px; 
  padding : 5px 10px; 
  background-color: #ffffff; // 배경색, 원하는 것으로 변경 가능 
  animation: ${fadeIn} 1s ease-in-out; // 1초 동안 fadeIn 애니메이션 적용 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const HeaderTitle = styled(Sub3)`
  color: black;
`

/* Title and Input */
const Title = styled(Sub3)`
  color: ${((props) => props.theme.mainGreen)};
`

const InputWrapper = styled.div`
  height: auto;
  border: 2px solid #01c897;
  border-radius: 8px;
  background: #F8FFFD;
  width: 60%;
  min-width: 280px;
  max-width: 800px;

  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
const Input = styled.input`
  width: 100%;
`
const TextArea = styled.textarea`
  height: auto;
  border: 2px solid #01c897;
  border-radius: 8px;
  background: #F8FFFD;
  width: 60%;
  min-width: 280px;
  max-width: 800px;
  resize: vertical;
  display: flex;
  padding: 8px 8px 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  resize: vertical;
`
const EmotionButton = styled.button<{ isSelected?: boolean }>`
  font-size: 30px;
  border: 1px solid ${(props) => props.isSelected ? '#01c897' : 'transparent'};
  border-radius: 5px;
  color: ${(props) => props.isSelected ? 'white' : 'black'};
  padding: 10px 10px;
`
const EmotionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const TasteToggle = styled.button`
  width: 80%;
  max-width: 400px;
  height: 40px;
  background-color: #659AF8;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;

`
const ImageInputWrapper = styled.div`
`
const ImageInput = styled.input`
  display: none;
`
const ImageUploadLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #01c897;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #019e76;
  }
`
const ImagePreview = styled.img`
  width: 200px;
  height: 160px;
  border: 1px solid #01c897;
  border-radius: 20px;
  object-fit: cover;
  padding: 10px 10px;
`
/* Taste */
const TasteWrapper = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 400px;
`
const TasteSelectWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;  
gap: 12px;
height: 1000px;
`

const TasteName = styled(Sub3)`
  color: ${((props) => props.theme.information)};
`

/* Bottom Sheet */
const BottomSheet = styled.div`
  position: fixed;
  bottom: 120px;
  width: 200px;
  right: 0;
  height: 230px; 
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-top: 1px solid #ccc;
  z-index: 10;
  //box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
`
const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 120px;
  z-index: 20;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

/* Taste Graph*/
const GraphWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
// const GraphTitle = styled(Sub3)`
//   color: ${((props) => props.theme.mainGreen)};
// `
type TastingNoteData = {
  subject: string;
  value: number;
};
const TastingNoteGraph = ({ graphData }: { graphData: TastingNoteData[] }) => {
  return (
    <>
      <RadarChart
        cx={75}
        cy={70}
        outerRadius={50}
        width={160}
        height={160}
        data={graphData}>
        <PolarGrid />
        <PolarAngleAxis
          dataKey="subject"
          tick={{
            fontSize: '10px',
            fill: '#659AF8',
            fontWeight: 700
          }} />
        <Radar
          name="Tasting Note"
          dataKey="value"
          stroke="#01C897"
          fill= "#01C897"
          fillOpacity={0.3} />
      </RadarChart>
    </>
  ) }
