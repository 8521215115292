import { useQuery } from 'react-query'

import { getVisitorLogs } from '@/apis/festival/getVisitorLog'

const getQueryKey = (festivalId: number) => ['visitor-logs', festivalId]

export default function useVisitorLogs(festivalId: number) {
  return useQuery(
    getQueryKey(festivalId),
    async () => {
      const visitorLogs = await getVisitorLogs({ festivalId })
      return visitorLogs
    }
  )
}
