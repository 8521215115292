import { Methods, request } from '@/libs/request'
import { TeaTogetherType } from '@/pages/tea-together/TeaTogetherCreatePage'
import { getAccessToken } from '@/utils/jwt'

export type postTeaTogetherType = TeaTogetherType

export const postTeaTogether = async({ type, category, title, summary, description, seats, participationFee, minimumSeats, location, locationLocal, city, cityLocal, subCity, subCityLocal, specificLocation, hostContact, hostDescription, hostName, externalUrl, recruitmentStartBefore, recruitmentCloseBefore, teahouseId, images, times }: postTeaTogetherType) => request<{ id: number }>({
  url: 'tea-together',
  method: Methods.POST,
  data: { type, category, title, summary, description, seats, participationFee, minimumSeats, location, locationLocal, city, cityLocal, subCity, subCityLocal, specificLocation, hostContact, hostDescription, hostName, externalUrl, recruitmentStartBefore, recruitmentCloseBefore, teahouseId, images, times },
  headers: { Authorization: `Bearer ${getAccessToken()}` }
})
