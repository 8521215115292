import styled from '@emotion/styled'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import BulletIcon from '@/assets/svg/icons/bullet.svg'
import entitleMakerComplete from '@/atoms/entitleMakerCompletedState'
import { Body2 } from '@/components/Base/Text'
import Image from '@/components/Common/Image'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
import BankList from '@/components/Input/BankList'
import CheckBoxLong from '@/components/Input/CheckBoxLong'
import { CTAButton } from '@/components/Input/CTAButton'
import InputBank from '@/components/Input/InputBank'
import InputDefault, { StatusType } from '@/components/Input/InputDefault'
import { ButtonWrapper, PageWrapper } from '@/components/Layout/frame/Frame'
import Header from '@/components/Layout/Header'
import ProgressBartest from '@/components/Layout/ProgressBartest'
import Title from '@/components/Layout/Title'
import { EntitleMakerContext } from '@/context/EntitleMakerContext'
import ToastType from '@/enums/toast'
import { useCheckMakerNickname } from '@/hooks/useCheckMakerNickname'
import { useConfirmBusiness } from '@/hooks/useConfirmBusiness'
import { useEntitleMaker } from '@/hooks/useEntitleMaker'
import { 메이커_신청_안내문 } from '@/meta'
import EntitleMakerProvider from '@/providers/EntitleMakerProvider'
import validateNickname from '@/validators/regex/validateNickname'

const EntitleMakerPage = () => {
  return (
    <>
      <EntitleMakerProvider>
        <Header
          title="메이커 신청"
          fadein={false}
          timing={1}
          back={true} />
        <Pages />
      </EntitleMakerProvider>
    </>
  )
}
export default EntitleMakerPage

const Pages = () => {
  const { step, setStep } = useContext(EntitleMakerContext)
  const totalPages = 3
  return (
    <PageWrapper>
      <ProgressBartest
        step= {step}
        totalPages={totalPages} />
      <button onClick={() => setStep(0)}>0</button>
      <button onClick={() => setStep(1)}>1</button>
      <button onClick={() => setStep(2)}>2</button>
      {step === 0 && <Disclaimer />}
      {step === 1 && <BasicInfoPage />}
      {step === 2 && <NickNamePage />}
    </PageWrapper>
  )
}
//Page 1 : 메이커 신청 동의
const Disclaimer = () => {
  const [checked, setChecked] = useState(false)
  const { setStep } = useContext(EntitleMakerContext)
  return (
    <>
      <Title
        title='메이커 신청'
        subtitle='메이커는 다루에서 모임을 만들고, 차문화 활동을 제공하는 사람을 의미해요.'
      />
      <DisclaimerWrapper>
        <DisclaimerList />
      </DisclaimerWrapper>
      <CheckBoxWrapper>
        <CheckBoxLong
          checked={checked}
          setChecked={setChecked}
          content='위의 내용과, 커뮤니티를 저해하는 행동에 대해 조치를 받을 수 있음에 확인합니다.' />
      </CheckBoxWrapper>
      <ButtonWrapper>
        <CTAButton
          colorType='green'
          disabled={!checked}
          text='다음'
          onClick={() => setStep(1)}
        />
      </ButtonWrapper>
      <BlankSpace />
    </>
  )
}
const DisclaimerList = () => {
  return (
    <>
      {메이커_신청_안내문.map((content, index) => (
        <DisclaimerItem
          key={index}
          content={content} />
      ))}
    </>
  )
}
const DisclaimerItem = ({ key, content }: { key: number; content: string }) => {
  return (
    <ItemWrapper>
      <Image
        alt='happy sign-up completion'
        src={BulletIcon}
        width='14px'
        height='14px'
      />
      <Body2Black key={key}>{content}</Body2Black>
    </ItemWrapper>
  )
}
//Page 2 : 사업자/개인 구분
const BasicInfoPage = () => {
  const [isBusiness, setIsBusiness] = useState(true)
  return (
    <>
      <Title
        title='사업자/개인 구분'
        subtitle='필수정보를 입력한 후, 정산에 필요한 계좌를 입력해주세요'
      />
      <SelectWrapper>
        <ButtonM
          selected={isBusiness}
          onClick={() => setIsBusiness(true) }>법인/개인 사업자</ButtonM>
        <ButtonM
          selected={!isBusiness}
          onClick={() => setIsBusiness(false) }>일반 사용자</ButtonM>
      </SelectWrapper>
      { (isBusiness) && <BusinessForm /> }
      { (!isBusiness) && <NormalForm /> }
    </>
  )
}
//Page 2-A
const BusinessForm = () => {
  const { setStep } = useContext(EntitleMakerContext)
  const [inputStep, setInputStep] = useState(0)
  if (inputStep == 0) return <사업자기본 setInputStep={setInputStep} />
  if (inputStep !== 0) return <사업자은행 setStep={setStep} />
  else return <사업자기본 setInputStep={setInputStep} />
}
const 사업자기본 = ({ setInputStep }: { setInputStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const { setMaker } = useContext(EntitleMakerContext)
  const [businessNumber, setBusinessNumber] = useState('')
  const [owner, setOwner] = useState('')
  const [date, setDate] = useState('')
  const [businessName, setBusinessName] = useState('')
  const isFilled = useMemo(() => {
    return businessNumber !== '' && owner !== '' && date !== '' && businessName
  }, [businessNumber, owner, date, businessName])
  const { success, confirm } = useConfirmBusiness()
  const handleButton = async() => {
    setMaker((prev) => ({ ...prev, businessNumber: businessNumber, owenerName: owner, businessStartDate: date, businessName: businessName }))
    await confirm({ businessNumber, ownerName: owner, businessStartDate: date })
  }
  useEffect(() => {
    if (success) setInputStep(1)
  }, [setInputStep, success])
  return (
    <>
      <InputDefault
        title='사업자 등록번호 (10자리)'
        placeholder='10자리 입력'
        inputType='number'
        hasButton={false}
        setInputValue={setBusinessNumber} />
      <InputDefault
        title='대표자명'
        placeholder='대표자 성함 입력'
        inputType='text'
        hasButton={false}
        setInputValue={setOwner} />
      <InputDefault
        title='개업일자'
        placeholder='YYYYMMDD'
        inputType='number'
        hasButton={false}
        setInputValue={setDate} />
      <InputDefault
        title='업체명'
        placeholder='가게 이름 입력'
        inputType='text'
        hasButton={false}
        setInputValue={setBusinessName} />
      <BlankSpace />
      <ButtonWrapper>
        <CTAButton
          disabled={!isFilled}
          text='다음'
          colorType='green'
          onClick={handleButton} />
      </ButtonWrapper>
    </>
  )
}
const 사업자은행 = ({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const { setMaker, setIsBusiness } = useContext(EntitleMakerContext)
  const [account, setAccount] = useState('')
  const [bank, setBank] = useState('')
  const [toggled, setToggled] = useState(false)
  const handelButton = () => {
    setMaker((prev) => ({ ...prev, bankName: bank, bankAccountNumber: account }))
    setIsBusiness(true)
    setStep(2)
  }

  return (
    <>
      {(toggled) && <BankList
        setCode={setBank}
        setToggled={setToggled} />}
      <BankInputWrapper>
        <InputBank
          bank={bank}
          onClick={setToggled} />
      </BankInputWrapper>
      <InputDefault
        title='계좌번호'
        placeholder='10자리 ~ 14자리 입력 (-제외)'
        hasButton={false}
        setInputValue={setAccount} />
      <ButtonWrapper>
        <CTAButton
          disabled={false}
          colorType='green'
          text='다음'
          onClick={handelButton} />
      </ButtonWrapper>
    </>
  )
}
//Page 2-B
const NormalForm = () => {
  const { setMaker, setStep, setIsBusiness } = useContext(EntitleMakerContext)
  const [bank, setBank] = useState('')
  const [toggled, setToggled] = useState(false)
  const [account, setAccount] = useState('')
  const [normalUserName, setNormalUserName] = useState('')
  const [residentRegistrationNumber, setResidentRegistrationNumber] = useState('')
  const handelButton = () => {
    setMaker((prev) => ({ ...prev, bank, bankAccountNumber: account, normalUserName, residentRegistrationNumber }))
    setIsBusiness(false)
    setStep(2)
  }
  return (
    <>
      {(toggled) && <BankList
        setCode={setBank}
        setToggled={setToggled} />}
      <InputDefault
        title='이름'
        placeholder='실명 입력'
        hasButton={false}
        setInputValue={setNormalUserName} />
      <InputDefault
        title='주민등록번호'
        placeholder='수정필요'
        hasButton={false}
        setInputValue={setResidentRegistrationNumber} />
      <InputBank
        bank={bank}
        onClick={setToggled} />
      <InputDefault
        title='계좌번호'
        placeholder='10자리 ~ 14자리 입력 (-제외)'
        hasButton={false}
        setInputValue={setAccount} />
      <ButtonWrapper>
        <CTAButton
          disabled={false}
          colorType='green'
          text='다음'
          onClick={handelButton} />
      </ButtonWrapper>
    </>
  )
}
//Page 3 : 별명입력
const NickNamePage = () => {
  const { maker, setMaker } = useContext(EntitleMakerContext)
  const [nickName, setNickName] = useState('')
  const navigate = useNavigate()
  const { duplicated, check } = useCheckMakerNickname()
  const [status, setStatus] = useState<StatusType>('normal')
  const [info, setInfo] = useState('')
  // 상태 변경 예시
  const setErrorStatus = () => {
    setStatus('error')
    setInfo('올바르지 않은 형식입니다.한글/영어/숫자/_’를 혼합하여 띄어쓰기 없이 2글자 이상 15글자 이내로 입력해주세요.')
  }

  const setCorrectStatus = () => {
    setStatus('correct')
    setInfo('올바른 별명 형태입니다.')
  }

  const setNormalStatus = () => {
    setStatus('normal')
    setInfo('‘한글/영어/숫자/_’를 혼합하여 띄어쓰기 없이 2글자 이상 15글자 이내로 입력해주세요.')
  }
  const isFilled = useMemo(() => {
    const validated = validateNickname(nickName)
    if (validated) setCorrectStatus()
    if (!validated) setErrorStatus()
    if (nickName.length < 1) setNormalStatus()
    return nickName !== '' && validated
  }, [nickName])
  //Maker entitle
  const { success, entitle, isLoading, isError, message } = useEntitleMaker()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [entitleCompleted, setEntitleCompleted] = useRecoilState(entitleMakerComplete)
  //실행시 nickname 확인
  const handleButton = async() => {
    setMaker((prev) => ({ ...prev, makerName: nickName }))
    await check(nickName)
  }
  //메이커 별명 성공 시 -> entitle 함수실행
  useEffect(() => {
    if (!duplicated) {
      entitle({ data: maker, isBusiness: false })
    }
  }, [duplicated, entitle, maker, navigate])
  //에러
  useEffect(() => {
    if (isError) notifyRenewal({ message, type: ToastType.ERROR })
    if (isLoading) notifyRenewal({ message, type: ToastType.CAUTION })
  }, [message, isLoading, isError])
  //entitle 성공 시에 recoilstate true
  useEffect(() => {
    if (success) {
      setEntitleCompleted(true)
      navigate('/maker/entitle/complete')
    } //TODO: 완성페이지로 이동
  }, [navigate, success, setEntitleCompleted])
  return (
    <>
      <Title
        title='메이커 이름 정하기'
        subtitle='메이커 이름은 별명과 구별되어 모임 주최자로 활동할 때 사용돼요' />
      <InputDefault
        title='메이커 이름'
        placeholder='최소 2글자 ~ 최대 15글자'
        info={info}
        status={status}
        inputType='text'
        hasButton={false}
        setInputValue={setNickName} />
      <ButtonWrapper>
      </ButtonWrapper>
      <ButtonWrapper>
        <CTAButton
          disabled={!isFilled}
          text='메이커 신청 완료'
          colorType='green'
          onClick={handleButton} />
      </ButtonWrapper>
    </>
  )
}

const SelectWrapper = styled.div`
display: flex;
padding: 16px 24px;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 15px;
`
const ButtonM = styled.button<{ selected?: boolean }>`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 30px;
gap: 8px;

width: 156px;
height: 36px;
border: 1px solid ${({ theme }) => theme.mainGreen};
/* Green/Main Green */

background: ${({ theme, selected }) => selected ? theme.mainGreen : theme.white};
border-radius: 10px;
color: ${({ theme, selected }) => selected ? theme.white : theme.mainGreen};

`
const BankInputWrapper = styled.div`
width: 303px;
`
const DisclaimerWrapper = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px;
gap: 8px;
height: 360px;
border: 1px solid ${({ theme }) => theme.gray1 };
//filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
border-radius: 10px;
overflow-y: scroll;
max-width: 450px;
`
const ItemWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
height: auto;
`

const Body2Black = styled(Body2)`
  color: ${({ theme }) => theme.black};
`

const CheckBoxWrapper = styled.div`
  max-width: 450px
`
const BlankSpace = styled.div`
  height: 100px;
`