import { Methods, request } from '@/libs/request'

export type PostUploadFilesRequestType = {
  formData: FormData;
}

export type PostUploadFilesResponseType = {
  images: string[];
}

export const postUploadFiles = async({ formData }: PostUploadFilesRequestType) => request<PostUploadFilesResponseType>({
  url: 'upload/multiple',
  method: Methods.POST,
  data: formData
})
