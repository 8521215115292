
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { H2, PageBlock } from '@/components/Common/Styled'
import Button from '@/components/Input/Button'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import { ROUTER } from '@/router'

const { ACCOUNT } = ROUTER

function JoinPage() {
  const [field, setField] = useState<{
    구분: '' | '법인' | '개인';
    사업자등록번호: string;
    대표자명: string;
    업체명: string;
  }>({
    구분: '',
    사업자등록번호: '',
    대표자명: '',
    업체명: ''
  })

  const buttonDisabled = useMemo(() => {
    const { 구분, 사업자등록번호, 대표자명, 업체명 } = field
    return 구분 === '개인' ? false : !(!!사업자등록번호 && !!대표자명 && !!업체명)
  }, [field])

  return (
    <Layout>
      <Header
        title="회원가입"
        back
      />
      <Content>

        <PageBlock>
          <H2>법인/개인 구분</H2>
          <p>다루에서는 휴대폰 번호를 아이디로 사용됩니다.</p>

          <ButtonGroup>
            <Button
              label='법인/개인 사업자'
              ghost={field.구분 !== '법인'}
              isRadius
              onClick={() => {
                setField((state) => ({ ...state, 구분: '법인' }))
              }}
            />
            <Button
              label='개인'
              ghost={field.구분 !== '개인'}
              isRadius
              onClick={() => {
                setField((state) => ({ ...state, 구분: '개인' }))
              }} />
          </ButtonGroup>
          {field.구분 === '법인' && (
            <>
              <Input
                label="사업자등록번호 (10자리)"
                type='number'
                placeholder="7018645312"
                value={field.사업자등록번호}
                onChange={(사업자등록번호) => {
                  setField((state) => ({ ...state, 사업자등록번호 }))
                }}
              />
              <Input
                label="대표자명"
                type='text'
                placeholder="홍길동"
                value={field.대표자명}
                onChange={(대표자명) => {
                  setField((state) => ({ ...state, 대표자명 }))
                }}
              />
              <Input
                label="업체명"
                type='text'
                placeholder="다루티하우스"
                value={field.업체명}
                onChange={(업체명) => {
                  setField((state) => ({ ...state, 업체명 }))
                }}
              />
            </>)}
        </PageBlock>
      </Content>

      <Link
        to={`${ACCOUNT}/create/form`}
      >
        <CTAButton
          disabled={buttonDisabled}
          label="다음"
        />
      </Link>
    </Layout>
  )
}

export default JoinPage

const ButtonGroup = styled.div`
  margin-bottom: 54px;
  display: flex;
  gap: 0 8px;
`
