import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}
const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  timeout: 100 * 1000 // 단위 ms
})
//export const accessToken = localStorage.getItem('drat')
export const refreshToken = localStorage.getItem('drrt')

// const onRequestConfigClient = async (config: AxiosRequestConfig) => {
//   //config.headers['User-Agent'] = process.env.USER_AGENT || ''
//   console.log('request config :', config)
//   return config
// }

const onRequestConfigClient = async (config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem('drat') || 'AAAAAA' //- 함수를 호출 할 때 항상 불러온다.
  if (!config.headers){
    config.headers = {}
  }
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return config
}
const onRequestError = (error: AxiosError) => Promise.reject(error)
client.interceptors.request.use(onRequestConfigClient, onRequestError)

// const onResponseSuccess = (config: AxiosResponse) => config.data
const onResponseSuccess = (config: AxiosResponse) => config.data
const onResponseerror = (error: AxiosError) => Promise.reject(error)
client.interceptors.response.use(onResponseSuccess, onResponseerror)

export const request = <T>(options: AxiosRequestConfig): Promise<T> => client.request(options)

export function isError(payload: any): payload is AxiosError<{ message: string; code: string }>{
  return axios.isAxiosError(payload)
}
