import { useQuery } from 'react-query'

import { getBestTeaTogethers } from '@/apis/tea-together/getBestTeaTogethers'

const getQueryKey = () => ['tea-together/best']
//const getQueryKey = ({ classCategory, mainRegionName, page }: { classCategory?: string; mainRegionName?: string;page: number }) => [`classList/${mainRegionName}/${classCategory}/${page}`]
export default function useBestTeaTogethers(){
  return useQuery(
    getQueryKey(),
    async () => {
      const teaTogethers = await getBestTeaTogethers()
      return teaTogethers
    }
  )
}
