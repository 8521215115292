import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { customerIdGenerate, customerNameGenerate, orderIdGenerate, orderNameGenerate, PaymentType, setPaymentInfoToLocalStorage } from '@/utils/payment'

interface ShowStatusRoomButtonType {
  roomId: number;
  currentSeats: number;
  seats: number;
  title: string;
  teaTogetherId: number;
  participationFee: number;
}
const ShowStatusRoomButton = ({ currentSeats, seats, roomId, title, teaTogetherId, participationFee }: ShowStatusRoomButtonType) => {
  const [clicked, setClicked] = useState(false)
  const [count, setCount] = useState(0)
  const navigate = useNavigate()
  const isAbled = (currentSeats < seats)
  const isEmergent = ((seats - currentSeats) <= 2)
  function ButtonTextHandler() {
    if (isAbled){
      if (isEmergent){
        return `곧 마감 예정! 지금 신청하세요! : ${seats - currentSeats}명 예약 가능`
      } else {
        return `"지금 바로 신청할 수 있어요" : ${seats - currentSeats}명 예약 가능`
      }
    } else {
      return '모집이 종료되었어요 🥲'
    }
  }
  return (
    <>
      <ButtonWrapper
        onClick={() => setClicked(!clicked)}
        isAbled={isAbled}
        isEmergent={isEmergent}>
        {ButtonTextHandler()}
      </ButtonWrapper>
      {clicked && <CountInputWrapper style={{ alignItems: 'center' }}>
        <CountTitleText>예약인원 정하기</CountTitleText>
        <div>최대인원 {seats - currentSeats}명</div>
        <CountInput
          type='number'
          value={count}
          onChange={(e) => {
            if (parseInt(e.target.value) < 0) {
              setCount(0)
            } else if (parseInt(e.target.value) > seats - currentSeats) {
              setCount(seats - currentSeats)
            } else {
              setCount(parseInt(e.target.value))
            }
          }} />
        <PaymentButton onClick={
          () => {
            if (count > 0) {
              alert('결제를 진행합니다.')
              setPaymentInfoToLocalStorage({ type: PaymentType.teaTogether, roomId: roomId, teaTogetherId: teaTogetherId, orderId: orderIdGenerate(), orderName: orderNameGenerate(title, count), customerId: customerIdGenerate(), customerName: customerNameGenerate('l_ha_no', 1), amount: count * participationFee, count })
              navigate('/payment')
            } else {
              alert('예약인원을 입력해주세요')
            }
          }
        }>결제하기</PaymentButton>
      </CountInputWrapper>}
    </>
  )
}

const ButtonWrapper = styled.button<{ isAbled: boolean; isEmergent: boolean }>`
  background-color: ${({ isAbled, isEmergent, theme }) => isAbled ? isEmergent ? theme.caution : theme.mainGreen : theme.gray3};
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
  max-width: 300px;
`
const CountInputWrapper = styled.div`
  display: flex;
  background-color: #f4f4f4;
  flex-direction: column;
  padding: 20px 20px;
  gap: 10px;
`
const CountInput = styled.input`
  border: 1px solid ${({ theme }) => theme.mainGreen};
  padding: 10px 10px;
  border-radius: 10px;
`
const CountTitleText = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.mainGreen};
`
const PaymentButton = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  color: white;
  background-color: ${({ theme }) => theme.mainGreen};
`

export default ShowStatusRoomButton

/*
            const successUrl = `${window.location.origin}/tea-together/${teaTogether?.id}/reservation/loading?ct=${1}&ri=${1}&cn=${'이한호'}&on=티클래스 1건 : (${newDate.toString})`
            paymentMethodsWidgetReady && await paymentWidget?.requestPayment({
              orderId: nanoid(),
              orderName: `티클래스 1건 : (${newDate.toString})`,
              customerName: '이한호',
              customerEmail: 'hanwoo7816@gmail.com',
              customerMobilePhone: '01094246901',
              successUrl: successUrl,
              failUrl: `${window.location.origin}/error`
            })
*/

