import { H2, PageBlockCenter, PageImage } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'

function NotFound() {
  return (
    <Layout>
      <Header />
      <Content>
        <PageBlockCenter>
          <PageImage
            alt=""
            src='/images/alert.png'
            width={70}
            height={70}
          />
          <H2>페이지를 찾을 수 없습니다.</H2>
        </PageBlockCenter>
      </Content>
      <CTAButton
        onClick={() => {
          window.location.replace('/')
        }}
        disabled={false}
        label="메인으로 가기"
      />
    </Layout>
  )
}

export default NotFound
