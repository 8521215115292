import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { IcHeart } from '@/components/Icons'

import Tag from './Tag'

interface ShoppingItemType {
  title: string;
  shopName: string;
  description: string;
  originalPrice: number;
  discountedPrice: number;
  discountRate: number;
  images: string[];
}
function formatNumber(number: number) {
  return new Intl.NumberFormat().format(number)
}
const ShoppingItem = ({ title, shopName, description, originalPrice, discountedPrice, discountRate, images }: ShoppingItemType) => {
  return (
    <ItemWrapper>
      <ImageRotator imageUrls={images} />
      <ShopName>{shopName}</ShopName>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <OriginalPrice>{formatNumber(originalPrice)}</OriginalPrice>
      <PriceWrapper>
        { discountRate && <DiscountRate>{discountRate}%</DiscountRate>}
        <DiscountedPrice>{formatNumber(discountedPrice)}</DiscountedPrice>
      </PriceWrapper>
    </ItemWrapper>
  )
}
export default ShoppingItem

interface ImageRotatorProps {
  imageUrls: string[];
}
const ImageRotator: React.FC<ImageRotatorProps> = ({ imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length)
    }, 8000)

    return () => clearInterval(intervalId) // Interval clear on component unmount
  }, [imageUrls])

  return (
    <ImageWrapper>
      <ItemImage
        src={imageUrls[currentIndex]}
        alt="rotating" />
      <BottomLikeTagWrapper>
        <Tag>인기</Tag>
        <IcHeart
          height='16px'
          fill='#01c897' />
      </BottomLikeTagWrapper>
    </ImageWrapper>
  )
}

const ItemWrapper = styled.div`
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2px;
`
const ShopName = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 700;
`
const Title = styled.div`
  display: flex;
  font-weight: 700;
`
const Description = styled.div`
  font-weight: 300;
  font-size: 14px;
`
const PriceWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`
const OriginalPrice = styled.div`
  font-weight: 300;
  font-size: 12px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.gray5};
`
const DiscountedPrice = styled.div`
  font-weight: 700;
`
const DiscountRate = styled.div`
  font-weight: 1000;
  color: ${({ theme }) => theme.mainGreen};
`
//Image Rotator
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px; 
`
const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const BottomLikeTagWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding : 2px 5px;
`

