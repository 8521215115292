import { useQuery } from 'react-query'

import { getPost } from '@/apis/post/getPost'

const getQueryKey = (postId: number) => ['postDetail', postId]

export default function usePostDetail(postId: number){
  return useQuery(
    getQueryKey(postId),
    async () => {
      const post = await getPost({ postId })
      return post
    }
  )
}