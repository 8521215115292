import { Methods, request } from '@/libs/request'

export const getEncryption = (value: string) => {
  return request<string>({
    url: `/crypto/encryption/${value}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api.json',
    method: Methods.GET
  })
}

export const getDecryption = (value: string) => {
  return request<string>({
    url: `/crypto/decryption/${value}`,
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api.json',
    method: Methods.GET
  })
}