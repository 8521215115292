import { Methods, request } from '@/libs/request'

export type GetTeaTogetherResponseType = {
  id: number;
  type: string;
  category: string;
  containedAt: string;
  progressType?: 'PLANNED' | 'INPROGRESS' | 'COMPLETED' ;
  title: string;
  participationFee: number;
  seats: number;
  minimumSeats: number;
  summary: string;
  description: string;
  city: string;
  subCity: string;
  hostName: string;
  hostDescription: string;
  hostContact: string;
  address: string;
  makerId: number;
  makerName: string;
  longitude: string;
  latitude: string;
  images: string[];
  teahouse: {
    id: number;
    name: string;
  };
  timetable: { roomId: number; times: { startAt: string; endAt: string }[]; currentSeats: number }[];
  //추가로 타입 선언 필요
}

export const getTeaTogether = ({ teaTogetherId }: { teaTogetherId: number }) => {
  return request<GetTeaTogetherResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/tea-together/${teaTogetherId}`,
    method: Methods.GET
  })
}
