import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { CTAHeight } from './CTAButton'
import { HeaderHeight } from './Header'
import { MarginBottom } from './Layout'

function Content({ children }: { children: ReactNode }) {
  return (
    <ContentsBlock>{children}</ContentsBlock>
  )
}

export default Content

const ContentsBlock = styled.main`
  /* padding: 12px 0 0; */
  min-height: calc(100vh - (env(safe-area-inset-top) + env(safe-area-inset-bottom) + ${HeaderHeight + CTAHeight + MarginBottom}px));
`