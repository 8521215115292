import { useMutation } from 'react-query'

import { postPreRegister, PostPreRegisterRequestType } from '@/apis/marketing/postPreRegister'

export default function usePostPreRegister() {
  return useMutation<void, unknown, PostPreRegisterRequestType>(
    async(postPreRegisterRequestType: PostPreRegisterRequestType) => {
      await postPreRegister(postPreRegisterRequestType)
    }
  )
}
