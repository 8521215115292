import { Methods, request } from '@/libs/request'

export const kakaoSearch = async(keyword: string) => {
  return request<any>({
    baseURL: 'https://dapi.kakao.com/v2',
    url: `/local/search/keyword.json?query=${keyword}`,
    method: Methods.GET,
    headers: { Authorization: 'KakaoAK aecc9365e8edf2ea82d6ddbc17e47abe' }
  })
}
