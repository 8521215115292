import styled from '@emotion/styled'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { IcClose } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import useTeaTogether from '@/hooks/useTeaTogether'
import { convertISOToKoreanDate } from '@/utils/text'

import HeaderSubPage from './component/HeaderSubPage'

const TeaTogetherReservationPage = () => {
  const { teaTogetherId } = useParams<{ teaTogetherId: string }>()
  const { data: teaTogether } = useTeaTogether(Number(teaTogetherId))
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [order, setOrder] = useState<{ roomId: number; count: number; time: string }>()
  const [selectedRoomId, setSelectedRoomId] = useState<number | null>(null)
  const [selectedTime, setSelectedTime] = useState<string>('')
  //결제 위젯 사용
  //결제 위젯 위에서 사용
  const openModal = (roomId: number, time: string) => {
    setSelectedRoomId(roomId)
    setSelectedTime(time)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setSelectedRoomId(null)
    setModalIsOpen(false)
  }
  const addReservation = () => {
    if (selectedRoomId !== null && selectedCount > 0) {
      setOrder({
        roomId: selectedRoomId,
        count: selectedCount,
        time: selectedTime
      })
      closeModal()
    }
  }
  const removeReservation = () => {
    setOrder(undefined)
  }

  const [selectedCount, setSelectedCount] = useState(0)

  return (
    <PageWrapperTopMargin>
      <HeaderSubPage title="예약하기" />
      {modalIsOpen && (
        <Modal>
          <ModalContent>
            <ModalTitle>인원 수 선택</ModalTitle>
            <Counter>
              <button onClick={() => setSelectedCount(selectedCount - 1)}>-</button>
              <span>{selectedCount}</span>
              <button onClick={() => setSelectedCount(selectedCount + 1)}>+</button>
            </Counter>
            <ConfirmButton onClick={addReservation}>확인</ConfirmButton>
            <CancelButton onClick={closeModal}>취소</CancelButton>
          </ModalContent>
        </Modal>
      )}
      {order && <ReservationInfo
        order={order}
        onRemove={removeReservation} />}
      <Title>예약하실 날짜를 클릭하고, 인원 수를 입력하세요</Title>
      {teaTogether?.timetable.map((room, index) => {
        return (
          <ReservationWrapper
            key={index}
            onClick={() => openModal(room.roomId, convertISOToKoreanDate(room.times[0].startAt))}
          >
            <DateTitle>{index + 1}번 일정</DateTitle>
            {room.times.map((time, index) => {
              return (
                <Time key={index}>
                  {`${index + 1}번째 : ${convertISOToKoreanDate(time.startAt)}`}
                </Time>
              )
            })}
          </ReservationWrapper>
        )
      })}
      <ButtonWrapper>
        <Button
          completed={order !== undefined}
          onClick={async () => {
            // const newDate = new Date().getTime()
            if (order) {
              // handleTossPaymentOfTeaTogether({
              //   method: '카드',
              //   amount: order.count * (teaTogether?.participationFee || 0),
              //   orderId: `daru-teatogether-${newDate}`,
              //   orderName: `${teaTogether?.title.replace(/\s+/g, '') || '다루'}:${order.count}명예약`,
              //   orderCount: order.count,
              //   roomId: order.roomId,
              //   customerName: '홍길동',
              //   id: 1
              // })
            }
          }}
        >
          결제하기
        </Button>
      </ButtonWrapper>
    </PageWrapperTopMargin>
  )
}

// 나머지 스타일 및 컴포넌트는 이전 코드와 동일하게 유지됩니다.

export default TeaTogetherReservationPage

const PageWrapperTopMargin = styled(PageWrapper)`
  padding: 60px 20px 20px 20px;
  gap: 30px;
`

const ReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.gray4};
  border-radius: 10px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.mainGreen};
  font-size: 24px;
  font-weight: 800;
`

const DateTitle = styled.div`
  color: ${({ theme }) => theme.mainGreen};
  font-weight: 800;
`

const Time = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.black};
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  margin: 20px 0;
  button {
    padding: 5px 10px;
    font-size: 16px;
  }
`

const ConfirmButton = styled.button`
  background-color: ${({ theme }) => theme.mainGreen};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
`

const CancelButton = styled.button`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`
const Button = styled.div<{ completed: boolean }>`
  background-color: ${({ theme, completed }) => completed ? theme.mainGreen : theme.gray4 };
  color: #fff;
  padding: 20px 10px;
  font-weight: 800;
  width: 90%;
  max-width: 500px;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  aling-items: center;
  justify-content: center;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 10vh;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: cenger;
`

// 새로운 컴포넌트 추가: 예약 정보를 보여주는 컴포넌트
interface ReservationInfoType {
  order: { roomId: number; count: number; time: string };
  onRemove: (roomIdToRemove: number) => void;
}
const ReservationInfo = ({ order, onRemove }: ReservationInfoType) => {
  return (
    <Block>
      <Title>예약할 일정</Title>
      <ListWrapper>
        <ReservationListWrapper>
          <ModalTitle>일정</ModalTitle>
          <ReservationTime>{order.time}</ReservationTime>
          <ReservationCount>{`${order.count} 명`}</ReservationCount>
          <IcClose
            width={20}
            height={20}
            fill='#01c897'
            onClick={() => onRemove(order.roomId)} />
        </ReservationListWrapper>
      </ListWrapper>
    </Block>
  )
}
const ReservationListWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.gray4};
  border-radius: 10px;
  gap: 10px;
`
const ReservationTime = styled.div`
  
`
const ReservationCount = styled.div`
  font-size: 20px;
  color: #01c897;
  font-weight: 800;
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`
const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`
