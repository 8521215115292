
import styled from '@emotion/styled'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { H2, Notice, PageBlock } from '@/components/Common/Styled'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useMakerInfo from '@/hooks/useMakerInfo'
import { numberWithCommas } from '@/utils/numbers'

function TransferPage() {
  const { data: makerInfo } = useMakerInfo()
  const [field, setField] = useState({
    price: '',
    password: ''
  })

  const buttonDisabled = useMemo(() => {
    const { price, password } = field
    return !(price && password)
  }, [field])

  if (!makerInfo){
    return <>!makerInfo</>
  }

  const { price, password } = field

  return (
    <Layout>
      <Header
        back
      />
      <Content>

        <PageBlock>
          <H2>정산금액 입력</H2>
          <TextBlock>정산하시고자 하는 금액을 입력하세요</TextBlock>
          <ListBlock>
            <ListItem>
              <h4>정산요청금액</h4>
              <Input
                type='text'
                placeholder="금액 입력"
                value={price}
                onChange={(price) => {
                  setField((state) => ({ ...state, price }))
                }}
              />

              <Notice>출금 최대 금액 {numberWithCommas(makerInfo.income)}</Notice>
            </ListItem>
            <ListItem>
              <h4>비밀번호</h4>
              <Input
                type='password'
                placeholder="비밀번호 입력"
                value={password}
                onChange={(password) => {
                  setField((state) => ({ ...state, password }))
                }}
              />
              <Notice>출금을 하기위해서는 비밀번호를 입력하셔야 합니다.</Notice>

            </ListItem>
          </ListBlock>
        </PageBlock>

      </Content>
      <Link
        to="complete"
        state={{ requestPrice: price }}
        replace>
        <CTAButton
          disabled={buttonDisabled}
          label="정산 진행"
        />
      </Link>
    </Layout>
  )
}

export default TransferPage

const TextBlock = styled.div`
  padding: 10px 0;
`

const ListBlock = styled.ul`
  margin-top: 30px;
`

const ListItem = styled.li`
  margin-bottom: 30px;
  line-height: 30px;
`

