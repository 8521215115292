import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'

import Share from '@/components/Common/Share'
import Button from '@/components/Input/Button'
import Content from '@/components/Layout/Content'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useClassDetail from '@/hooks/useClassDetail'
import { numberWithCommas } from '@/utils/numbers'
import { dateText, dateTextForPeriod } from '@/utils/text'

function MakerClassDetailPage() {
  const { classId } = useParams()
  if (!classId){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <Detail classId={classId} />
}

function Detail({ classId }: { classId: string }) {
  const { data: classDetail } = useClassDetail({ classId })

  if (!classDetail){
    return <>!classDetail</>
  }

  const { classMainName, recruitmentTarget, address, classSubName, recruitProgressDate: { recruitStartDate: startDate, recruitEndDate: endDate }, recruitPersonnel, currentPersonnel, requiredClassFee, detailImagesURL, teacherInformation, classProgressDate, classDetailContent, tasteTeaList } = classDetail

  return (
    <Layout>
      <Header
        title="상세정보 메이커"
        appendRight={
          <Share />
        }
        back
      />
      <Content>
        <ClassDetailBlock>
          <ClassListItem>
            <strong>{classMainName}</strong>
            <Swipe images={detailImagesURL} />
          </ClassListItem>
          <ClassListItem>
            <strong>어떤 클래스 인가요?</strong>
            <div>{classSubName}</div>
          </ClassListItem>
          <ClassListItem>
            <strong>대상</strong>
            <div>{recruitmentTarget}</div>
          </ClassListItem>
          <ClassListItem>
            <strong>강사정보</strong>
            {teacherInformation.split('\n').map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </ClassListItem>
          <ClassListItem>
            <strong>수업날짜</strong>
            {classProgressDate.map(({ classRoundStartDate: start, classRoundEndDate: end }, index) => (
              <div key={index}>{index}회차 {dateTextForPeriod(start, end)}</div>
            ))}
          </ClassListItem>
          <ClassListItem>
            <strong>모집기간</strong>
            <div>{dateText(startDate)} ~ {dateText(endDate)}</div>
          </ClassListItem>
          <ClassListItem>
            <strong>현재인원 / 모집인원</strong>
            <div>{`${currentPersonnel}/${recruitPersonnel}`}</div>
          </ClassListItem>
          <ClassListItem>
            <strong>장소</strong>
            <div>{address.address} ({address.jibun})</div>
          </ClassListItem>
          <ClassListItem>
            <strong>수업료</strong>
            <div>{numberWithCommas(requiredClassFee, '원')} / 1인</div>
          </ClassListItem>

          {classDetailContent && (<ClassListItem>
            <strong>수업내용</strong>
            {classDetailContent.split('\n').map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </ClassListItem>)}
          {tasteTeaList && (<ClassListItem>
            <strong>시음목록</strong>
            {tasteTeaList.map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </ClassListItem>)}
        </ClassDetailBlock>
      </Content>
      <Button
        link='edit'
        label="수정하기"
      />
    </Layout>
  )
}

export default MakerClassDetailPage

const ClassDetailBlock = styled.ul`
`
const ClassListItem = styled.li`
  line-height: 18px;
  padding: 10px 0;
  /* > * {
    display: block;
    margin-bottom: 4px;
  } */
`

function Swipe({ images }: { images: string[] }){
  const settings = {
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear'

  }
  return (
    <SwipeBlock {...settings}>
      {
        images.map((image, index) => (
          <SwipeItem key={index}>
            <img
              src={image}
              alt={`상세이미지-${index}`}
            />
          </SwipeItem>
        ))
      }
    </SwipeBlock>
  )
}

const SwipeBlock = styled(Slider)`
  margin: 10px;
`

const SwipeItem = styled.div`
  /* TODO: 이미지 높이 */
  height: 200px;
  background-color: ${({ theme }) => theme.white };
  color: ${({ theme }) => theme.white }
`