import styled from '@emotion/styled'
import { useContext, useMemo, useState } from 'react'

import { ThemeContext } from '@/providers/ThemeProvider'

import Image from '../Common/Image'
import Switch from '../Input/Switch'

function ThemeSwitch({ title }: { title: string }) {
  const { onChangeTheme } = useContext(ThemeContext)
  const [checked, setChecked] = useState(true)
  return (
    <SwitchBlock>
      {title && <span>{title}:</span>}
      <Switch
        checked={checked}
        onChange={() => {
          setChecked((checked) => !checked)
          onChangeTheme()
        }}
      />
    </SwitchBlock>
  )
}

export default ThemeSwitch

const SwitchBlock = styled.div`
  display: flex;
  align-items: center;
`

export function ThemeMode() {
  const { theme, onChangeTheme } = useContext(ThemeContext)

  const themeImagePath = useMemo(() => {
    return theme === 'dark' ? '/images/theme-light.png' : '/images/theme-dark.png'
  }, [theme])

  return (
    <ModeBlock
      onClick={() => {
        onChangeTheme()
      }}>
      <Image
        alt="theme-switch"
        src={themeImagePath}
        width={34}
        height={34}
      />
    </ModeBlock>
  )
}

const ModeBlock = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 100px;
  padding: 20px;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  z-index: 18;
  /* border: 2px solid #01c897; */

`