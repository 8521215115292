import { Methods, request } from '@/libs/request'

import { PostSendSMSRequestType, PostSendSMSResponseType } from './postSendSMS'

export const postSendSMSForReset = async ({
  code,
  phoneNumber
}: PostSendSMSRequestType) => {
  try {
    const response = await request<PostSendSMSResponseType>({
      url: 'ncp/send-sms/validation/phone-number/reset-password',
      method: Methods.POST,
      data: {
        code,
        phoneNumber
      }
    })
    return response
  } catch (error) {
    console.error('Failed to Request Validation SMS', error)
    // 예상치 못한 에러 발생 시 적절한 예외 처리를 수행합니다.
    throw new Error('Failed to Request Validation SMS')
  }
}
