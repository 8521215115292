import { useQuery } from 'react-query'

import { getParticipatorOfFestival } from '@/apis/festival/getParticipatorOfFestival'

const getQueryKey = (festivalId: number) => ['participators', festivalId]

export default function useParticipatorOfFestival(festivalId: number) {
  return useQuery(
    getQueryKey(festivalId),
    async () => {
      const participators = await getParticipatorOfFestival({ festivalId })
      return participators
    })
}
