import styled from '@emotion/styled'
import { ChangeEvent, forwardRef, ReactNode } from 'react'

import { Error, Info, Label } from '../Common/Styled'

  type InputType = {
    info?: string;
    error?: string;
    label?: string;
    value: string;
    type: 'text' | 'number' | 'password';
    placeholder?: string;
    maxLength?: number;
    readonly?: boolean;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    left?: ReactNode;
    right?: ReactNode;
  }
const Input = forwardRef<HTMLInputElement, InputType>(({
  info,
  error,
  label,
  value,
  type,
  placeholder,
  maxLength,
  readonly = false,
  onChange,
  onFocus,
  left,
  right
}, ref) => {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (readonly || !onChange){
      return
    }

    if (type === 'number'){
      onChange(value.replace(/\D*/ig, ''))
    } else {
      onChange(value)
    }
  }

  return (
    <>
      {label && (<Label htmlFor={label}>{label}</Label>)}
      <Block>
        {left && (<LeftSection>{left}</LeftSection>)}
        <InputBlock
          ref={ref}
          id={label}
          type={type === 'password' ? 'password' : 'text' }
          readOnly={readonly}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          inputMode={type === 'number' ? 'numeric' : 'text' }
        />
        {right && (<RightSection>{right}</RightSection>)}
      </Block>
      {info && (<Info>{info}</Info>)}
      {error && (<Error>{error}</Error>)}
    </>
  )
})

Input.displayName = 'Input'
export default Input

const Block = styled.div`
    position: relative;
  `

const InputBlock = styled.input`
    border: 1px solid ${({ theme }) => theme.gray4};
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.gray4};
    font-size: 12px;
    border-radius: 100px;

    padding: 2px 10px;
    height: 40px;
    width: 100%;

    &:read-only{
      background: ${({ theme }) => theme.gray3};
      color: ${({ theme }) => theme.gray4};
    }
  `

const LeftSection = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
  `

const RightSection = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
    height: 40px;
    line-height: 40px;
  `