import React, { createContext } from 'react'

export interface Maker {
  makerName: string;
  bankName: string;
  bankAccountNumber: string;
  normalUserName?: string;
  residentRegistrationNumber?: string;
  businessNumber?: string;
  ownerName?: string;
  businessStartDate?: string;
  businessName?: string;
}

export interface EntitleMakerContextType {
  maker: Maker;
  setMaker: React.Dispatch<React.SetStateAction<Maker>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isBusiness: boolean;
  setIsBusiness: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EntitleMakerContext = createContext<EntitleMakerContextType>({
  maker: {
    makerName: '',
    bankName: '',
    bankAccountNumber: ''
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMaker: () => {},
  step: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: () => {},
  isBusiness: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsBusiness: () => {}
})