import { useQuery } from 'react-query'

import { getImagesOfTeahouse } from '@/apis/teahouse/getImagesOfTeahouse'

const getQueryKey = (id: number) => ['teahouse', id, 'images']

export default function useImagesOfTeahouse(id: number){
  return useQuery(
    getQueryKey(id),
    async () => {
      const images = await getImagesOfTeahouse(id)
      return images
    }
  )
}
