
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { H2, PageBlockCenter } from '@/components/Common/Styled'
import { notify } from '@/components/Common/Toast'
import Button from '@/components/Input/Button'
import Input from '@/components/Input/Input'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useClassDetailByMaker from '@/hooks/useClassDetailByMaker'
import { ROUTER } from '@/router'
import { dateText, dateTextForPeriod } from '@/utils/text'

const { CLASS, MAKER } = ROUTER
function getTargetURL(classId: number){
  return `${window.origin}${CLASS}/${classId}`
}

function CreateCompletePage() {
  /* TODO: makerID */
  const CLASS_ID = '234'
  const MAKER_ID = 234
  const { data: classDetail } = useClassDetailByMaker({ classId: CLASS_ID })
  const handleLinkCopyButtonClick = () => {
    if (!classDetail?.id) return

    const copyInput = document.createElement('input')
    copyInput.style.cssText = 'position:fixed;left:-9999px;top:-9999px;'
    document.body.appendChild(copyInput)
    copyInput.value = getTargetURL(classDetail.id)
    copyInput.focus()
    copyInput.select()

    try {
      document.execCommand('copy')
      notify('링크가 복사되었습니다.')
    } catch (error) {
      notify('복사에 실패하였습니다. 잠시 후 다시 시도해주세요.')
    } finally {
      copyInput.remove()
    }
  }
  if (!classDetail){
    return <>!classDetail</>
  }

  const { id, title, classProgressDate, price, recruitPersonnel, recruitProgressDate: { start: startDate, end: endDate } } = classDetail

  return (
    <Layout>
      <Header />
      <Content>
        <PageBlockCenter>
          <H2>개설이 완료되었습니다.</H2>
          <H2>링크를 복사하여, 다른 사람들에게 알리세요!</H2>

          <div>

            <Input
              readonly
              type="text"
              value={getTargetURL(id)} />
            <Button
              onClick={handleLinkCopyButtonClick}
              label='복사하기'
              inline />
          </div>
          <p>
          Tip!
인스타그램이나 SNS 계정 프로필이나 게시물이랑 같이 올린다면 더 좋은 홍보효과를 가질 수 있어요.
          </p>

          <InfoBlock>
            <h2>주요정보</h2>
            <ClassDetailBlock>

              <ClassListItem>
                <strong>클래스명</strong>
                <div>{title}</div>

              </ClassListItem>
              <ClassListItem>
                <strong>수업날짜</strong>
                {classProgressDate.map(({ start, end }, index) => (
                  <div key={index}>{index}회차 {dateTextForPeriod(start, end)}</div>
                ))}

              </ClassListItem>
              <ClassListItem>
                <strong>모집기간</strong>
                <div>{dateText(startDate)} ~ {dateText(endDate)}</div>

              </ClassListItem>
              <ClassListItem>
                <strong>수강료</strong>
                <div>{price}</div>
              </ClassListItem>
              <ClassListItem>
                <strong>예약인원</strong>
                <div>{recruitPersonnel}</div>
              </ClassListItem>
            </ClassDetailBlock>

          </InfoBlock>
        </PageBlockCenter>

      </Content>
      <Link
        to={`${MAKER}/${MAKER_ID}/my`}
        replace>
        <CTAButton
          disabled={false}
          label="관리자페이지로 이동"
        />
      </Link>
    </Layout>
  )
}

export default CreateCompletePage

const InfoBlock = styled.div`
  padding: 10px;
`
const ClassDetailBlock = styled.ul`
`
const ClassListItem = styled.li`
  line-height: 18px;
  padding: 10px 0;
`