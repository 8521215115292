import * as React from "react";
import { SVGProps } from "react";

const SvgTeahouseFound = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 20h5v-6h4v6h5V10h3L10 0 0 10h3v10Zm5.984-7.218a4.4 4.4 0 0 0 3.111-.906h.005l2.932 2.937.781-.781-2.943-2.931a4.4 4.4 0 1 0-3.886 1.68Zm1.675-1.331a3.3 3.3 0 1 1-2.526-6.099 3.3 3.3 0 0 1 2.526 6.099Z"
    />
  </svg>
);

export default SvgTeahouseFound;
