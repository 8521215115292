import { MouseEvent } from 'react'

import useKakaoSDK from '@/hooks/useKakaoSDK'
import { BRAND_NAME } from '@/meta'

function KakaoTalksharing({ title, imageUrl, path = '', onClick }: { title?: string; imageUrl?: string; path?: string; onClick?: () => void }){
  const kakao = useKakaoSDK()

  const handleKakaoTalkShareButtonClick = (e: MouseEvent) => {
    e.preventDefault()

    if (!kakao) return

    onClick && onClick()
    const targetUrl = path ? `${window.location.origin}${path}` : window.location.href
    kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: title ?? BRAND_NAME,
        description: '차모임, 티클래스, 다회 등 모든 종류의 차 모임을 만나보세요.',
        imageUrl: imageUrl ?? 'https://daruteaworld.com/images/thumbnail.png',
        link: {
          mobileWebUrl: targetUrl,
          webUrl: targetUrl
        }
      },
      buttons: [
        {
          title: '클래스 보러가기',
          link: { mobileWebUrl: targetUrl }
        }
      ],
      installTalk: true
    })
  }

  return (
    <img
      id="kakaotalk-sharing-btn"
      src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
      alt="카카오톡 공유 보내기 버튼"
      onClick={handleKakaoTalkShareButtonClick}
    />
  )
}

export default KakaoTalksharing

