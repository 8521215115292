import { Methods, request } from '@/libs/request'

export type PostQuickStartRequestType = {
  phoneNumber: string;
  code: number;
}
export type PostQuickStartResponseType = {
  accessToken: string;
  refreshToken: string;
}

export const postQuickStart = async({ phoneNumber, code }: PostQuickStartRequestType) => request<PostQuickStartResponseType>({
  url: 'auth/quick-start',
  method: Methods.POST,
  data: { phoneNumber, code }
})
