import { Methods, request } from '@/libs/request'
import { getAccessToken } from '@/utils/jwt'

export type PatchMakerRequestType = {
  nickname?: string;
  profileImage?: string;
}

export const patchMaker = async(maker: PatchMakerRequestType) => request<void>({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  url: 'daru-maker',
  method: Methods.PATCH,
  data: maker,
  headers: { 'Authorization': `Bearer ${getAccessToken()}` }
})
