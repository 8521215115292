import { useQuery } from 'react-query'

import { getTeaTogethersForCity } from '@/apis/tea-together/getTeaTogethersForCity'

const getQueryKey = ({ city, category, limit, page }: { city?: string; category?: string; limit: number; page: number }) => [`?city=${city}/${category}/${limit}/${page}`]
//const getQueryKey = ({ classCategory, mainRegionName, page }: { classCategory?: string; mainRegionName?: string;page: number }) => [`classList/${mainRegionName}/${classCategory}/${page}`]
export default function useTeaTogethersForCity(city: string, category: string, limit: number, page: number){
  return useQuery(
    getQueryKey({ city, category, limit, page }),
    async () => {
      const teaTogethers = await getTeaTogethersForCity(city, category, limit, page)
      return teaTogethers
    }
  )
}
