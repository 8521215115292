import { Methods, request } from '@/libs/request'

import { PostEntitleMakerRequestType, PostEntitleMakerResponseType } from './postEntitleMakerNormal'

export const postEntitleMakerBusiness = async({ makerName, bankName, bankAccountNumber, businessNumber, ownerName, businessStartDate, businessName }: PostEntitleMakerRequestType) => request<PostEntitleMakerResponseType>({
  url: 'daru-maker/entitle/business',
  method: Methods.POST,
  data: {
    makerName,
    bankName,
    bankAccountNumber,
    businessNumber,
    ownerName,
    businessStartDate,
    businessName
  }
})

