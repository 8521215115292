import { Methods, request } from '@/libs/request'

export type GetReservationInfoResponseType = {
  classMainName: string;
  classProgressDate: [
    {
      classRoundStartDate: string;
      classRoundEndDate: string;
    }
  ];
  applicantName: string;
  requestPersonnel: number;
  requiredClassFee: number;
  cancelFee: number;
}

export const getReservationInfo = ({ classId, applicantPhoneNumber }: { classId: string; applicantPhoneNumber: string }) => {
  return request<GetReservationInfoResponseType>({
    url: 'reservation/confirm',
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/getClassReservationConfirm.json',
    method: Methods.GET,
    params: { classId, applicantPhoneNumber }
  })
}
