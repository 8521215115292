import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'

import { IcSearch } from '@/components/Icons'
import useSearchTeahouses from '@/hooks/useSearchTeahouses'
import RegionTag, { koreanRegionToEnglish, Region } from '@/pages/tea-together/component/RegionTag'

interface SearchBarType {
  mapMoveHandler: (lat: number, lon: number, zoom: number) => void;
}
const SearchBar = ({ mapMoveHandler }: SearchBarType) => {
  const [clicked, setClicked] = useState(false)
  const [searched, setSearched] = useState(false)
  const [keyword, setKeyword] = useState('')
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const handleButtonClick = () => {
    setClicked(true)
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }
  const handleInputChange = (keyword: string) => {
    setSearched(false)
    setKeyword(keyword)
  }
  const handleButtonBlur = () => {
    setSearched(false)
    setClicked(false)
  }
  const handleDocumentClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const isSearchWrapper = target.closest('#search-wrapper')

    if (!isSearchWrapper) {
      handleButtonBlur()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchWrapper id='search-wrapper'>
      <SearchInputSection>
        <ToggleButton
          onClick={handleButtonClick}
          clicked={clicked}>
          <IcSearch
            width={24}
            height={24}
            fill='#01c897' />
        </ToggleButton>
        <SearchInput
          ref={searchInputRef}
          onChange={(e) => handleInputChange(e.target.value)}
          clicked={clicked}
          onBlur={() => (searched) ? setClicked(false) : null}
          placeholder={clicked ? '찻집 이름 검색' : ''} />
        <SearchButton
          clicked={ clicked }
          onClick={() => setSearched(true)}>검색</SearchButton>
      </SearchInputSection>
      {searched && <SearchResults
        mapMoveHandler={mapMoveHandler}
        keyword={keyword}
        clickHandler={handleButtonBlur} />}
    </SearchWrapper>
  )
}
export default SearchBar

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`
const SearchInputSection = styled.div`
  display: flex;
  flex-direction: row;
`
const ToggleButton = styled.div<{ clicked: boolean }>`
  display: flex;
  flex-direction: row;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transform: ${({ clicked }) => (clicked ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`
const SearchInput = styled.input<{ clicked: boolean }>`
  position: absolute;
  left: 20px;
  padding: ${({ clicked }) => clicked ? '0px 35px' : '0px'};
  height: 40px;
  transition: width 0.3s ease-in-out;
  background-color: #fff;
  width: ${({ clicked }) => (clicked ? '250px' : '0px')};
  border-radius: 5px;
`
const SearchButton = styled.button<{ clicked: boolean }>`
  position: absolute;
  left: 280px;
  top: 15px;
  display: ${({ clicked }) => clicked ? 'flex' : 'none'};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  background-color: #01c897;
`
interface SearchResultsType {
  keyword: string;
  clickHandler: () => void;
  mapMoveHandler: (lat: number, lon: number, zoom: number) => void;
}
const SearchResults = ({ keyword, clickHandler, mapMoveHandler }: SearchResultsType) => {
  const { data } = useSearchTeahouses(keyword)
  return (
    <SearchResultsWrapper>
      { data?.map((teahouse, index) => {
        return (
          <SearchResultItemWrapper
            key={index}
            onClick={() => {
              console.log(teahouse.name)
              console.log(teahouse.latitude)
              console.log(teahouse.longtitude)
              mapMoveHandler(teahouse.latitude, teahouse.longtitude, 12)
              clickHandler()
            }}>
            <RegionTag region={koreanRegionToEnglish(teahouse.cityLocal) as Region} />
            {teahouse.name}
          </SearchResultItemWrapper>
        )
      })}
    </SearchResultsWrapper>
  )
}
const SearchResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SearchResultItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  background-color: rgba(255,255,255, 0.6);
  color: #000;
  align-items: center;
  gap: 10px;
`
