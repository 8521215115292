import styled from '@emotion/styled'
import { useCallback, useEffect, useRef, useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { notifyRenewal } from '@/components/Common/ToastRenewal'
import { IcCheckmarkOutline, IcClose, IcCloseOutline } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import Title from '@/components/Layout/Title'
import ToastType from '@/enums/toast'
import usePostTeaTogether from '@/hooks/usePostTeaTogether'
import useSearchTeahouses from '@/hooks/useSearchTeahouses'
import usePostUploadFiles from '@/hooks/useUploadFiles'
import { isError } from '@/libs/request'

import MainTabSlider from '../tea/component/MainSlider'
import Calendar from './component/Calendar'
import CategoryBadge from './component/Category'
import HeaderCreateTeaTogetherPage from './component/HeaderCreateTeaTogetherPage'

const categoryData = [
  {
    code: 'TEA_CLASS',
    icon: '👩🏻‍🏫',
    title: '티클래스',
    description: '전문가와 함께 차에 대해 배워가는 클래스의 형태에요.'
  },
  {
    code: 'TEA_SOCIALING',
    icon: '🍵',
    title: '다회(차회)/차모임',
    description: '전문가와 함께 차에 대해 배워가는 클래스의 형태에요.'
  },
  {
    code: 'CERTIFICATE',
    icon: '🎖️',
    title: '자격증',
    description: '차에 대한 전문 자격증을 취득하는 교육을 제공해요.'
  },
  {
    code: 'MEDITATION',
    icon: '🧎🏻',
    title: '명상',
    description: '전문가와 함께 차에 대해 배워가는 클래스의 형태에요.'
  },
  {
    code: 'YOGA',
    icon: '🧘🏻',
    title: '요가',
    description: '전문가와 함께 차에 대해 배워가는 클래스의 형태에요.'
  },
  {
    code: 'TEA_PAIRING',
    icon: '🍮',
    title: '티페어링',
    description: '전문가와 함께 차에 대해 배워가는 클래스의 형태에요.'
  }
]

//커스터마이징 텍스트 함수
export function getTitleFromCategoryCode (categoryCode: string) {
  const category = categoryData.find((item) => item.code === categoryCode)
  return category ? category.title : '티클래스' // returns the title if found, otherwise null
}
function feeTextFromCategoryCode (categoryCode: string) {
  switch (categoryCode){
    case 'TEA_CLASS':
      return '수업료'
    case 'TEA_SOCIALING':
      return '참여비'
    case 'CERTIFICATE':
      return '수업료'
    case 'MEDITATION':
      return '참여비'
    case 'YOGA':
      return '참여비'
    case 'TEA_PAIRING':
      return '참여비'
    default:
      return '참여비'

  }
}
export function hostNameFromCategoryCode (categoryCode: string) {
  switch (categoryCode){
    case 'TEA_CLASS':
      return '선생님'
    case 'TEA_SOCIALING':
      return '진행자(팽주)'
    case 'CERTIFICATE':
      return '선생님'
    case 'MEDITATION':
      return '선생님'
    case 'YOGA':
      return '선생님'
    case 'TEA_PAIRING':
      return '진행자(팽주)'
    default:
      return '진행자(팽주)'
  }
}
export function classNameFromCategoryCode (categoryCode: string) {
  switch (categoryCode){
    case 'TEA_CLASS':
      return '수업'
    case 'TEA_SOCIALING':
      return '모임'
    case 'CERTIFICATE':
      return '수업'
    case 'MEDITATION':
      return '모임'
    case 'YOGA':
      return '모임'
    case 'TEA_PAIRING':
      return '모임'
    default:
      return '모임'
  }
}

export type TimesType = { startAt: string; endAt: string }[];

export interface TeaTogetherType {
  type: 'ONEDAY' | 'SERIES' | 'BANNER';
  category: string;
  title: string;
  summary: string;
  description: string;
  seats: number;
  participationFee: number;
  minimumSeats: number;
  location: string;
  locationLocal: string;
  city: string;
  cityLocal: string;
  subCity: string;
  subCityLocal: string;
  specificLocation: string;
  hostContact: string;
  hostName: string;
  hostDescription: string;
  externalUrl: string;
  recruitmentStartBefore: number;
  recruitmentCloseBefore: number;
  teahouseId: number;
  images: string[];
  times: TimesType;
}

const TeaTogetherCreatePage = () => {
  const tabs = ['유형 선택', '기본 정보', '상세 정보', '장소', '일시']
  const [contentFilled, setContentFilled] = useState([true, false, false, false, false])
  const { mutate: postUploadFiles } = usePostUploadFiles()
  const { mutate: postTeaTogether } = usePostTeaTogether()
  const navigate = useNavigate()
  const [teaTogetherData, setTeaTogetherData] = useState<TeaTogetherType>({
    type: 'ONEDAY',
    category: 'TEA_CLASS',
    title: '',
    summary: '',
    description: '',
    seats: 0,
    minimumSeats: 0,
    location: '',
    locationLocal: '',
    city: '',
    cityLocal: '',
    subCity: '',
    subCityLocal: '',
    specificLocation: '',
    hostContact: '',
    hostName: '',
    hostDescription: '',
    participationFee: 0,
    externalUrl: '',
    recruitmentStartBefore: -1,
    recruitmentCloseBefore: -1,
    teahouseId: -1,
    images: [],
    times: []
  })
  const updateTeaTogetherData = useCallback((newData: Partial<TeaTogetherType>) => {
    setTeaTogetherData((prevData) => ({
      ...prevData,
      ...newData
    }))
  }, [])
  const [imageFiles, setImageFiles] = useState<FileArrayState>([])
  const updateTeaTogetherImage = useCallback((data: FileArrayState) => {
    setImageFiles(data)
  }, [])
  const content = [
    <SubPage1
      teaTogetherData={teaTogetherData}
      updateTeaTogetherData={updateTeaTogetherData}
      key={1} />,
    <SubPage2
      teaTogetherData={teaTogetherData}
      updateTeaTogetherData={updateTeaTogetherData}
      key={2} />,
    <SubPage3
      teaTogetherData={teaTogetherData}
      setImageFiles={updateTeaTogetherImage}
      updateTeaTogetherData={updateTeaTogetherData}
      key={3} />,
    <SubPage4
      teaTogetherData={teaTogetherData}
      updateTeaTogetherData={updateTeaTogetherData}
      key={4} />,
    <SubPage5
      teaTogetherData={teaTogetherData}
      updateTeaTogetherData={updateTeaTogetherData}
      key={5} />
  ]
  const [currentIndex, setCurrentIndex] = useState(0)
  const createHandler = () => {
    if (imageFiles){
      try {
        postUploadFiles(imageFiles,
          {
            onError: (error) => {
              if (isError(error)){
                const message = error?.response?.data?.message || 'Error'
                notifyRenewal({ message, type: ToastType.ERROR })
              }
            },
            onSuccess: (data) => {
              notifyRenewal({ message: '이미지 업로드를 성공하였습니다. 이제 모임을 생성합니다', type: ToastType.INFORMATION })
              const updatedData = { ...teaTogetherData, images: data.images }
              postTeaTogether(updatedData, {
                onError: (error) => {
                  if (isError(error)){
                    const message = error?.response?.data?.message || 'Error'
                    notifyRenewal({ message, type: ToastType.ERROR })
                  }
                },
                onSuccess: (data) => {
                  notifyRenewal({ message: '이미지 업로드를 성공하였습니다. 이제 모임을 생성합니다', type: ToastType.INFORMATION })
                  navigate(`${data.id}/complete`, { replace: true })
                }
              })
            }
          }
        )
      } catch (error) {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      }
    }
  }
  useEffect(() => {
    const updatedContentFilled = [...contentFilled]

    // 기본 정보 탭 상태 업데이트
    updatedContentFilled[1] = teaTogetherData.title.length > 5 &&
                              teaTogetherData.summary.length > 5 &&
                              teaTogetherData.seats > 0 &&
                              teaTogetherData.participationFee >= 0 &&
                              teaTogetherData.minimumSeats >= 1

    // 상세 정보 탭 상태 업데이트
    updatedContentFilled[2] = teaTogetherData.description.length > 10 && !!imageFiles

    // 장소 탭 상태 업데이트
    updatedContentFilled[3] = teaTogetherData.specificLocation.length > 1 &&
                              teaTogetherData.hostContact.length > 1 &&
                              teaTogetherData.hostDescription.length > 1 &&
                              teaTogetherData.hostName.length > 0 &&
                              !!teaTogetherData.location

    // 일시 탭 상태 업데이트
    updatedContentFilled[4] = teaTogetherData.times.length > 0

    setContentFilled(updatedContentFilled)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaTogetherData, imageFiles])

  return (
    <PageWrapperCentered>
      <HeaderCreateTeaTogetherPage
        title='모임 만들기'
        create={createHandler}
        contentFilled={contentFilled} />
      <button onClick={() => console.log(teaTogetherData)}>데이터 확인하기</button>
      <SliderButtonWrapper>
        {tabs.map((tab, index) => {
          return (
            <SliderButton
              key={index}
              onClick={() => setCurrentIndex(index)}
              currentIndex={index == currentIndex}>
              {tab}
              {contentFilled[index] && <IcCheckmarkOutline
                fill='#01c897'
                width={24}
                height={24} />}
            </SliderButton>
          )
        })}
      </SliderButtonWrapper>
      <MainTabSlider
        tabs={tabs}
        content={content}
        currentIndex={currentIndex}
        onSlideChange={(index: number) => setCurrentIndex(index)} />
    </PageWrapperCentered>
  )
}
export default TeaTogetherCreatePage

const PageWrapperCentered = styled(PageWrapper)`
  padding: 60px 0px 0px 0px; 
  align-items: center;
  gap: 10px;
`
/* Slick Button Nav */
const SliderButtonWrapper = styled.div`
  width : 100%;
  padding : 10px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
`
const SliderButton = styled.div<{ currentIndex: boolean }>`
  color: ${ ({ currentIndex, theme }) => currentIndex ? theme.mainGreen : theme.black};
  font-weight: ${ ({ currentIndex }) => currentIndex ? '800' : '500'};
  font-size: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`
interface SubPageType {
  teaTogetherData: TeaTogetherType;
  updateTeaTogetherData: (newData: Partial<TeaTogetherType>) => void;
}
interface SubPage3Type {
  teaTogetherData: TeaTogetherType;
  setImageFiles: (data: FileArrayState) => void;
  updateTeaTogetherData: (newData: Partial<TeaTogetherType>) => void;
}
const SubPage1 = ({ teaTogetherData, updateTeaTogetherData }: SubPageType) => {
  return (
    <SubPageWrapper>
      <Title
        title='모임 유형을 선택해주세요'
        subtitle='티클래스, 다회, 차모임, 축제 등 다양한 모임 유형을 선택해요' />
      <CategoryItemListSection>
        <CategoryItemListWrapper>
          {categoryData.map((data, index) => {
            return (
              <CategoryItemWrapper
                key={index}
                clicked={data.code == teaTogetherData.category}
                onClick={() => updateTeaTogetherData({ category: data.code })}>
                <CategoryItemIcon>{data.icon}</CategoryItemIcon>
                <CategoryItemTitle>{data.title}</CategoryItemTitle>
                <CategoryItemDescription>{data.description}</CategoryItemDescription>
              </CategoryItemWrapper>
            )
          })}
        </CategoryItemListWrapper>
      </CategoryItemListSection>
    </SubPageWrapper>
  )
}
//카테고리 선택

const CategoryItemListSection = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  padding: 0px 10px;
  flex-direction: row;
  justify-content: center;
`
const CategoryItemListWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
`
const CategoryItemWrapper = styled.div<{ clicked: boolean }>`
  width: 45%;
  min-width: 130px;
  max-width : 300px;
  border: ${({ theme, clicked }) => clicked ? `2px solid ${theme.mainGreen}` : `1px solid ${theme.black}`};
  background-color: ${({ theme, clicked }) => clicked ? `${theme.lightGreen01}` : `${theme.white}`};
  border-radius: 10px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  aling-items: center;
`
const CategoryItemIcon = styled.div`
  font-size: 40px;
  text-align: center;
`
const CategoryItemTitle = styled.div`
  font-weight: 800;
`
const CategoryItemDescription = styled.div`
`

const SubPage2 = ({ teaTogetherData, updateTeaTogetherData }: SubPageType) => {
  function titleHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ title: e.target.value })
  }
  function summaryHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ summary: e.target.value })
  }
  function seatsHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ seats: Number(e.target.value) })
  }
  function minimumSeatsHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ minimumSeats: Number(e.target.value) })
  }
  function participationFeeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ participationFee: Number(e.target.value) })
  }
  return (
    <SubPageWrapper>
      <CategoryBadge>{getTitleFromCategoryCode(teaTogetherData.category)}</CategoryBadge>
      <Title
        title='기본정보'
        subtitle={`${getTitleFromCategoryCode(teaTogetherData.category)}에 필요한 기본 정보를 입력해요`} />
      <InputTitle>{`${getTitleFromCategoryCode(teaTogetherData.category)} 제목`}</InputTitle>
      <Input onChange={titleHandler} />
      <InputTitle>한 줄 설명</InputTitle>
      <Input onChange={summaryHandler} />
      <InputTitle>참여 인원</InputTitle>
      <Row>
        <Input
          type='number'
          onChange={seatsHandler} />
        명
      </Row>
      <InputTitle>최소 참여 인원</InputTitle>
      <Row>
        <Input
          type='number'
          onChange={minimumSeatsHandler} />
        명
      </Row>
      <InputTitle>{feeTextFromCategoryCode(teaTogetherData.category)}</InputTitle>
      <Row>
        <Input
          type='number'
          onChange={participationFeeHandler} />
        원
      </Row>
    </SubPageWrapper>
  )
}

type FileArrayState = File[] | null
const SubPage3 = ({ teaTogetherData, setImageFiles, updateTeaTogetherData }: SubPage3Type) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  //type FileListState = FileList | null
  const [selectedFiles, setSelectedFiles] = useState<FileArrayState>(null)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files) // FileList를 File[]로 변환
      setSelectedFiles(fileArray)
    }
  }
  const handleImageRemove = (indexToRemove: number) => {
    if (selectedFiles) {
      const updatedFiles = Array.from(selectedFiles).filter((_, index) => index !== indexToRemove)
      setSelectedFiles(updatedFiles)
    }
  }
  const setDescriptionData = useCallback((description: string) => {
    updateTeaTogetherData({ description: description })
  }, [updateTeaTogetherData])
  useEffect(() => {
    setImageFiles(selectedFiles)
  }, [selectedFiles, setImageFiles])
  return (
    <SubPageWrapper>
      <CategoryBadge>{getTitleFromCategoryCode(teaTogetherData.category)}</CategoryBadge>
      <Title
        title='상세정보'
        subtitle={`${getTitleFromCategoryCode(teaTogetherData.category)}에 대한 상세한 설명과 필요한 사진을 입력해요`} />
      <InputTitle> 사진</InputTitle>
      <ImageInputWrapper>
        <ImageUploadLabel htmlFor="imageInput">사진 올리기</ImageUploadLabel>
        <ImageInput
          id="imageInput"
          type="file"
          onChange={handleFileChange}
          name='images'
          multiple
          ref={fileInputRef} />
      </ImageInputWrapper>
      <ImagePreviewWrapper>
        {selectedFiles && selectedFiles.map((file, index) => (
          <ImagePreviewItemWrapper key={index}>
            {file && <ImagePreview file={file} />}
            {/* <ImagePreviewBlock
              src={URL.createObjectURL(file)}
              alt={`Preview ${index + 1}`}
              loading='lazy'
            /> */}
            <IconWrapper>
              <IcCloseOutline
                fill='#01c897'
                width='24px'
                onClick={() => handleImageRemove(index)} />
            </IconWrapper>
          </ImagePreviewItemWrapper>
        ))}
      </ImagePreviewWrapper>
      <InputTitle>{`${getTitleFromCategoryCode(teaTogetherData.category)} 상세내용`}</InputTitle>
      <ResizableTextArea setData={setDescriptionData} />
    </SubPageWrapper>
  )
}
interface ImagePreviewType {
  file: any;
}
const ImagePreview = ({ file }: ImagePreviewType) => {
  const [previewUrl, setPreviewUrl] = useState('')

  useEffect(() => {
    const objectUrl = URL.createObjectURL(file)
    setPreviewUrl(objectUrl)

    // 메모리 관리
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  return (
    <ImagePreviewBlock
      src={previewUrl}
      alt="Preview"
      loading="lazy" />
  )
}
const ImageInputWrapper = styled.div`
`
const ImageInput = styled.input`
  display: none;
`
const ImageUploadLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #01c897;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #019e76;
  }
`
const ImagePreviewBlock = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #01c897;
  border-radius: 20px;
  object-fit: cover;
`
const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`
const ImagePreviewItemWrapper = styled.div`
  position: relative;
  width: 30%;
  max-width: 150px;
`
const IconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`

interface SearchedTeahouseType {
  keyword: string;
}

const SubPage4 = ({ teaTogetherData, updateTeaTogetherData }: SubPageType) => {
  const [teahouseSearchClicked, setTeahouseSearchClicked] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [searched, setSearched] = useState(false)
  const [teahouseName, setTeahouseName] = useState('')
  function openPostcode() {
    setTeahouseSearchClicked(false)
    new window.daum.Postcode({
      oncomplete: function(data: any) {
        updateTeaTogetherData({ locationLocal: data.address, location: data.addressEnglish, city: data.sidoEnglish, cityLocal: data.sido, subCity: data.sigunguEnglish, subCityLocal: data.sigungu })
      }
    }).open()
  }
  function specificAddressHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ specificLocation: e.target.value })
  }
  function hostContactHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ hostContact: e.target.value })
  }
  function hostNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
    updateTeaTogetherData({ hostName: e.target.value })
  }
  function hostDescriptionHandler(e: string) {
    updateTeaTogetherData({ hostDescription: e })
  }
  const SearchedTeahouse = React.memo(({ keyword }: SearchedTeahouseType) => {
    const { data: teahouses } = useSearchTeahouses(keyword)
    return (
      <SearchedTeahouseListWrapper>
        {teahouses?.map((teahouse, index) => {
          return (
            <SearchedTeahouseWrapper
              key={index}
              onClick={() => {
                setSearched(false)
                setTeahouseSearchClicked(false)
                setTeahouseName(teahouse.name)
                updateTeaTogetherData({ locationLocal: teahouse.locationLocal, location: teahouse.location, city: teahouse.city, cityLocal: teahouse.cityLocal, subCity: teahouse.subCity, subCityLocal: teahouse.subCityLocal, teahouseId: teahouse.id })
              }}>
              <b>{teahouse.name}</b>
              <>{'  '}</>
              {teahouse.locationLocal}
            </SearchedTeahouseWrapper>
          )
        })}
      </SearchedTeahouseListWrapper>
    )
  })
  SearchedTeahouse.displayName = 'SearchedTeahouse'
  return (
    <SubPageWrapper>
      <CategoryBadge>{getTitleFromCategoryCode(teaTogetherData.category)}</CategoryBadge>
      <Title
        title='장소'
        subtitle={`${getTitleFromCategoryCode(teaTogetherData.category)}를 하는 구체적인 장소에 대한 정보를 입력해요`} />
      <InputTitle>장소</InputTitle>
      <TeahouseSearchButton onClick={() => setTeahouseSearchClicked(true)}>찻집으로 검색</TeahouseSearchButton>
      {teahouseSearchClicked && <Input
        placeholder='찻집 이름 검색'
        onChange={(e) => {
          setSearched(false)
          setKeyword(e.target.value)
        }} />}
      {teahouseSearchClicked && <SearchButton onClick={() => setSearched(true)}>검색하기</SearchButton>}
      {searched && <SearchedTeahouse keyword={keyword} />}
      {(teahouseName.length >= 1) && <TeahouseName>🏡 {teahouseName} <IcClose
        onClick={() => {
          setTeahouseName('')
          updateTeaTogetherData({ teahouseId: -1 })
        }}
        fill='#aaa'
        width={20}
        height={20} /></TeahouseName>}
      <PostCodeButton onClick={openPostcode}>우편번호 검색</PostCodeButton>
      { teaTogetherData?.location && <PostAddressWrapper>{teaTogetherData?.locationLocal}</PostAddressWrapper>}
      { teaTogetherData?.location && <Input
        placeholder='상세주소 입력'
        onChange={specificAddressHandler} />}
      <InputTitle>{hostNameFromCategoryCode(teaTogetherData.category)} 이름</InputTitle>
      <Input onChange={hostNameHandler} />
      <InputTitle>{hostNameFromCategoryCode(teaTogetherData.category)} 소개</InputTitle>
      <ResizableTextArea setData={hostDescriptionHandler} />
      <InputTitle>문의 번호(- 제외)</InputTitle>
      <Input
        type='number'
        onChange={hostContactHandler} />
    </SubPageWrapper>
  )
}
export const PostAddressWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.gray2};
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 400px;
`
export const PostCodeButton = styled.button`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #01c897;
  border-radius: 5px;
  max-width: 200px;
  color: #fff;
  `
const TeahouseSearchButton = styled(PostCodeButton)`
  background-color: ${({ theme }) => theme.information};
  `
const SearchButton = styled.button`
  background-color: #ccc;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SearchedTeahouseListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 300px;
`
const SearchedTeahouseWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc
`
const TeahouseName = styled.div`
  font-weight: 700;
  color: #01c897;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

const SubPage5 = ({ teaTogetherData, updateTeaTogetherData }: SubPageType) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  return (
    <SubPageWrapper>
      <CategoryBadge>{getTitleFromCategoryCode(teaTogetherData.category)}</CategoryBadge>
      <Title
        title='일시'
        subtitle={`${getTitleFromCategoryCode(teaTogetherData.category)}를 하는 날짜 및 시간을 입력해요`} />
      <Calendar
        updateTeaTogetherData={updateTeaTogetherData}
        month={currentMonth}
        year={currentYear} />
    </SubPageWrapper>
  )
}

const SubPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const InputTitle = styled.div`
  font-weight: 700;
`
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.gray2 };
  border-radius: 10px;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  height: 35px;
  padding: 20px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.mainGreen };
    outline: none; // this is optional, to remove the default browser outline on focus
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const AutoResizingTextArea = styled.textarea`
  width : 100%;
  min-width: 200px;
  max-width: 500px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.gray2 };
  border-radius: 10px;
  height: auto;
  &:focus {
    border: 2px solid ${({ theme }) => theme.mainGreen };
    outline: none; // this is optional, to remove the default browser outline on focus
  }
  resize: none; // 브라우저에서 제공하는 resize 핸들러를 제거
`
interface ResizableTextAreaType {
  setData: (value: React.SetStateAction<any>) => void;
}
export const ResizableTextArea = ({ setData }: ResizableTextAreaType) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const [content, setContent] = useState('')

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textArea = textAreaRef.current
      const newValue = e.target.value
      setContent(newValue)
      setData(newValue)
      if (textArea) {
        textArea.style.height = 'auto'
        textArea.style.height = `${textArea.scrollHeight}px`
      }
    },
    [setData])

  return (
    <AutoResizingTextArea
      ref={textAreaRef}
      value={content}
      onChange={handleChange}
    />
  )
}
