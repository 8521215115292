import { useState } from 'react'

import { PostConfirmSMSRequestType, PostConfirmSMSResponseType } from '@/apis/ncp/postConfirmSMS'
import { postConfirmSMSForReset } from '@/apis/ncp/postConfirmSMSForResetPassword'

export type UsePostConfirmSMSReturnType = {
  isLoading: boolean;
  error: string;
  passed: boolean;
  confirmSMS: (confirmSMSData: PostConfirmSMSRequestType) => Promise<void>;
};

export const useConfirmSMS = (confirmSMSData: PostConfirmSMSRequestType): UsePostConfirmSMSReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [passed, setPassed] = useState(false)
  const confirmSMS = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setError('')
      const response = await postConfirmSMSForReset(confirmSMSData)
      if (response) {
        const { validated }: PostConfirmSMSResponseType = response
        console.log('success : ', validated)
        setPassed(validated)
      }
    } catch (error) {
      setError('SMS 인증에 실패하였습니다.')
    } finally {
      setIsLoading(false)
    }
  }
  return { isLoading, error, passed, confirmSMS }
}
