import { Methods, request } from '@/libs/request'

export type updateParticipatorRequestType = {
  festivalId: number;
  participatorId: number;
  name?: string;
  description?: string;
  tag?: string;
  boothNumber?: string;
  handledTea?: string;
  country?: string;
}

export const updateParticipator = async({ festivalId, participatorId, name, description, tag, boothNumber, handledTea, country }: updateParticipatorRequestType) => request<void>({
  url: `/festival/${festivalId}/participator/${participatorId}`,
  method: Methods.PATCH,
  data: { name, description, tag, boothNumber, handledTea, country }
})
