import { ReactNode, useState } from 'react'

import { SignUpContext, User } from '@/context/SignUpContext'

const SignUpProvider = ({ children }: { children?: ReactNode }) => {
  const [user, setUser] = useState<User>({
    email: '',
    password: '',
    nickname: '',
    phoneNumber: '',
    realName: '',
    birth: '',
    code: 82,
    termCodes: []
  })
  const [step, setStep] = useState(0)
  const [error, setError] = useState('')
  return (
    <SignUpContext.Provider value={{ user, setUser, step, setStep, error, setError }}>
      { children }
    </SignUpContext.Provider>
  )
}
export default SignUpProvider