import { useQuery } from 'react-query'

import { getMakerInfo } from '@/apis/maker/getMakerInfo'

const getQueryKey = () => ['maker/info']

/* TODO: 타입논의 및 인증기반 정보조회 논의 필요 */
export default function useMakerInfo() {
  return useQuery(getQueryKey(), async () => {
    const user = await getMakerInfo()
    return user
  })
}
