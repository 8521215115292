import { useQuery } from 'react-query'

import { getTeaTogehtersOfTeahouse as getTeaTogethersOfTeahouse } from '@/apis/teahouse/getTeaTogethersOfTeahouse'

const getQueryKey = (id: number) => ['teahouse', id, 'tea-together']

export default function useTeaTogethersOfTeahouse(id: number){
  return useQuery(
    getQueryKey(id),
    async () => {
      const teaTogethers = await getTeaTogethersOfTeahouse(id)
      return teaTogethers
    }
  )
}
