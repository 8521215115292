import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { IcLocation } from '@/components/Icons'

interface MapInfoType {
  location: string;
  teahouse: string;
  latitude: string;
  longtitude: string;
}
const LocationInfo = ({ location, teahouse, latitude, longtitude }: MapInfoType) => {
  const navigate = useNavigate()
  console.log(latitude)
  console.log(longtitude)
  const buttonHandler = () => {
    navigate(`/map?lat=${latitude}&lon=${longtitude}`)
  }
  return (
    <LocationInfoWrapper>
      <IcLocation
        width={24}
        height={24} />
      <ContentWrapper>
        <Title>{teahouse}</Title>
        <Location>{location}</Location>
      </ContentWrapper>
      <GoToButton onClick={buttonHandler}>지도 보기</GoToButton>
    </LocationInfoWrapper>
  )
}

export default LocationInfo

const LocationInfoWrapper = styled.div`
  width: 100%;
  max-width: 400px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`
const Location = styled.div`
font-size: 14px;
font-weight: 400;
`
const GoToButton = styled.div`
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #01c897;
  color: #fff;
`
