import styled from '@emotion/styled'
import { useState } from 'react'

// import Slider from 'react-slick'
import { Sub2, Sub3 } from '@/components/Base/Text'
import { notifyRenewal } from '@/components/Common/ToastRenewal'
// import { IcSearch } from '@/components/Icons'
import { PageWrapper } from '@/components/Layout/frame/Frame'
import ToastType from '@/enums/toast'
import useParticipatorOfFestival from '@/hooks/useParticipatorOfFestival'
import usePostParticipator from '@/hooks/usePostParticipator'
import { isError } from '@/libs/request'

const FestivalAdminPage = () => {
  const [approved, setApproved] = useState(true)
  if (!approved) return <PasswordPage setApproved={setApproved} />
  return <FestivalAdminDetailPage />
}
export default FestivalAdminPage
interface PasswordPageProps {
  setApproved: React.Dispatch<React.SetStateAction<boolean>>;
}
const PasswordPage = ({ setApproved }: PasswordPageProps) => {
  const [value, setValue] = useState('')
  const InputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }
  const clickButton = () => {
    if (value == 'daru0916admin') setApproved(true)
    else notifyRenewal({ message: '비밀번호가 틀렸습니다.', type: ToastType.ERROR })
  }
  return (
    <PageWrapperCentered>
      <Title>Password</Title>
      <Input
        type='password'
        placeholder='비밀번호 입력'
        value={value}
        onChange={InputHandler} />
      <Button onClick={clickButton}> 확인 </Button>
    </PageWrapperCentered>
  )
}
const FestivalAdminDetailPage = () => {
  const [companyName, setCompanyName] = useState('')
  const [boothNumber, setBoothNumber] = useState<number>(1)
  const [boothDescription, setBoothDescription] = useState('')
  const [tea, setTea] = useState('')
  const [country, setCountry] = useState<'KR' | 'CN' | 'JP' | 'LK' | 'TW'>('KR')
  const handleBoothNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 입력된 값이 숫자인 경우에만 상태를 업데이트
    if (/^\d*$/.test(e.target.value)) {
      setBoothNumber(Number(e.target.value))
    }
  }
  const { mutate: postParticipator } = usePostParticipator()
  const clickHandler = () => {
    postParticipator({ festivalId: 1, name: companyName, boothNumber, description: boothDescription, handledTea: tea, country, tag: 'GYEONGJU_2023' }, {
      onError: (error) => {
        if (isError(error)){
          const message = error?.response?.data?.message || 'Error'
          notifyRenewal({ message, type: ToastType.ERROR })
        }
      },
      onSuccess: (data) => {
        const messageString = `Created : #${data.boothNumber} ${data.name} (${data.participatorId})`
        notifyRenewal({ message: `${messageString}`, type: ToastType.INFORMATION })
      }
    })
  }
  return (
    <PageWrapperCentered>
      <Title>업체 이름</Title>
      <Input
        placeholder='업체 이름 입력'
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)} />

      <Title>부스번호</Title>
      <Input
        type='number'
        placeholder='부스 번호 입력'
        value={boothNumber.toString()}
        onChange={handleBoothNumberChange} />

      <Title>부스설명</Title>
      <TextArea
        placeholder='부스 설명 입력'
        value={boothDescription}
        onChange={(e) => setBoothDescription(e.target.value)} />

      <Title>준비된 차</Title>
      <Input
        placeholder='차(Tea) 입력'
        value={tea}
        onChange={(e) => setTea(e.target.value)} />

      <Title>국가 : {country}</Title>
      <CountryButtonWrapper>
        <CountryButton onClick={() => setCountry('KR')}>🇰🇷 KR</CountryButton>
        <CountryButton onClick={() => setCountry('CN')}>🇨🇳 CN</CountryButton>
        <CountryButton onClick={() => setCountry('JP')}>🇯🇵 JP</CountryButton>
        <CountryButton onClick={() => setCountry('LK')}>🇱🇰 LK</CountryButton>
        <CountryButton onClick={() => setCountry('TW')}>🇹🇼 TW</CountryButton>
      </CountryButtonWrapper>

      <Title>Tag : GYENGJU_2023</Title>
      <Title>festivalId : 1</Title>
      <Button onClick={clickHandler}>참여 단체 만들기</Button>
      <Search />
    </PageWrapperCentered>
  )
}
/* Page */
const PageWrapperCentered = styled(PageWrapper)`
  padding: 50px 0px 0px 0px; 
  align-items: center;
`

/* Input */
const Title = styled(Sub2)`
  color: #01c897;
`
const Input = styled.input`
  width: 300px;
  height: 50px;
  font-size: 20px;
  padding: 10px 10px;
  border: 1px solid #01c897;
  border-radius: 10px;
`
const TextArea = styled.textarea`
  width: 300px;
  height: 300px;
  font-size: 20px;
  padding: 10px 10px;
  border: 1px solid #01c897;
  border-radius: 10px;
  resize: vertical;
`
const CountryButtonWrapper = styled.div`

  padding: 10px 10px;
  border: 1px solid #01c897;
  border-radius: 10px;
  display: flex;
  gap: 10px;
`
const CountryButton = styled.button`
&:active {
  background: #01c897;
  color: #fff;
}
`
const Button = styled.button`
  background: #01c897;
  color: #fff;
  width: 200px;
  height: 50px;
  border-radius: 20px;
  font-size: 20px;
  border-bottom: 1px solid #01c897;

`

/* Search Wrapper */
const Search = () => {
  return (
    <SearchWrapper>
      <SearchTitle>부스 이름을 확인하세요</SearchTitle>
      <SearchOutcomes />
    </SearchWrapper>
  )
}
const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 8px 16px;
`
const SearchTitle = styled(Sub3)`
  color: ${((props) => props.theme.gray4)};
  font-weight: 300;
`
/* Search Outcome Wrapper */
const SearchOutcomes = () => {
  const { data: participators } = useParticipatorOfFestival(1)
  console.log('😀🍵', participators)
  const sortedParticipators = participators?.slice().sort((a, b) => b.boothNumber - a.boothNumber)
  return (
    <SearchOutcomeListWrapper>
      {
        sortedParticipators?.map((participator, index) => {
          return (
            <SearchOutcomeItem
              key={index}
              number={participator.boothNumber}
              nation={participator.nation}
              name={participator.name}
              tea={participator.handledTea} />
          )
        })
      }
    </SearchOutcomeListWrapper>
  )
}
const SearchOutcomeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
interface SearchOutcomeItem {
  number: number;
  nation: string;
  name: string;
  tea: string;
}
const SearchOutcomeItem = ({ number, nation, name, tea }: SearchOutcomeItem) => {
  const nationFlag = () => {
    if (nation == 'CN') return '🇨🇳'
    else if (nation == 'LK') return '🇱🇰'
    else if (nation == 'JP') return '🇯🇵'
    else if (nation == 'TW') return '🇹🇼'
    else return '🇰🇷'
  }
  return (
    <SearchOutcomeListItemWrapper>
      <ItemText>{number}</ItemText>
      <ItemNation>{nationFlag()}</ItemNation>
      <ItemText>{name}</ItemText>
      <ItemText>{tea}</ItemText>
    </SearchOutcomeListItemWrapper>
  )
}

const SearchOutcomeListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  background: #fff;
  align-items: center;
  cursor: pointer;
  border: 1px solid #01c897;
  border-radius: 5px;
  padding: 10px;
`
const ItemText = styled.div`
  font-size: 16px;
  color: black;
  font-weight: 700;
`
const ItemNation = styled.span`
  font-size: 20px;
  color: black;
`
