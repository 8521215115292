import { Methods, request } from '@/libs/request'

export type GetSettlementListResponseType = {
  id: string;
  date: string;
  title: string;
  description: string;
  price: number;
}[]

export const getSettlementList = () => {
  return request<GetSettlementListResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    url: '/mocks/api/getSettlementList.json',
    method: Methods.GET
  })
}