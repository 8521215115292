import { Methods, request } from '@/libs/request'

export type PostConfirmSMSRequestType = {
  phoneNumber: string;
  validationNumber: number;
  code: number;
}
export type PostConfirmSMSResponseType = {
  validated: boolean;
}
export const postConfirmSMS = async ({
  phoneNumber,
  validationNumber,
  code
}: PostConfirmSMSRequestType) => {
  try {
    const response = await request<PostConfirmSMSResponseType>({
      url: 'ncp/validation/phone-number/sign-up',
      method: Methods.POST,
      data: {
        phoneNumber,
        validationNumber,
        code
      }
    })
    return response
  } catch (error) {
    console.error('인증번호가 일치하지 않습니다')
    throw error
  }
}
