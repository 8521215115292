import styled from '@emotion/styled'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CheckIcon from '@/assets/svg/icons/Check.svg'
import NavIcon from '@/assets/svg/icons/navigate_next.svg'
import UnCheckedIcon from '@/assets/svg/icons/unchecked.svg'
import TermsType from '@/enums/termsType'
import useAgreementList from '@/hooks/useAgreementList'

import { CaptionBold } from '../Base/Text'

type AgreementListProps = {
  category: string;
  isAllchecked: (e: boolean) => void;
  setTermsCode: Dispatch<SetStateAction<TermsType[]>>;
}

const AgreementList = ({ category, isAllchecked, setTermsCode }: AgreementListProps) => {
  const { data, isLoading, error } = useAgreementList({ category })
  const [checkedItems, setCheckedItems] = useState<Array<boolean>>([])
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [termsCodeList, setTermsCodeList] = useState<TermsType[]>([])
  const [termsCodeListSelected, setTermsCodeListSelected] = useState<TermsType[]>([])
  //Generating array state for handling  item components
  useEffect(() => {
    if (data) {
      setCheckedItems(data.map(() => false))
      setTermsCodeList(data.map((e) => (e.termsCode)) as TermsType[])
    }
  }, [data])

  useEffect(() => {
    if (checkedItems.length > 0) {
      setAllChecked(checkedItems.every((item) => item))
      const filteredTermsCodeList = checkedItems
        .map((item, index) => item ? termsCodeList[index] : null)
        .filter((item) => item !== null) as TermsType[]
      setTermsCodeListSelected(filteredTermsCodeList)
    }
  }, [checkedItems, termsCodeList])

  useEffect(() => {
    setTermsCode(termsCodeListSelected)
  }, [setTermsCode, termsCodeListSelected])
  //Array of booleans and return agreement list for each index
  const handleCheck = (index: number) => {
    const newCheckedItems = [...checkedItems]
    newCheckedItems[index] = !newCheckedItems[index]
    setCheckedItems(newCheckedItems)
  }
  //Convey whether the agreement lists are all checked
  useEffect(() => {
    isAllchecked(allChecked)
  }, [allChecked, isAllchecked])
  //According to status from API, returning 3 types of element Loading | Error | Normal status
  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div></div>
  }

  if (!data) {
    return <div>No data</div>
  }

  return (
    <div>
      {data.map((agreement, index) => (
        <ListItem
          key={index}
          title={agreement.title}
          checked={checkedItems[index]}
          link={agreement.url}
          onCheck={() => handleCheck(index)}
        />
      ))}
    </div>
  )
}

export default AgreementList
type ListItemProps = {
  title: string;
  checked: boolean;
  link: string;
  onCheck: () => void;
}

const ListItem = ({ title, checked, link, onCheck }: ListItemProps) => {
  return (
    <ItemWrapper>
      <ContentWrapper
        checked={checked}
        onClick={onCheck}>
        <Span
          src={checked ? CheckIcon : UnCheckedIcon}
          checked={checked} />
        <Tag checked={checked}>필수</Tag>
        <Text checked={checked}>{title}</Text>
      </ContentWrapper>
      <Link to={link}>
        <Span
          src={NavIcon}
          checked={checked}
          onClick={() => console.log(link)} />
      </Link>
    </ItemWrapper>
  )
}
const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const ContentWrapper = styled.div<{ checked: boolean }>`
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 8px;
`

const Span = styled.img<{ checked: boolean }>`
  fill: ${(props) => props.checked ? '#01C897' : '#B4B4B4'};
`
const Tag = styled(CaptionBold)<{ checked: boolean }>`
  background-color: ${(props) => props.checked ? '#01C897' : '#ffffff'};
  color: ${(props) => props.checked ? '#FFFFFF' : '#B4B4B4'};
  border: ${(props) => props.checked ? 'none' : '1px solid #B4B4B4' };
  border-radius: 5px;
  width: 35px;
  height: 22px; 
  display: flex;
  text-align: center;
  line-height: 16px;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const Text = styled.div<{ checked: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.checked ? '#676767' : '#B4B4B4'};
  width: 200px
`
