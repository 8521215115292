import styled from '@emotion/styled'

const DescriptionWrapper = styled.div`
  padding: 10px 0px;
  white-space: pre-wrap;
  min-width: 60%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.mainGreen };
`
interface DescriptionType {
  content: string;
}

const Description = ({ content }: DescriptionType) => {
  return (
    <DescriptionWrapper>
      {`"${content}"`}
    </DescriptionWrapper>
  )
}
export default Description
