import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { GetTeaTogethersForCityResponseType } from '@/apis/tea-together/getTeaTogethersForCity'
import appLink from '@/businees/app_link'

import RegionTag, { Region } from './RegionTag'

type TeaTogetherBestSliderProps = {
  teaTogethers: GetTeaTogethersForCityResponseType | undefined;
};

const TeaTogetherBestSlider = ({ teaTogethers }: TeaTogetherBestSliderProps) => {
  const [sliderLoaded, setSliderLoaded] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setSliderLoaded(true)
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1800, // Web screen
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplaySpeed: 2500
        }
      },
      {
        breakpoint: 1300, // Tablet screen
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplaySpeed: 3000
        }
      },
      {
        breakpoint: 750, // Mobile screen
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 2500
        }
      }
    ]
  }

  return (
    <SliderWrapper>
      {sliderLoaded && (
        <Slider {...settings}>
          {teaTogethers?.map((teaTogether) => (
            <SliderImageWrapper
              key={teaTogether.id}
              backgroundImage={teaTogether.profileImage}
              onClick={() => navigate(`/tea-together/${teaTogether.id}`)}
            >
              <Overlay />
              <RegionWrapper>
                <RegionTag region={teaTogether.city as Region} />
              </RegionWrapper>
              <ContentWrapper>
                <Title>{teaTogether.title}</Title>
                <Content>{teaTogether.summary}</Content>
              </ContentWrapper>
            </SliderImageWrapper>
          ))}
          <ImageHero
            onClick={() => window.open(appLink)}
            backgroundImage='/hero/we_are_opened.png' />
        </Slider>
      )}
    </SliderWrapper>
  )
}

export default TeaTogetherBestSlider

const SliderWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`

const SliderImageWrapper = styled.div<{ backgroundImage: string }>`
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15); /* Dark overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  z-index: 2;
  text-align: center;
`

const Title = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
`

const Content = styled.div`
  color: #fff;
  font-weight: 100;
  font-size: 1.25rem;
`

const RegionWrapper = styled.div`
  padding: 20px;
  position: relative;
  z-index: 5;
`

const ImageHero = styled.div<{ backgroundImage: string }>`
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`
