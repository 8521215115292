import { Methods, request } from '@/libs/request'
import { TimetableType } from '@/pages/settings/SettingPage'
import { getAccessToken } from '@/utils/jwt'

export type UserSettingType = { id: number; nickname: string; handle: string; description: string; profileImage: string };
export type MakerSettingType = { id: number; nickname: string; profileImage: string } | null;
export type TeahouseSettingType = {
  id: number;
  name: string;
  description: string;
  location: string;
  specificLocation: string;
  city: string;
  subCity: string;
  latitude: string;
  longitude: string;
  times: TimetableType;
  menus: {
    id: number;
    category: string;
    name: string;
    description: string;
    image: string;
    price: number;
  }[];
}
export type TeahousesSettingType = TeahouseSettingType[];
export type GetMySettingsType = {
  user: UserSettingType;
  maker: MakerSettingType;
  ownedTeahouses: TeahousesSettingType;
}

export const getMySettings = () => {
  return request<GetMySettingsType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: '/user/setting',
    method: Methods.GET,
    headers: { 'Authorization': `Bearer ${getAccessToken()}` }
  })
}
