import { Methods, request } from '@/libs/request'

type validateCancelReservationRequestType = {
  classId: string;
  applicantPhoneNumber: string;
}
type validateCancelReservationResponseType = {
  validated: boolean;
  applicantPhoneNumber: string;
}

/* TODO: 인증확인을 위한 API */
export const validateCancelReservation = async ({ classId, applicantPhoneNumber }: validateCancelReservationRequestType) => {
  return request<validateCancelReservationResponseType>({
    url: 'reservation/validation/cancel',
    // baseURL: process.env.REACT_APP_SERVER_ENDPOINT_LOCAL,
    // url: '/mocks/api/validateCancelReservation.json',
    method: Methods.GET,
    params: { classId, applicantPhoneNumber }
  })
}
