import styled from '@emotion/styled'

import { IcHeart } from '@/components/Icons'

interface LikeType {
  likes: number;
  isLiked: boolean;
  teaTogetherId: number;
  onClick: () => void;
}
const Like = ({ likes, isLiked, onClick }: LikeType) => {
  return (
    <LikeWrapper onClick={onClick}>
      {isLiked && <IcHeart
        width='24px'
        height='20px'
        fill='#01c897' />}
      {!isLiked && <IcHeart
        width='24px'
        height='20px'
        fill='#ddd' />}
      {likes}
    </LikeWrapper>
  )
}
const LikeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  max-width: 100px;
  font-size: 16px;
  gap: 5px;
`
export default Like
