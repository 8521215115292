import styled from '@emotion/styled'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { postWithdrawal } from '@/apis/auth/postWithdrawal'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Body1, Body1Bold, Body2, Body2Bold, H3, H5, Sub1 } from '@/components/Base/Text'
import { CTAButton } from '@/components/Input/CTAButton'
import useWithdrawal from '@/hooks/useWithdrawal'

const APITestPage = () => {
  return (
    <PageBlock>
      <ModuleBlock>
        <H3>API Test</H3>
        <H5>회원가입</H5>
      </ModuleBlock>
    </PageBlock>
  )
}
export default APITestPage

//Withdrawal

type TestType = {
  title: string;
  hook: () => void;
  data1: Record<string, unknown>;
}
/*TODO : 테스트 블록 및 데이터 단위로 API 테스트 하는 페이지 만들기 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TestBlock = ({ title, hook, data1 }: TestType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate, isLoading } = useWithdrawal()
  return (
    <>
      <ModuleBlock>
        <Sub1_m>{title}</Sub1_m>
        <CTAButton
          colorType='green'
          text={`${title} Test`}
          disabled={false}
          onClick={() => mutate}></CTAButton>
      </ModuleBlock>
    </>
  )
}

const PageBlock = styled.div`
  margin-left: 10px;
  overflow: scroll;
  color: ${({ theme }) => theme.black}
`
const ModuleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.gray1};
`
const Sub1_m = styled(Sub1)`
  color: ${({ theme }) => theme.mainGreen};
`

// //Withdrawal User
// const withdrawal_normal = { 'phoneNumber': '01094246901' }
// //SignUp
// const signUp_normal = {
//   'email': 'contact@daruteaworld.com',
//   'password': 'password1234!',
//   'nickname': 'test11123',
//   'phoneNumber': '01094246901',
//   'realName': '이한호',
//   'birth': '911111-1',
//   'code': 82,
//   'termCodes': [
//     'SIGNUP_TERMSOFSERVICE',
//     'SIGNUP_PRIVACYPOLICY'
//   ]
// }
// const phoneNumber_signUp_normal = {
//   'code': 82,
//   'phoneNumber': '01094246901'
// }
// //Login
// const login_normal = {
//   'code': 82,
//   'phoneNumber': '01094246901',
//   'password': 'password1234!'
// }
