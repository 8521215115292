import { Methods, request } from '@/libs/request'

export type GetTastingNotesOfParticipatorType = {
  id: number;
  createdAt: string;
  title: string;
  content: string;
  image: string;
  tag: string;
  tastes: {
    bitterness: number;
    spicy: number;
    sweetness: number;
    astringency: number;
    freshness: number;
    sourness: number;
    nuttiness: number;
  };
}[];

export const getTastingNotesOfParticipator = ({ festivalId, participatorId }: { festivalId: number; participatorId: number }) => {
  return request<GetTastingNotesOfParticipatorType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/festival/${festivalId}/participator/${participatorId}/tasting-note`,
    method: Methods.GET
  })
}
