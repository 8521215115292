import { Methods, request } from '@/libs/request'
import { PostItemInfoModel } from '@/models/post/postItemInfoModel'

export type GetPostsResponseType = PostItemInfoModel[];

export const getPosts = ({ limit, page }: { limit: number; page: number }) => {
  return request<GetPostsResponseType>({
    baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
    url: `/post?limit=${limit}&page=${page}`,
    method: Methods.GET
  })
}

