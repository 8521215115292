import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'

const TabSliderContainer = styled.div`
  background-color: none;
  width: 100%;
  padding : 20px 20px;
`

const TabItem = styled.div`
  // 탭 아이템 스타일을 여기에 추가하세요
  color: #000;
`

interface TabSliderProps {
  tabs: string[];
  content: React.ReactNode[];
  currentIndex: number;
  onSlideChange: (index: number) => void;
}

const MainTabSlider = ({ tabs, content, currentIndex, onSlideChange }: TabSliderProps) => {
  const sliderRef = useRef<any>(null)
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentIndex)
    }
  }, [currentIndex])

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      onSlideChange(index)
    }
  }
  return (
    <TabSliderContainer>
      <Slider
        ref={sliderRef}
        {...settings}>
        {tabs.map((tab, index) => (
          <TabItem key={tab}>
            {content[index]}
          </TabItem>
        ))}
      </Slider>
    </TabSliderContainer>
  )
}

export default MainTabSlider

