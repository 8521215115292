
import styled from '@emotion/styled'
import { Link, useLocation, useParams } from 'react-router-dom'

import { H2, H3, PageBlockCenter, PageImage } from '@/components/Common/Styled'
import Content from '@/components/Layout/Content'
import CTAButton from '@/components/Layout/CTAButton'
import FullLoading from '@/components/Layout/FullLoading'
import Header from '@/components/Layout/Header'
import Layout from '@/components/Layout/Layout'
import useReservationInfo from '@/hooks/useReservationInfo'
import { ACCOUNT } from '@/meta'
import { numberWithCommas } from '@/utils/numbers'
import { dateTextForPeriod } from '@/utils/text'

function ReservationCompletePage() {
  const { classId } = useParams()
  if (!classId){
    return <>요청 URL이 잘못되었습니다.</>
  }
  return <ReservationComplete classId={classId} />
}

function ReservationComplete({ classId }: { classId: string }) {
  const { state } = useLocation() as { state: { applicantPhoneNumber: '' } }
  if (!state || !state.applicantPhoneNumber){
    throw Error('!state || !state.applicantPhoneNumber')
  }
  const { data: ClassReservationConfirm } = useReservationInfo({
    classId,
    applicantPhoneNumber: state.applicantPhoneNumber
  })

  if (!ClassReservationConfirm){
    return <FullLoading />
  }

  const { requiredClassFee, classMainName, classProgressDate, applicantName, requestPersonnel } = ClassReservationConfirm

  return (
    <Layout>
      <Header
        title="예약 신청 완료"
        timing={1}
        fadein
      />
      <Content>
        <PageBlockCenter>
          <PageImage
            alt=""
            src='/images/calendar.png'
            width={70}
            height={70}
          />
          <H2>예약 신청이 완료되었습니다.</H2>
          <Description>{'예약 신청 후 30분 이내 아래 계좌를 통해 입금 진행\n해주셔야 예약 완료가 됩니다. (미입금시 예약 취소됨)'}</Description>
          <Title>{`${ACCOUNT}\n입금금액 ${numberWithCommas(requiredClassFee * requestPersonnel)}`}</Title>
          <InfoList>
            <InfoListItem>
              <div>클래스명</div>
              <div>{classMainName}</div>
            </InfoListItem>
            <InfoListItem>
              <div>일시</div>
              {classProgressDate.map(({ classRoundStartDate: start, classRoundEndDate: end }, index) => (
                <div key={index}>{index + 1}회차 {dateTextForPeriod(start, end)}</div>
              ))}
            </InfoListItem>
            <InfoListItem>
              <div>신청자 이름(대표자)</div>
              <div>{applicantName}</div>
            </InfoListItem>
            <InfoListItem>
              <div>예약인원</div>
              <div>{requestPersonnel}</div>
            </InfoListItem>
          </InfoList>
          <Title>{'정상적으로 입금이 진행된 후에는\n입력한 연락처로 예약 확정 알림이 전달됩니다.'}</Title>
        </PageBlockCenter>
      </Content>

      <Link
        to='/'
        replace>
        <CTAButton
          disabled={false}
          label="메인으로 가기"
        />
      </Link>
    </Layout>
  )
}

export default ReservationCompletePage

const Description = styled(H3)`
  margin-top: 0;
  font-size: 15px;
  line-height: 30px;
  white-space: pre;
`
const Title = styled.div`
  font-size: 16px;
  white-space: pre;

  color: ${({ theme }) => theme.mainGreen};
  margin-top: 48px;
  line-height: 25px;
  font-weight: 600;
`

const InfoList = styled.ul`
  margin-top: 48px;
  padding: 60px 47px;
  border-radius: 14px;
  background: ${({ theme }) => theme.white};  
  color: ${({ theme }) => theme.white};

  font-size: 12px;
  font-weight: 500;
`
const InfoListItem = styled.li`
  text-align: left;

  line-height: 18px;
  margin-bottom: 24px;
  &:last-of-type{
    margin-bottom: 0;
  }
  
`
