import { useMutation } from 'react-query'

import { postReservation } from '@/apis/reservation/postReservation'

export default function usePostReservation() {
  return useMutation<void, unknown, {
    classId: string;
    applicantName: string;
    applicantPhoneNumber: string;
    gender: 'MALE' | 'FEMALE' | 'NONE';
    requestPersonnel: number;
    /* TODO: 약관코드타입 정의 필요 */
    // RESERVATION_TERMSOFSERVICE | RESERVATION_PRIVACYPOLICY
    termCodes: string[]; // 약관코드
  }>(
    async ({
      classId,
      applicantName,
      applicantPhoneNumber,
      gender,
      requestPersonnel,
      termCodes
    }) => {

      await postReservation({
        classId,
        applicantName,
        applicantPhoneNumber,
        gender,
        requestPersonnel,
        termCodes
      })
    })
}
