import { nanoid } from 'nanoid'

type SetPaymentInfoToLocalStorageType = {
  type?: string;
  count?: number;
  amount?: number;
  orderName?: string;
  orderId?: string;
  customerName?: string;
  customerId?: string;
  roomId?: number;
  teaTogetherId?: number;
  festivalId?: number;
}
export const setPaymentInfoToLocalStorage = ({ type, count, roomId, teaTogetherId, customerId, customerName, festivalId, orderId, orderName, amount }: SetPaymentInfoToLocalStorageType) => {
  //필수
  if (type) localStorage.setItem(PaymentStorageName.type, type)
  if (count) localStorage.setItem(PaymentStorageName.count, String(count))
  if (amount) localStorage.setItem(PaymentStorageName.amount, String(amount))
  if (customerId) localStorage.setItem(PaymentStorageName.customerId, customerId)
  if (customerName) localStorage.setItem(PaymentStorageName.customerName, customerName)
  if (orderName) localStorage.setItem(PaymentStorageName.orderName, orderName)
  if (orderId) localStorage.setItem(PaymentStorageName.orderId, orderId)
  //선택적
  if (roomId) localStorage.setItem(PaymentStorageName.roomId, String(roomId))
  if (teaTogetherId) localStorage.setItem(PaymentStorageName.teaTogetherId, String(teaTogetherId))
  if (festivalId) localStorage.setItem(PaymentStorageName.festivalId, String(festivalId))
}

export const deletePaymentInfoOfLocalStorage = () => {
  localStorage.removeItem(PaymentStorageName.type)
  localStorage.removeItem(PaymentStorageName.count)
  localStorage.removeItem(PaymentStorageName.amount)
  localStorage.removeItem(PaymentStorageName.customerId)
  localStorage.removeItem(PaymentStorageName.customerName)
  localStorage.removeItem(PaymentStorageName.orderId)
  localStorage.removeItem(PaymentStorageName.orderName)
  localStorage.removeItem(PaymentStorageName.roomId)
  localStorage.removeItem(PaymentStorageName.teaTogetherId)
  localStorage.removeItem(PaymentStorageName.festivalId)
}

export enum PaymentStorageName {
  type = 'ty',
  count = 'ct',
  amount = 'at',
  customerId = 'ci',
  customerName = 'cn',
  orderName = 'on',
  orderId = 'oi',
  roomId = 'ri',
  teaTogetherId = 'ti',
  festivalId = 'fi'
}
export enum PaymentType {
  festival = 'festival',
  teaTogether = 'tea-together',
  teahouse = 'teahouse'
}

//CustomerNameGenerate, OrderNameGenerate
export const customerNameGenerate = (handle: string, userId: number) => {
  console.log(handle)
  return `daru_user_${userId}`
}
export const orderNameGenerate = (product: string, count: number) => {
  return `${product}: ${count}건 예약`
}
//orderId, customerId
export const customerIdGenerate = () => {
  return nanoid()
}
export const orderIdGenerate = () => {
  return nanoid()
}
