import { toggleAuthModal } from '@/components/Modal/AuthModal'

interface JWTTokenPayload {
  exp: number;
}

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem('drat', accessToken)
}
export const storeRefreshToken = (refreshToken: string) => {
  localStorage.setItem('drrt', refreshToken)
}
export const getAccessToken = () => {
  const token = localStorage.getItem('drat') || 'default'
  return token
}
export const getRefreshToken = () => {
  const token = localStorage.getItem('drrt') || 'default'
  return token
}
export const isUserLoggedIn = (): boolean => {
  const token = getAccessToken()
  const length = token.length > 30
  return !!token && length // token이 존재하면 true, 아니면 false 반환
}
export const userLoggedInRequired = (required: 'ABSOLUTE' | 'FLEXIBLE') => {
  if (!isUserLoggedIn()) {
    toggleAuthModal(required)
  }
}

/* 서버 - 인증 문제로 토큰은 로컬 스토리지에 저장
export const storeAccessToken = (accessToken: string) => {
  cookies.set('accessToken', accessToken, {
    path: '/', // 전체 사이트에서 접근 가능하도록 경로 설정
    httpOnly: true, // JavaScript에서 쿠키에 접근 불가능하도록 설정
    secure: true, // HTTPS에서만 쿠키에 접근 가능하도록 설정
    sameSite: 'strict' // 동일 출처 요청에서만 쿠키에 접근 가능하도록 설정
  })
}
export const storeRefreshToken = (refreshToken: string) => {
  cookies.set('refreshToken', refreshToken, {
    path: '/', // 전체 사이트에서 접근 가능하도록 경로 설정
    httpOnly: true, // JavaScript에서 쿠키에 접근 불가능하도록 설정
    secure: true, // HTTPS에서만 쿠키에 접근 가능하도록 설정
    sameSite: 'strict' // 동일 출처 요청에서만 쿠키에 접근 가능하도록 설정
  })
}
// export const getAccessToken = (): string => {
  //   const at = cookies.get('accessToken')
  //   return `Bearer ${at}`
  // }
  export const getAccessToken = (): string => {
    const at = cookies.get('accessToken')
    return at
  }
  export const getRefreshToken = (): string => {
    const rt = cookies.get('refreshToken')
    return rt
  }
*/
const decodeJWT = (accessToken: string): JWTTokenPayload | null => {
  if (!accessToken) {
    return null
  }
  const tokenParts = accessToken.split('.')
  if (tokenParts.length !== 3) {
    throw new Error('Invalid JWT')
  }

  const encodedPayload = tokenParts[1]
  const decodedPayload = atob(encodedPayload)

  const payload: JWTTokenPayload = JSON.parse(decodedPayload)
  return payload
}
export const getPhoneNumberHashed = () => {
  const at = getAccessToken()
  return decodeJWT(at)
}

const extractExpirationTime = (accessToken: string): number | null => {
  try {
    const payload = decodeJWT(accessToken)
    if (payload && payload.exp) {
      return payload.exp
    }
  } catch (error) {
    console.error('Error decoding JWT:', error)
  }

  return null
}
export const deleteToken = () => {
  localStorage.removeItem('drat')
  localStorage.removeItem('drrt')
}

export const validateToken = (token: string) => {
  const expiredtime = extractExpirationTime(token)
  const currenttime = Date.now() / 1000
  if (expiredtime)
    return currenttime < expiredtime
  else
    return false
}
export const validateCurrentAccessToken = () => {
  const at = getAccessToken()
  return validateToken(at)
}
export const validateCurrentRefreshToken = () => {
  const rt = getRefreshToken()
  return validateToken(rt)
}
