import styled from '@emotion/styled'

interface StatusType {
  statusType: 'NOTOPENED' | 'OPENED' | 'COMPLETED' | 'CLOSED' ;
}
const Status = ({ statusType }: StatusType) => {
  return (
    <StatusWrapper type={statusType}>
      {getTextFromStatus(statusType)}
    </StatusWrapper>
  )
}
export default Status

const StatusWrapper = styled.div<{ type: 'NOTOPENED' | 'OPENED' | 'COMPLETED' | 'CLOSED' }>` 
  background-color: ${({ type }) => getBackgroundColorFromStatus(type)};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
`

function getBackgroundColorFromStatus(type: 'NOTOPENED' | 'OPENED' | 'COMPLETED' | 'CLOSED') {
  switch (type){
    case 'NOTOPENED':
      return '#D2DE32'
      break
    case 'OPENED':
      return '#01c897'
      break
    case 'COMPLETED':
      return '#cccccc'
      break
    case 'CLOSED':
      return '#614BC3'
    default:
      return '#cccccc'
  }
}
function getTextFromStatus(type: 'NOTOPENED' | 'OPENED' | 'COMPLETED' | 'CLOSED') {
  switch (type){
    case 'NOTOPENED':
      return '오픈 예정'
      break
    case 'OPENED':
      return '모집 중'
      break
    case 'COMPLETED':
      return '종료'
      break
    case 'CLOSED':
      return '모집 완료'
    default:
      return '다루'
  }
}
